import { CopyDownLineItem } from "components/CopyDownLineItem";
import { InsertLineItem } from "components/InsertLineItem";
import { LineTableCell } from "components/formFields/LineTableCell";
import { DragHandle } from "components/utility/DragHandle";

import { theme } from "SosTheme";

export function LineDragInsertCopy(props) {
  const {
    draggableProvided,
    snapshot,
    insertEmptyLine,
    onCopyDown,
    lineNumber,
    showCopyDown,
  } = props;

  return (
    <LineTableCell
      sx={{
        borderLeft: `1px solid ${theme.palette.lineSeparatorMinor}`,
        position: "relative",
      }}
    >
      {!snapshot.isDragging && (
        <>
          <InsertLineItem onClick={() => insertEmptyLine(lineNumber)} />
          {showCopyDown && <CopyDownLineItem onCopyDown={onCopyDown} />}
        </>
      )}
      <div {...draggableProvided.dragHandleProps}>
        <DragHandle />
      </div>
    </LineTableCell>
  );
}
