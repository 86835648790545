import { Box, Typography } from "@mui/material";

export function GroupingBox(props) {
  const { heading, children, error, errorText, width } = props;

  return (
    <Box mt={0.5}>
      <Box
        sx={{
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: error ? "warning.error" : "groupingBracket",
          borderRadius: "6px",
          width: width || "inherit",
          position: "relative",
        }}
      >
        <Typography
          component="span"
          className="groupingBoxLabel"
          sx={{
            color: error ? "warning.error" : "primary.main",
            position: "absolute",
            top: "-9px",
            left: "0.6rem",
            fontSize: "0.65rem",
            backgroundColor: "multiPanelBackground",
            px: "0.2rem",
          }}
        >
          {heading}
        </Typography>
        <Box sx={{ p: "0.5rem" }}>{children}</Box>
      </Box>
      <Typography>{errorText}</Typography>
    </Box>
  );
}
