import { useSelector } from "react-redux";

import { TOTALS_SALES_COLUMN_DIMENSION } from "appConfig";

import { Decimal, Money } from "classes/DecimalClasses";

import { TotalsLine as TotalsSalesLine } from "components/formFields/Totals/Sales/TotalsLine";
import { ToggleTaxDiscount } from "components/formFields/Totals/ToggleTaxDiscount";

import { i18n } from "services/i18nService";
import {
  globalTaxCalculation,
  nonGlobalTaxCalculation,
} from "services/sosInventoryService/salesTransaction/domainLogic";
import {
  currencyMinMaxFractionDigits,
  formatPercentDigits,
} from "services/utility/formatting";
import { LOCALIZATION } from "services/utility/localization";
import { isNonZeroDecimalOrMoney } from "services/utility/misc";
import {
  getPercentFromAmount,
  getAmountFromPercent,
} from "services/utility/misc";

import { TotalsDiscount } from "views/SalesTransactions/TotalsDiscount";
import { TotalsShipping } from "views/SalesTransactions/TotalsShipping";
import { TotalsTax } from "views/SalesTransactions/TotalsTax";

export function LineItemTotals(props) {
  const {
    totals,
    record,
    objectType,
    customer,
    setRecord,
    handleInputFieldChange,
    taxCodes,
  } = props;

  // SETTINGS
  const globalEnabled = useSelector(
    (state) => state.userCompanySettings.settings.globalEnabled
  );
  const taxEnabled = useSelector(
    (state) => state.userCompanySettings.settings.taxEnabled
  );
  const discountEnabled = useSelector(
    (state) => state.userCompanySettings.settings.discountEnabled
  );
  const shippingEnabled = useSelector(
    (state) => state.userCompanySettings.settings.shippingEnabled
  );
  const shippingTaxable = useSelector(
    (state) => state.userCompanySettings.settings.shippingTaxable
  );

  function getShowTax(taxAmount) {
    if (taxEnabled || record.taxUpdated) {
      return true;
    }
    return globalEnabled
      ? isNonZeroDecimalOrMoney(totals.globalTax)
      : isNonZeroDecimalOrMoney(taxAmount);
  }

  const taxCalculator = globalEnabled
    ? globalTaxCalculation
    : nonGlobalTaxCalculation;
  const tax =
    customer?.tax.taxable || globalEnabled
      ? taxCalculator(
          globalEnabled ? totals.globalTax : totals.taxableAmount,
          record.discountTaxable,
          record.discountAmount,
          record.taxPercent,
          shippingTaxable,
          record.shippingAmount
        )
      : Money.ZERO;

  // only show the tax, discount, and shipping UIs if their respective
  // setting enabled flag is true or the value is not equal to zero
  const showTax = getShowTax(tax);
  const showDiscount =
    discountEnabled ||
    record.discountUpdated ||
    isNonZeroDecimalOrMoney(record.discountAmount);
  const showShipping =
    shippingEnabled ||
    record.shippingUpdated ||
    isNonZeroDecimalOrMoney(record.shippingAmount);

  function getTotalAmount() {
    return totals.amount
      .plus(tax)
      .plus(record.shippingAmount)
      .plus(record.discountAmount);
  }

  function getLocalizedValue(value) {
    return currencyMinMaxFractionDigits(
      value,
      LOCALIZATION.locale,
      LOCALIZATION.currencyMinimumFractionDigits,
      LOCALIZATION.currencyMinimumFractionDigits,
      true
    );
  }

  const localizedAmount = getLocalizedValue(totals.amount);
  const localizedTax = getLocalizedValue(tax);
  const localizedTotal = getLocalizedValue(getTotalAmount());
  const localizedDiscountPercent = record.discountPercent
    ? formatPercentDigits(record.discountPercent, LOCALIZATION.locale)
    : record.discountPercent;
  const localizedTaxPercent = formatPercentDigits(
    record.taxPercent,
    LOCALIZATION.locale
  );

  function onDiscountPercentChange(name, value) {
    const discountPercent = new Decimal(+value);
    const discountAmount = getAmountFromPercent(discountPercent, totals.amount);
    setRecord((prevRecord) => ({
      ...prevRecord,
      discountPercent,
      discountAmount: isNonZeroDecimalOrMoney(discountAmount)
        ? discountAmount.times(new Decimal(-1))
        : discountAmount,
      discountType: name,
      discountUpdated: true,
    }));
  }

  function onDiscountAmountChange(name, value) {
    const discountAmount = new Decimal(+value).abs().times(new Decimal(-1));
    const discountPercent = getPercentFromAmount(
      discountAmount.times(new Decimal(-1)),
      totals.amount
    );
    setRecord((prevRecord) => ({
      ...prevRecord,
      discountAmount,
      discountPercent,
      discountType: name,
      discountUpdated: true,
    }));
  }

  function onTaxPercentChange(_, value) {
    const taxPercent = new Decimal(+value);
    const taxAmount = taxPercent.times(totals.amount).times(new Decimal(0.01));
    setRecord((prevRecord) => ({
      ...prevRecord,
      taxPercent,
      taxAmount,
      taxUpdated: true,
    }));
  }

  function setDiscountTaxable(discountTaxable) {
    setRecord((prevRecord) => ({ ...prevRecord, discountTaxable }));
  }

  return (
    <div style={{ display: "flex", width: TOTALS_SALES_COLUMN_DIMENSION }}>
      <div>
        <TotalsSalesLine
          label={i18n("global.Subtotal")}
          value={localizedAmount}
          dataTesting="subTotal"
        />
        {shippingTaxable && showShipping && (
          <TotalsShipping
            onValueChange={handleInputFieldChange}
            value={record.shippingAmount}
          />
        )}
        {record.discountTaxable ? (
          <>
            {showDiscount && (
              <TotalsDiscount
                onAmountChange={onDiscountAmountChange}
                onPercentChange={onDiscountPercentChange}
                value={record.discountAmount}
                percentValue={localizedDiscountPercent}
              />
            )}
            {showTax && (
              <TotalsTax
                onPercentChange={onTaxPercentChange}
                value={localizedTax}
                percentValue={localizedTaxPercent}
                taxCode={record.taxCode}
                setRecord={setRecord}
                objectType={objectType}
                taxCodes={taxCodes}
              />
            )}
          </>
        ) : (
          <>
            {showTax && (
              <TotalsTax
                onPercentChange={onTaxPercentChange}
                value={localizedTax}
                percentValue={localizedTaxPercent}
                taxCode={record.taxCode}
                setRecord={setRecord}
                objectType={objectType}
                taxCodes={taxCodes}
              />
            )}
            {showDiscount && (
              <TotalsDiscount
                onAmountChange={onDiscountAmountChange}
                onPercentChange={onDiscountPercentChange}
                value={record.discountAmount}
                percentValue={localizedDiscountPercent}
              />
            )}
          </>
        )}
        {!shippingTaxable && showShipping && (
          <TotalsShipping
            onValueChange={handleInputFieldChange}
            value={record.shippingAmount}
          />
        )}
        <TotalsSalesLine
          label={i18n("global.Total")}
          value={localizedTotal}
          dataTesting="total"
        />
      </div>
      {showDiscount && showTax && (
        <div style={{ marginTop: shippingTaxable ? "7.6rem" : "4.5rem" }}>
          <ToggleTaxDiscount
            discountTaxable={record.discountTaxable}
            setDiscountTaxable={setDiscountTaxable}
          />
        </div>
      )}
    </div>
  );
}
