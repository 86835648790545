import { i18n } from "services/i18nService";

import {
  NONE_GROUP_BY_OPTION,
  GROUP_BY_ITEM_OR_VENDOR_OPTIONS,
  SUB_COMPONENT,
} from "views/Reports/reportConstants";

import { ALL_FILTER_OPTION } from "appConstants";

const STATIC_ORDER_TYPE = { id: "IR" };

export const PurchaseVariances = {
  userHasSufficientPrivilege: (p) => p.receiveInventory && p.viewCosts,
  planIncludesReport: () => true,
  relatedLists: ["vendor", "location"],
  customFieldObjectTypes: ["itemreceipt", "item"],
  columns: [
    { apiName: "OrderNumber", label: "Receipt #", default: true },
    { apiName: "Receipt Date", default: true },
    { apiName: "Vendor", default: true },
    { apiName: "Item", default: true },
    { apiName: "Location" },
    { apiName: "Description" },
    { apiName: "Quantity", number: true },
    { apiName: "UOM" },
    { apiName: "Customer" },
    { apiName: "VendorPartNumber", label: "Vendor Part #" },
    { apiName: "Comment" },
    { apiName: "On Hand", number: true },
    { apiName: "Standard Cost", number: true, alwaysShow: true },
    { apiName: "Actual Cost", number: true, alwaysShow: true },
    { apiName: "Variance", number: true, alwaysShow: true },
  ],
  settings: [
    {
      apiName: "date1",
      macroApiName: "date1Macro",
      headerText: i18n("filter.heading.from"),
      component: "DateFilter",
      label: i18n("reports.FilterLabel.FromDate"),
      defaultValue: null,
      datePrefix: "startOf",
      type: "filter",
    },
    {
      apiName: "date1Macro",
      defaultValue: null,
      component: SUB_COMPONENT,
      type: "filter",
    },
    {
      apiName: "date2",
      macroApiName: "date2Macro",
      component: "DateFilter",
      label: i18n("reports.FilterLabel.ToDate"),
      datePrefix: "endOf",
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "date2Macro",
      defaultValue: null,
      component: SUB_COMPONENT,
      type: "filter",
    },
    {
      label: i18n("filter.Location"),
      component: "LocationFilter",
      apiName: "locations",
      defaultValue: [ALL_FILTER_OPTION],
      includeAllOption: true,
      type: "filter",
    },
    {
      label: i18n("filter.Vendor"),
      component: "VendorFilter",
      apiName: "vendor",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.ItemNameContains"),
      component: "FrmTextField",
      apiName: "nameContains",
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "showFromTo",
      defaultValue: false,
      label: i18n("reports.OptionLabel.ShowFromAndToDates"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "groupBy",
      label: i18n("reports.OptionLabel.GroupBy"),
      component: "FrmButtonGroup",
      defaultValue: NONE_GROUP_BY_OPTION,
      options: GROUP_BY_ITEM_OR_VENDOR_OPTIONS,
      type: "option",
    },
    {
      apiName: "header",
      defaultValue: "",
      label: i18n("global.Header"),
      component: "FrmTextField",
      type: "option",
    },
    {
      apiName: "footer",
      defaultValue: "",
      label: i18n("global.Footer"),
      component: "FrmTextField",
      type: "option",
    },
    {
      apiName: "orderType",
      defaultValue: STATIC_ORDER_TYPE,
      type: "static",
    },
  ],
};
