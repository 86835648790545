import { SETTINGS as company } from "views/Configuration/settings/company";
import { SETTINGS as fulfillment } from "views/Configuration/settings/fulfillment";
import { SETTINGS as inventory } from "views/Configuration/settings/inventory";
import { SETTINGS as purchasing } from "views/Configuration/settings/purchasing";
import { SETTINGS as rentals } from "views/Configuration/settings/rentals";
import { SETTINGS as sales } from "views/Configuration/settings/sales";

export const SETTINGS = {
  company,
  inventory,
  purchasing,
  fulfillment,
  rentals,
  sales,
};
