import { useState, useEffect } from "react";

import { TableSortLabel } from "@mui/material";

import { DEBOUNCE_WAIT } from "appConfig";

import { ItemSelect } from "components/formFields/ItemSelect";
import { LineTableHeadingCell } from "components/formFields/LineTableCell";

import { i18n } from "services/i18nService";
import { descriptionForPurchasingTransactions } from "services/sosInventoryService/purchasingTransaction/domainLogic";
import { getItemQuicklist } from "services/sosInventoryService/sosApi";
import { setPageDirty } from "services/utility/edit";
import { checkForUnexpectedProps } from "services/utility/misc";

const sx = { "& .MuiTableSortLabel-icon": { fontSize: "14px" } };

export function LineInventoryItemHeading(props) {
  const { lineHandler, label = i18n("columns.Item") } = props;
  const [sort, setSort] = useState(false);

  function handleSort() {
    const newSort = sort !== "asc" ? "asc" : "desc";
    setSort(newSort);
    setPageDirty();
    lineHandler({ type: "sort", direction: newSort, sortBy: "name" });
  }

  return (
    <LineTableHeadingCell onClick={handleSort} sx={{ cursor: "pointer" }}>
      <TableSortLabel
        sx={sx}
        active={Boolean(sort)}
        direction={sort ? sort : "asc"}
      >
        {label}
      </TableSortLabel>
    </LineTableHeadingCell>
  );
}

export function LineInventoryItem(props) {
  const {
    line,
    itemsCount,
    items: externalItems,
    addItem,
    onValueChange,
    allowExpand,
    expandItemGroup,
    lineLoading,
    itemFormType,
    error,
    ...unexpected
  } = props;
  checkForUnexpectedProps("LineInventoryItem", unexpected);

  const [items, setItems] = useState(externalItems);
  const [timeoutId, setTimeoutId] = useState();

  useEffect(() => setItems(externalItems), [externalItems]);

  const allowApi = items && items.length < itemsCount;

  function handleInputChange(query, reason) {
    // checking for reset avoids running this API call on initial component
    // load
    if (reason === "reset") {
      return;
    }
    clearTimeout(timeoutId);
    setTimeoutId(
      setTimeout(async () => {
        const { data } = await getItemQuicklist({
          formType: itemFormType,
          query,
        });
        setItems(data);
      }, DEBOUNCE_WAIT)
    );
  }

  // does items contain the item referenced? if not, add it (this can happen if
  // an inventory item was looked up by name, barcode, or SKU, which ignores the
  // "show on purchasing forms" flag)
  let itemsPlus = items ? [...items] : [];
  if (line.item) {
    const foundItem = items?.find((item) => item.id === line.item.id);
    if (!foundItem) {
      itemsPlus.unshift({
        id: line.item.id,
        name: line.item.name,
        description: descriptionForPurchasingTransactions(line.item),
      });
    }
  }

  return (
    <ItemSelect
      loading={lineLoading}
      line={line}
      addItem={addItem}
      options={items ? itemsPlus : null}
      value={line.item}
      onValueChange={onValueChange}
      allowExpand={allowExpand}
      expandItemGroup={expandItemGroup}
      onInputChange={(e, value, reason) =>
        allowApi && handleInputChange(value, reason)
      }
      onBlur={() => allowApi && setItems(externalItems)}
      usingApi={allowApi}
      error={error}
    />
  );
}
