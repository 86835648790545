import { Decimal } from "classes/DecimalClasses";

export function afterGet(record) {
  let newRecord = { ...record };
  const { inputs, outputs } = record;
  inputs.forEach((input, i) => {
    newRecord.inputs[i].quantity = new Decimal(input.quantity || 0);
  });
  outputs.forEach((output, i) => {
    newRecord.outputs[i].quantity = new Decimal(output.quantity || 0);
  });
  return newRecord;
}
