import { useState, useEffect } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { TextField } from "@mui/material";

import { Money } from "classes/DecimalClasses";

import {
  outlinedStyles,
  inputPropStyles,
} from "components/formFields/lineItemFieldStyle";

import { currencyMinMaxFractionDigits } from "services/utility/formatting";
import { LOCALIZATION } from "services/utility/localization";
import { isZero } from "services/utility/misc";

export function RowMoneyField(props) {
  const { dataIndex, name, onBlur: externalBlur, value: initialValue } = props;
  const [focused, setFocused] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(
      currencyMinMaxFractionDigits(
        initialValue,
        LOCALIZATION.locale,
        LOCALIZATION.currencyMinimumFractionDigits,
        LOCALIZATION.currencyMinimumFractionDigits,
        false
      )
    );
  }, [initialValue]);

  function onChange(e) {
    setUpdated(true);
    setValue(e.target.value);
  }

  function onBlur() {
    setFocused(false);
    if (updated) {
      externalBlur(name, value ? new Money(value) : value, dataIndex);
    }
  }

  function getValue() {
    //added to update the input on focus to not show the default zero value
    if (focused && isZero(value) && !updated) {
      return "";
    }
    return value;
  }

  return (
    <TextField
      autoComplete="off"
      value={getValue()}
      variant="outlined"
      onFocus={() => setFocused(true)}
      name={name}
      type="number"
      margin="dense"
      onChange={onChange}
      onBlur={onBlur}
      fullWidth
      inputProps={{ style: { textAlign: "right" } }}
      sx={{ marginTop: 0, marginBottom: 0, ...outlinedStyles }}
      InputProps={{ sx: { borderRadius: "6px", ...inputPropStyles } }}
      SelectProps={{ IconComponent: KeyboardArrowDownIcon }}
    />
  );
}
