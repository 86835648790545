import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";

import { i18n } from "services/i18nService";
import { checkForUnexpectedProps } from "services/utility/misc";

import { useRelated } from "hooks/useRelated";

export function FrmAssignedTo(props) {
  const { value, onValueChange, error, maxWidth, ...unexpected } = props;
  checkForUnexpectedProps("FrmAssignedTo", unexpected);

  const retrievedUsers = useRelated("user");

  function getValue() {
    if (value?.name) {
      return value;
    }
    return retrievedUsers
      ? retrievedUsers?.find(({ id }) => id === value?.id)
      : null;
  }

  return (
    <FrmSelectFromObjects
      name="assignedToUser"
      label={i18n("frmLabel.AssignedToUser")}
      value={getValue()}
      onValueChange={onValueChange}
      options={retrievedUsers}
      error={error}
      maxWidth={maxWidth}
    />
  );
}
