import { Decimal } from "classes/DecimalClasses";

import { i18n } from "services/i18nService";

import { NONE_GROUP_BY_OPTION } from "views/Reports/reportConstants";

import { ALL_FILTER_OPTION, ITEM_QUICKLIST_CASES } from "appConstants";

const GROUP_BY_OPTIONS = [
  NONE_GROUP_BY_OPTION,
  { id: "Component", name: i18n("reports.OptionLabel.Component") },
];

export const ExpandedBOM = {
  userHasSufficientPrivilege: (p) =>
    (p.viewInventory || p.buildAssemblies || p.viewItems || p.editItems) &&
    p.viewCosts,
  planIncludesReport: () => true,
  relatedLists: ["location"],
  customFieldObjectTypes: "item",
  requiredFiltersNotSetMsg: i18n("reports.ItemFilterRequired"),
  columns: [
    { apiName: "ItemNumber", label: "Item #" },
    { apiName: "Component", default: true },
    { apiName: "Category" },
    { apiName: "Description", default: true },
    { apiName: "Purchase Description" },
    { apiName: "SKU" },
    { apiName: "Barcode" },
    { apiName: "Vendor", default: true },
    { apiName: "VendorPartNumber", label: "Vendor Part #" },
    { apiName: "Sales Price", number: true },
    { apiName: "Purchase Cost", number: true },
    { apiName: "Default Bin" },
    { apiName: "Asset Account" },
    { apiName: "Lead Time", number: true },
    { apiName: "Reorder Point", number: true },
    { apiName: "Max Stock", number: true },
    { apiName: "On Hand", default: true, number: true },
    { apiName: "Available", number: true },
    { apiName: "On SO", number: true },
    { apiName: "On SR", number: true },
    { apiName: "On PO", number: true },
    { apiName: "On RMA", number: true },
    { apiName: "On WO", number: true },
    { apiName: "On Rental", number: true },
    { apiName: "Notes", default: true },
  ],
  settings: [
    {
      label: i18n("filter.Location"),
      component: "LocationFilter",
      apiName: "locations",
      defaultValue: [ALL_FILTER_OPTION],
      includeAllOption: true,
      type: "filter",
    },
    {
      label: i18n("filter.Item"),
      component: "ItemFilter",
      apiName: "item",
      defaultValue: null,
      type: "filter",
      required: true,
      itemType: ITEM_QUICKLIST_CASES.ASSEMBLY_AND_KIT,
    },
    {
      label: i18n("reports.FilterLabel.Quantity"),
      component: "FrmTextField",
      apiName: "quantity",
      defaultValue: new Decimal(1),
      number: true,
      type: "filter",
    },
    {
      apiName: "showFullOnly",
      defaultValue: false,
      label: i18n("reports.OptionLabel.ExpandSubAssemblies"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "highlightReorders",
      defaultValue: false,
      label: i18n("reports.OptionLabel.HighlightReorders"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "groupBy",
      label: i18n("reports.OptionLabel.GroupBy"),
      component: "FrmButtonGroup",
      defaultValue: NONE_GROUP_BY_OPTION,
      options: GROUP_BY_OPTIONS,
      type: "option",
    },
    {
      apiName: "header",
      defaultValue: "",
      label: i18n("global.Header"),
      component: "FrmTextField",
      type: "option",
    },
    {
      apiName: "footer",
      defaultValue: "",
      label: i18n("global.Footer"),
      component: "FrmTextField",
      type: "option",
    },
    {
      apiName: "suppressIfEmpty",
      defaultValue: false,
      type: "static",
    },
  ],
};
