import { i18n } from "services/i18nService";
import { formatLinkField, formatPercent } from "services/utility/formatting";

export const columnInfo = [
  {
    name: "name",
    heading: i18n("columns.Name"),
    formatFunc: formatLinkField,
    fixed: true,
    linkField: true,
    sortable: true,
  },
  {
    heading: i18n("columns.Description"),
    apiName: "description",
    sortable: true,
  },
  {
    name: "yield",
    heading: i18n("columns.Yield"),
    formatFunc: formatPercent,
    align: "right",
    sortable: true,
  },
];

export const LINE_ACTIONS = ["delete"];
export const BATCH_ACTIONS = ["delete"];

export function getEmptyRecord() {
  return {};
}
