import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";

import { i18n } from "services/i18nService";

import { useLocations } from "hooks/useLocations";

export function FrmLocation(props) {
  const {
    value,
    name = "location",
    label = i18n("frmLabel.Location"),
    onlyBinTracked = false,
    onValueChange,
    disabled,
    error,
    width,
    sx,
  } = props;

  const locations = useLocations(onlyBinTracked);

  return (
    <FrmSelectFromObjects
      disableClearable
      name={name}
      options={locations}
      value={value}
      disabled={disabled}
      label={label}
      onValueChange={onValueChange}
      error={error}
      sx={{ width, maxWidth: "100%", ...sx }}
      dataTesting={name}
    />
  );
}
