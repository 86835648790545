import { i18n } from "services/i18nService";

import {
  GROUP_BY_ITEM_OR_CUSTOMER_OPTIONS,
  NONE_GROUP_BY_OPTION,
  SUB_COMPONENT,
} from "views/Reports/reportConstants";

import { ALL_FILTER_OPTION } from "appConstants";

const STATIC_ORDER_TYPE = { id: "Rental" };

export const OpenRentalDetail = {
  userHasSufficientPrivilege: (p) => p.viewRentals || p.editRentals,
  planIncludesReport: (plus, pro) => plus || pro,
  relatedLists: ["customer", "location"],
  customFieldObjectTypes: ["rental", "item"],
  columns: [
    { apiName: "OrderNumber", label: "Rental #", default: true },
    { apiName: "Rental Date", default: true },
    { apiName: "Customer" },
    { apiName: "Item", default: true },
    { apiName: "Location" },
    { apiName: "Description", default: true },
    { apiName: "Rented", default: true },
    { apiName: "Returned" },
    { apiName: "Outstanding", default: true },
    { apiName: "UOM" },
    { apiName: "Due Date" },
    { apiName: "Comment" },
    { apiName: "On Hand" },
    { apiName: "Vendor" },
    { apiName: "VendorPartNumber", label: "Vendor Part #" },
    { apiName: "Rented", number: true, alwaysShow: true },
    { apiName: "Returned", number: true, alwaysShow: true },
    { apiName: "Outstanding", number: true, alwaysShow: true },
  ],
  settings: [
    {
      apiName: "date1",
      macroApiName: "date1Macro",
      headerText: i18n("filter.heading.from"),
      component: "DateFilter",
      label: i18n("reports.FilterLabel.FromDate"),
      defaultValue: null,
      datePrefix: "startOf",
      type: "filter",
    },
    {
      apiName: "date1Macro",
      defaultValue: null,
      component: SUB_COMPONENT,
      type: "filter",
    },
    {
      apiName: "date2",
      macroApiName: "date2Macro",
      component: "DateFilter",
      label: i18n("reports.FilterLabel.ToDate"),
      datePrefix: "endOf",
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "date2Macro",
      defaultValue: null,
      component: SUB_COMPONENT,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.Location"),
      component: "LocationFilter",
      apiName: "locations",
      defaultValue: [ALL_FILTER_OPTION],
      includeAllOption: true,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.ItemNameContains"),
      component: "FrmTextField",
      apiName: "nameContains",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.Customer"),
      component: "CustomerFilter",
      apiName: "customer",
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "highlightReorders",
      defaultValue: true,
      label: i18n("reports.OptionLabel.HighlightPastDueDates"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "showFromTo",
      defaultValue: false,
      label: i18n("reports.OptionLabel.ShowFromAndToDates"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "groupBy",
      label: i18n("reports.OptionLabel.GroupBy"),
      component: "FrmButtonGroup",
      defaultValue: NONE_GROUP_BY_OPTION,
      options: GROUP_BY_ITEM_OR_CUSTOMER_OPTIONS,
      type: "option",
    },
    {
      apiName: "header",
      defaultValue: "",
      label: i18n("global.Header"),
      component: "FrmTextField",
      type: "option",
    },
    {
      apiName: "footer",
      defaultValue: "",
      label: i18n("global.Footer"),
      component: "FrmTextField",
      type: "option",
    },
    {
      apiName: "orderType",
      defaultValue: STATIC_ORDER_TYPE,
      type: "static",
    },
  ],
};
