import { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { Box, Paper } from "@mui/material";

import { Header } from "components/ListPage/Header";
import { List } from "components/ListPage/List/List";
import { Widgets } from "components/ListPage/Widgets/Widgets";
import { FILTERS } from "components/ListPage/listConfig";
import {
  listPageStyles,
  configurationListPageStyles,
} from "components/ListPage/styles";
import { FormErrors } from "components/utility/FormErrors";
import { Loading } from "components/utility/Loading";

import {
  getListPageRecords,
  getById,
} from "services/sosInventoryService/sosApi";
import {
  loadListSettings,
  saveListSettings,
} from "services/sosInventoryService/userListSettings";
import { calendarFormat } from "services/utility/dates";
import { setPageDirty } from "services/utility/edit";
import { LOCALIZATION } from "services/utility/localization";
import { showClosedToStatus } from "services/utility/misc";

import { useCustomFieldDefinitions } from "hooks/useCustomFieldDefinitions";
import { useErrors } from "hooks/useErrors";
import { useFormTemplates } from "hooks/useFormTemplates";
import { useListPageColumns } from "hooks/useListPageColumns";

import { getConfigFromFullString } from "views/Calendar/calendarConfig";

import {
  loadingIndicatorOn,
  loadingIndicatorOff,
} from "globalState/loadingSlice";

import { OBJECT_TYPES } from "appConstants";

const STATUS_OBJECTS = Object.keys(FILTERS).filter((object) =>
  FILTERS[object].includes("status")
);

// this object is to track the Object Types that have their "location"
// filter set in v8 as "Location" but need their query param mapped to
// "toLocationId"
const toLocationObjects = [OBJECT_TYPES.RENTAL_RETURN.fullString];

export const ListPageState = createContext();

export function ListPage(props) {
  const { objectType, identifierProperty, isConfigurationList } = props;

  // OTHER SETUP
  const customFieldDefinitions = useCustomFieldDefinitions(objectType);
  const { allColumns, columnConfig, fixedColumns } = useListPageColumns(
    objectType,
    customFieldDefinitions
  );

  const { dateFormat } = LOCALIZATION;
  const dispatch = useDispatch();
  const location = useLocation();

  const userId = useSelector(
    (state) => state.userCompanySettings.company.userId
  );
  const isLoading = useSelector((state) => state.loading.list);

  const [selectAllState, setSelectAllState] = useState(false);

  const [retrievalParams, setRetrievalParams] = useState(null);

  const [records, setRecords] = useState(null);
  const [totalRecords, setTotalRecords] = useState(null);

  const [columnParams, setColumnParams] = useState(null);

  const [checkedIds, setCheckedIds] = useState([]);

  const { errors, setErrors } = useErrors();

  const formTemplates = useFormTemplates(objectType);

  const recordsChanged = useSelector((state) => state.recordsChanged);

  // this code block checks for an id from "Save and go to" from an edit page;
  // it may also be useful for filtering, etc., later
  const queryString = location.search;
  const paramsObject = new URLSearchParams(queryString);

  const id = paramsObject.get("id");
  const status = paramsObject.get("status");
  const fromCalendar =
    paramsObject.get("fromCalendar") === "true" ? true : false;
  const calendarFilterField = paramsObject.get("filterField");
  const calendarDate = paramsObject.get("date");

  // load saved user settings when component loads
  useEffect(() => {
    (async () => {
      const { showFields, maxResults, filterSettings } = await loadListSettings(
        userId,
        objectType,
        dateFormat,
        columnConfig
      );
      setColumnParams(showFields);
      setRetrievalParams((prev) => ({
        ...prev,
        maxResults,
        filterSettings,
        startingRecord: 1,
        columns: showFields,
        persistUpdate: false,
      }));
    })();
  }, [objectType, userId, columnConfig, dateFormat]);

  // fetches list data
  useEffect(() => {
    (async () => {
      if (!retrievalParams || !customFieldDefinitions || !formTemplates) {
        return;
      }
      dispatch(loadingIndicatorOn());
      setPageDirty(false);
      let response;
      // four possibilities:
      // 1. id= was passed: get just that record
      // 2. status= was passed from the dashboard; add it to the filter params
      // 3. fromCalendar=true was passed from the calendar; get certain records
      // 4. none of the above, do standard get with user settings
      // fetch all records, or utilize either the
      // id or status param to return a subset of the data
      switch (true) {
        case Boolean(id):
          response = await getById(objectType, id);
          setRecords(response);
          setTotalRecords(1);
          dispatch(loadingIndicatorOff());
          break;
        case Boolean(status):
          response = await getListPageRecords(objectType, {
            status,
            maxresults: retrievalParams.maxResults,
          });
          setRecords(response.data);
          setTotalRecords(response.totalCount);
          dispatch(loadingIndicatorOff());
          break;
        case fromCalendar:
          const additionalFilters =
            getConfigFromFullString(objectType).listAdditionalFilters;
          response = await getListPageRecords(objectType, {
            [calendarFilterField]: calendarDate,
            ...additionalFilters,
            maxresults: retrievalParams.maxResults,
          });
          setRecords(response.data);
          setTotalRecords(response.totalCount);
          dispatch(loadingIndicatorOff());
          break;
        default:
          if (!retrievalParams) {
            dispatch(loadingIndicatorOff());
            return;
          }
          response = await getListPageRecords(objectType, {
            start: retrievalParams.startingRecord,
            maxresults: retrievalParams.maxResults,

            action: retrievalParams.filterSettings?.Action,
            archived:
              retrievalParams.filterSettings?.ShowArchived?.toLowerCase(),
            categoryId: retrievalParams.filterSettings?.Category,
            customerId: retrievalParams.filterSettings?.Customer,
            direction: retrievalParams.direction,
            from: retrievalParams.filterSettings?.FromDate
              ? calendarFormat(retrievalParams.filterSettings?.FromDate)
              : null,
            fromExpiration: retrievalParams.filterSettings?.FromExpiration
              ? calendarFormat(retrievalParams.filterSettings?.FromExpiration)
              : null,
            locationId: retrievalParams.filterSettings?.Location,
            tolocationId: toLocationObjects.includes(objectType)
              ? retrievalParams.filterSettings?.Location
              : retrievalParams.filterSettings?.ToLocation,
            fromLocationId: retrievalParams.filterSettings?.FromLocation,
            itemId: retrievalParams.filterSettings?.Item,
            query: retrievalParams.query,
            showVariants:
              retrievalParams.filterSettings?.ShowVariants === "HideVariants"
                ? "false"
                : "",
            status: STATUS_OBJECTS.includes(objectType)
              ? retrievalParams.filterSettings?.ShowStatus
              : showClosedToStatus(retrievalParams.filterSettings?.ShowClosed),
            tags: retrievalParams.filterSettings?.Tags,
            to: retrievalParams.filterSettings?.ToDate
              ? calendarFormat(retrievalParams.filterSettings?.ToDate)
              : null,
            toExpiration: retrievalParams.filterSettings?.ToExpiration
              ? calendarFormat(retrievalParams.filterSettings?.ToExpiration)
              : null,
            type: retrievalParams.filterSettings?.Type,
            userId: retrievalParams.filterSettings?.UserId,
            vendorId: retrievalParams.filterSettings?.Vendor,

            sort: retrievalParams.sort,
            columns: columnParams
              ? columnParams
                  .map(({ name, apiColumnName }) => apiColumnName || name)
                  .join(",")
              : "",
          });
          // response can be undefined, if the get was aborted by further
          // typing in the search field
          if (response) {
            setRecords(response.data);
            setTotalRecords(response.totalCount);
            dispatch(loadingIndicatorOff());
          }
      }
    })();
    return () => dispatch(loadingIndicatorOff());
  }, [
    recordsChanged,
    retrievalParams,
    id,
    dispatch,
    objectType,
    customFieldDefinitions,
    formTemplates,
    columnParams,
    status,
    calendarDate,
    calendarFilterField,
    fromCalendar,
  ]);

  // updates user list settings when columns/filters updated
  useEffect(() => {
    if (retrievalParams?.persistUpdate) {
      const { FromDate, FromExpiration, ToDate, ToExpiration } =
        retrievalParams.filterSettings || {};
      const filterSettings = {
        ...retrievalParams.filterSettings,
        FromDate: FromDate ? calendarFormat(FromDate) : FromDate,
        ToDate: ToDate ? calendarFormat(ToDate) : ToDate,
        ToExpiration: ToExpiration
          ? calendarFormat(ToExpiration)
          : ToExpiration,
        FromExpiration: FromExpiration
          ? calendarFormat(FromExpiration)
          : FromExpiration,
      };

      saveListSettings(
        userId,
        objectType,
        [...fixedColumns, ...retrievalParams.columns], // V8BACK: can remove ...fixedColumns when v8 hits end of life
        filterSettings,
        retrievalParams.maxResults
      );
    }
  }, [objectType, retrievalParams, userId, fixedColumns]);

  const listLoading = !records || !formTemplates || !customFieldDefinitions;

  const styles = isConfigurationList
    ? configurationListPageStyles
    : listPageStyles;

  return (
    <Box sx={{ p: 2, pb: 1, display: "grid", ...styles }}>
      <Box sx={{ position: "relative", margin: -2 }}>
        {isConfigurationList && isLoading && <Loading />}
      </Box>
      <Box>
        <Header
          objectType={objectType}
          totalRecords={totalRecords}
          retrievalParams={retrievalParams}
          statusQueryParam={status}
        />
        <FormErrors errors={errors} setErrors={setErrors} />
      </Box>
      <Box>
        <Paper
          elevation={1}
          sx={{
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
            borderBottomColor: "lineSeparatorMinor",
          }}
        >
          {retrievalParams && formTemplates && (
            <Widgets
              objectType={objectType}
              checkedIds={checkedIds}
              setCheckedIds={setCheckedIds}
              setSelectAllState={setSelectAllState}
              retrievalParams={retrievalParams}
              setRetrievalParams={setRetrievalParams}
              totalRecords={totalRecords}
              setColumnParams={setColumnParams}
              allColumns={allColumns}
              formTemplates={formTemplates}
              setErrors={setErrors}
              columnConfig={columnConfig}
            />
          )}
        </Paper>
      </Box>
      <Box boxShadow={1} sx={{ height: "100%", overflow: "auto" }}>
        <Paper
          sx={{
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            height: "100%",
          }}
        >
          {!listLoading && (
            <List
              objectType={objectType}
              identifierProperty={identifierProperty}
              records={records}
              fixedColumns={fixedColumns}
              checkedIds={checkedIds}
              columnConfig={columnConfig}
              selectAllIds={() => setCheckedIds(records.map(({ id }) => id))}
              selectAllState={selectAllState}
              setSelectAllState={setSelectAllState}
              selectNoIds={() => setCheckedIds([])}
              setCheckedIds={setCheckedIds}
              customFieldDefinitions={customFieldDefinitions}
              formTemplates={formTemplates}
              retrievalParams={retrievalParams}
              setRetrievalParams={setRetrievalParams}
              setRecords={setRecords}
              isConfigurationList={isConfigurationList}
            />
          )}
        </Paper>
      </Box>
    </Box>
  );
}
