import {
  getSettings,
  getCompanyUserInfo,
} from "services/sosInventoryService/sosApi";

import globalState from "globalState/globalState";

export const CHECK_LOGIN_STATUS = true;

// gets user/company settings via the API and updates the global Redux store
export async function retrieveSettings(checkLoginStatus = false) {
  if (
    checkLoginStatus &&
    globalState.getState().authentication.isLoggedIn !== "yes"
  ) {
    return false;
  }
  let response;
  response = await getSettings();
  if (response.success) {
    const settings = response.data;
    const { localeCode } = globalState.getState().userCompanySettings.settings;
    globalState.dispatch({
      type: "userCompanySettings/setSettings",
      payload: { ...settings, localeCode },
    });

    // now get company-related properties, which include the user's privileges
    // for this company
    response = await getCompanyUserInfo();
    if (response.success) {
      const { expirationDate, userPrivileges, planLevel, restrictedSalesRep } =
        response.record;
      globalState.dispatch({
        type: "userCompanySettings/setCompany",
        payload: {
          ...globalState.getState().userCompanySettings.company,
          expirationDate,
          userPrivileges,
          planLevel,
          restrictedSalesRep,
        },
      });
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}
