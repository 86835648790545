// See services/sosInventoryService/README.md for information about the structure
// of schema.js files.
import { Decimal, Money } from "classes/DecimalClasses";

import { i18n } from "services/i18nService";
import {
  extractName,
  formatDateTimeToDate,
  formatDocumentInfo,
  formatLinkField,
  truncate,
  formatBooleanToYesOrBlank,
  formatQuantityWithUom,
  extractDescription,
  formatLinkableItem,
} from "services/utility/formatting";
import {
  dateTypeEditStrFunction,
  referenceTypeEditStrFunction,
  arrayOfReferencesTypeEditStrFunction,
  inventoryItemTypeEditStrFunction,
  textTypeEditStrFunction,
  linkedTransactionTypeEditStrFunction,
  numberTypeEditStrFunction,
  decimalTypeEditStrFunction,
} from "services/utility/list";
import { parseHTML } from "services/utility/parsing";
import { starColumn, TYPES } from "services/utility/schema";

import { NO_REF_NUMBER_STRING, NO_COLUMNS } from "appConstants";

export const columnInfo = [
  starColumn,
  {
    name: "number",
    fixed: true,
    heading: i18n("columns.ReferenceNumber"),
    formatFunc: formatLinkField,
    minWidth: "7em",
    linkField: true,
    sortable: true,
  },
  {
    name: "date",
    heading: i18n("columns.Date"),
    formatFunc: formatDateTimeToDate,
    defaultSort: true,
    sortDirection: "asc",
    sortable: true,
    default: true,
  },
  {
    name: "vendor",
    heading: i18n("columns.Vendor"),
    formatFunc: extractName,
    minWidth: "10em",
    maxWidth: "20em",
    sortable: true,
    default: true,
  },
  {
    name: "location",
    heading: i18n("columns.Location"),
    formatFunc: extractName,
    sortable: true,
    default: true,
  },
  {
    name: "comment",
    heading: i18n("columns.Comment"),
    formatFunc: [parseHTML, truncate],
    minWidth: "25em",
    maxWidth: "25em",
    sortable: true,
    default: true,
  },
  {
    name: "documents",
    heading: i18n("columns.Documents"),
    formatFunc: formatDocumentInfo,
    minWidth: "15em",
    default: true,
  },
  {
    name: "department",
    heading: i18n("columns.Department"),
    formatFunc: extractName,
    sortable: true,
  },
  {
    name: "vendorNotes",
    heading: i18n("columns.VendorNotes"),
    formatFunc: [parseHTML, truncate],
    minWidth: "25em",
    maxWidth: "25em",
  },
  {
    name: "trackingNumber",
    heading: i18n("columns.TrackingNumber"),
  },
  {
    name: "shippingMethod",
    heading: i18n("columns.ShipMethod"),
    formatFunc: extractName,
  },
  {
    name: "account",
    heading: i18n("columns.Account"),
    formatFunc: extractName,
    minWidth: "12em",
  },
  {
    name: "hasSignature",
    heading: i18n("columns.Signature"),
    formatFunc: formatBooleanToYesOrBlank,
  },
];

export function getEmptyRecord() {
  return {
    archived: false,
    comment: null,
    createVendorCredit: false,
    customFields: [],
    date: new Date(), // will be populated by useNewRecord
    department: null,
    lines: [],
    location: null,
    number: "",
    starred: 0,
    subTotal: new Money(0),
    summaryOnly: false,
    syncToken: 0,
    taxAmount: new Money(0),
    total: new Money(0),
    trackingNumber: "",
    vendor: null,
    vendorNotes: "",
  };
}

export const LINE_ITEM_METADATA = {
  lineNumber: {},
  item: {
    type: TYPES.reference,
    editStr: inventoryItemTypeEditStrFunction,
    initialValue: { id: null, name: null, description: null },
  },
  vendorPartNumber: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  class: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  bin: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  jobWorkcenter: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  customer: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  taxCode: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  description: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  quantity: {
    type: TYPES.decimal,
    placesRight: 5,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  weight: {
    type: TYPES.decimal,
    placesRight: 5,
    editStr: numberTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  weightunit: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  volume: {
    type: TYPES.decimal,
    placesRight: 5,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  volumeunit: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  unitprice: {
    type: TYPES.money,
    currency: true,
    placesRight: 5,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Money(0),
  },
  amount: {
    type: TYPES.money,
    currency: true,
    placesRight: 5,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Money(0),
  },
  received: {
    type: TYPES.decimal,
    placesRight: 5,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  uom: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  serials: {
    type: TYPES.arrayOfReferences,
    editStr: arrayOfReferencesTypeEditStrFunction,
    initialValue: null,
  },
  lot: {
    type: TYPES.reference,
    editStr: textTypeEditStrFunction,
  },
  lotExpiration: {
    type: TYPES.date,
    editStr: dateTypeEditStrFunction,
  },
  linkedTransaction: {
    type: TYPES.linkedTransaction,
    editStr: linkedTransactionTypeEditStrFunction,
  },
  linkedTransactionRefNumber: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  linkedTransactionLineNumber: {
    type: TYPES.number,
    editStr: numberTypeEditStrFunction,
  },
  relatedRecords: { initialValue: {} },
  itemDetails: { initialValue: {} },
};

export const FIELDS_TO_TRANSFORM = {
  DECIMAL_FIELDS: [],
  MONEY_FIELDS: [],
  DATE_FIELDS: [],
  DATETIME_FIELDS: ["date"],
  NON_UTC_DATETIME_FIELDS: [],
};

export const REFERENCE_FIELDS = ["location", "vendor"];

export const LINE_ACTIONS = [
  ["quickView", "pdf", "email"],
  "template",
  "shipUPS",
  "archive",
  "delete",
  "history",
  "sync",
];
export const BATCH_ACTIONS = [["pdf", "email"], "delete", "archive", "sync"];

export const ITEM_CALCULATED_FIELDS = [NO_COLUMNS];

export const QUICK_VIEW_CONFIG = {
  tableConfig: [{ type: "lines" }],
  headerFields: [
    {
      name: "date",
      formatFunc: formatDateTimeToDate,
      label: i18n("columns.Date"),
    },
    {
      name: "number",
      label: i18n("frmLabel.RtvNumber"),
      defaultValue: NO_REF_NUMBER_STRING,
    },
  ],
  fields: [
    { label: i18n("frmLabel.Vendor"), name: "vendor", formatFunc: extractName },
    {
      label: i18n("frmLabel.Location"),
      name: "location",
      formatFunc: extractName,
    },
    {
      name: "shippingMethod",
      label: i18n("frmLabel.ShippedVia"),
      formatFunc: extractName,
    },
    {
      name: "trackingNumber",
      label: i18n("frmLabel.TrackingNumber"),
    },
  ],
  lines: [
    {
      name: "item",
      formatFunc: formatLinkableItem,
      heading: i18n("columns.Item"),
      align: "left",
      isItemField: true,
    },
    {
      name: "description",
      formatFunc: extractDescription,
      heading: i18n("columns.Description"),
      align: "left",
    },
    {
      name: "quantity",
      formatFunc: formatQuantityWithUom,
      heading: i18n("columns.Quantity"),
      align: "right",
    },
  ],
};
