import { Decimal } from "classes/DecimalClasses";

import { i18n } from "services/i18nService";
import { formatLinkField } from "services/utility/formatting";

export const columnInfo = [
  {
    name: "name",
    heading: i18n("columns.Name"),
    formatFunc: formatLinkField,
    fixed: true,
    linkField: true,
    sortable: true,
  },
];

export const LINE_ACTIONS = ["delete", "sync"];
export const BATCH_ACTIONS = ["delete", "sync"];

export function getEmptyRecord() {
  return {
    name: null,
    dayOfMonthDue: new Decimal(0),
    dueDays: new Decimal(0),
    dueNextMonthDays: new Decimal(0),
    discountDays: new Decimal(0),
    discountDayOfMonth: new Decimal(0),
    discountPercent: new Decimal(0),
    dateDiscountPercent: new Decimal(0),
  };
}
