import { i18n } from "services/i18nService";

import {
  SALES_ORDER_ORDER_TYPE_OPTION,
  NONE_GROUP_BY_OPTION,
  SUB_COMPONENT,
} from "views/Reports/reportConstants";

const ORDER_TYPE_OPTIONS = [
  { id: "Invoices", name: i18n("objectType.invoice.SentencePlural") },
  SALES_ORDER_ORDER_TYPE_OPTION,
  { id: "SR", name: i18n("objectType.salesreceipt.SentencePlural") },
];

const GROUP_BY_OPTIONS = [
  NONE_GROUP_BY_OPTION,
  { id: "Customer", name: i18n("reports.OptionLabel.Customer") },
  { id: "Item", name: i18n("reports.OptionLabel.Item") },
  { id: "Sales Rep", name: i18n("reports.OptionLabel.SalesRep") },
];

export const CommissionReport = {
  userHasSufficientPrivilege: (p) => p.editSalesReps && p.viewCosts,
  includePaymentDates: true,
  planIncludesReport: () => true,
  relatedLists: ["customer"],
  customFieldObjectTypes: ["salesorder", "salesreceipt", "invoice", "item"],
  notes: [i18n("notes.ShownOnFirstLine")],
  requiredFiltersNotSetMsg: i18n("reports.SalesRepFilterRequired"),
  columns: [
    { apiName: "OrderNumber", label: "Order #", default: true },
    { apiName: "Order Date", default: true },
    { apiName: "Txn Type", default: true },
    { apiName: "Customer", default: true },
    { apiName: "Item", default: true },
    { apiName: "Location" },
    { apiName: "Description", default: true },
    { apiName: "Ordered", number: true },
    { apiName: "Picked", number: true },
    { apiName: "Shipped", number: true },
    { apiName: "Backordered", number: true },
    { apiName: "UOM" },
    { apiName: "Unit Price", number: true },
    { apiName: "Open Amt", number: true },
    { apiName: "Total Amt", default: true },
    { apiName: "Due Date" },
    { apiName: "Sales Rep", default: true },
    { apiName: "Channel" },
    { apiName: "Category" },
    { apiName: "Class" },
    { apiName: "Customer Type" },
    { apiName: "Department" },
    { apiName: "Comment" },
    { apiName: "Priority" },
    { apiName: "Stage" },
    { apiName: "Customer PO" },
    { apiName: "On Hand", number: true },
    { apiName: "Available", number: true },
    { apiName: "List Price", number: true },
    { apiName: "Vendor" },
    { apiName: "VendorPartNumber", label: "Vendor Part #" },
    { apiName: "Invoiced", number: true },
    { apiName: "Item-Based Commission", number: true, alwaysShow: true },
    { apiName: "Rep-Based Commission", number: true, alwaysShow: true },
    { apiName: "Total Commission", number: true, alwaysShow: true },
  ],
  settings: [
    {
      apiName: "date1",
      macroApiName: "date1Macro",
      headerText: i18n("filter.heading.from"),
      component: "DateFilter",
      label: i18n("reports.FilterLabel.FromDate"),
      defaultValue: () => new Date(),
      datePrefix: "startOf",
      type: "filter",
    },
    {
      apiName: "date1Macro",
      defaultValue: null,
      component: SUB_COMPONENT,
      type: "filter",
    },
    {
      apiName: "date2",
      macroApiName: "date2Macro",
      component: "DateFilter",
      label: i18n("reports.FilterLabel.ToDate"),
      datePrefix: "endOf",
      defaultValue: () => new Date(),
      type: "filter",
    },
    {
      apiName: "date2Macro",
      defaultValue: null,
      component: SUB_COMPONENT,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.SalesRep"),
      component: "SalesRepsFilter",
      apiName: "salesReps",
      defaultValue: null,
      required: true,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.ItemNameContains"),
      component: "FrmTextField",
      apiName: "nameContains",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("filter.Customer"),
      component: "CustomerFilter",
      apiName: "customer",
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "users",
      label: i18n("reports.FilterLabel.OrderType"),
      component: "ReportMultiSelect",
      defaultValue: [SALES_ORDER_ORDER_TYPE_OPTION],
      options: ORDER_TYPE_OPTIONS,
      type: "filter",
    },
    {
      apiName: "includeNulls",
      defaultValue: false,
      label: i18n("reports.OptionLabel.ShowTransactionSummaryOnly"),
      component: "FrmCheckboxWithHoverHelp",
      helpText: i18n("reports.hoverHelp.ShowTransactionSummaryOnly"),
      type: "option",
    },
    {
      apiName: "useAvailable",
      defaultValue: false,
      label: i18n("reports.OptionLabel.SubtractCustomerReturns"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "showFullOnly",
      defaultValue: false,
      label: i18n("reports.OptionLabel.ShowOnlyIfOrderInvoicedOrInvoicePaid"),
      component: "FrmCheckboxWithHoverHelp",
      helpText: i18n("reports.hoverHelp.ShowOnlyIfOrderInvoicedOrInvoicePaid"),
      type: "option",
    },
    {
      apiName: "paymentDate1",
      macroApiName: "paymentDate1Macro",
      component: "PaymentDateFilter",
      label: i18n("reports.OptionLabel.FromPaymentDate"),
      defaultValue: () => new Date(),
      datePrefix: "startOf",
      type: "option",
    },
    {
      apiName: "paymentDate1Macro",
      defaultValue: null,
      component: SUB_COMPONENT,
      type: "option",
    },
    {
      apiName: "paymentDate2",
      macroApiName: "paymentDate2Macro",
      component: "PaymentDateFilter",
      label: i18n("reports.OptionLabel.ToPaymentDate"),
      defaultValue: () => new Date(),
      datePrefix: "endOf",
      type: "option",
    },
    {
      apiName: "paymentDate2Macro",
      defaultValue: null,
      component: SUB_COMPONENT,
      type: "option",
    },
    {
      apiName: "showPicked",
      defaultValue: false,
      label: i18n("reports.OptionLabel.UseNetInsteadOfGrossBeta"),
      component: "FrmCheckboxWithHoverHelp",
      helpText: i18n("reports.hoverHelp.UseNetInsteadOfGrossBeta"),
      type: "option",
    },
    {
      apiName: "showFromTo",
      defaultValue: false,
      label: i18n("reports.OptionLabel.ShowFromAndToDates"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "groupBy",
      label: i18n("reports.OptionLabel.GroupBy"),
      component: "FrmButtonGroup",
      defaultValue: NONE_GROUP_BY_OPTION,
      options: GROUP_BY_OPTIONS,
      type: "option",
    },
    {
      apiName: "header",
      defaultValue: "",
      label: i18n("global.Header"),
      component: "FrmTextField",
      type: "option",
    },
    {
      apiName: "footer",
      defaultValue: "",
      label: i18n("global.Footer"),
      component: "FrmTextField",
      type: "option",
    },
  ],
};
