import { formatDate } from "services/utility/dates";
import { handleProgramError } from "services/utility/errors";
import {
  formatBoolean,
  formatMoneyWithAdornments,
} from "services/utility/formatting";

const labelStyles = {
  minWidth: "20ch",
  maxWidth: "20ch",
  fontWeight: "bold",
  textAlign: "right",
};

export function CustomField(props) {
  const { dataType, value, name } = props;

  switch (dataType) {
    case "Text":
    case "Number":
    case "List":
      return (
        <>
          <div style={labelStyles}>{name}:</div>
          <div style={{ flexGrow: 1 }}>{value}</div>
        </>
      );
    case "Boolean":
      return (
        <>
          <div style={labelStyles}>{name}:</div>
          <div style={{ flexGrow: 1 }}>{formatBoolean(value)}</div>
        </>
      );
    case "TextArea":
      return (
        <>
          <div style={labelStyles}>{name}:</div>
          <div
            style={{
              flexGrow: 1,
              whiteSpace: "pre-wrap",
              maxWidth: "15rem",
              overflowWrap: "break-word",
            }}
          >
            {value}
          </div>
        </>
      );
    case "Date":
      return (
        <>
          <div style={labelStyles}>{name}:</div>
          <div style={{ flexGrow: 1 }}>{formatDate(value)}</div>
        </>
      );
    case "Money":
      return (
        <>
          <div style={labelStyles}>{name}:</div>
          <div style={{ flexGrow: 1 }}>{formatMoneyWithAdornments(value)}</div>
        </>
      );
    default:
      handleProgramError(
        new Error(`CustomFields | unexpected dataType ${dataType}`)
      );
      return null;
  }
}
