import { i18n } from "services/i18nService";
import {
  extractName,
  extractId,
  formatMoneyWithoutAdornments,
  formatDecimal,
} from "services/utility/formatting";

export const WhereToBuy = {
  userHasSufficientPrivilege: (p) =>
    (p.viewInventory || p.viewItems || p.editItems) &&
    (p.viewVendors || p.editVendors),
  planIncludesReport: () => true,
  relatedLists: ["vendor", "category"],
  isJsonReport: true,
  reportAction: {
    id: "AddToReorderList",
    label: i18n("reports.AddToReorderList"),
  },
  customFieldObjectTypes: ["item", "vendor"],
  columns: [
    {
      apiName: "Item",
      jsonName: "item",
      formatFunc: extractName,
      default: true,
    },
    {
      apiName: "Vendor",
      jsonName: "vendor",
      formatFunc: extractName,
      default: true,
    },
    {
      apiName: "VendorPartNumber",
      jsonName: "vendorPartNumber",
      label: "Vendor Part #",
    },
    {
      apiName: "Preferred Vendor",
      jsonName: "preferredVendor",
      formatFunc: extractName,
    },
    {
      apiName: "Price",
      jsonName: "price",
      formatFunc: formatMoneyWithoutAdornments,
      default: true,
      number: true,
    },
    {
      apiName: "ItemNumber",
      jsonName: "item",
      formatFunc: extractId,
      label: "Item #",
    },
    { apiName: "Category", jsonName: "category", formatFunc: extractName },
    { apiName: "Description", jsonName: "description", default: true },
    { apiName: "Purchase Description", jsonName: "purchaseDescription" },
    { apiName: "SKU", jsonName: "sku" },
    { apiName: "Barcode", jsonName: "barcode" },
    { apiName: "Default Bin", jsonName: "defaultBin" },
    {
      apiName: "Asset Account",
      jsonName: "assetAccount",
      formatFunc: formatDecimal,
    },
    {
      apiName: "Lead Time",
      jsonName: "leadTime",
      formatFunc: formatDecimal,
      number: true,
    },
    {
      apiName: "Reorder Point",
      jsonName: "reorderLevel",
      formatFunc: formatDecimal,
      number: true,
    },
    {
      apiName: "Max Stock",
      jsonName: "maxStockLevel",
      formatFunc: formatDecimal,
      number: true,
    },
    {
      apiName: "On Hand",
      jsonName: "onHand",
      formatFunc: formatDecimal,
      number: true,
    },
    {
      apiName: "Available",
      jsonName: "available",
      formatFunc: formatDecimal,
      number: true,
    },
    {
      apiName: "Needed",
      jsonName: "needed",
      formatFunc: formatDecimal,
      default: true,
      number: true,
    },
  ],
  settings: [
    {
      label: i18n("filter.Categories"),
      component: "CategoryFilter",
      apiName: "categories",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.ItemNameContains"),
      component: "FrmTextField",
      apiName: "nameContains",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("filter.Vendor"),
      component: "VendorFilter",
      apiName: "vendor",
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "header",
      defaultValue: "",
      label: i18n("global.Header"),
      component: "FrmTextField",
      type: "option",
    },
    {
      apiName: "footer",
      defaultValue: "",
      label: i18n("global.Footer"),
      component: "FrmTextField",
      type: "option",
    },
  ],
};
