import { Decimal, Money } from "classes/DecimalClasses";
import { Loading } from "classes/Loading";

import { totalWeight, weightUnit } from "services/utility/afterTouchLine";
import { LOCALIZATION } from "services/utility/localization";
import {
  getPercentFromAmount,
  getAmountFromPercent,
} from "services/utility/misc";
import { isNonZeroDecimalOrMoney } from "services/utility/misc";

import globalState from "globalState/globalState";

import { DEFAULT_DECIMALS_ROUNDED } from "appConstants";

export const ZERO_TOTALS = {
  amount: Money.ZERO,
  cost: Money.ZERO,
  globalTax: Money.ZERO,
  margin: Decimal.ZERO,
  quantity: Decimal.ZERO,
  taxableAmount: Money.ZERO,
  volume: Decimal.ZERO,
  weight: Decimal.ZERO,
};

export function updateTotals(
  lines,
  taxCodes,
  discountType,
  setTotals,
  setRecord
) {
  if (!lines || lines.length === 0) {
    setTotals(ZERO_TOTALS);
    return;
  }

  function getTaxRate(taxCodeId) {
    if (!taxCodeId) {
      return Decimal.ZERO;
    }
    const rate = taxCodes.find(({ id }) => id === taxCodeId).salesTaxRate;
    // rates are stored as percents, so divide by 100 to get rate as fraction
    return rate.div(new Decimal(100));
  }

  const quantity = lines.reduce(
    (total, line) => total.plus(line.quantity),
    Decimal.ZERO
  );

  const amount = lines.reduce(
    (total, { amount }) =>
      amount instanceof Loading ? total : total.plus(amount),
    Money.ZERO
  );

  let globalTax, taxableAmount;

  // if globalEnabled, tax will be the sum of the tax on line items, and
  // the non-global taxableAmount will be irrelevant
  const { globalEnabled } = globalState.getState().userCompanySettings.settings;
  if (globalEnabled) {
    globalTax = lines.reduce((total, { amount, taxCode }) => {
      const lineTax =
        amount instanceof Loading
          ? Decimal.ZERO
          : amount.times(getTaxRate(taxCode?.id));
      return total.plus(lineTax);
    }, Money.ZERO);
    taxableAmount = undefined;
    // if not globalEnabled, add up the taxable line item amounts
  } else {
    globalTax = undefined;
    taxableAmount = lines.reduce(
      (total, { tax, amount }) =>
        tax?.taxable && !(amount instanceof Loading)
          ? total.plus(amount)
          : total,
      Money.ZERO
    );
  }

  const weight = totalWeight(lines, LOCALIZATION);
  const weightunit = weightUnit(lines);

  // if we ever support more than cbm for volume, this will have to be
  // changed to mirror the weight calculation
  const volume = lines.reduce(
    (total, { volume }) => total.plus(volume),
    Decimal.ZERO
  );

  const cost = lines.reduce(
    (total, { cost }) =>
      cost && !(cost instanceof Loading) ? total.plus(cost) : total,
    Money.ZERO
  );

  const margin =
    !(cost instanceof Loading) && isNonZeroDecimalOrMoney(cost)
      ? amount
          .minus(cost)
          .div(cost)
          .times(new Decimal(100))
          .round(DEFAULT_DECIMALS_ROUNDED, Decimal.roundUp)
      : Decimal.ZERO;

  if (discountType === "discountPercent") {
    setRecord((prevRecord) => {
      return prevRecord
        ? {
            ...prevRecord,
            discountAmount: getAmountFromPercent(
              prevRecord.discountPercent.times(new Decimal(-1)),
              amount
            ),
          }
        : prevRecord;
    });
  } else {
    setRecord((prevRecord) => {
      return prevRecord
        ? {
            ...prevRecord,
            discountPercent: getPercentFromAmount(
              prevRecord.discountAmount.times(new Decimal(-1)),
              amount
            ),
          }
        : prevRecord;
    });
  }

  setTotals({
    quantity,
    cost,
    margin,
    amount,
    weight,
    weightunit,
    volume,
    volumeunit: "cbm",
    globalTax,
    taxableAmount,
  });
}
