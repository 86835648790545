import { useState } from "react";
import { useDispatch } from "react-redux";

import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  TextField,
} from "@mui/material";

import { ButtonProgress } from "components/utility/ButtonProgress";

import { i18n } from "services/i18nService";
import { generateAndDownloadPdfs } from "services/utility/pdfs";

import { useFormTemplates } from "hooks/useFormTemplates";

import {
  loadingIndicatorOn,
  loadingIndicatorOff,
} from "globalState/loadingSlice";

export function PdfDialog(props) {
  const {
    objectType,
    objectIds,
    recordNumber,
    open,
    onClose,
    formTemplates: externalFormTemplates,
  } = props;

  const formTemplates = useFormTemplates(objectType, externalFormTemplates);
  const [pdfInfo, setPdfInfo] = useState({
    template: {
      id: null,
    },
  });
  const [formIsValid, setFormIsValid] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  const dispatch = useDispatch();

  if (!formTemplates || !formTemplates.length) {
    return null;
  }

  if (!pdfInfo.template.id) {
    setPdfInfo((previous) => ({
      ...previous,
      template: {
        id: formTemplates[0].id,
      },
    }));
    setFormIsValid(true);
  }

  function handleTemplateChange(e) {
    setPdfInfo({
      ...pdfInfo,
      template: { id: e.target.value },
    });
    setFormIsValid(true);
  }

  async function generate() {
    setInProgress(true);
    dispatch(loadingIndicatorOn());
    const downloadText =
      objectIds.length === 1
        ? `${i18n(`objectType.${objectType}.Sentence`)}${
            recordNumber ? ` ${recordNumber}` : ""
          }.pdf`
        : `${i18n(`objectType.${objectType}.SentencePlural`)}.pdf`;
    onClose();
    await generateAndDownloadPdfs(
      objectType,
      objectIds,
      pdfInfo.template.id,
      downloadText
    );
    setInProgress(false);
    dispatch(loadingIndicatorOff());
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      sx={{ inset: "0 0 30%" }}
    >
      <DialogTitle>{i18n("global.GeneratePdf")}</DialogTitle>
      <DialogContent>
        <Box>
          <TextField
            select
            name="template"
            required
            label={i18n("global.Template")}
            margin="dense"
            onChange={handleTemplateChange}
            value={pdfInfo.template.id || ""}
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
          >
            {formTemplates.map((el) => (
              <MenuItem key={el.id} value={el.id}>
                {el.name}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          {i18n("button.Cancel")}
        </Button>
        <div style={{ position: "relative" }}>
          <Button
            color="secondary"
            disabled={!formIsValid || inProgress}
            onClick={generate}
          >
            {i18n("button.Generate")}
          </Button>
          {inProgress && <ButtonProgress color="secondary" />}
        </div>
      </DialogActions>
    </Dialog>
  );
}
