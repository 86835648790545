import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from "@mui/material";

import { LineItemText } from "components/formFields/LineItemText";
import { LineTableCell } from "components/formFields/LineTableCell";
import { LineColumnHeading } from "components/formFields/line/LineColumnHeading";
import { RowDecimalField } from "components/formFields/row/RowDecimalField";
import { RowSelectReference } from "components/formFields/row/RowSelectReference";
import { FormErrors } from "components/utility/FormErrors";
import { ModalHeader } from "components/utility/ModalHeader";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import { getItemLocationSettings } from "services/sosInventoryService/sosApi";
import { saveItemLocationSettings } from "services/sosInventoryService/sosApi";
import { handleProgramError } from "services/utility/errors";

import { useBins } from "hooks/useBins";
import { useErrors } from "hooks/useErrors";

import { openAlert } from "globalState/alertSlice";

import { theme } from "SosTheme";

export function LocationSettings(props) {
  const { id, close, identifierText } = props;

  const [locations, setLocations] = useState();
  const { errors, setErrors } = useErrors();

  const bins = useBins();

  const dispatch = useDispatch();

  useEffect(() => {
    async function _getItemLocationSettings() {
      const response = await getItemLocationSettings(id);
      if (response.success) {
        setLocations(response.data);
      } else {
        handleProgramError(
          new Error(i18n("error.CouldNotRetrieveItemLocationSettings"))
        );
      }
    }
    _getItemLocationSettings();
  }, [id]);

  if (!locations || !bins) {
    return null;
  }

  function filteredBins(locationId) {
    return bins.filter((bin) => bin.location.id === locationId);
  }

  function handleFieldChange(fieldName, newValue, dataIndex) {
    let newData = [...locations];
    newData[dataIndex][fieldName] = newValue;
    setLocations(newData);
  }

  async function saveAndClose() {
    const response = await saveItemLocationSettings(id, locations);
    if (response.success) {
      dispatch(
        openAlert({
          type: "success",
          message: i18n("alert.SaveLocationSettingsSuccess"),
        })
      );
      close();
    } else {
      handleProgramError(new Error(i18n("error.SaveLocationSettingsFailure")));
    }
  }

  return (
    <Dialog open={true} onClose={() => close()} maxWidth="md">
      <ModalHeader
        label={i18n("global.LocationSettings")}
        onClose={() => close()}
        text={identifierText}
        save={saveAndClose}
      />
      <DialogContent>
        <FormErrors errors={errors} setErrors={setErrors} />
        <VSpace space={1} />
        <Table size="small" padding="none" stickyHeader>
          <TableHead>
            <TableRow>
              <LineColumnHeading labelCode="Location" />
              <LineColumnHeading align="right" labelCode="ReorderPoint" />
              <LineColumnHeading align="right" labelCode="MaxStock" />
              <LineColumnHeading labelCode="DefaultBin" />
            </TableRow>
          </TableHead>
          <TableBody>
            {locations.map((location, index) => (
              <LocationRow
                location={location}
                key={index}
                dataIndex={index}
                handleFieldChange={handleFieldChange}
                filteredBins={filteredBins}
              />
            ))}
          </TableBody>
        </Table>
        <VSpace space={1} />
      </DialogContent>
    </Dialog>
  );
}

function LocationRow(props) {
  const { location, handleFieldChange, filteredBins, dataIndex } = props;

  return (
    <TableRow>
      <LineTableCell
        sx={{
          width: "20.0rem",
          borderLeft: `1px solid ${theme.palette.lineItemBorder}`,
        }}
      >
        <LineItemText value={location.location.name} />
      </LineTableCell>
      <LineTableCell sx={{ width: "8.0rem" }}>
        <RowDecimalField
          name="reorderPoint"
          dataIndex={dataIndex}
          value={location.reorderPoint || ""}
          onValueBlur={handleFieldChange}
        />
      </LineTableCell>
      <LineTableCell sx={{ width: "8.0rem" }}>
        <RowDecimalField
          name="maxStock"
          dataIndex={dataIndex}
          value={location.maxStock || ""}
          onValueBlur={handleFieldChange}
        />
      </LineTableCell>
      <LineTableCell sx={{ width: "12.0rem" }}>
        {filteredBins(location.location.id).length !== 0 && (
          <RowSelectReference
            name="defaultBin"
            value={location.defaultBin}
            options={filteredBins(location.location.id)}
            optionDisplayText="number"
            onValueChange={handleFieldChange}
            dataIndex={dataIndex}
            disableClearable
          />
        )}
      </LineTableCell>
    </TableRow>
  );
}
