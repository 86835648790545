import { FilterSelectField } from "components/formFields/FilterSelectField";

import { i18n } from "services/i18nService";

import { useLocations } from "hooks/useLocations";
import { usePlans } from "hooks/usePlans";

import { ALL_FILTER_OPTION } from "appConstants";

export function EditLocationFilter(props) {
  const { filters, setFilters } = props;

  const { filterSettings } = filters;
  const { Location } = filterSettings;

  const { allowPlusFeatures, allowProFeatures } = usePlans();

  const retrievedLocations = useLocations();

  function handleChange(_, value) {
    setFilters((prevParams) => ({
      ...prevParams,
      filterSettings: {
        ...prevParams.filterSettings,
        Location: value?.id || "",
      },
      startingRecord: 1,
    }));
  }
  if (!allowProFeatures && !allowPlusFeatures) {
    return null;
  }

  const locations = retrievedLocations?.map(({ id, name }) => ({
    id: id.toString(),
    name,
  }));

  const options = locations ? [ALL_FILTER_OPTION, ...locations] : [];

  return (
    <FilterSelectField
      options={options}
      onValueChange={handleChange}
      value={Location ? { id: Location } : ALL_FILTER_OPTION}
      label={i18n("filter.Location")}
      dataTesting="locationFilter"
      disableClearable
    />
  );
}
