import { useCallback, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { AttachFile } from "@mui/icons-material";
import { IconButton, Button } from "@mui/material";

import { CONFIGURATION_LISTS } from "appConfig";

import { Star } from "components/ListPage/List/Star";
import { Tooltip } from "components/Tooltip";
import { Link } from "components/html/Link";

import { i18n } from "services/i18nService";
import {
  setStarred,
  getDocument,
  updateBatchRecords,
} from "services/sosInventoryService/sosApi";
import {
  getCustomFieldName,
  getCustomFieldValue,
} from "services/utility/customFields";
import { handleProgramError } from "services/utility/errors";
import {
  formatMoneyWithAdornments,
  formatDateTimeToDate,
  formatBoolean,
} from "services/utility/formatting";

import { openAlert } from "globalState/alertSlice";
import {
  loadingIndicatorOn,
  loadingIndicatorOff,
} from "globalState/loadingSlice";
import { recordsChangedTick } from "globalState/recordsChangedSlice";

import { theme } from "SosTheme";
import {
  getObjectFromTypeString,
  SYNC_ITEM_OBJECT_TYPES_GO_TO,
  OBJECT_TYPES,
} from "appConstants";

function formattedContents(value, column, row) {
  if (!column.formatFunc) {
    return value;
  }
  if (Array.isArray(column.formatFunc)) {
    return column.formatFunc.reduce(
      (seed, fn) => fn(seed, row, column, getDocument),
      value
    );
  }
  return column.formatFunc(value, row, column, getDocument);
}

function preparedContents(column, row, customFieldDefs) {
  // grab the custom field values, if appropriate
  if (column.isCustomField) {
    const fieldName = getCustomFieldName(column.name);
    const customValue = getCustomFieldValue(row.customFields, fieldName);

    const dataType = customFieldDefs[fieldName].dataType;
    if (dataType === "Money") {
      return formatMoneyWithAdornments(customValue);
    }
    if (dataType === "Date") {
      return formatDateTimeToDate(customValue);
    }
    if (dataType === "Boolean") {
      return formatBoolean(customValue);
    }
    return customValue;
  } else {
    const cellValue = row[column.fieldName || column.name];
    return formattedContents(cellValue, column, row);
  }
}

function CellContents_(props) {
  const {
    objectType,
    row,
    column,
    customFieldDefinitions,
    setShowRefund,
    setShowAddDocument,
  } = props;

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const stars = useSelector(
    (state) => state.userCompanySettings.settings.stars
  );

  const updateStarred = useCallback(
    (value) => setStarred(objectType, row.id, value),
    [objectType, row.id]
  );

  async function handleButtonAction(type, id) {
    dispatch(loadingIndicatorOn());
    switch (type) {
      case "restore":
        const { success, message } = await updateBatchRecords(objectType, {
          ids: [id],
          field: "deleted",
        });
        if (success) {
          dispatch(recordsChangedTick());
          dispatch(
            openAlert({
              type: "success",
              message: i18n("alert.SuccessRestore"),
            })
          );
        } else {
          handleProgramError(new Error(message));
        }
        break;

      case "refund":
        setShowRefund(true);
        break;

      case "goTo":
        const resource = getObjectFromTypeString(row.object.type).fullString;
        navigate(`/${resource}?id=${row.object.id}`);
        break;

      default:
        handleProgramError(
          new Error(`unexpected value for button type: ${type}`)
        );
    }
    dispatch(loadingIndicatorOff());
  }

  // like linkFields (below), starred fields are handled outside of the
  // column definition method, because they involve UI actions--in this
  // case, updating the star value of a record via the API
  if (column.starredField && stars) {
    return (
      <Star value={row[column.name]} updateFunc={updateStarred} stars={stars} />
    );
  }

  if (column.isButton) {
    // sync item list has item objects where the button action does
    // not apply since these item objects can not be "gone to"
    if (
      objectType === OBJECT_TYPES.SYNC_ITEM.fullString &&
      !SYNC_ITEM_OBJECT_TYPES_GO_TO.includes(row.object?.type)
    ) {
      return null;
    }

    return (
      <Button
        variant="outlined"
        size="small"
        sx={{ p: 0 }}
        onClick={() => handleButtonAction(column.buttonAction, row.id)}
      >
        {column.buttonLabel}
      </Button>
    );
  }

  if (column.linkField) {
    // linkFields are the primary identifiers that are linked to the view/edit
    // functions and on which row-level functions operate
    const linkPrefix = CONFIGURATION_LISTS.includes(objectType)
      ? "/configuration/list"
      : "";
    return (
      <div style={theme.typography.body1}>
        <Link
          to={`${linkPrefix}/${objectType}/${row.id}${location.search}`}
          underline="none"
        >
          {preparedContents(column, row, customFieldDefinitions.asObject)}
        </Link>
      </div>
    );
  }

  if (column.isDocument) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <div style={theme.typography.body1}>
          {preparedContents(column, row, customFieldDefinitions.asObject)}
        </div>
        <Tooltip title={i18n("global.Documents")}>
          <IconButton
            size="small"
            sx={{ p: 0 }}
            onClick={() => setShowAddDocument(true)}
          >
            <AttachFile fontSize="small" />
          </IconButton>
        </Tooltip>
      </div>
    );
  }
  // otherwise it's just a normal field
  return (
    <div style={theme.typography.body1}>
      {preparedContents(column, row, customFieldDefinitions.asObject)}
    </div>
  );
}

export const CellContents = memo(CellContents_);
