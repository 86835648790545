import globalState from "globalState/globalState";

import { enUS } from "date-fns/locale";

const locales = { "en-US": enUS };

// arbitrary number to use for extracting decimal separator
// in a given locale
const NUM_IN_DEFAULT_LOCALE = 1.1;

function getLocalization() {
  const { settings } = globalState.getState().userCompanySettings;
  const locale = locales[settings.localeCode];
  const userDateFormat = settings.dateFormat;

  // get decimal number decimal point character
  const decimalSeparator = Intl.NumberFormat(locale)
    .formatToParts(NUM_IN_DEFAULT_LOCALE)
    .find((part) => part.type === "decimal").value;

  // get minimum decimal places for currency
  const currencyMinimumFractionDigits = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: settings.homeCurrency.code,
  }).resolvedOptions().minimumFractionDigits;

  return {
    locale,
    decimalSeparator,
    currencyMinimumFractionDigits,
    validNumber: new RegExp("^-?\\d*\\" + decimalSeparator + "?\\d*$"),
    dateFormat: userDateFormat || locale.formatLong.date({ width: "short" }),
    homeCurrency: settings.homeCurrency?.code,
  };
}

export const LOCALIZATION = getLocalization();
