import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { Box } from "@mui/material";

import { Alert } from "components/Alert";
import { ErrorHandler } from "components/ErrorHandler";
import { GlobalDialog } from "components/GlobalDialog";
import { LeftNav } from "components/LeftNav/LeftNav";
import { Modal } from "components/Modal";
import { TopNavBar } from "components/TopNav/TopNavBar";

import {
  retrieveSettings,
  CHECK_LOGIN_STATUS,
} from "services/sosInventoryService/settings";

import { useLeftNavWidth } from "hooks/useLeftNavWidth";

import { Routes } from "Routes";
import {
  HIDDEN_LEFT_NAV_PATHS,
  NOT_LOGGED_IN_PATHS,
  TOP_NAV_BAR_HEIGHT,
} from "appConstants";

export function GlobalLayout() {
  const { pathname } = useLocation();
  const isLoggedIn = useSelector((state) => state.authentication.isLoggedIn);

  const showLeftNav =
    !HIDDEN_LEFT_NAV_PATHS.some((path) => pathname.startsWith(path)) &&
    isLoggedIn;

  const width = useLeftNavWidth();

  const isLogin = pathname.includes("/login");
  const areas = isLogin
    ? '"main main" "main main"'
    : '"header header" "nav main"';

  const styles = {
    display: "grid",
    paddingBottom: 0,
    gridTemplateAreas: areas,
    gridTemplateColumns: showLeftNav ? `${width}rem 1fr` : "0 1fr",
    gridTemplateRows: `${TOP_NAV_BAR_HEIGHT} 1fr`,
    gap: "0 0",
    "@media print": { gridTemplateColumns: "1fr", gridTemplateRows: "1fr" },
  };

  useEffect(() => {
    if (NOT_LOGGED_IN_PATHS.includes(pathname)) {
      return;
    }
    async function _retrieveSettings() {
      retrieveSettings(CHECK_LOGIN_STATUS);
    }
    _retrieveSettings();
  }, [pathname]);

  return (
    <Box sx={styles} data-testing="globalLayout">
      <TopNavBar />
      <LeftNav />
      <ErrorHandler isInBody pathname={pathname}>
        <Routes />
        <Modal />
        <GlobalDialog />
        <Alert />
      </ErrorHandler>
    </Box>
  );
}
