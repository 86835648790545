// Note: the maxHeight of 2.5em on the Autocomplete component is to prevent
// it from expanding vertically when the grid it's in expands. If it expands,
// then the field can receive focus from a click that seems to be in the
// empty space below it, which is confusing behavior. It appears to be a MUI
// bug, so should be retested on MUI updates.
import { useState, useEffect } from "react";

import { Typography } from "@mui/material";

import { FrmSelectBase } from "components/formFields/FrmSelectBase";

import { i18n } from "services/i18nService";
import { exactNameMatch } from "services/sosInventoryService/sosApi";
import { checkForUnexpectedProps } from "services/utility/misc";

export function FrmSelectWithAdd(props) {
  const {
    name,
    label,
    onValueChange,
    value: initialValue,
    options: initialOptions,
    optionDisplayText = "name",
    error,
    dataTesting,
    sx,
    ...unexpected
  } = props;
  checkForUnexpectedProps("FrmSelectWithAdd", unexpected);

  const [value, setValue] = useState(initialValue);
  const [options, setOptions] = useState(initialOptions);
  const [inputValue, setInputValue] = useState("");
  const [dirtyObjectInput, setDirtyObjectInput] = useState(false);

  const ADD_TEXT = i18n("global.AddNewIndicator") + " " + name.toLowerCase();

  useEffect(() => {
    const addOption = {
      id: "add",
      [optionDisplayText]: (
        <Typography
          color="primary"
          sx={{ fontWeight: "fontWeightMedium" }}
          data-testing="quickAddOption"
        >
          {ADD_TEXT}
        </Typography>
      ),
    };
    const newOptions = initialOptions
      ? [addOption, ...initialOptions]
      : [addOption];

    if (!value) {
      setOptions(newOptions);
      return;
    }

    // if value is not in the options, then add it
    const foundOption = newOptions.find(({ id }) => id === value.id);
    setOptions(foundOption ? newOptions : [...newOptions, value]);
    return;
  }, [initialOptions, value, optionDisplayText, ADD_TEXT]);

  useEffect(() => setValue(initialValue), [initialValue]);

  // this gets called whenever the user changes the selected option
  function handleValueChange(fieldName, newValue) {
    setValue(newValue);
    onValueChange(fieldName, newValue, inputValue);
    setDirtyObjectInput(false);
  }

  // this gets called whenever what the user types in the field changes
  function onInputChange(_, newInputValue, reason) {
    setInputValue(newInputValue);
    if (reason === "input") {
      setDirtyObjectInput(true);
    }
  }

  async function onBlur() {
    if ((value && !dirtyObjectInput) || (!value && !inputValue)) {
      return;
    }

    if ((!value || dirtyObjectInput) && inputValue) {
      // first, see if the user's input is an exact match on any option
      const objectMatch = options.find(
        (option) =>
          typeof option[optionDisplayText] === "string" &&
          option[optionDisplayText].toLowerCase() === inputValue.toLowerCase()
      );
      if (objectMatch) {
        setValue(objectMatch);
        onValueChange(name, objectMatch);
        return;
      }

      // no match on selectable options; see if there is a match on an object that is not
      // in the option list for some reason (archived, not shown on forms, etc.)
      const object = await exactNameMatch(name, inputValue);
      if (object) {
        // add the "new" object to the options list
        const newObject = {
          id: object.id,
          [optionDisplayText]: object[optionDisplayText],
        };
        const newOptions = [newObject, ...options];
        setOptions(newOptions);
        setValue(newObject);
        onValueChange(name, object);
        return;
      }

      // if not, invoke the "add object" dialog
      onValueChange(name, { id: "add" }, inputValue);
    }
  }

  // this gets called when the user changes what is in the input field; it
  // should return a boolean, indicating whether the given option should be
  // in the filtered list of options; in the current case, we're just
  // looking for a simple substring match
  function filterOptions(options, state) {
    return options.filter((option) => {
      if (option.id === "add") {
        return true;
      }
      return (
        option.id !== "" &&
        option[optionDisplayText]
          .toLowerCase()
          .includes(state.inputValue.toLowerCase())
      );
    });
  }

  // this gets called when the component needs to know what to display
  // in the input field; not the value, which is the item id, but the
  // human-friendly *name* of the item
  function getOptionLabel(option) {
    // option or option.id can be blank on a newly inserted line
    if (option === "" || option.id === "") {
      return "";
    }
    if (option.id === "add") {
      return inputValue;
    }
    if (option[optionDisplayText]) {
      return option[optionDisplayText];
    }
    if (options.length === 0) {
      return "";
    }
    const selectedOption = options.find(({ id }) => id === option.id);

    return selectedOption ? selectedOption[optionDisplayText] : "";
  }

  function renderOption(props, option) {
    return (
      <li {...props} data-testing="selectOption" key={option.id}>
        <div style={{ minHeight: "1.2em" }}>{option[optionDisplayText]}</div>
      </li>
    );
  }

  const isLoading = !Array.isArray(initialOptions);

  return (
    <FrmSelectBase
      value={value}
      label={label}
      loading={isLoading}
      options={isLoading ? [] : options}
      onValueChange={handleValueChange}
      getOptionLabel={getOptionLabel}
      filterOptions={filterOptions}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={renderOption}
      dataTesting={dataTesting}
      error={error}
      sx={sx}
      onBlur={onBlur}
      onInputChange={onInputChange}
      disableListWrap
    />
  );
}
