import { Decimal } from "classes/DecimalClasses";

import { i18n } from "services/i18nService";
import { formatLinkField, extractName } from "services/utility/formatting";

export const columnInfo = [
  {
    name: "number",
    heading: i18n("columns.BinNumber"),
    formatFunc: formatLinkField,
    fixed: true,
    linkField: true,
    sortable: true,
  },
  {
    name: "location",
    heading: i18n("columns.Location"),
    formatFunc: extractName,
    fixed: true,
    fixedRightOfAction: true,
    sortable: true,
  },
  {
    name: "description",
    heading: i18n("columns.Description"),
    fixed: true,
    fixedRightOfAction: true,
    sortable: true,
  },
  {
    name: "contents",
    heading: i18n("columns.Contents"),
    fixed: true,
    fixedRightOfAction: true,
  },
];

export const LINE_ACTIONS = ["pdf", "contents", "delete"];
export const BATCH_ACTIONS = ["delete"];

export function getEmptyRecord() {
  return {
    number: null,
    description: null,
    location: null,
    sortOrder: Decimal.ZERO,
  };
}
