import { useNavigate } from "react-router-dom";

import { Box, ListItemText, MenuItem, MenuList } from "@mui/material";
import { teal } from "@mui/material/colors";

import { getConfigurationHeight } from "services/utility/misc";
import { dirtyFnCheck } from "services/utility/misc";

import { usePlans } from "hooks/usePlans";

import { AlertList } from "views/Alerts/AlertList";
import { AopRuleList } from "views/AopRules/AopRuleList";
import { BinList } from "views/Bins/BinList";
import { ChannelList } from "views/Channels/ChannelList";
import { SettingsPage } from "views/Configuration/SettingsPage";
import {
  CONFIGURATION_NAVIGATION,
  getLevel2Selections,
} from "views/Configuration/navigationStructure";
import { CustomFieldList } from "views/CustomFields/CustomFieldList";
import { CustomerMessageList } from "views/CustomerMessages/CustomerMessageList";
import { CustomerTypeList } from "views/CustomerTypes/CustomerTypeList";
import { DocumentList } from "views/Documents/DocumentList";
import { FormTemplateList } from "views/FormTemplates/FormTemplateList";
import { LocationList } from "views/Locations/LocationList";
import { OrderStageList } from "views/OrderStages/OrderStageList";
import { PaymentMethodList } from "views/PaymentMethods/PaymentMethodList";
import { PriceTierList } from "views/PriceTiers/PriceTierList";
import { PriorityList } from "views/Priorities/PriorityList";
import { ProcessTemplateList } from "views/ProcessTemplates/ProcessTemplateList";
import { SalesRepList } from "views/SalesReps/SalesRepList";
import { ShipMethodList } from "views/ShipMethods/ShipMethodList";
import { TagList } from "views/Tags/TagList";
import { TemplateLibraryList } from "views/TemplateLibrary/TemplateLibraryList";
import { TermList } from "views/Terms/TermList";
import { UomList } from "views/Uoms/UomList";
import { UpsShipmentList } from "views/UpsShipments/UpsShipmentList";
import { UserList } from "views/Users/UserList";
import { VendorItemList } from "views/VendorItems/VendorItemList";
import { VendorMessageList } from "views/VendorMessages/VendorMessageList";
import { WarrantyList } from "views/Warranties/WarrantyList";
import { WorkCenterList } from "views/WorkCenters/WorkCenterList";
import { WorkerList } from "views/Workers/WorkerList";

import { OBJECT_TYPES } from "appConstants";

function topLevelStyling(selected) {
  return {
    fontWeight: "bold",
    backgroundColor: selected ? teal[200] : "inherit",
    "&:hover": {
      backgroundColor: selected ? teal[200] : teal[50],
    },
  };
}

function secondLevelStyling(selected) {
  return {
    backgroundColor: selected ? teal[100] : "inherit",
    "&:hover": {
      backgroundColor: selected ? teal[100] : teal[25],
    },
  };
}

const navWrapperStyles = {
  height: "100%",
  overflow: "auto",
  borderRight: "1px solid #cccccc",
};

const LIST_COMPONENTS = {
  [OBJECT_TYPES.ALERT.fullString]: AlertList,
  [OBJECT_TYPES.AOP_RULE.fullString]: AopRuleList,
  [OBJECT_TYPES.BIN.fullString]: BinList,
  [OBJECT_TYPES.CHANNEL.fullString]: ChannelList,
  [OBJECT_TYPES.CUSTOM_FIELD.fullString]: CustomFieldList,
  [OBJECT_TYPES.CUSTOMER_MESSAGE.fullString]: CustomerMessageList,
  [OBJECT_TYPES.CUSTOMER_TYPE.fullString]: CustomerTypeList,
  [OBJECT_TYPES.DOCUMENT.fullString]: DocumentList,
  [OBJECT_TYPES.FORM_TEMPLATE.fullString]: FormTemplateList,
  [OBJECT_TYPES.LOCATION.fullString]: LocationList,
  [OBJECT_TYPES.ORDER_STAGE.fullString]: OrderStageList,
  [OBJECT_TYPES.PAYMENT_METHOD.fullString]: PaymentMethodList,
  [OBJECT_TYPES.PRICE_TIER.fullString]: PriceTierList,
  [OBJECT_TYPES.PRIORITY.fullString]: PriorityList,
  [OBJECT_TYPES.SALES_REP.fullString]: SalesRepList,
  [OBJECT_TYPES.SHIP_METHOD.fullString]: ShipMethodList,
  [OBJECT_TYPES.PROCESS_TEMPLATE.fullString]: ProcessTemplateList,
  [OBJECT_TYPES.TEMPLATE_LIBRARY.fullString]: TemplateLibraryList,
  [OBJECT_TYPES.TERM.fullString]: TermList,
  [OBJECT_TYPES.TAG.fullString]: TagList,
  [OBJECT_TYPES.UPS_SHIPMENT.fullString]: UpsShipmentList,
  [OBJECT_TYPES.UOM.fullString]: UomList,
  [OBJECT_TYPES.USER.fullString]: UserList,
  [OBJECT_TYPES.VENDOR_ITEM.fullString]: VendorItemList,
  [OBJECT_TYPES.VENDOR_MESSAGE.fullString]: VendorMessageList,
  [OBJECT_TYPES.WARRANTY.fullString]: WarrantyList,
  [OBJECT_TYPES.WORK_CENTER.fullString]: WorkCenterList,
  [OBJECT_TYPES.WORKER.fullString]: WorkerList,
};

export function ConfigurationNav(props) {
  // Level 1 is the left-most nav column, the main config entries
  // Level 2 is the next nav column, the "sub-nav" for the main selection
  // level1 and level2 props are strings, mapping to the routePart properties
  // in SETTINGS
  const { level1, level2 } = props;

  const { allowPlusFeatures, allowProFeatures } = usePlans();

  const navigate = useNavigate();

  // if a level1 is selected, show the related level2 entries
  const level2Selections = level1 ? getLevel2Selections(level1) : [];

  function isSelected(objectType) {
    return level2 === objectType;
  }

  function handleLevel1Selected(level1Selected) {
    dirtyFnCheck(() => navigate(`/configuration/${level1Selected}`));
  }

  function handleLevel2Selected(level2Selected) {
    dirtyFnCheck(() => navigate(`/configuration/${level1}/${level2Selected}`));
  }

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "10rem 10rem 1fr",
        height: getConfigurationHeight(),
      }}
    >
      {/* level 1 nav */}
      <Box sx={navWrapperStyles}>
        <MenuList sx={{ mt: 1 }}>
          {CONFIGURATION_NAVIGATION.map(({ label, routePart }) => (
            <MenuItem
              key={`${level2}-${label}`}
              onClick={() => handleLevel1Selected(routePart)}
              sx={topLevelStyling(level1 === routePart)}
            >
              <ListItemText sx={{ fontWeight: "fontWeightBold" }}>
                {label}
              </ListItemText>
            </MenuItem>
          ))}
        </MenuList>
      </Box>

      {/* level 2 nav */}
      <Box sx={navWrapperStyles}>
        <MenuList sx={{ mt: 1 }}>
          {level2Selections.map(({ label, routePart, planIncludesEntry }) => {
            if (
              !planIncludesEntry ||
              planIncludesEntry(allowPlusFeatures, allowProFeatures)
            ) {
              return (
                <MenuItem
                  key={label}
                  onClick={() => handleLevel2Selected(routePart)}
                  sx={secondLevelStyling(level2 === routePart)}
                >
                  <ListItemText>{label}</ListItemText>
                </MenuItem>
              );
            } else {
              return null;
            }
          })}
        </MenuList>
      </Box>

      <Box>
        {level1 !== "list" && level2 !== "user" && Boolean(level2) && (
          <SettingsPage key={level2} level1={level1} level2={level2} />
        )}
        {Object.keys(LIST_COMPONENTS).map((objectType, i) => {
          const ListComponent = LIST_COMPONENTS[objectType];
          return isSelected(objectType) && <ListComponent key={i} />;
        })}
      </Box>
    </Box>
  );
}
