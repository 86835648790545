import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import Delete from "@mui/icons-material/Delete";
import {
  Dialog,
  DialogContent,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Typography,
  IconButton,
  TableFooter,
} from "@mui/material";

import { AddLines } from "components/EditPage/AddLines";
import { FrmSelectFromObjectsWithHoverHelp } from "components/formFields/FrmSelectFromObjectsWithHoverHelp";
import { LineTableCell } from "components/formFields/LineTableCell";
import { LineColumnHeading } from "components/formFields/line/LineColumnHeading";
import { RowDecimalField } from "components/formFields/row/RowDecimalField";
import { RowMoneyField } from "components/formFields/row/RowMoneyField";
import { RowSelectReference } from "components/formFields/row/RowSelectReference";
import { FixedLoadingIndicator } from "components/utility/FixedLoadingIndicator";
import { FormErrors } from "components/utility/FormErrors";
import { Loading } from "components/utility/Loading";
import { ModalHeader } from "components/utility/ModalHeader";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import { getItemUom, saveItemUom } from "services/sosInventoryService/sosApi";
import { isValid } from "services/sosInventoryService/uomSet/isValid";
import {
  EMPTY_RECORD,
  BASE_UOM_SKELETON,
} from "services/sosInventoryService/uomSet/schema";
import { handleProgramError } from "services/utility/errors";
import { textForAddLineOrLines } from "services/utility/lineItems";
import { getBaseUom, getNonBaseUoms } from "services/utility/uoms";

import { useErrors } from "hooks/useErrors";
import { useUoms } from "hooks/useUoms";

import { openAlert } from "globalState/alertSlice";

import { theme } from "SosTheme";

const styles = {
  "& .MuiDialog-paper": {
    minHeight: "50vh",
    maxHeight: "93vh",
    "@media print": { minHeight: "100%", maxHeight: "100%" },
  },
};

export function UnitsOfMeasure(props) {
  const { close, id, identifierText } = props;

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [itemUoms, setItemUoms] = useState(null);
  const [baseUom, setBaseUom] = useState(null);
  const { errors, setErrors, isInError } = useErrors();

  const numLinesToAdd = useSelector(
    (state) => state.userCompanySettings.settings.numLinesToAdd
  );

  const uoms = useUoms();

  useEffect(() => {
    async function _getItemUom(id) {
      if (id) {
        const response = await getItemUom(id);
        const defaultLines = new Array(numLinesToAdd).fill(EMPTY_RECORD);
        const nonBaseUoms = getNonBaseUoms(response);
        setItemUoms(nonBaseUoms.length ? nonBaseUoms : defaultLines);
        setBaseUom(getBaseUom(response));
      }
    }
    _getItemUom(id);
  }, [id, numLinesToAdd]);

  function addLines() {
    const newLines = new Array(numLinesToAdd).fill(EMPTY_RECORD);
    setItemUoms((prevRecord) => [...prevRecord, ...newLines]);
  }

  function handleFieldChange(name, value, rowIndex) {
    setItemUoms((prevRecord) =>
      prevRecord.map((e, index) =>
        rowIndex === index ? { ...e, [name]: value } : e
      )
    );
  }

  function deleteRow(rowIndex) {
    setItemUoms((prevRecord) =>
      prevRecord.filter((_, index) => index !== rowIndex)
    );
  }

  async function save() {
    const errors = isValid(itemUoms, baseUom);
    if (errors.messages.length) {
      setErrors(errors);
      return;
    }
    setIsLoading(true);
    const baseUomForPost = { ...BASE_UOM_SKELETON, uom: baseUom };
    const response = await saveItemUom(id, [...itemUoms, baseUomForPost]);
    if (response.success) {
      setIsLoading(false);
      const message = i18n("alert.SaveUnitsOfMeasureSuccess");
      dispatch(openAlert({ type: "success", message }));
      close();
    } else {
      setIsLoading(false);
      handleProgramError(new Error(i18n("error.CouldNotSaveUom")));
    }
  }

  return (
    <Dialog open={true} onClose={close} sx={styles}>
      <ModalHeader
        label={i18n("global.UOM")}
        onClose={close}
        text={identifierText}
        save={save}
      />
      {(!itemUoms || isLoading) && <Loading />}
      <DialogContent>
        <FormErrors errors={errors} setErrors={setErrors} />
        {itemUoms ? (
          <>
            <VSpace space={1} />
            <FrmSelectFromObjectsWithHoverHelp
              selectProps={{
                name: "baseUom",
                minWidth: "16rem",
                label: i18n("frmLabel.BaseUnit"),
                options: uoms,
                value: baseUom,
                onValueChange: (_, value) => setBaseUom(value),
                error: isInError("baseUnit"),
                dataTesting: "baseUom",
              }}
              helpText={i18n("hoverHelp.BaseUnit")}
            />
            <VSpace space={1} />
            <Table size="small" padding="none" stickyHeader>
              <TableHead>
                <TableRow>
                  <LineColumnHeading />
                  <LineColumnHeading labelCode="Unit" />
                  <LineColumnHeading labelCode="Ratio" align="right" />
                  <LineColumnHeading labelCode="SalesPrice" align="right" />
                  <LineColumnHeading labelCode="PurchaseCost" align="right" />
                </TableRow>
              </TableHead>
              <TableBody>
                {itemUoms.map((uomData, index) => (
                  <Row
                    key={index}
                    baseUom={baseUom}
                    uoms={uoms}
                    rowIndex={index}
                    uomData={uomData}
                    setItemUoms={setItemUoms}
                    errors={errors}
                    handleFieldChange={handleFieldChange}
                    deleteRow={deleteRow}
                  />
                ))}
              </TableBody>
              <TableFooter>
                <TableRow sx={{ textAlign: "center" }}>
                  <AddLines
                    onClick={addLines}
                    title={textForAddLineOrLines(numLinesToAdd)}
                  />
                </TableRow>
              </TableFooter>
            </Table>
            <ul style={{ listStylePosition: "outside", paddingLeft: "1.0em" }}>
              <Typography component="li">
                {i18n("uom.EnterAllUnits")}
              </Typography>
              <Typography component="li">
                {i18n("uom.ConversionRatio")}
              </Typography>
            </ul>
            <VSpace space={1} />
          </>
        ) : (
          <FixedLoadingIndicator text={`${i18n("global.Loading")}...`} />
        )}
      </DialogContent>
    </Dialog>
  );
}

export function Row(props) {
  const {
    rowIndex,
    uoms,
    uomData,
    handleFieldChange,
    errors,
    deleteRow,
    baseUom,
  } = props;
  const { uom, conversion, salesPrice, purchaseCost } = uomData;

  const uomsWithoutBase = useMemo(
    () => uoms?.filter(({ id }) => id !== baseUom?.id),
    [uoms, baseUom?.id]
  );

  function isInError(field) {
    return errors.line[rowIndex]
      ? Boolean(errors.line[rowIndex].find((f) => f === field))
      : false;
  }

  return (
    <TableRow>
      <LineTableCell
        sx={{
          width: "2rem",
          borderLeft: `1px solid ${theme.palette.lineItemBorder}`,
          textAlign: "center",
        }}
      >
        <IconButton size="small" onClick={() => deleteRow(rowIndex)}>
          <Delete />
        </IconButton>
      </LineTableCell>
      <LineTableCell sx={{ width: "8rem" }}>
        <RowSelectReference
          name="uom"
          options={uomsWithoutBase}
          value={uom}
          onValueChange={handleFieldChange}
          dataIndex={rowIndex}
          dataTesting="uom"
          error={isInError("uom")}
        />
      </LineTableCell>
      <LineTableCell>
        <RowDecimalField
          name="conversion"
          value={conversion || ""}
          onValueBlur={handleFieldChange}
          dataIndex={rowIndex}
          error={isInError("conversion")}
        />
      </LineTableCell>

      <LineTableCell>
        <RowMoneyField
          name="salesPrice"
          value={salesPrice || ""}
          onBlur={handleFieldChange}
          dataIndex={rowIndex}
        />
      </LineTableCell>

      <LineTableCell>
        <RowMoneyField
          name="purchaseCost"
          value={purchaseCost || ""}
          onBlur={handleFieldChange}
          dataIndex={rowIndex}
        />
      </LineTableCell>
    </TableRow>
  );
}
