import { useSelector } from "react-redux";

import { Loading } from "classes/Loading";

import { LineItemNumericCalculatorField } from "components/formFields/LineItemNumericCalculatorField";
import { LineSkeleton } from "components/formFields/LineSkeleton";
import { LineTableCell } from "components/formFields/LineTableCell";

import { LINE_ITEM_METADATA } from "editConfig";

export function LineMargin(props) {
  const { onValueChange, objectType, margin } = props;
  const marginPricingEnabled = useSelector(
    (state) => state.userCompanySettings.settings.marginPricingEnabled
  );

  if (!marginPricingEnabled) {
    return null;
  }

  const isLoading = margin instanceof Loading;

  return (
    <LineTableCell sx={{ minWidth: "7rem" }}>
      {isLoading ? (
        <LineSkeleton />
      ) : (
        <LineItemNumericCalculatorField
          name="margin"
          value={margin || ""}
          onValueChange={onValueChange}
          metadata={LINE_ITEM_METADATA[objectType].margin}
          dataTesting="lineMargin"
        />
      )}
    </LineTableCell>
  );
}
