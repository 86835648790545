import { useEffect, useState } from "react";

import { IN_TRANSACTION } from "appConfig";

import { getAll } from "services/sosInventoryService/sosApi";
import { handleProgramError } from "services/utility/errors";

import { INDENTED_LIST_PADDING } from "appConstants";

const ALL_ACCOUNTS = "[all-accounts]";

export const ACCOUNT_TYPES = {
  income: ["Income", "OtherIncome"],
  expense: ALL_ACCOUNTS, // include all accounts
  asset: ["OtherCurrentAsset", "FixedAsset"],
  liability: ["OtherCurrentLiability"],
  otherCurrentAsset: ["OtherCurrentAsset"],
  // the two spellings below are the result of a historical QBO issue, and
  // should remain, probably indefinitely
  cogs: ["CostOfGoodsSold", "CostofGoodsSold"],
  deposit: ["OtherCurrentAsset", "Bank", "UndepositedFunds"],
};

export const ACCOUNT_NUMBER_WITH_NAME = 0;
export const ACCOUNT_NUMBER_SEPARATE_FIELD = 1;

function seperateAccountsByType(accounts) {
  return Object.keys(ACCOUNT_TYPES).reduce((seed, account) => {
    const types = ACCOUNT_TYPES[account];
    if (types === ALL_ACCOUNTS) {
      seed[account] = accounts;
    } else {
      seed[account] = accounts.filter(({ accountType }) =>
        types.includes(accountType)
      );
    }
    return seed;
  }, {});
}

/**
 * @name    createIndentedChartOfAccounts
 *
 * @summary 1. concatenates the (optional) account number and account name, and
            2. adds padding to indent subaccounts
 *
 * @param   accounts (array) - array of accounts
 * 
 * @param   numberPresentation (string) - one of two values; constants are
 *          provided for import: include the account number as prefix to
 *          the account name (ACCOUNT_NUMBER_WITH_NAME) or include the
 *          account number as a separate field (ACCOUNT_NUMBER_SEPARATE_FIELD)
 *
 * @returns (array) - an array of accounts, with added padding to indent subaccounts
 */

export function createIndentedChartOfAccounts(
  accounts,
  numberPresentation = ACCOUNT_NUMBER_WITH_NAME
) {
  if (numberPresentation === ACCOUNT_NUMBER_WITH_NAME) {
    return accounts.map((record) => ({
      id: record.id,
      name: [record.accountNumber, record.name]
        .join(" ")
        .padStart(
          [record.accountNumber, record.name].join(" ").length +
            record.sublevel,
          INDENTED_LIST_PADDING
        ),
      accountType: record.accountType,
      documents: record.documents,
    }));
  } else {
    return accounts.map((record) => ({
      id: record.id,
      name: record.name.padStart(
        record.name.length + 1 + record.sublevel,
        INDENTED_LIST_PADDING
      ),
      accountType: record.accountType,
      documents: record.documents,
      accountNumber: record.accountNumber,
      description: record.description,
    }));
  }
}

/**
 * @name    useChartOfAccounts
 *
 * @summary retrieves accounts and either returns them as either an array of indented
 *          accounts or an object seperated by types depending on the presence of the
 *          separateByType param
 *
 * @param   separateByType (boolean)
 *
 * @param   numberPresentation (string) - one of two values; constants are
 *          provided for import: include the account number as prefix to
 *          the account name (ACCOUNT_NUMBER_WITH_NAME) or include the
 *          account number as a separate field (ACCOUNT_NUMBER_SEPARATE_FIELD)
 *
 * @returns (array or object)
 *          (1) if separateByType is true, then this hook returns array of accounts
 *          (2) if seperateByType is false, then this hook returns an object containing
 *          income, asset, cogs, expense, and deposit keys containing their
 *          respective arrays that correspond to each key value
 */
export function useChartOfAccounts(
  separateByType,
  numberPresentation = ACCOUNT_NUMBER_WITH_NAME
) {
  const [records, setRecords] = useState(null);

  // TODO: This needs to account for > 200 chart of account records
  useEffect(() => {
    async function retrieveRecords() {
      const response = await getAll("account", IN_TRANSACTION);
      if (response.success) {
        const records = createIndentedChartOfAccounts(
          response.data.records,
          numberPresentation
        );
        setRecords(separateByType ? seperateAccountsByType(records) : records);
      } else {
        handleProgramError(
          new Error(
            `useChartOfAccounts | unsuccessful call to getList, message: ${response.message}`
          )
        );
      }
    }
    retrieveRecords();
  }, [separateByType, numberPresentation]);

  return records;
}
