// See services/sosInventoryService/README.md for information about the structure
// of schema.js files.
import { Decimal, Money } from "classes/DecimalClasses";

import { i18n } from "services/i18nService";
import { truncate } from "services/utility/formatting";
import { extractName } from "services/utility/formatting";
import { formatLinkField } from "services/utility/formatting";
import { formatDecimal } from "services/utility/formatting";
import { formatMoneyWithAdornments } from "services/utility/formatting";

import { USER_PRIVILEGE } from "appConstants";

export const columnInfo = [
  {
    name: "item",
    heading: i18n("columns.Item"),
    defaultSort: true,
    sortDirection: "asc",
    sortable: true,
    default: true,
    fixed: true,
    formatFunc: [extractName, formatLinkField],
    minWidth: "15em",
    linkField: true,
  },
  {
    name: "description",
    heading: i18n("columns.Description"),
    minWidth: "15em",
    formatFunc: truncate,
    sortable: true,
    default: true,
  },
  {
    name: "vendor",
    heading: i18n("columns.Vendor"),
    formatFunc: extractName,
    minWidth: "10em",
    maxWidth: "20em",
    sortable: true,
    default: true,
  },
  {
    name: "vendorPartNumber",
    heading: i18n("columns.VendorPartNumber"),
    sortable: true,
    minWidth: "10em",
    privilegeRestriction: [
      USER_PRIVILEGE.editItems,
      USER_PRIVILEGE.viewVendors,
    ],
  },
  {
    name: "quantity",
    heading: i18n("columns.Quantity"),
    formatFunc: formatDecimal,
    align: "right",
    sortable: true,
  },
  {
    name: "unitprice",
    heading: i18n("columns.UnitPrice"),
    formatFunc: formatMoneyWithAdornments,
    align: "right",
    sortable: true,
  },
  {
    name: "location",
    heading: i18n("columns.Location"),
    formatFunc: extractName,
    sortable: true,
  },
];

export function getEmptyRecord() {
  return {
    item: null,
    description: "",
    location: null,
    unitprice: new Money(0),
    quantity: new Decimal(0),
    vendor: null,
    vendorPartNumber: "",
  };
}

export const FIELDS_TO_TRANSFORM = {
  DECIMAL_FIELDS: ["quantity"],
  MONEY_FIELDS: ["unitprice"],
  DATE_FIELDS: [],
  DATETIME_FIELDS: [],
  NON_UTC_DATETIME_FIELDS: [],
};

export const REFERENCE_FIELDS = ["location", "vendor"];

export const LINE_ACTIONS = ["delete"];
export const BATCH_ACTIONS = ["delete", "pos"];

export const ITEM_CALCULATED_FIELDS = [];
