import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import {
  CalendarMonth,
  Delete,
  RemoveCircleOutline,
} from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";

import { Link } from "components/html/Link";
import { WarningModal } from "components/utility/WarningModal";

import { i18n } from "services/i18nService";
import {
  getAll,
  getReportFavorites,
  unfavoriteReport,
  deleteCustomReport,
  unscheduleReport,
  saveAsNewCustomReport,
  scheduleReport,
} from "services/sosInventoryService/sosApi";
import { handleProgramError } from "services/utility/errors";
import { userPrivilegesObject } from "services/utility/privileges";
import { formatSchedule } from "services/utility/reports";

import { usePlans } from "hooks/usePlans";
import { usePrivileges } from "hooks/usePrivileges";

import { REPORTS_BY_CATEGORY, REPORTS } from "views/Reports/reportConfig";

import { theme } from "SosTheme";

export function Home() {
  const [expanded, setExpanded] = useState("favorites");
  const [favorites, setFavorites] = useState();
  const [customsAndScheduleds, setCustomsAndScheduleds] = useState([]);
  const [favoriteToBeDeleted, setFavoriteToBeDeleted] = useState();
  const [reportToBeDeleted, setCustomToBeDeleted] = useState();

  const navigate = useNavigate();

  async function getFavoritesAndCustomAndScheduled() {
    let response = await getAll("customReport");
    if (!response.success) {
      handleProgramError(
        new Error(i18n("reports.Alert.Custom.ErrorRetrieving"))
      );
    }
    const customs = response.data.records;
    // get scheduled reports
    response = await getAll("schedule");
    if (!response.success) {
      handleProgramError(
        new Error(i18n("reports.Alert.Scheduled.ErrorRetrieving"))
      );
    }
    const scheduleds = response.data.records;
    // filter to just standard (no custom id)
    const scheduledStandards = scheduleds.filter(
      ({ customReport }) => !customReport
    );
    // create name field from standard report type
    const namedScheduledStandards = scheduledStandards.map((standard) => ({
      ...standard,
      name: i18n(`reports.Report.${standard.type}`),
      scheduledStandard: true,
      schedule: {
        frequency: standard.frequency,
        dayOfWeek: standard.dayOfWeek,
        timeOfDay: standard.timeOfDay,
        email: standard.email,
        ftp: standard.ftp,
      },
    }));
    // add to custom
    const combined = customs.concat(namedScheduledStandards);
    // sort
    const sorted = combined.sort((a, b) => (a.name < b.name ? -1 : 1));
    setCustomsAndScheduleds(sorted);

    const favorites = await getReportFavorites();

    // now that we have favorites and custom and scheduled, add schedule
    // to favorites as appropriate
    const favoritesWithSchedule = favorites.map((favorite) => {
      // a custom favorite has a reportCustomizationId
      if (favorite.reportCustomizationId) {
        const schedule = sorted.find(
          (report) => report.id === favorite.reportCustomizationId
        )?.schedule;
        return { ...favorite, schedule };
      } else {
        // it's a standard report
        const schedule = sorted.find(
          (report) => report.type === favorite.type
        )?.schedule;
        return { ...favorite, schedule };
      }
    });

    setFavorites(favoritesWithSchedule);
  }

  useEffect(() => {
    getFavoritesAndCustomAndScheduled();
  }, []);

  const userPrivilegesRaw = useSelector(
    (state) => state.userCompanySettings.company.userPrivileges,
    (prev, curr) => curr?.id === prev?.id
  );
  const userPrivileges = userPrivilegesObject(userPrivilegesRaw);
  const { isAdmin, isSupport } = usePrivileges();

  const { allowPlusFeatures, allowProFeatures } = usePlans();

  function expandUnexpandPanel(panel) {
    setExpanded(panel === expanded ? null : panel);
  }

  async function deleteFavorite() {
    const response = await unfavoriteReport(favoriteToBeDeleted.id);
    if (response.success) {
      setFavoriteToBeDeleted(null);
    } else {
      handleProgramError(new Error("Could not unfavorite this report"));
    }
    getFavoritesAndCustomAndScheduled();
  }

  async function deleteCustom() {
    if (reportToBeDeleted.scheduledStandard) {
      const success = await unscheduleReport(reportToBeDeleted.id);
      if (success) {
        setCustomToBeDeleted(null);
      } else {
        handleProgramError(new Error("Could not unschedule this report"));
      }
    } else {
      const success = await deleteCustomReport(reportToBeDeleted.id);
      if (success) {
        setCustomToBeDeleted(null);
      } else {
        handleProgramError(new Error("Could not delete this report"));
      }
    }
    getFavoritesAndCustomAndScheduled();
  }

  async function openCustomOrScheduled(report) {
    if (report.scheduledStandard) {
      // save as a custom report first...
      const customResponse = await saveAsNewCustomReport(
        report.type,
        report.name,
        {}, // leave settings...
        [] // ...and columns null, since this is a standard report
      );
      // ...then create a new schedule record...
      const scheduleResponse = await scheduleReport(
        report.type,
        { id: customResponse.report.id },
        report.schedule.frequency,
        report.schedule.timeOfDay,
        report.schedule.dayOfWeek,
        report.schedule.email,
        report.schedule.ftp
      );
      // ...and delete the old schedule record
      const deleteResponse = await unscheduleReport(report.id);
      if (
        customResponse.success &&
        scheduleResponse.success &&
        deleteResponse
      ) {
        navigate(`/customreport/${customResponse.report.id}`);
      } else {
        handleProgramError(
          new Error(i18n("reports.Alert.ConvertScheduledStandardError"))
        );
      }
    } else {
      navigate(`/customreport/${report.id}`);
    }
  }

  return (
    <>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          columnGap: theme.spacing(3),
          margin: theme.spacing(2),
          marginBottom: 0,
          width: "100%",
          alignContent: "start",
        }}
      >
        <div
          style={{
            gridColumnStart: 1,
            gridColumnEnd: 3,
            marginBottom: theme.spacing(2),
          }}
        >
          <Typography variant="h2">{i18n("global.Reports")}</Typography>
        </div>
        <div>
          {REPORTS_BY_CATEGORY.map((category) => (
            <Fragment key={category.categoryCode}>
              {category.planIncludesCategory(
                allowPlusFeatures,
                allowProFeatures
              ) && (
                <Accordion
                  expanded={expanded === category.categoryCode}
                  onChange={() => expandUnexpandPanel(category.categoryCode)}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5">
                      {i18n(`reports.CategoryName.${category.categoryCode}`)}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {category.reports.map((report) => (
                      <Fragment key={report}>
                        {(isAdmin ||
                          isSupport ||
                          REPORTS[report].userHasSufficientPrivilege(
                            userPrivileges
                          )) &&
                          REPORTS[report].planIncludesReport(
                            allowPlusFeatures,
                            allowProFeatures
                          ) && (
                            <div key={report}>
                              <Link
                                to={
                                  report === "DeletedItems"
                                    ? "/deleteditem"
                                    : `/report/${report}`
                                }
                                underline="none"
                              >
                                {i18n(`reports.Report.${report}`)}
                              </Link>
                            </div>
                          )}
                      </Fragment>
                    ))}
                  </AccordionDetails>
                </Accordion>
              )}
            </Fragment>
          ))}
        </div>
        <div>
          <Accordion
            expanded={expanded === "favorites"}
            onChange={() => expandUnexpandPanel("favorites")}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5" color={theme.palette.accent}>
                Favorites
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {favorites ? (
                <>
                  {favorites.map((favorite) => (
                    <Box
                      key={favorite.id}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box>
                        <Link
                          to={
                            favorite.reportCustomizationId
                              ? `/customreport/${favorite.reportCustomizationId}`
                              : `/report/${favorite.type}`
                          }
                        >
                          {favorite.reportCustomizationId
                            ? favorite.customizationName
                            : i18n(`reports.Report.${favorite.type}`)}
                        </Link>
                        {favorite.schedule && (
                          <Chip
                            label={formatSchedule(favorite.schedule)}
                            size="small"
                            sx={{
                              marginLeft: "0.25rem",
                              marginBottom: "0.15rem",
                            }}
                            icon={<CalendarMonth />}
                          />
                        )}
                      </Box>
                      <Tooltip title={i18n("tooltip.unfavorite")}>
                        <IconButton
                          onClick={() => setFavoriteToBeDeleted(favorite)}
                          sx={{ height: "20px", width: "20px" }}
                        >
                          <RemoveCircleOutline
                            sx={{
                              color: "favoritedHeart",
                              cursor: "pointer",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  ))}
                </>
              ) : (
                <Typography>{i18n("global.Loading")}...</Typography>
              )}
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "customAndScheduled"}
            onChange={() => expandUnexpandPanel("customAndScheduled")}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5" color={theme.palette.accent}>
                {i18n("reports.CustomScheduled.Heading")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {customsAndScheduleds ? (
                <>
                  {customsAndScheduleds.map((customOrScheduled) => (
                    <Box
                      key={customOrScheduled.id}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        lineHeight: "1.75rem",
                        color: "primaryLink",
                        cursor: "pointer",
                      }}
                    >
                      <span
                        onClick={() => openCustomOrScheduled(customOrScheduled)}
                      >
                        {customOrScheduled.name}
                        {customOrScheduled.schedule && (
                          <Chip
                            label={formatSchedule(customOrScheduled.schedule)}
                            size="small"
                            sx={{
                              marginLeft: "0.25rem",
                              marginBottom: "0.15rem",
                            }}
                            icon={<CalendarMonth />}
                          />
                        )}
                      </span>
                      <IconButton
                        onClick={() => setCustomToBeDeleted(customOrScheduled)}
                        sx={{ height: "20px", width: "20px" }}
                        size="small"
                      >
                        <Delete sx={{ color: "selectIcon" }} />
                      </IconButton>
                    </Box>
                  ))}
                </>
              ) : (
                <Typography>{i18n("global.Loading")}...</Typography>
              )}
            </AccordionDetails>
          </Accordion>
        </div>
      </div>

      <WarningModal
        title={i18n("reports.UnfavoriteThisReportHeader")}
        message={
          favoriteToBeDeleted
            ? i18n("reports.UnfavoriteThisReport", {
                reportName: favoriteToBeDeleted?.reportCustomizationId
                  ? favoriteToBeDeleted.customizationName
                  : i18n(`reports.Report.${favoriteToBeDeleted?.type}`),
              })
            : ""
        }
        open={Boolean(favoriteToBeDeleted?.id)}
        onOk={() => deleteFavorite()}
        onClose={() => setFavoriteToBeDeleted(null)}
      />

      {reportToBeDeleted && (
        <WarningModal
          title={
            reportToBeDeleted.scheduledStandard
              ? i18n("reports.Unschedule")
              : i18n("global.Delete")
          }
          message={
            reportToBeDeleted.scheduledStandard
              ? i18n("reports.UnscheduleThisReport", {
                  reportName: i18n(`reports.Report.${reportToBeDeleted.type}`),
                })
              : reportToBeDeleted.schedule
              ? i18n("reports.UnscheduleAndDelete", {
                  reportName: reportToBeDeleted.name,
                })
              : i18n("reports.DeleteThisReport", {
                  reportName: reportToBeDeleted.name,
                })
          }
          open={Boolean(reportToBeDeleted?.id)}
          onOk={() => deleteCustom()}
          onClose={() => setCustomToBeDeleted(null)}
        />
      )}
    </>
  );
}
