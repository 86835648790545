import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Delete } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  Table,
  TableFooter,
  TableHead,
  TableRow,
  TableBody,
  IconButton,
  Typography,
} from "@mui/material";

import { CopyDownLineItem } from "components/CopyDownLineItem";
import { AddLines } from "components/EditPage/AddLines";
import { ClearLines } from "components/EditPage/ClearLines";
import { InsertLineItem } from "components/InsertLineItem";
import { LineTableHeadingCell } from "components/formFields/LineTableCell";
import { LineTableCell } from "components/formFields/LineTableCell";
import { LineColumnHeading } from "components/formFields/line/LineColumnHeading";
import { RowItem } from "components/formFields/row/RowItem";
import { RowMoneyField } from "components/formFields/row/RowMoneyField";
import { RowTextField } from "components/formFields/row/RowTextField";
import { FixedLoadingIndicator } from "components/utility/FixedLoadingIndicator";
import { FormErrors } from "components/utility/FormErrors";
import { HoverHelp } from "components/utility/HoverHelp";
import { Loading } from "components/utility/Loading";
import { ModalHeader } from "components/utility/ModalHeader";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import {
  getVariants,
  saveVariants,
  VARIANT_TYPE_MISMATCH,
  VARIANT_OF_ANOTHER_ITEM,
} from "services/sosInventoryService/sosApi";
import { EMPTY_LINE } from "services/sosInventoryService/variant/schema";
import { handleProgramError } from "services/utility/errors";
import { textForAddLineOrLines } from "services/utility/lineItems";

import { useErrors } from "hooks/useErrors";
import { useItems } from "hooks/useItems";

import { openAlert } from "globalState/alertSlice";

import { theme } from "SosTheme";

const sx = {
  "& .MuiDialog-paper": {
    minHeight: "50vh",
    maxHeight: "93vh",
    "@media print": { minHeight: "100%", maxHeight: "100%" },
  },
};

export function Variants(props) {
  const { close, id, identifierText, itemType } = props;

  const [isLoading, setIsLoading] = useState(false);
  const { items, itemsCount } = useItems();
  const [record, setRecord] = useState(null);
  const { errors, setErrors } = useErrors();

  const dispatch = useDispatch();

  const numLinesToAdd = useSelector(
    (state) => state.userCompanySettings.settings.numLinesToAdd
  );

  useEffect(() => {
    async function _getItemUom(id) {
      if (id) {
        const response = await getVariants(id);
        setRecord(response);
      }
    }
    _getItemUom(id);
  }, [id]);

  function handleFieldChange(name, value, rowIndex) {
    setRecord((prevRecord) => ({
      ...prevRecord,
      lines: prevRecord.lines.map((e, index) =>
        rowIndex === index ? { ...e, [name]: value } : e
      ),
    }));
  }

  async function handleItemChange(_, value, rowIndex) {
    setRecord((prevRecord) => ({
      ...prevRecord,
      lines: prevRecord.lines.map((e, index) =>
        rowIndex === index ? { ...e, item: value } : e
      ),
    }));
  }

  function clearLines() {
    const lines = new Array(numLinesToAdd).fill(EMPTY_LINE);
    setRecord((prevRecord) => ({ ...prevRecord, lines }));
  }

  function deleteRow(rowIndex) {
    setRecord((prevRecord) => ({
      ...prevRecord,
      lines: prevRecord.lines.filter((_, index) => index !== rowIndex),
    }));
  }

  function copyRow(rowIndex) {
    const lines = record.lines;
    lines.splice(rowIndex + 1, 1, record.lines[rowIndex]);
    setRecord((prevRecord) => ({ ...prevRecord, lines }));
  }

  function insertRow(rowIndex) {
    let lines = record.lines;
    lines.splice(rowIndex, 0, EMPTY_LINE);
    setRecord((prevRecord) => ({ ...prevRecord, lines }));
  }

  function appendRows() {
    const newLines = new Array(numLinesToAdd).fill(EMPTY_LINE);
    const lines = [...record.lines, ...newLines];
    setRecord((prevRecord) => ({ ...prevRecord, lines }));
  }

  async function save() {
    setIsLoading(true);
    const response = await saveVariants(id, record);
    if (response.success) {
      const message = i18n("alert.SaveVariantSuccess");
      dispatch(openAlert({ type: "success", message }));
      close();
    } else if (response.message === VARIANT_TYPE_MISMATCH) {
      const message = i18n("alert.VariantTypeMismatch");
      dispatch(openAlert({ type: "error", message }));
    } else if (response.message === VARIANT_OF_ANOTHER_ITEM) {
      const message = i18n("alert.VariantOfAnotherItem");
      dispatch(openAlert({ type: "error", message }));
    } else {
      handleProgramError(
        new Error(
          `${i18n("error.SaveVariantsFailure")} : ${response.message || ""}`
        )
      );
    }
    setIsLoading(false);
  }

  return (
    <Dialog open={true} fullWidth maxWidth="lg" onClose={close} sx={sx}>
      <ModalHeader
        label={i18n("global.Variants")}
        onClose={close}
        text={identifierText}
        save={save}
      />
      {(!record || isLoading) && <Loading />}
      <DialogContent>
        <FormErrors errors={errors} setErrors={setErrors} />
        {record ? (
          <>
            <VSpace space={1} />
            <Table size="small" padding="none" stickyHeader>
              <TableHead>
                <TableRow>
                  <ClearLines setClearLines={clearLines} />
                  <LineColumnHeading labelCode="VariantName" />
                  <LineColumnHeading labelCode="Sku" />
                  <LineColumnHeading labelCode="SalesPrice" align="right" />
                  <LineColumnHeading labelCode="PurchaseCost" align="right" />
                  <LineTableHeadingCell>
                    <div style={{ display: "flex" }}>
                      <div>{i18n(`columns.LinkedItem`)}</div>
                      <HoverHelp
                        title={i18n("hoverHelp.VariantItemName")}
                        sx={{ marginLeft: 0.5 }}
                      />
                    </div>
                  </LineTableHeadingCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {record.lines.map((variant, index, array) => (
                  <Row
                    itemName={record.item.name}
                    key={index}
                    variant={variant}
                    items={items}
                    itemsCount={itemsCount}
                    itemType={itemType}
                    rowIndex={index}
                    handleItemChange={handleItemChange}
                    handleFieldChange={handleFieldChange}
                    copyRow={copyRow}
                    insertRow={insertRow}
                    deleteRow={deleteRow}
                    showCopyDown={index !== array.length - 1}
                  />
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <AddLines
                    title={textForAddLineOrLines(numLinesToAdd)}
                    onClick={appendRows}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </>
        ) : (
          <FixedLoadingIndicator text={`${i18n("global.Loading")}...`} />
        )}
      </DialogContent>
    </Dialog>
  );
}

export function Row(props) {
  const {
    rowIndex,
    items,
    itemsCount,
    itemName,
    itemType,
    variant,
    handleFieldChange,
    handleItemChange,
    insertRow,
    copyRow,
    showCopyDown,
    deleteRow,
  } = props;
  const { variantName, item, salesPrice, purchaseCost, sku } = variant;

  return (
    <TableRow>
      <LineTableCell
        sx={{
          width: "3rem",
          borderLeft: `1px solid ${theme.palette.lineItemBorder}`,
          textAlign: "center",
          position: "relative",
        }}
      >
        <InsertLineItem onClick={() => insertRow(rowIndex)} />
        {showCopyDown && (
          <CopyDownLineItem onCopyDown={() => copyRow(rowIndex)} />
        )}
        <IconButton size="small" onClick={() => deleteRow(rowIndex)}>
          <Delete />
        </IconButton>
      </LineTableCell>
      <LineTableCell sx={{ width: "20rem" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            whiteSpace: "nowrap",
            paddingLeft: "1rem",
          }}
        >
          <Typography sx={{ color: "disabled" }}>
            {itemName} {" - "}
          </Typography>
          <RowTextField
            name="variantName"
            value={variantName}
            onValueBlur={handleFieldChange}
            dataIndex={rowIndex}
          />
        </div>
      </LineTableCell>
      <LineTableCell sx={{ width: "16rem" }}>
        <RowTextField
          name="sku"
          value={sku}
          onValueBlur={handleFieldChange}
          dataIndex={rowIndex}
        />
      </LineTableCell>
      <LineTableCell>
        <RowMoneyField
          name="salesPrice"
          value={salesPrice || ""}
          onBlur={handleFieldChange}
          dataIndex={rowIndex}
        />
      </LineTableCell>

      <LineTableCell>
        <RowMoneyField
          name="purchaseCost"
          value={purchaseCost || ""}
          onBlur={handleFieldChange}
          dataIndex={rowIndex}
        />
      </LineTableCell>

      <LineTableCell sx={{ width: "16rem" }}>
        <RowItem
          dataIndex={rowIndex}
          itemsCount={itemsCount}
          items={items}
          onValueChange={handleItemChange}
          value={item}
          itemType={itemType}
        />
      </LineTableCell>
    </TableRow>
  );
}
