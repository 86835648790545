import { i18n } from "services/i18nService";

import {
  NONE_GROUP_BY_OPTION,
  GROUP_BY_ITEM_OR_VENDOR_OPTIONS,
  SUB_COMPONENT,
} from "views/Reports/reportConstants";

import { ALL_FILTER_OPTION } from "appConstants";

export const VendorEfficiency = {
  userHasSufficientPrivilege: (p) =>
    p.viewPurchaseOrders || p.editPurchaseOrders || p.receiveInventory,
  planIncludesReport: () => true,
  relatedLists: ["vendor", "location", "category"],
  customFieldObjectTypes: ["item", "purchaseorder"],
  columns: [
    { apiName: "OrderNumber", label: "PO #", default: true },
    { apiName: "PO Date", default: true },
    { apiName: "Vendor", default: true },
    { apiName: "Item", default: true },
    { apiName: "Location" },
    { apiName: "Description", default: true },
    { apiName: "Ordered", default: true, number: true },
    { apiName: "Received", default: true, number: true },
    { apiName: "Backordered" },
    { apiName: "UOM" },
    { apiName: "Unit Price", number: true },
    { apiName: "Open Amt", number: true },
    { apiName: "Total Amt", number: true },
    { apiName: "ETA" },
    { apiName: "Expected Ship" },
    { apiName: "Customer" },
    { apiName: "VendorPartNumber", label: "Vendor Part #" },
    { apiName: "Terms" },
    { apiName: "Ship Method" },
    { apiName: "Comment" },
    { apiName: "Weight Each", number: true },
    { apiName: "Weight Unit" },
    { apiName: "On Hand", number: true },
  ],
  settings: [
    {
      apiName: "date1",
      macroApiName: "date1Macro",
      headerText: i18n("filter.heading.from"),
      component: "DateFilter",
      label: i18n("reports.FilterLabel.FromDate"),
      defaultValue: null,
      datePrefix: "startOf",
      type: "filter",
    },
    {
      apiName: "date1Macro",
      defaultValue: null,
      component: SUB_COMPONENT,
      type: "filter",
    },
    {
      apiName: "date2",
      macroApiName: "date2Macro",
      component: "DateFilter",
      label: i18n("reports.FilterLabel.ToDate"),
      datePrefix: "endOf",
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "date2Macro",
      defaultValue: null,
      component: SUB_COMPONENT,
      type: "filter",
    },
    {
      label: i18n("filter.Location"),
      component: "LocationFilter",
      apiName: "locations",
      defaultValue: [ALL_FILTER_OPTION],
      includeAllOption: true,
      type: "filter",
    },
    {
      label: i18n("filter.Categories"),
      component: "CategoryFilter",
      apiName: "categories",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.ItemNameContains"),
      component: "FrmTextField",
      apiName: "nameContains",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("filter.Vendor"),
      component: "VendorFilter",
      apiName: "vendor",
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "showFromTo",
      defaultValue: false,
      label: i18n("reports.OptionLabel.ShowFromAndToDates"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "groupBy",
      label: i18n("reports.OptionLabel.GroupBy"),
      component: "FrmButtonGroup",
      defaultValue: NONE_GROUP_BY_OPTION,
      options: GROUP_BY_ITEM_OR_VENDOR_OPTIONS,
      type: "option",
    },
    {
      apiName: "header",
      defaultValue: "",
      label: i18n("global.Header"),
      component: "FrmTextField",
      type: "option",
    },
    {
      apiName: "footer",
      defaultValue: "",
      label: i18n("global.Footer"),
      component: "FrmTextField",
      type: "option",
    },
  ],
};
