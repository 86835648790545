import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";

import {
  TableContainer,
  TableHead,
  Table,
  TableBody,
  TableRow,
  TableFooter,
} from "@mui/material";

import { AddLines } from "components/EditPage/AddLines";
import { ClearLines } from "components/EditPage/ClearLines";
import { LineTableHeadingCell } from "components/formFields/LineTableCell";
import { LineTableFooterCell } from "components/formFields/LineTableCell";
import { LineColumnFillHeading } from "components/formFields/line/LineColumnFillHeading";
import { LineColumnHeading } from "components/formFields/line/LineColumnHeading";
import { LineDragInsertCopy } from "components/formFields/line/LineDragInsertCopy";
import { DragHandleHeading } from "components/utility/DragHandle";

import { i18n } from "services/i18nService";
import { setPageDirty } from "services/utility/edit";
import { handleDrop } from "services/utility/lineItems";
import { textForAddLineOrLines } from "services/utility/lineItems";

import { useJobWorkcenters } from "hooks/useJobWorkcenters";

import { LineItem } from "views/Payments/Payment/LineItem";

import { EMPTY_LINE_ITEM } from "editConfig";

const sx = { verticalAlign: "bottom", lineHeight: "1" };

export function LineItems(props) {
  const {
    relatedRecords,
    objectType,
    lines,
    classes,
    lineHandler,
    lineItemsErrors,
    setErrors,
    setClearLines,
  } = props;

  const jobWorkcenters = useJobWorkcenters();

  // SETTINGS
  const classEnabled = useSelector(
    (state) => state.userCompanySettings.settings.classEnabled
  );
  const jobEnabled = useSelector(
    (state) => state.userCompanySettings.settings.jobEnabled
  );
  const numLinesToAdd = useSelector(
    (state) => state.userCompanySettings.settings.numLinesToAdd
  );

  function insertEmptyLine(insertAt) {
    setPageDirty();
    lineHandler({
      type: "insert",
      insertAt: insertAt,
      newLine: EMPTY_LINE_ITEM[objectType],
    });
  }

  function copyDown(line) {
    const updatedLine = { ...line, lineNumber: line.lineNumber + 1 };
    delete updatedLine.linkedTransaction;
    delete updatedLine.id;
    setPageDirty();
    lineHandler({ type: "update", updatedLine });
  }

  function addLines() {
    setPageDirty();
    lineHandler({
      type: "appendEmpty",
      numLinesToAdd,
      objectType,
    });
  }

  if (!lines) {
    return null;
  }

  return (
    <TableContainer data-testing="lineItems" style={{ overflowX: "initial" }}>
      <Table
        sx={{ borderCollapse: "collapse" }}
        size="small"
        padding="none"
        stickyHeader
      >
        <TableHead>
          <TableRow sx={sx}>
            <DragHandleHeading />
            <ClearLines setClearLines={setClearLines} />
            <LineTableHeadingCell>{i18n("columns.Line")}</LineTableHeadingCell>
            <LineColumnHeading labelCode="Description" />
            <LineColumnHeading labelCode="Amount" />
            <LineColumnHeading labelCode="AppliedTo" />
            {classEnabled && (
              <LineColumnFillHeading
                labelCode="Class"
                field="class"
                lineHandler={lineHandler}
              />
            )}
            {jobEnabled && (
              <LineColumnFillHeading
                labelCode="Job"
                field="jobWorkcenter"
                lineHandler={lineHandler}
              />
            )}
          </TableRow>
        </TableHead>
        <DragDropContext
          onDragEnd={(result) => handleDrop(result, lineHandler, lines)}
        >
          <Droppable droppableId="lineItems">
            {(provided) => (
              <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                {lines.map((line, index) => (
                  <Draggable key={index} draggableId={`${index}`} index={index}>
                    {(draggableProvided, snapshot) => {
                      return (
                        <TableRow
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          style={{
                            ...draggableProvided.draggableProps.style,
                            position: "inherit",
                            backgroundColor: snapshot.isDragging
                              ? "dragBackground"
                              : "none",
                          }}
                          data-testing={"line-" + index}
                        >
                          <LineDragInsertCopy
                            draggableProvided={draggableProvided}
                            snapshot={snapshot}
                            insertEmptyLine={insertEmptyLine}
                            lineNumber={line.lineNumber}
                            onCopyDown={() => copyDown(line)}
                            showCopyDown={
                              line.lineNumber === lines.length ? false : true
                            }
                          />

                          <LineItem
                            lineNumber={line.lineNumber}
                            relatedRecords={relatedRecords}
                            line={line}
                            lineHandler={lineHandler}
                            classes={classes}
                            jobWorkcenters={jobWorkcenters}
                            lineItemsErrors={lineItemsErrors}
                            setErrors={setErrors}
                            draggableProvided={draggableProvided}
                            draggableSnapshot={snapshot}
                          />
                        </TableRow>
                      );
                    }}
                  </Draggable>
                ))}
                {provided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
        <TableFooter>
          <TableRow>
            <LineTableFooterCell sx={{ border: "none" }} />
            <AddLines
              title={textForAddLineOrLines(numLinesToAdd)}
              onClick={addLines}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
