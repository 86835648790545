import { useNavigate, useLocation } from "react-router-dom";

import { CONFIGURATION_LISTS } from "appConfig";

export function useCustomNavigate(objectType) {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const linkPrefix = CONFIGURATION_LISTS.includes(objectType)
    ? "/configuration/list"
    : "";

  function goToList() {
    if (pathname.includes(objectType)) {
      navigate(`${linkPrefix}/${objectType}${search}`);
    }
  }

  function clearListParams() {
    if (pathname.includes(objectType)) {
      navigate(`${linkPrefix}/${objectType}`);
    }
  }

  return { goToList, clearListParams };
}
