import { Decimal, Money } from "classes/DecimalClasses";

import { determineBestUnitToDisplay } from "services/sosInventoryService/domainLogic";
import { decimalMaximumFractionDigits } from "services/utility/formatting";
import { isNonZeroDecimalOrMoney } from "services/utility/misc";

import {
  DEFAULT_DECIMALS_ROUNDED,
  WEIGHT_CONVERSION_FACTORS,
} from "appConstants";

export function updateAmount(line) {
  const { quantity, unitprice } = line;
  const amount = quantity
    .times(unitprice || new Money(0))
    .round(DEFAULT_DECIMALS_ROUNDED);
  return { ...line, amount };
}

export function updateWeightAndVolume(line) {
  const { uom, itemDetails, quantity } = line;
  const conversion = getUomConversion(itemDetails, uom);

  const weight = itemDetails.itemWeight
    ? quantity.times(itemDetails.itemWeight).times(conversion)
    : new Decimal(0);

  const volume = itemDetails.itemVolume
    ? quantity.times(itemDetails.itemVolume).times(conversion)
    : new Decimal(0);

  return { ...line, weight, volume };
}

export function updateLot(line) {
  return { ...line, lot: null };
}

export function updateSerials(line) {
  return { ...line, serials: null };
}

// always returns result in the units that weightUnit will return for
// the same set of lines
export function totalWeight(lines, localization) {
  if (!lines || lines.length === 0) {
    return new Decimal(0);
  }

  // convert each line weight to grams
  // weightunit may be blank; weight is always present, but may be 0.00
  const totalWeight = lines.reduce((total, line) => {
    if (line.weightunit === null) {
      return total;
    }
    // the following adds nothing to the weight total if the weightunit is blank
    let convertedWeight = Decimal.ZERO;
    if (line.weightunit !== "") {
      convertedWeight = line.weight.times(
        WEIGHT_CONVERSION_FACTORS[line.weightunit]
      );
    }
    return total.plus(convertedWeight);
  }, Decimal.ZERO);

  const unit = weightUnit(lines);
  const convertedWeight = totalWeight.div(WEIGHT_CONVERSION_FACTORS[unit]);
  return decimalMaximumFractionDigits(
    convertedWeight,
    localization.locale,
    5,
    true
  );
}

export function weightUnit(lines) {
  const units = lines.map((line) => {
    return line.weightunit;
  });
  return determineBestUnitToDisplay(units);
}

// this should be called each time a line item is modified;
// it returns a new copy of the line, with any necessary changes made
export function afterTouchLine(updaters, line, changedField, record) {
  if (!updaters[changedField]) {
    return line;
  }

  let newLine = { ...line };

  updaters[changedField].forEach((updater) => {
    newLine = updater(newLine, record);
  });

  return newLine;
}

export function getUomDataFromItemDetails(itemDetails, uom) {
  return itemDetails.itemUoms?.find((uomData) => uomData.uom.id === uom?.id);
}

export function getUomConversion(itemDetails, uom) {
  const uomData = getUomDataFromItemDetails(itemDetails, uom);
  return isNonZeroDecimalOrMoney(uomData?.conversion)
    ? uomData.conversion
    : new Decimal(1);
}

export function getUomConversionRatio(itemDetails, uom, oldUom) {
  const oldConversion = getUomConversion(itemDetails, oldUom);
  const conversion = getUomConversion(itemDetails, uom);
  return oldConversion.div(conversion);
}
