import { useState, useRef } from "react";

import { KeyboardArrowDown } from "@mui/icons-material";
import { Button } from "@mui/material";

import { ActionMenu } from "components/ActionMenu";
import { BatchRefundDialog } from "components/BatchRefundDialog";
import { DeleteDialog } from "components/DeleteDialog";
import { EmailDialog } from "components/EmailDialog";
import { GeneratePurchaseOrdersDialog } from "components/GeneratePurchaseOrdersDialog";
import { MergeDialog } from "components/MergeDialog";
import { PdfDialog } from "components/PdfDialog";

import { i18n } from "services/i18nService";
import { checkForUnexpectedProps } from "services/utility/misc";

export function BatchActions(props) {
  const {
    objectType,
    checkedIds,
    setCheckedIds,
    formTemplates,
    setSelectAllState,
    ...unexpected
  } = props;
  checkForUnexpectedProps("BatchActions", unexpected);

  const [showPdf, setShowPdf] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showMerge, setShowMerge] = useState(false);
  const [showRefund, setShowRefund] = useState(false);
  const [showGeneratePurchaseOrders, setShowGeneratePurchaseOrders] =
    useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const menuRef = useRef();

  function handleClose() {
    setShowPdf(false);
    setShowEmail(false);
    setShowDelete(false);
    setShowMerge(false);
    setShowRefund(false);
    setShowGeneratePurchaseOrders(false);
    setAnchorEl(null);
    setCheckedIds([]);
    setSelectAllState(false);
  }

  return (
    <>
      <Button
        ref={menuRef}
        onClick={() => setAnchorEl(menuRef.current)}
        sx={{ cursor: "pointer" }}
      >
        {i18n("global.BatchActions")}
        <KeyboardArrowDown sx={{ marginLeft: "0.5rem" }} />
      </Button>
      <ActionMenu
        ids={checkedIds}
        setCheckedIds={setCheckedIds}
        setSelectAllState={setSelectAllState}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        keepMounted={true}
        formTemplates={formTemplates}
        setShowPdf={setShowPdf}
        setShowEmail={setShowEmail}
        setShowDelete={setShowDelete}
        setShowRefund={setShowRefund}
        setShowMerge={setShowMerge}
        setShowGeneratePurchaseOrders={setShowGeneratePurchaseOrders}
        isBatchMenu={true}
        objectType={objectType}
        disabled={!checkedIds.length}
      />
      <PdfDialog
        open={showPdf}
        onClose={handleClose}
        objectType={objectType}
        objectIds={checkedIds}
        formTemplates={formTemplates}
      />
      <EmailDialog
        multiple
        open={showEmail}
        onClose={handleClose}
        objectType={objectType}
        objectIds={checkedIds}
        formTemplates={formTemplates}
      />
      <DeleteDialog
        open={showDelete}
        objectType={objectType}
        objectIds={checkedIds}
        message={i18n("global.deleteSelectedRecords")}
        onClose={handleClose}
      />
      <MergeDialog
        multiple
        open={showMerge}
        objectType={objectType}
        objectIds={checkedIds}
        message={i18n("global.mergeSelectedRecords", {
          objectType: i18n(`objectType.salesreceipt.LowerPlural`),
        })}
        onClose={handleClose}
      />
      <GeneratePurchaseOrdersDialog
        open={showGeneratePurchaseOrders}
        onClose={handleClose}
        objectIds={checkedIds}
      />
      {showRefund && (
        <BatchRefundDialog isBatch close={handleClose} ids={checkedIds} />
      )}
    </>
  );
}
