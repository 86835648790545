import { getPdfs } from "services/sosInventoryService/sosApi";

export async function generateAndDownloadPdfs(
  objectType,
  objectIds,
  templateId,
  downloadText
) {
  const pdf = await getPdfs(objectType, objectIds, templateId);
  const a = document.createElement("a");
  a.style.display = "none";
  document.body.appendChild(a);
  a.href = window.URL.createObjectURL(pdf);
  a.setAttribute("download", downloadText);
  a.click();
  window.URL.revokeObjectURL(a.href);
  document.body.removeChild(a);
}
