import { FormControlLabel, Checkbox } from "@mui/material";

import { HoverHelp } from "components/utility/HoverHelp";

import { checkForUnexpectedProps } from "services/utility/misc";

export function FrmCheckboxWithHoverHelp(props) {
  const { name, value, onValueChange, label, helpText, ...unexpected } = props;
  checkForUnexpectedProps("FrmCheckbox", unexpected);

  return (
    <div style={{ display: "flex", alignItems: "start" }}>
      <FormControlLabel
        control={
          <Checkbox
            name={name}
            checked={value}
            onChange={(e) => onValueChange(e.target.name, e.target.checked)}
            sx={{ py: 0 }}
          />
        }
        label={label}
        sx={{ alignItems: "start" }}
      />
      <HoverHelp title={helpText} />
    </div>
  );
}
