import { i18n } from "services/i18nService";

import {
  NONE_GROUP_BY_OPTION,
  SUB_COMPONENT,
} from "views/Reports/reportConstants";

import { ALL_FILTER_OPTION } from "appConstants";

const GROUP_BY_PARENT_OR_SALES_REP_OPTIONS = [
  NONE_GROUP_BY_OPTION,
  { id: "Parent", name: i18n("reports.OptionLabel.Parent") },
  { id: "Sales Rep", name: i18n("reports.OptionLabel.SalesRep") },
];

export const ProfitLossByCustomer = {
  userHasSufficientPrivilege: (p) =>
    (p.viewCustomers || p.editCustomers) && p.viewCosts,
  planIncludesReport: () => true,
  relatedLists: ["customer", "location"],
  customFieldObjectTypes: "customer",
  columns: [
    { apiName: "CustomerNumber", label: "Customer #" },
    { apiName: "Name", default: true },
    { apiName: "Contact" },
    { apiName: "Company" },
    { apiName: "Notes" },
    { apiName: "Sales Rep" },
    { apiName: "Price Tier" },
    { apiName: "Revenue", number: true, alwaysShow: true },
    { apiName: "COGS", number: true, alwaysShow: true },
    { apiName: "Profit", number: true, alwaysShow: true },
    { apiName: "% Profit", number: true, alwaysShow: true },
  ],
  settings: [
    {
      apiName: "date1",
      macroApiName: "date1Macro",
      headerText: i18n("filter.heading.from"),
      component: "DateFilter",
      label: i18n("reports.FilterLabel.FromDate"),
      defaultValue: null,
      datePrefix: "startOf",
      type: "filter",
    },
    {
      apiName: "date1Macro",
      defaultValue: null,
      component: SUB_COMPONENT,
      type: "filter",
    },
    {
      apiName: "date2",
      macroApiName: "date2Macro",
      component: "DateFilter",
      label: i18n("reports.FilterLabel.ToDate"),
      datePrefix: "endOf",
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "date2Macro",
      defaultValue: null,
      component: SUB_COMPONENT,
      type: "filter",
    },
    {
      label: i18n("filter.Location"),
      component: "LocationFilter",
      apiName: "locations",
      defaultValue: [ALL_FILTER_OPTION],
      includeAllOption: true,
      type: "filter",
    },
    {
      label: i18n("filter.Customer"),
      component: "CustomerFilter",
      apiName: "customer",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.CustomerNameContains"),
      component: "FrmTextField",
      apiName: "nameContains",
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "suppressIfEmpty",
      defaultValue: true,
      label: i18n("reports.OptionLabel.HideRowsWithNoSalesOrShipments"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "showArchived",
      defaultValue: false,
      label: i18n("reports.OptionLabel.IncludeArchivedCustomers"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "showFromTo",
      defaultValue: false,
      label: i18n("reports.OptionLabel.ShowFromAndToDates"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "groupBy",
      label: i18n("reports.OptionLabel.GroupBy"),
      component: "FrmButtonGroup",
      defaultValue: NONE_GROUP_BY_OPTION,
      options: GROUP_BY_PARENT_OR_SALES_REP_OPTIONS,
      type: "option",
    },
    {
      apiName: "header",
      defaultValue: "",
      label: i18n("global.Header"),
      component: "FrmTextField",
      type: "option",
    },
    {
      apiName: "footer",
      defaultValue: "",
      label: i18n("global.Footer"),
      component: "FrmTextField",
      type: "option",
    },
  ],
};
