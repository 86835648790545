import { useSelector } from "react-redux";

import { TotalsLine } from "components/formFields/Totals/TotalsLine";

import { i18n } from "services/i18nService";
import { currencyMinMaxFractionDigits } from "services/utility/formatting";
import { LOCALIZATION } from "services/utility/localization";
import { isNonZeroDecimalOrMoney } from "services/utility/misc";

import { usePrivileges } from "hooks/usePrivileges";

import { USER_PRIVILEGE } from "appConstants";

export function LineItemTotals(props) {
  const { totals } = props;

  const { hasPrivilegesOrIsAdmin } = usePrivileges();
  const viewCosts = hasPrivilegesOrIsAdmin(USER_PRIVILEGE.viewCosts);

  const globalEnabled = useSelector(
    (state) => state.userCompanySettings.settings.globalEnabled
  );
  const taxEnabled = useSelector(
    (state) => state.userCompanySettings.settings.taxEnabled
  );

  const localizedAmount = currencyMinMaxFractionDigits(
    totals.amount,
    LOCALIZATION.locale,
    LOCALIZATION.currencyMinimumFractionDigits,
    LOCALIZATION.currencyMinimumFractionDigits,
    true
  );

  const showTax = taxEnabled || isNonZeroDecimalOrMoney(totals.tax);

  const localizedTax =
    globalEnabled && showTax
      ? currencyMinMaxFractionDigits(
          totals.tax,
          LOCALIZATION.locale,
          LOCALIZATION.currencyMinimumFractionDigits,
          LOCALIZATION.currencyMinimumFractionDigits,
          true
        )
      : null;

  const localizedTotal = currencyMinMaxFractionDigits(
    globalEnabled ? totals.amount.plus(totals.tax) : totals.amount,
    LOCALIZATION.locale,
    LOCALIZATION.currencyMinimumFractionDigits,
    LOCALIZATION.currencyMinimumFractionDigits,
    true
  );

  if (!viewCosts) {
    return null;
  }

  return (
    <div style={{ alignSelf: "baseline" }}>
      {globalEnabled && showTax ? (
        <>
          <TotalsLine label={i18n("global.Subtotal")} value={localizedAmount} />
          {showTax && (
            <TotalsLine label={i18n("global.Tax")} value={localizedTax} />
          )}
          <TotalsLine
            dataTesting="total"
            label={i18n("global.Total")}
            value={localizedTotal}
          />
        </>
      ) : (
        <TotalsLine
          dataTesting="total"
          label={i18n("global.Total")}
          value={localizedAmount}
        />
      )}
    </div>
  );
}
