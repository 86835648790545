import { useSelector } from "react-redux";

import { TableFooter, TableRow } from "@mui/material";

import { AddLines } from "components/EditPage/AddLines";
import { LineTableFooterCell } from "components/formFields/LineTableCell";

import { setPageDirty } from "services/utility/edit";
import { currencyMinMaxFractionDigits } from "services/utility/formatting";
import { decimalMaximumFractionDigits } from "services/utility/formatting";
import { textForAddLineOrLines } from "services/utility/lineItems";
import { LOCALIZATION } from "services/utility/localization";

import {
  DEFAULT_DECIMALS_UNROUNDED,
  DEFAULT_DECIMALS_ROUNDED,
} from "appConstants";

export function LineItemFooter(props) {
  const { totals, lineHandler, objectType } = props;
  const { quantity, cost, margin, weight, weightunit, volume, volumeunit } =
    totals;

  // SETTINGS
  const uomEnabled = useSelector(
    (state) => state.userCompanySettings.settings.uomEnabled
  );
  const showWeightOnSales = useSelector(
    (state) => state.userCompanySettings.settings.showWeightOnSales
  );
  const showVolumeOnSales = useSelector(
    (state) => state.userCompanySettings.settings.showVolumeOnSales
  );
  const lineItemDiscountsEnabled = useSelector(
    (state) => state.userCompanySettings.settings.lineItemDiscountsEnabled
  );
  const marginPricingEnabled = useSelector(
    (state) => state.userCompanySettings.settings.marginPricingEnabled
  );
  const numLinesToAdd = useSelector(
    (state) => state.userCompanySettings.settings.numLinesToAdd
  );
  const retrieveQuantityOnHand = useSelector(
    (state) => state.userCompanySettings.settings.retrieveQuantityOnHand
  );
  const localizedQuantity = decimalMaximumFractionDigits(
    quantity,
    LOCALIZATION.locale,
    DEFAULT_DECIMALS_UNROUNDED,
    true
  );

  const localizedMargin = decimalMaximumFractionDigits(
    margin,
    LOCALIZATION.locale,
    DEFAULT_DECIMALS_ROUNDED,
    false
  );

  const localizedCost = currencyMinMaxFractionDigits(
    cost,
    LOCALIZATION.locale,
    LOCALIZATION.currencyMinimumFractionDigits,
    LOCALIZATION.currencyMinimumFractionDigits,
    true
  );

  return (
    <TableFooter>
      <TableRow>
        <LineTableFooterCell sx={{ border: "none" }} />
        <AddLines
          title={textForAddLineOrLines(numLinesToAdd)}
          onClick={() => {
            setPageDirty();
            lineHandler({ type: "appendEmpty", numLinesToAdd, objectType });
          }}
        />
        <LineTableFooterCell colSpan={retrieveQuantityOnHand ? 4 : 3} />
        <LineTableFooterCell bordered sx={{ padding: "0.5rem 14px" }}>
          {localizedQuantity.toString()}
        </LineTableFooterCell>
        {uomEnabled && <LineTableFooterCell />}
        {showWeightOnSales && (
          <LineTableFooterCell bordered sx={{ padding: "0.5rem 0.3rem" }}>
            {`${weight.toString()}${weightunit ? ` ${weightunit}` : ""}`}
          </LineTableFooterCell>
        )}
        {showVolumeOnSales && (
          <LineTableFooterCell bordered sx={{ padding: "0.5rem 0.3rem" }}>
            {`${volume.toString()}${volumeunit ? ` ${volumeunit}` : ""}`}
          </LineTableFooterCell>
        )}
        {lineItemDiscountsEnabled && <LineTableFooterCell colSpan={2} />}
        {marginPricingEnabled && (
          <>
            <LineTableFooterCell bordered sx={{ padding: "0.5rem 0.3rem" }}>
              {localizedCost.toString()}
            </LineTableFooterCell>
            <LineTableFooterCell bordered sx={{ padding: "0.5rem 14px" }}>
              {localizedMargin.toString()}
            </LineTableFooterCell>
          </>
        )}
      </TableRow>
    </TableFooter>
  );
}
