import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";

import { useTaxCodes, TAX_CODE_TYPE_SALES } from "hooks/useTaxCodes";

export function FrmSalesTaxCode(props) {
  const { value, onValueChange } = props;

  const taxCodes = useTaxCodes(TAX_CODE_TYPE_SALES);

  return (
    <FrmSelectFromObjects
      name="taxCode"
      options={taxCodes?.length ? taxCodes : []}
      onValueChange={onValueChange}
      value={value}
    />
  );
}
