import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";

import {
  TableContainer,
  TableHead,
  Table,
  TableBody,
  TableRow,
} from "@mui/material";

import { ClearLines } from "components/EditPage/ClearLines";
import { LineTableHeadingCell } from "components/formFields/LineTableCell";
import { LineColumnFillHeading } from "components/formFields/line/LineColumnFillHeading";
import { LineColumnHeading } from "components/formFields/line/LineColumnHeading";
import { LineDragInsertCopy } from "components/formFields/line/LineDragInsertCopy";
import { LineInventoryItemHeading } from "components/formFields/line/LineInventoryItem";
import { LineItemFooter } from "components/formFields/line/LineItemFooter";
import { DragHandleHeading } from "components/utility/DragHandle";

import { i18n } from "services/i18nService";
import { descriptionForPurchasingTransactions } from "services/sosInventoryService/purchasingTransaction/domainLogic";
import { getItemBom, getByIds } from "services/sosInventoryService/sosApi";
import { setPageDirty } from "services/utility/edit";
import { getBaseUom } from "services/utility/uoms";

import { useJobWorkcenters } from "hooks/useJobWorkcenters";

import { LineItem } from "views/ReturnsToVendor/ReturnToVendor/LineItem";

import {
  editModalLoadingIndicatorOn,
  editModalLoadingIndicatorOff,
} from "globalState/loadingSlice";

import { OBJECT_TYPES } from "appConstants";
import { EMPTY_LINE_ITEM } from "editConfig";

const OBJECT_TYPE = OBJECT_TYPES.RETURN_TO_VENDOR.fullString;

export function LineItems(props) {
  const {
    record,
    lines,
    taxCodes,
    items,
    totals,
    itemsCount,
    addItem,
    classes,
    lineHandler,
    setClearLines,
    relatedRecords,
    lineItemsErrors,
    setErrors,
  } = props;

  const jobWorkcenters = useJobWorkcenters();

  const dispatch = useDispatch();

  // SETTINGS
  const showVolumeOnPurchases = useSelector(
    (state) => state.userCompanySettings.settings.showVolumeOnPurchases
  );
  const showWeightOnPurchases = useSelector(
    (state) => state.userCompanySettings.settings.showWeightOnPurchases
  );
  const classEnabled = useSelector(
    (state) => state.userCompanySettings.settings.classEnabled
  );
  const jobEnabled = useSelector(
    (state) => state.userCompanySettings.settings.jobEnabled
  );
  const globalEnabled = useSelector(
    (state) => state.userCompanySettings.settings.globalEnabled
  );
  const uomEnabled = useSelector(
    (state) => state.userCompanySettings.settings.uomEnabled
  );

  function handleDrop(result) {
    const { source, destination } = result;

    // if there's no destination, the item was dropped outside of the
    // droppable area
    if (!destination) {
      return;
    }

    // destination same as source, no-op
    if (destination.index === source.index) {
      return;
    }

    // make the move
    const newLines = [...lines];
    const savedSource = newLines.splice(source.index, 1)[0];
    newLines.splice(destination.index, 0, savedSource);

    // renumber and update changed lines
    const start = Math.min(source.index, destination.index);
    const end = Math.max(source.index, destination.index);
    setPageDirty();
    for (let i = start; i <= end; i++) {
      newLines[i].lineNumber = i + 1;
      lineHandler({ type: "update", updatedLine: newLines[i] });
    }
  }

  async function expandItemGroup(lineToExpand) {
    dispatch(editModalLoadingIndicatorOn());
    const bom = await getItemBom(lineToExpand.item.id);
    const bomIds = bom.map((bomItem) => bomItem.componentItem.id);
    const data = await getByIds("item", bomIds);
    lineHandler({ type: "delete", deleteAt: lineToExpand.lineNumber });
    const lines = bom.map((bomItem, index) => {
      const item = data.filter(({ id }) => id === bomItem.componentItem.id)[0];
      const newLine = {
        ...lineToExpand,
        lineNumber: lineToExpand.lineNumber + index,
        item: bomItem.componentItem,
        description: descriptionForPurchasingTransactions(item),
        weightunit: item.weightUnit,
        volumeunit: item.volumeUnit,
        uom: getBaseUom(item.uoms),
        vendorPartNumber: item.vendorPartNumber,
        quantity: bomItem.quantity.times(lineToExpand.quantity),
        bin: EMPTY_LINE_ITEM[OBJECT_TYPE].bin,
        lot: EMPTY_LINE_ITEM[OBJECT_TYPE].lot,
        serials: EMPTY_LINE_ITEM[OBJECT_TYPE].serials,
        relatedRecords: { item },
        itemDetails: {
          itemWeight: item.weight,
          itemVolume: item.volume,
          itemUoms: item.uoms,
          serialTracking: item.serialTracking,
          lotTracking: item.lotTracking,
        },
      };
      return newLine;
    });
    setPageDirty();
    lineHandler({
      type: "insertMany",
      insertAt: lineToExpand.lineNumber,
      lines,
    });
    dispatch(editModalLoadingIndicatorOff());
  }

  function insertEmptyLine(insertAt) {
    setPageDirty();
    lineHandler({
      type: "insert",
      insertAt: insertAt,
      newLine: EMPTY_LINE_ITEM[OBJECT_TYPE],
    });
  }

  function copyDown(line) {
    let updatedLine = {
      ...line,
      lineNumber: line.lineNumber + 1,
      lot: EMPTY_LINE_ITEM[OBJECT_TYPE].lot,
      lotExpiration: EMPTY_LINE_ITEM[OBJECT_TYPE].lotExpiration,
    };
    delete updatedLine.id;
    setPageDirty();
    lineHandler({ type: "update", updatedLine: updatedLine });
  }
  if (!lines) {
    return null;
  }
  return (
    <TableContainer data-testing="lineItems" sx={{ overflowX: "initial" }}>
      <Table
        sx={{ borderCollapse: "collapse" }}
        size="small"
        padding="none"
        stickyHeader
      >
        <TableHead>
          <TableRow sx={{ verticalAlign: "bottom", lineHeight: "1" }}>
            <DragHandleHeading />
            <ClearLines setClearLines={setClearLines} />
            <LineTableHeadingCell>{i18n("columns.Line")}</LineTableHeadingCell>
            <LineInventoryItemHeading lineHandler={lineHandler} />
            <LineColumnHeading labelCode="Description" />
            <LineColumnHeading labelCode="Quantity" />
            {uomEnabled && <LineColumnHeading labelCode="UOM" />}
            {showWeightOnPurchases && <LineColumnHeading labelCode="Weight" />}
            {showVolumeOnPurchases && <LineColumnHeading labelCode="Volume" />}
            {classEnabled && (
              <LineColumnFillHeading
                labelCode="Class"
                field="class"
                lineHandler={lineHandler}
              />
            )}
            {globalEnabled && (
              <LineColumnFillHeading
                labelCode="Tax"
                field="taxCode"
                lineHandler={lineHandler}
              />
            )}
            {jobEnabled && (
              <LineColumnFillHeading
                labelCode="Job"
                field="jobWorkcenter"
                lineHandler={lineHandler}
              />
            )}
          </TableRow>
        </TableHead>
        <DragDropContext onDragEnd={handleDrop}>
          <Droppable droppableId="lineItems">
            {(provided) => (
              <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                {lines.map((line, index) => (
                  <Draggable key={index} draggableId={`${index}`} index={index}>
                    {(draggableProvided, snapshot) => {
                      return (
                        <TableRow
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          sx={{
                            ...draggableProvided.draggableProps.style,
                            position: "inherit",
                            backgroundColor: snapshot.isDragging
                              ? "dragBackground"
                              : "none",
                          }}
                          data-testing={"line-" + index}
                        >
                          <LineDragInsertCopy
                            draggableProvided={draggableProvided}
                            snapshot={snapshot}
                            insertEmptyLine={insertEmptyLine}
                            lineNumber={line.lineNumber}
                            onCopyDown={() => copyDown(line)}
                            showCopyDown={
                              line.lineNumber === lines.length ? false : true
                            }
                          />

                          <LineItem
                            record={record}
                            line={line}
                            lineHandler={lineHandler}
                            expandItemGroup={expandItemGroup}
                            items={items}
                            itemsCount={itemsCount}
                            addItem={addItem}
                            taxCodes={taxCodes}
                            classes={classes}
                            jobWorkcenters={jobWorkcenters}
                            relatedRecords={relatedRecords}
                            lineItemsErrors={lineItemsErrors}
                            setErrors={setErrors}
                            draggableProvided={draggableProvided}
                            draggableSnapshot={snapshot}
                          />
                        </TableRow>
                      );
                    }}
                  </Draggable>
                ))}
                {provided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
        <LineItemFooter
          showWeight={showWeightOnPurchases}
          showVolume={showVolumeOnPurchases}
          totals={totals}
          firstCellSpan={4}
          lineHandler={lineHandler}
          objectType={OBJECT_TYPE}
        />
      </Table>
    </TableContainer>
  );
}
