import { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { FormControlLabel } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker as MuiDateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import { ActionButton } from "components/ActionButton";

import { i18n } from "services/i18nService";
import { timeZoneAwareDateTime, utcDateTime } from "services/utility/dates";
import { LOCALIZATION } from "services/utility/localization";

const sx = {
  dialog: {
    "& .MuiDialogActions-root": { display: "block", textAlign: "right" },
  },
};

export function DateTimePicker(props) {
  const {
    name,
    label,
    value: externalValue,
    onValueChange,
    isZoneless,
    error,
    dataTesting,
    hideNowButton,
  } = props;

  const userTimeZone = useSelector(
    (state) => state.userCompanySettings.settings.userTimeZone
  );
  const [localValue, setLocalValue] = useState(
    isZoneless ? externalValue : utcDateTime(externalValue, userTimeZone)
  );

  const [localError, setLocalError] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);

  const { locale, dateFormat } = LOCALIZATION;

  const value = useMemo(
    () =>
      isZoneless
        ? externalValue
        : timeZoneAwareDateTime(externalValue, userTimeZone),
    [externalValue, userTimeZone, isZoneless]
  );

  function onDateChange(newDate) {
    setIsUpdated(true);
    if (!newDate || isNaN(newDate)) {
      setLocalError(true);
      setLocalValue(null);
    } else {
      setLocalError(false);
      setLocalValue(isZoneless ? newDate : utcDateTime(newDate, userTimeZone));
    }
  }

  function handleNow() {
    const currentDate = new Date();
    const newDate = isZoneless
      ? currentDate
      : utcDateTime(currentDate, userTimeZone);
    setLocalValue(newDate);
    onValueChange(name, newDate);
  }

  function handleOnBlur() {
    if (isUpdated) {
      onValueChange(name, localValue);
      setIsUpdated(false);
    }
  }

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <FormControlLabel
        sx={{ mx: 0 }}
        control={
          <>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={locale}
            >
              <MuiDateTimePicker
                key={externalValue}
                format={`${dateFormat}     H:mm`}
                ampm={false}
                label={label}
                defaultValue={value}
                onChange={onDateChange}
                inputVariant="outlined"
                views={["year", "day"]}
                error={error || localError}
                slotProps={{
                  actionBar: { actions: ["today"] },
                  textField: {
                    InputLabelProps: { shrink: true },
                    margin: "dense",
                    sx: { width: "15em" },
                    "data-testing": dataTesting,
                  },
                }}
                DialogProps={{ sx: sx.dialog, disableScrollLock: true }}
              />
            </LocalizationProvider>
          </>
        }
        onBlur={handleOnBlur}
      />
      {!hideNowButton && (
        <ActionButton
          sx={{ marginTop: "0.3rem" }}
          headerButton
          onClick={handleNow}
        >
          {i18n("global.Now")}
        </ActionButton>
      )}
    </div>
  );
}
