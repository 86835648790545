import { useEffect, useState } from "react";

// TODO: This doesn't handle currency values with adornment(s) (like "$" prefix);
// it may need to at some point.
import { Typography } from "@mui/material";

import { Loading } from "classes/Loading";

import { LineSkeleton } from "components/formFields/LineSkeleton";

import { currencyMinMaxFractionDigits } from "services/utility/formatting";
import { LOCALIZATION } from "services/utility/localization";

const DEFAULT_STYLE = {
  padding: "0 0.3rem",
  whiteSpace: "nowrap",
  fontSize: "0.8125rem",
};

export function RowStaticMoney(props) {
  const { name, value: initialValue, style, decimalPlaces } = props;
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (!(initialValue instanceof Loading)) {
      setValue(
        currencyMinMaxFractionDigits(
          initialValue,
          LOCALIZATION.locale,
          LOCALIZATION.currencyMinimumFractionDigits,
          decimalPlaces || LOCALIZATION.currencyMinimumFractionDigits,
          false
        )
      );
    }
  }, [initialValue, decimalPlaces]);

  if (initialValue instanceof Loading) {
    return <LineSkeleton />;
  }

  return (
    <Typography name={name} align="right" sx={style || DEFAULT_STYLE}>
      {value.toString()}
    </Typography>
  );
}
