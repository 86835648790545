import { i18n } from "services/i18nService";

export const PriceBook = {
  userHasSufficientPrivilege: (p) =>
    (p.viewInventory || p.viewItems || p.editItems) &&
    (p.viewCustomers || p.editCustomers),
  planIncludesReport: (plus, pro) => plus || pro,
  relatedLists: ["customer", "pricetier", "category"],
  customFieldObjectTypes: ["customer", "item"],
  columns: [
    { apiName: "Customer", default: true },
    { apiName: "Price Tier", default: true },
    { apiName: "Item", default: true },
    { apiName: "Quantity", default: true, number: true },
    { apiName: "Sales Price", default: true, number: true },
    { apiName: "ItemNumber", label: "Item #" },
    { apiName: "Category" },
    { apiName: "Description", default: true },
    { apiName: "Purchase Description" },
    { apiName: "SKU" },
    { apiName: "Barcode" },
    { apiName: "Vendor" },
    { apiName: "VendorPartNumber", label: "Vendor Part #" },
    { apiName: "Purchase Cost" },
    { apiName: "Default Bin" },
    { apiName: "Asset Account" },
    { apiName: "Lead Time", number: true },
    { apiName: "Reorder Point", number: true },
    { apiName: "Max Stock", number: true },
  ],
  settings: [
    {
      label: i18n("filter.Category"),
      component: "CategoryFilter",
      apiName: "categories",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.ItemNameContains"),
      component: "FrmTextField",
      apiName: "nameContains",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("filter.Customer"),
      component: "CustomerFilter",
      apiName: "customer",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.PriceTier"),
      component: "PriceTierFilter",
      apiName: "priceGroup",
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "header",
      defaultValue: "",
      label: i18n("global.Header"),
      component: "FrmTextField",
      type: "option",
    },
    {
      apiName: "footer",
      defaultValue: "",
      label: i18n("global.Footer"),
      component: "FrmTextField",
      type: "option",
    },
  ],
};
