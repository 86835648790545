import { FormControlLabel, Checkbox } from "@mui/material";

import { HoverHelp } from "components/utility/HoverHelp";

import { i18n } from "services/i18nService";

export function FrmBlanketPO(props) {
  const { onValueChange, value } = props;

  return (
    <div style={{ width: "80%", display: "inline-block" }}>
      <FormControlLabel
        control={
          <Checkbox
            name="blanketPO"
            checked={value}
            onChange={(e) => onValueChange(e.target.name, e.target.checked)}
          />
        }
        label={i18n("frmLabel.BlanketPO")}
        sx={{ marginRight: 0 }}
      />
      <HoverHelp
        title={i18n("hoverHelp.blanketPO")}
        sx={{ marginRight: 0.5 }}
      />
    </div>
  );
}
