import { i18n } from "services/i18nService";
import { formatLinkField, formatBoolean } from "services/utility/formatting";

export const columnInfo = [
  {
    name: "name",
    heading: i18n("columns.Name"),
    formatFunc: formatLinkField,
    fixed: true,
    linkField: true,
    defaultSort: true,
    sortable: true,
  },
  {
    name: "type",
    heading: i18n("columns.Type"),
    sortable: true,
    fixed: true,
    fixedRightOfAction: true,
  },

  {
    name: "monitor",
    heading: i18n("columns.Monitor"),
    fixed: true,
    fixedRightOfAction: true,
  },
  {
    name: "dateCompare",
    heading: i18n("columns.Trigger"),
    fixed: true,
    fixedRightOfAction: true,
  },
  {
    name: "active",
    heading: i18n("columns.Enabled"),
    formatFunc: formatBoolean,
    fixed: true,
    fixedRightOfAction: true,
  },
  {
    name: "repeat",
    heading: i18n("columns.Repeat"),
    sortable: true,
    fixed: true,
    fixedRightOfAction: true,
  },
  {
    name: "interval",
    heading: i18n("columns.Interval"),
    sortable: true,
    fixed: true,
    fixedRightOfAction: true,
  },
  {
    name: "method",
    heading: i18n("columns.AlertMethod"),
    sortable: true,
    fixed: true,
    fixedRightOfAction: true,
  },
  {
    name: "email",
    heading: i18n("columns.Email"),
    sortable: true,
    fixed: true,
    fixedRightOfAction: true,
  },
];

export const LINE_ACTIONS = ["test", "delete"];
export const BATCH_ACTIONS = ["delete"];

export function getEmptyRecord() {
  return {};
}
