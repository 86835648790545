import { useState } from "react";

import { FormControlLabel } from "@mui/material";
import {
  DatePicker as MuiDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { LOCALIZATION } from "services/utility/localization";
import { checkForUnexpectedProps } from "services/utility/misc";

import { DATE_SELECT_MIN_DATE, DATE_SELECT_MAX_DATE } from "appConstants";
import { isValid, isBefore, isAfter } from "date-fns";

const sx = {
  picker: {
    "& .MuiSvgIcon-root": { color: "txnFieldIcons" },
    "& .MuiButtonBase-root": { marginRight: "-12px" },
  },
  dialog: {
    "& .MuiDialogActions-root": { display: "block", textAlign: "right" },
  },
};

export function DatePicker(props) {
  const {
    label,
    value,
    name,
    onValueChange,
    error,
    dataTesting,
    textSx,
    dialogProps,
    inputProps,
    disabled,
    onError,
    onAccept,
    ...unexpected
  } = props;
  checkForUnexpectedProps("DatePicker", unexpected);

  const [localValue, setLocalValue] = useState(value);

  const { locale, dateFormat } = LOCALIZATION;

  function handleOnBlur() {
    if (localValue !== null && localValue.toString() !== "Invalid Date") {
      onValueChange(name, localValue);
      return;
    }
    onValueChange(name, null);
  }

  function handleOnChange(value) {
    setLocalValue(value);
    if (
      value &&
      isValid(value) &&
      isBefore(value, DATE_SELECT_MAX_DATE) &&
      isAfter(value, DATE_SELECT_MIN_DATE) &&
      onAccept
    ) {
      onAccept();
    }
  }

  return (
    <FormControlLabel
      sx={{ marginLeft: 0, marginRight: 0 }}
      control={
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={locale}
        >
          <MuiDatePicker
            onError={onError}
            minDate={DATE_SELECT_MIN_DATE}
            maxDate={DATE_SELECT_MAX_DATE}
            format={dateFormat}
            name={name}
            label={label}
            defaultValue={value}
            onChange={handleOnChange}
            sx={sx.picker}
            slotProps={{
              actionBar: { actions: ["today"] },
              textField: {
                variant: "outlined",
                sx: { margin: "0.5rem 0", ...textSx },
                InputLabelProps: { shrink: true },
                "data-testing": dataTesting,
                error,
              },
              dialog: dialogProps,
              input: inputProps,
            }}
            DialogProps={{ sx: sx.dialog, disableScrollLock: true }}
            disabled={disabled}
          />
        </LocalizationProvider>
      }
      onBlur={handleOnBlur}
    />
  );
}
