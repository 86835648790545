import { useState } from "react";
import { useLocation } from "react-router-dom";

import { Search as SearchIcon } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";

// number of milliseconds to use for the debounce function
import { DEBOUNCE_WAIT } from "appConfig";

import { i18n } from "services/i18nService";

import { useCustomNavigate } from "hooks/useCustomNavigate";

export function Search(props) {
  const { setRetrievalParams, objectType } = props;

  const [searchText, setSearchText] = useState("");
  const [timeoutId, setTimeoutId] = useState();

  const customNavigate = useCustomNavigate(objectType);
  const location = useLocation();

  const queryString = location.search;
  const paramsObject = new URLSearchParams(queryString);
  // this route should only ever be all records or just one id specified
  const id = paramsObject.get("id");
  // this debounces the input by setting the update of the retrieval
  // params to occur x milliseconds in the future; if this function
  // gets called again before that (due to further user input), we
  // cancel the previous update of the retrieval params and establish
  // a new future update
  function onChange(e) {
    const query = e.target.value;
    if (query && id) {
      customNavigate.clearListParams();
    }
    setSearchText(query);
    clearTimeout(timeoutId);
    setTimeoutId(
      setTimeout(() => {
        setRetrievalParams((previous) => ({
          ...previous,
          query,
          startingRecord: 1,
          persistUpdate: false,
        }));
      }, DEBOUNCE_WAIT)
    );
  }

  return (
    <TextField
      size="small"
      value={searchText}
      variant="outlined"
      color="primary"
      onChange={onChange}
      placeholder={i18n("global.QuickSearchPlaceholder")}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      data-testing="quickSearch"
    />
  );
}
