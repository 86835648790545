import { useSelector } from "react-redux";

import { TableFooter, TableRow } from "@mui/material";

import { AddLines } from "components/EditPage/AddLines";
import { LineTableFooterCell } from "components/formFields/LineTableCell";

import { setPageDirty } from "services/utility/edit";
import { decimalMaximumFractionDigits } from "services/utility/formatting";
import { textForAddLineOrLines } from "services/utility/lineItems";
import { LOCALIZATION } from "services/utility/localization";

import { DEFAULT_DECIMALS_UNROUNDED } from "appConstants";

export function LineItemFooter(props) {
  const { totals, lineHandler, objectType } = props;

  const numLinesToAdd = useSelector(
    (state) => state.userCompanySettings.settings.numLinesToAdd
  );

  const localizedQuantity = decimalMaximumFractionDigits(
    totals["quantity"],
    LOCALIZATION.locale,
    DEFAULT_DECIMALS_UNROUNDED,
    true
  );

  function addLines() {
    setPageDirty();
    lineHandler({
      type: "appendEmpty",
      numLinesToAdd,
      objectType,
      lineType: "inputs",
    });
  }

  return (
    <TableFooter>
      <TableRow>
        <LineTableFooterCell sx={{ border: "none" }} />
        <AddLines
          title={textForAddLineOrLines(numLinesToAdd)}
          onClick={addLines}
        />
        <LineTableFooterCell colSpan={5} />
        <LineTableFooterCell sx={{ padding: "0.5rem 14px" }}>
          {localizedQuantity.toString()}
        </LineTableFooterCell>
      </TableRow>
    </TableFooter>
  );
}
