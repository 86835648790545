import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Autocomplete, TextField } from "@mui/material";

import { checkForUnexpectedProps } from "services/utility/misc";

export function MultipleSelectField(props) {
  const {
    value,
    options,
    onValueChange,
    optionId = "id",
    name,
    label,
    freeSolo,
    createValueOnBlur,
    ...unexpected
  } = props;
  checkForUnexpectedProps("MultipleSelectField", unexpected);

  function renderOption(props, option) {
    return (
      <li {...props} key={option[optionId]}>
        {option.name}
      </li>
    );
  }

  function handleOnBlur(e) {
    if (createValueOnBlur && e.target.value) {
      onValueChange(name, [...value, e.target.value]);
    }
  }

  return (
    <Autocomplete
      multiple
      name={name}
      freeSolo={freeSolo}
      loading={!Array.isArray(options)}
      onChange={(_, value) => onValueChange(name, value)}
      options={options || []}
      getOptionLabel={({ name }) => name}
      isOptionEqualToValue={(option, value) =>
        option[optionId] === value[optionId]
      }
      clearOnBlur={Boolean(createValueOnBlur)}
      onBlur={handleOnBlur}
      value={value}
      filterSelectedOptions
      size="small"
      popupIcon={<KeyboardArrowDownIcon sx={{ color: "selectIcon" }} />}
      renderOption={renderOption}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          label={label}
        />
      )}
      sx={{ marginTop: "8px", marginBottom: "4px" }}
    />
  );
}
