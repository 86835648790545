import { i18n } from "services/i18nService";
import { extractName } from "services/utility/formatting";

// TODO: Verify list works once https://app.clickup.com/t/8687508h6 complete
export const columnInfo = [
  {
    name: "item",
    heading: i18n("columns.Item"),
    formatFunc: extractName,
    defaultSort: true,
    sortDirection: "asc",
    fixed: true,
    sortable: true,
    default: true,
  },
  {
    name: "vendor",
    heading: i18n("columns.Vendor"),
    formatFunc: extractName,
    fixed: true,
    fixedRightOfAction: true,
    sortable: true,
    default: true,
  },
  {
    name: "vendorPartNumber",
    heading: i18n("columns.VendorPartNumber"),
    fixedRightOfAction: true,
    fixed: true,
    sortable: true,
    default: true,
  },
  {
    name: "price",
    heading: i18n("columns.Price"),
    fixed: true,
    fixedRightOfAction: true,
    sortable: true,
    default: true,
  },
  {
    name: "leadTime",
    heading: i18n("columns.LeadTime"),
    fixed: true,
    fixedRightOfAction: true,
    sortable: true,
    default: true,
  },
];

export const LINE_ACTIONS = ["delete"];
export const BATCH_ACTIONS = ["delete"];
