import { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Layout } from "Layout";

import { CustomFields } from "components/CustomFields/CustomFields";
import { EditModalHeader } from "components/EditModalHeader";
import { FrmFieldGrid } from "components/FrmFieldGrid";
import { ObjectEditDialogContent } from "components/ObjectEditDialogContent";
import { QuickAddVendor } from "components/QuickAddVendor";
import { FrmTextField } from "components/formFields/FrmTextField";
import {
  FrmLocation,
  FrmNumber,
  FrmVendorWithAdd,
  FrmVendorNotes,
  FrmAccount,
  FrmComment,
  FrmDepartment,
  FrmCreateVendorCredit,
  FrmShipMethod,
} from "components/formFields/frm";
import { FrmSignature } from "components/formFields/frm/FrmSignature";
import { DateTimePicker } from "components/html/Input/DateTimePicker";
import { FixedLoadingIndicator } from "components/utility/FixedLoadingIndicator";
import { FormErrors } from "components/utility/FormErrors";
import { Loading } from "components/utility/Loading";
import { VSpace } from "components/utility/VSpace";
import { WarningModal } from "components/utility/WarningModal";

import { i18n } from "services/i18nService";
import {
  updateTotals,
  ZERO_TOTALS,
} from "services/sosInventoryService/purchasingTransaction/updateTotals";
import {
  fullAddNewVendor,
  quickAddNewVendor,
} from "services/utility/controller";
import { setPageDirty } from "services/utility/edit";

import { useCustomFieldDefsAsObject } from "hooks/useCustomFieldDefinitions";
import { useErrors } from "hooks/useErrors";
import { useItems } from "hooks/useItems";
import { useLineHandler } from "hooks/useLineHandler";
import { usePlans } from "hooks/usePlans";
import { useRelated } from "hooks/useRelated";
import { useTaxCodes, TAX_CODE_TYPE_PURCHASE } from "hooks/useTaxCodes";
import { useVendors } from "hooks/useVendors";

import { BottomWidgets } from "views/PurchaseTransactions/BottomWidgets";
import { LineItems } from "views/ReturnsToVendor/ReturnToVendor/LineItems";
import {
  getTransaction,
  updateTransaction,
} from "views/ReturnsToVendor/ReturnToVendor/controller";

import {
  editModalLoadingIndicatorOn,
  editModalLoadingIndicatorOff,
} from "globalState/loadingSlice";

import { theme } from "SosTheme";
import { OBJECT_TYPES, ITEM_QUICKLIST_CASES } from "appConstants";
import { EMPTY_LINE_ITEM } from "editConfig";

const OBJECT_TYPE = OBJECT_TYPES.RETURN_TO_VENDOR.fullString;
const CUSTOM_FIELD_OBJECT_TYPES = [OBJECT_TYPE, OBJECT_TYPES.VENDOR.fullString];

export function ReturnToVendor(props) {
  const { id, onClose, newFromObjectType, newFromId } = props;

  // TRANSACTION STATE
  const [record, setRecord] = useState();
  const [lines, lineHandler] = useLineHandler();
  const [relatedRecords, setRelatedRecords] = useState({});
  const CURRENT_STATE = { record, lines, relatedRecords };

  // UI STATE
  const { errors, setErrors, isInError } = useErrors();
  const loadingState = useSelector((state) => state.loading.editModal);
  const [totals, setTotals] = useState(ZERO_TOTALS);
  const [addNewVendor, setAddNewVendor] = useState(false);
  const [newVendorName, setNewVendorName] = useState("");
  const [vendorAdded, setVendorAdded] = useState(0);
  const [itemAdded, setItemAdded] = useState(0);
  const [clearLines, setClearLines] = useState(false);
  const customFieldDefs = useCustomFieldDefsAsObject(CUSTOM_FIELD_OBJECT_TYPES);

  // RELATED RECORD LISTS
  const classes = useRelated("class");
  const vendors = useVendors(vendorAdded);
  const taxCodes = useTaxCodes(TAX_CODE_TYPE_PURCHASE);
  const { items, itemsCount } = useItems(
    ITEM_QUICKLIST_CASES.PURCHASING,
    itemAdded
  );
  const currencies = useRelated("currency");

  // SETTINGS
  const departmentEnabled = useSelector(
    (state) => state.userCompanySettings.settings.departmentEnabled
  );
  const { allowPlusFeatures, allowProFeatures } = usePlans();

  // OTHER SETUP
  const dispatch = useDispatch();

  // UPDATERS FOR CONTROLLER TO UPDATE STATE
  const UPDATERS = useMemo(
    () => ({
      setRecord,
      lineHandler,
      setRelatedRecords,
    }),
    [setRecord, lineHandler]
  );

  // LOAD INITIAL TRANSACTION STATE
  useEffect(() => {
    async function _getTransaction() {
      dispatch(editModalLoadingIndicatorOn());
      await getTransaction(
        id,
        newFromId,
        newFromObjectType,
        UPDATERS,
        customFieldDefs[OBJECT_TYPE]
      );
      dispatch(editModalLoadingIndicatorOff());
    }
    if (customFieldDefs) {
      _getTransaction();
    }
  }, [
    dispatch,
    id,
    newFromId,
    newFromObjectType,
    lineHandler,
    UPDATERS,
    customFieldDefs,
  ]);

  useEffect(() => updateTotals(lines, setTotals, taxCodes), [lines, taxCodes]);

  // HANDLE ALL USER INPUT
  function handleInputFieldChange(field, newValue) {
    setPageDirty();
    updateTransaction(
      field,
      newValue,
      customFieldDefs[OBJECT_TYPE],
      CURRENT_STATE,
      UPDATERS,
      currencies
    );
  }

  async function handleQuickAddNewVendor(name) {
    dispatch(editModalLoadingIndicatorOn());
    await quickAddNewVendor(name, UPDATERS);

    // force a re-get of the vendor list, since we know one was just added
    setVendorAdded((prev) => prev + 1);
    dispatch(editModalLoadingIndicatorOff());
    setPageDirty();
    setAddNewVendor(false);
  }

  async function handleAddNewVendor(newVendor) {
    await fullAddNewVendor(
      newVendor,
      customFieldDefs.vendor,
      customFieldDefs[OBJECT_TYPE],
      record.customFields,
      UPDATERS
    );
    // force a re-get of the vendor list, since we know one was just added
    setVendorAdded((prev) => prev + 1);
    dispatch(editModalLoadingIndicatorOff());
    setAddNewVendor(false);
  }

  function doClearLines() {
    setPageDirty();
    lineHandler({ type: "clear", blankLine: EMPTY_LINE_ITEM[OBJECT_TYPE] });
    setClearLines(false);
  }

  return (
    <>
      <EditModalHeader
        record={record}
        setRecord={setRecord}
        objectType={OBJECT_TYPE}
        relatedRecords={relatedRecords}
        lines={lines}
        setErrors={setErrors}
        errors={errors}
        text={record?.number}
        handleClose={onClose}
        vendorId={record?.vendor?.id}
      />
      {loadingState && <Loading />}
      <ObjectEditDialogContent>
        <Layout
          pageTitle={
            id
              ? `${i18n("objectType.returntovendor.Sentence")} ${
                  record?.number || ""
                }`
              : i18n("objectType.returntovendor.New")
          }
        >
          <div id="B" style={{ margin: theme.spacing(2) }}>
            <FormErrors errors={errors} setErrors={setErrors} />
            {record ? (
              <>
                <FrmFieldGrid gridAutoFlow="dense">
                  <DateTimePicker
                    name="date"
                    value={record.date}
                    onValueChange={handleInputFieldChange}
                    error={isInError("date")}
                    label={i18n("frmLabel.DateTime")}
                    dataTesting="date"
                  />
                  <FrmNumber
                    recordId={record.id}
                    label={i18n("returnToVendor.labelNumber")}
                    value={record.number}
                    onValueChange={handleInputFieldChange}
                    error={isInError("number")}
                  />
                  <div style={{ gridColumn: "1 / 2" }}>
                    <FrmVendorWithAdd
                      value={record.vendor}
                      onValueChange={handleInputFieldChange}
                      setAddNewVendor={setAddNewVendor}
                      setNewVendorName={setNewVendorName}
                      vendors={vendors}
                      error={isInError("vendor")}
                    />
                  </div>
                  <div style={{ gridColumn: "1 / 2" }}>
                    <FrmVendorNotes
                      value={record.vendorNotes}
                      onValueChange={handleInputFieldChange}
                    />
                  </div>
                  {(allowPlusFeatures || allowProFeatures) && (
                    <FrmLocation
                      value={record.location}
                      onValueChange={handleInputFieldChange}
                      error={isInError("location")}
                    />
                  )}
                </FrmFieldGrid>
                <VSpace space={1} />
                <LineItems
                  record={record}
                  lines={lines}
                  taxCodes={taxCodes}
                  items={items}
                  totals={totals}
                  itemsCount={itemsCount}
                  addItem={() => setItemAdded((prev) => prev + 1)}
                  classes={classes}
                  lineHandler={lineHandler}
                  setClearLines={setClearLines}
                  relatedRecords={relatedRecords}
                  lineItemsErrors={errors.line}
                  setErrors={setErrors}
                />
                <BottomWidgets
                  objectType={OBJECT_TYPE}
                  lineHandler={lineHandler}
                />
                <VSpace space={1} />
                <FrmFieldGrid gridAutoFlow="dense">
                  <div>
                    <FrmShipMethod
                      value={record.shippingMethod}
                      onValueChange={handleInputFieldChange}
                      maxWidth="20em"
                    />
                    <FrmTextField
                      name="trackingNumber"
                      label={i18n("frmLabel.TrackingNumber")}
                      sx={{ maxWidth: "20rem" }}
                      maxLength={21}
                      value={record.trackingNumber}
                      onValueBlur={handleInputFieldChange}
                    />
                    <FrmCreateVendorCredit
                      value={record.createVendorCredit}
                      onValueChange={handleInputFieldChange}
                    />
                  </div>

                  {!record.createVendorCredit && (
                    <FrmAccount
                      name="account"
                      label={i18n("frmLabel.Account")}
                      onValueChange={handleInputFieldChange}
                      value={record.account}
                    />
                  )}

                  {departmentEnabled && (
                    <FrmDepartment
                      maxWidth="20em"
                      value={record.department}
                      onValueChange={handleInputFieldChange}
                    />
                  )}

                  <div style={{ gridColumn: "span 2" }}>
                    <FrmComment
                      value={record.comment}
                      onValueChange={handleInputFieldChange}
                    />
                  </div>
                  <div style={{ gridColumn: "1 / 2" }}>
                    <FrmSignature
                      objectType={OBJECT_TYPE}
                      id={record.id}
                      hasSignature={record.hasSignature}
                    />
                  </div>
                </FrmFieldGrid>
                <CustomFields
                  customFieldDefinitions={customFieldDefs[OBJECT_TYPE]}
                  customFields={record.customFields}
                  onValueChange={handleInputFieldChange}
                  setErrors={setErrors}
                />
              </>
            ) : (
              !isInError("record") && (
                <FixedLoadingIndicator text={`${i18n("global.Loading")}...`} />
              )
            )}
            <VSpace space={4} />
          </div>
          {record && (
            <>
              {!!addNewVendor && (
                <QuickAddVendor
                  open
                  initialNameValue={newVendorName}
                  onClose={() => setAddNewVendor(false)}
                  onQuickAdd={handleQuickAddNewVendor}
                  onAdd={handleAddNewVendor}
                />
              )}
              <WarningModal
                title={i18n("global.ClearLines")}
                message={i18n("global.clearLinesMessage")}
                open={clearLines}
                onOk={doClearLines}
                onClose={() => setClearLines(false)}
              />
            </>
          )}
        </Layout>
      </ObjectEditDialogContent>
    </>
  );
}
