import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";

import {
  TableContainer,
  TableHead,
  Table,
  TableBody,
  TableRow,
} from "@mui/material";

import { ClearLines } from "components/EditPage/ClearLines";
import { LineTableHeadingCell } from "components/formFields/LineTableCell";
import { LineItemFooter } from "components/formFields/Totals/Sales/LineItemFooter";
import { LineColumnFillHeading } from "components/formFields/line/LineColumnFillHeading";
import { LineColumnHeading } from "components/formFields/line/LineColumnHeading";
import { LineDragInsertCopy } from "components/formFields/line/LineDragInsertCopy";
import { LineInventoryItemHeading } from "components/formFields/line/LineInventoryItem";
import { DragHandleHeading } from "components/utility/DragHandle";

import { i18n } from "services/i18nService";
import { expandSalesItemGroup } from "services/sosInventoryService/domainLogic";
import { setPageDirty } from "services/utility/edit";
import { handleDrop, copyDown } from "services/utility/lineItems";

import { useJobWorkcenters } from "hooks/useJobWorkcenters";

import { LineItem } from "views/SalesTransactions/LineItem";

import { EMPTY_LINE_ITEM } from "editConfig";

export function LineItems(props) {
  const {
    record,
    objectType,
    customer,
    lines,
    taxCodes,
    items,
    totals,
    itemsCount,
    addItem,
    classes,
    lineHandler,
    setClearLines,
    lineItemsErrors,
    setErrors,
    showPicked,
    showInvoiced,
    showShipped,
    showDueDate,
  } = props;

  const jobWorkcenters = useJobWorkcenters();

  // SETTINGS
  const retrieveQuantityOnHand = useSelector(
    (state) => state.userCompanySettings.settings.retrieveQuantityOnHand
  );
  const showVolumeOnSales = useSelector(
    (state) => state.userCompanySettings.settings.showVolumeOnSales
  );
  const showWeightOnSales = useSelector(
    (state) => state.userCompanySettings.settings.showWeightOnSales
  );
  const lineItemDiscountsEnabled = useSelector(
    (state) => state.userCompanySettings.settings.lineItemDiscountsEnabled
  );
  const classEnabled = useSelector(
    (state) => state.userCompanySettings.settings.classEnabled
  );
  const marginPricingEnabled = useSelector(
    (state) => state.userCompanySettings.settings.marginPricingEnabled
  );
  const jobEnabled = useSelector(
    (state) => state.userCompanySettings.settings.jobEnabled
  );
  const globalEnabled = useSelector(
    (state) => state.userCompanySettings.settings.globalEnabled
  );
  const uomEnabled = useSelector(
    (state) => state.userCompanySettings.settings.uomEnabled
  );

  function insertEmptyLine(insertAt) {
    setPageDirty();
    lineHandler({
      type: "insert",
      insertAt: insertAt,
      newLine: EMPTY_LINE_ITEM[objectType],
    });
  }

  if (!lines) {
    return null;
  }

  return (
    <>
      <TableContainer data-testing="lineItems" sx={{ overflowX: "initial" }}>
        <Table
          sx={{ borderCollapse: "collapse" }}
          size="small"
          padding="none"
          stickyHeader
        >
          <TableHead>
            <TableRow sx={{ verticalAlign: "bottom", lineHeight: "1" }}>
              <DragHandleHeading />
              <ClearLines setClearLines={setClearLines} />
              <LineTableHeadingCell>
                {i18n("columns.Line")}
              </LineTableHeadingCell>
              <LineInventoryItemHeading lineHandler={lineHandler} />
              <LineColumnHeading labelCode="Description" />
              {retrieveQuantityOnHand && (
                <LineColumnHeading labelCode="Available" align="right" />
              )}
              <LineColumnHeading labelCode="Quantity" />
              {uomEnabled && <LineColumnHeading labelCode="UOM" />}
              {showWeightOnSales && <LineColumnHeading labelCode="Weight" />}
              {showVolumeOnSales && <LineColumnHeading labelCode="Volume" />}
              {lineItemDiscountsEnabled && (
                <LineColumnHeading labelCode="ListPrice" align="right" />
              )}
              {lineItemDiscountsEnabled && (
                <LineColumnHeading
                  labelCode="Discount"
                  sx={{ minWidth: "7rem" }}
                />
              )}
              {marginPricingEnabled && (
                <>
                  <LineColumnHeading labelCode="TotalCost" align="right" />
                  <LineColumnHeading
                    labelCode="Margin"
                    align="right"
                    sx={{ minWidth: "7rem" }}
                  />
                </>
              )}
              <LineColumnHeading labelCode="UnitPrice" align="right" />
              <LineColumnHeading labelCode="Amount" align="right" />
              {showPicked && (
                <LineColumnHeading
                  labelCode="Picked"
                  align="right"
                  sx={{ minWidth: "4rem" }}
                />
              )}
              {showShipped && (
                <LineColumnHeading
                  labelCode="Shipped"
                  align="right"
                  sx={{ minWidth: "4rem" }}
                />
              )}
              {showInvoiced && (
                <LineColumnHeading
                  labelCode="Invoiced"
                  align="right"
                  sx={{ minWidth: "4rem" }}
                />
              )}
              {showDueDate && (
                <LineColumnFillHeading
                  labelCode="DueDate"
                  field="duedate"
                  lineHandler={lineHandler}
                />
              )}
              {classEnabled && (
                <LineColumnFillHeading
                  labelCode="Class"
                  field="class"
                  lineHandler={lineHandler}
                />
              )}
              <LineColumnFillHeading
                lineHandler={lineHandler}
                labelCode="Tax"
                field={globalEnabled ? "taxCode" : "tax"}
              />
              {jobEnabled && (
                <LineColumnFillHeading
                  labelCode="Job"
                  field="jobWorkcenter"
                  lineHandler={lineHandler}
                />
              )}
            </TableRow>
          </TableHead>
          <DragDropContext
            onDragEnd={(result) => handleDrop(result, lineHandler, lines)}
          >
            <Droppable droppableId="lineItems">
              {(provided) => (
                <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                  {lines.map((line, index) => (
                    <Draggable
                      key={index}
                      draggableId={`${index}`}
                      index={index}
                    >
                      {(draggableProvided, snapshot) => {
                        return (
                          <TableRow
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            sx={{
                              ...draggableProvided.draggableProps.style,
                              position: "inherit",
                              backgroundColor: snapshot.isDragging
                                ? "dragBackground"
                                : "none",
                            }}
                            data-testing={"line-" + index}
                          >
                            <LineDragInsertCopy
                              draggableProvided={draggableProvided}
                              snapshot={snapshot}
                              insertEmptyLine={insertEmptyLine}
                              lineNumber={line.lineNumber}
                              onCopyDown={() =>
                                copyDown(line, lineHandler, record, objectType)
                              }
                              showCopyDown={
                                line.lineNumber === lines.length ? false : true
                              }
                            />

                            <LineItem
                              record={record}
                              objectType={objectType}
                              allowPicked={true}
                              customer={customer}
                              line={line}
                              lineHandler={lineHandler}
                              expandItemGroup={(lineToExpand) =>
                                expandSalesItemGroup(
                                  lineToExpand,
                                  lineHandler,
                                  objectType
                                )
                              }
                              items={items}
                              itemsCount={itemsCount}
                              addItem={addItem}
                              taxCodes={taxCodes}
                              classes={classes}
                              jobWorkcenters={jobWorkcenters}
                              lineItemsErrors={lineItemsErrors}
                              setErrors={setErrors}
                              showPicked={showPicked}
                              showInvoiced={showInvoiced}
                              showShipped={showShipped}
                              showAvailable={retrieveQuantityOnHand}
                              showDueDate={showDueDate}
                              draggableProvided={draggableProvided}
                              draggableSnapshot={snapshot}
                            />
                          </TableRow>
                        );
                      }}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
          <LineItemFooter
            totals={totals}
            lineHandler={lineHandler}
            objectType={objectType}
          />
        </Table>
      </TableContainer>
    </>
  );
}
