import { useEffect, useState } from "react";

import { IN_TRANSACTION } from "appConfig";

import {
  getQuickList,
  getAll,
  getItemQuicklist,
} from "services/sosInventoryService/sosApi";
import { handleProgramError } from "services/utility/errors";
import { formatName } from "services/utility/formatting";

import { OBJECT_TYPES } from "appConstants";

const CATEGORY_TYPE = "category";

const QUICKLIST_TYPES = [
  OBJECT_TYPES.VENDOR.fullString,
  OBJECT_TYPES.CUSTOMER.fullString,
  OBJECT_TYPES.ITEM.fullString,
];

/**
 * @name    useRelatedLists
 *
 * @summary get an object which fetches the lists for each passed in
 *          objectType and returns a key value pair for each
 *
 * @param   objectTypesArray (Array) - object type array to be mapped
 *          through in order to fetch data
 *
 * @returns (object) object corresponding to a key value pair for each passed in
 *          objectType in objectTypesArray
 */

export function useRelatedLists(objectTypesArray, isActive) {
  const [relatedLists, setRelatedLists] = useState(null);

  useEffect(() => {
    async function retrieveRecords() {
      const listArray = await Promise.all(
        objectTypesArray.map(async (objectType) => {
          if (QUICKLIST_TYPES.includes(objectType)) {
            const { data } = await getQuickList(objectType, IN_TRANSACTION);
            return data;
          } else if (objectType === CATEGORY_TYPE) {
            // Category is unique in that it uses the item endpoint,
            // with a type filter to specify only categories
            const { data } = await getItemQuicklist({ type: "Category" });
            return data;
          } else {
            const {
              success,
              message,
              data: { records },
            } = await getAll(objectType, IN_TRANSACTION);
            if (success) {
              if (objectType === OBJECT_TYPES.SALES_REP.fullString) {
                return records.map((rep) => ({
                  ...rep,
                  name: formatName(rep),
                }));
              } else {
                return records;
              }
            } else {
              handleProgramError(
                new Error(
                  `useRelated | unsuccessful call to getAll, message: ${message}`
                )
              );
            }
          }
        })
      );
      const listObject = listArray.reduce((seed, e, i) => {
        const objectType = objectTypesArray[i];
        seed[objectType] = e;
        return seed;
      }, {});
      setRelatedLists(listObject);
    }
    if (isActive && objectTypesArray?.length) {
      retrieveRecords();
    }
  }, [objectTypesArray, isActive]);

  return relatedLists;
}
