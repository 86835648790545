import { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Button, Menu, MenuItem, ButtonGroup } from "@mui/material";

import { CONFIGURATION_LISTS } from "appConfig";

import { Loading } from "classes/Loading";

import { Tooltip } from "components/Tooltip";

import { i18n } from "services/i18nService";
import { isValid as isValidAdjustment } from "services/sosInventoryService/adjustment/isValid";
import { isValid as isValidBin } from "services/sosInventoryService/bin/isValid";
import { isValid as isValidBuild } from "services/sosInventoryService/build/isValid";
import { isValid as isValidCustomField } from "services/sosInventoryService/customField/isValid";
import { isValid as isValidCustomer } from "services/sosInventoryService/customer/isValid";
import { isValid as isValidEstimate } from "services/sosInventoryService/estimate/isValid";
import { isValid as isValidFormTemplate } from "services/sosInventoryService/formTemplate/isValid";
import { isValid as isValidInvoice } from "services/sosInventoryService/invoice/isValid";
import { isValid as isValidItem } from "services/sosInventoryService/item/isValid";
import { isValid as isValidItemReceipt } from "services/sosInventoryService/itemReceipt/isValid";
import { isValid as isValidJob } from "services/sosInventoryService/job/isValid";
import { isValid as isValidLot } from "services/sosInventoryService/lot/isValid";
import { nameIsValid } from "services/sosInventoryService/nameIsValid";
import { isValid as isValidPayment } from "services/sosInventoryService/payment/isValid";
import { isValid as isValidPickTicket } from "services/sosInventoryService/pickTicket/isValid";
import { isValid as isValidPriority } from "services/sosInventoryService/priority/isValid";
import { isValid as isValidProcess } from "services/sosInventoryService/process/isValid";
import { isValid as isValidPurchaseOrder } from "services/sosInventoryService/purchaseOrder/isValid";
import { isValid as isValidRental } from "services/sosInventoryService/rental/isValid";
import { isValid as isValidRentalReturn } from "services/sosInventoryService/rentalReturn/isValid";
import { isValid as isValidReorder } from "services/sosInventoryService/reorder/isValid";
import { isValid as isValidReturn } from "services/sosInventoryService/return/isValid";
import { isValid as isValidReturnToVendor } from "services/sosInventoryService/returnToVendor/isValid";
import { isValid as isValidRma } from "services/sosInventoryService/rma/isValid";
import { isValid as isValidSalesOrder } from "services/sosInventoryService/salesOrder/isValid";
import { isValid as isValidSalesReceipt } from "services/sosInventoryService/salesReceipt/isValid";
import { isValid as isValidSalesRep } from "services/sosInventoryService/salesRep/isValid";
import { isValid as isValidSerial } from "services/sosInventoryService/serial/isValid";
import { isValid as isValidShipment } from "services/sosInventoryService/shipment/isValid";
import {
  putRecord,
  postRecord,
  deleteRecords,
  SUCCESS,
} from "services/sosInventoryService/sosApi";
import { isValid as isValidSyncItem } from "services/sosInventoryService/syncItem/isValid";
import { isValid as isValidTask } from "services/sosInventoryService/task/isValid";
import { isValid as isValidLibraryTemplate } from "services/sosInventoryService/templateLibrary/isValid";
import { isValid as isValidTransfer } from "services/sosInventoryService/transfer/isValid";
import { isValid as isValidUom } from "services/sosInventoryService/uom/isValid";
import { isValid as isValidVendor } from "services/sosInventoryService/vendor/isValid";
import { isValid as isValidWarranty } from "services/sosInventoryService/warranty/isValid";
import { isValid as isValidWorkCenter } from "services/sosInventoryService/workCenter/isValid";
import { isValid as isValidWorkOrder } from "services/sosInventoryService/workOrder/isValid";
import { isValid as isValidWorker } from "services/sosInventoryService/worker/isValid";
import { setPageDirty } from "services/utility/edit";
import { handleProgramError } from "services/utility/errors";

import { useCustomNavigate } from "hooks/useCustomNavigate";
import { usePrivileges } from "hooks/usePrivileges";

import { initialErrorState } from "views/common";

import { openAlert } from "globalState/alertSlice";
import { openDialog } from "globalState/globalDialogSlice";
import {
  editModalLoadingIndicatorOn,
  editModalLoadingIndicatorOff,
} from "globalState/loadingSlice";
import { closeModal, openModal } from "globalState/modalSlice";
import { deleteNotifications } from "globalState/notificationsSlice";
import { recordsChangedTick } from "globalState/recordsChangedSlice";

import { OBJECT_TYPES, VIEW_COST_REQUIRED, USER_PRIVILEGE } from "appConstants";
import { saveActions } from "editConfig";

export const isValid = {
  adjustment: isValidAdjustment,
  bins: isValidBin,
  build: isValidBuild,
  class: nameIsValid,
  channel: nameIsValid,
  customer: isValidCustomer,
  customfield: isValidCustomField,
  customermessage: nameIsValid,
  customertype: nameIsValid,
  department: nameIsValid,
  estimate: isValidEstimate,
  formtemplate: isValidFormTemplate,
  invoice: isValidInvoice,
  item: isValidItem,
  itemreceipt: isValidItemReceipt,
  job: isValidJob,
  location: nameIsValid,
  lot: isValidLot,
  orderstage: nameIsValid,
  payment: isValidPayment,
  paymentmethod: nameIsValid,
  pickticket: isValidPickTicket,
  purchaseorder: isValidPurchaseOrder,
  process: isValidProcess,
  priority: isValidPriority,
  rental: isValidRental,
  rentalreturn: isValidRentalReturn,
  reorder: isValidReorder,
  return: isValidReturn,
  returntovendor: isValidReturnToVendor,
  rma: isValidRma,
  salesorder: isValidSalesOrder,
  salesreceipt: isValidSalesReceipt,
  salesrep: isValidSalesRep,
  serial: isValidSerial,
  shipment: isValidShipment,
  syncitem: isValidSyncItem,
  tag: nameIsValid,
  task: isValidTask,
  terms: nameIsValid,
  templatelibrary: isValidLibraryTemplate,
  transfer: isValidTransfer,
  uom: isValidUom,
  vendor: isValidVendor,
  vendormessage: nameIsValid,
  warranty: isValidWarranty,
  workorder: isValidWorkOrder,
  workcenter: isValidWorkCenter,
  worker: isValidWorker,
};

export function SaveButtonSuite(props) {
  const {
    objectType,
    setRecord,
    record,
    relatedRecords,
    lines,
    inputs,
    outputs,
    stages,
    otherCosts,
    setErrors,
    errors,
    vendorId,
    customerId,
    onAdd,
    fromForm,
    newFromId,
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customNavigate = useCustomNavigate(objectType);
  const [anchorEl, setAnchorEl] = useState(null);
  const anchorRef = useRef();
  const { hasPrivilegesOrIsAdmin } = usePrivileges();
  const viewCosts = hasPrivilegesOrIsAdmin(USER_PRIVILEGE.viewCosts);

  const costLoading = lines?.reduce(
    (seed, { cost }) => (cost && cost instanceof Loading ? true : seed),
    false
  );

  function getObjectIdentifier(record) {
    if (record.number) {
      return record.number;
    }
    if (record.name) {
      return record.name;
    }
    return "";
  }

  // afterSave can be "close", "keepEditing", "new", "goTo", "pdf", or "email"
  async function save(afterSave) {
    if (errors?.customFieldErrors?.length) {
      // check for customFieldErrors seperately as those are
      // dependant on a user updating the field to an incorrect
      // value and not just the state of the value itself. This
      // customFieldErrors value can set in CustomFieldDatePicker.js
      setErrors((prev) => ({
        ...prev,
        messages: prev.customFieldErrors.map(({ message }) => message),
      }));
      return;
    }
    // if the record is not in a valid state...
    const isValidErrors = isValid[objectType](
      record,
      relatedRecords,
      lines,
      outputs,
      inputs
    );
    if (isValidErrors.messages.length) {
      setErrors(isValidErrors);
      return;
    }
    dispatch(editModalLoadingIndicatorOn());
    const completeRecord = {
      ...record,
      lines,
      otherCosts,
      workcenters: stages,
      inputs: inputs,
      outputs: outputs,
    };
    // the presence of record.id indicates that this is an existing
    // record, that we update; otherwise, we create a new record
    const persistenceFunc = record.id ? putRecord : postRecord;
    const {
      success,
      record: newRecord,
      message,
    } = await persistenceFunc(objectType, completeRecord, newFromId);
    dispatch(editModalLoadingIndicatorOff());
    if (success) {
      dispatch(
        openAlert({
          type: "success",
          message: `${i18n(
            `objectType.${objectType}.Sentence`
          )} ${getObjectIdentifier(newRecord)} ${i18n("alert.SuccessSave")}`,
        })
      );
      if (onAdd) {
        onAdd(newRecord);
      } else {
        setPageDirty(false);
        const linkPrefix = CONFIGURATION_LISTS.includes(objectType)
          ? "/configuration/list"
          : "";
        switch (afterSave) {
          case "close":
            dispatch(closeModal());
            dispatch(recordsChangedTick());
            customNavigate.goToList();
            break;
          case "new":
            dispatch(closeModal());
            navigate(`${linkPrefix}/${objectType}/new`);
            break;
          case "keepEditing":
            setRecord(newRecord);
            break;
          case "goTo":
            dispatch(closeModal());
            navigate(`${linkPrefix}/${objectType}?id=${newRecord.id}`);
            break;
          case "ship":
            customNavigate.clearListParams();
            dispatch(closeModal());
            dispatch(
              openModal({
                objectType: OBJECT_TYPES.SHIPMENT.fullString,
                modalProps: {
                  newFromObjectType: objectType,
                  newFromId: newRecord.id,
                },
              })
            );
            break;
          case "pdf":
            dispatch(closeModal());
            customNavigate.goToList();
            dispatch(recordsChangedTick());
            dispatch(
              openDialog({
                type: "pdf",
                dialogProps: {
                  objectType: objectType,
                  objectIds: [newRecord.id],
                  recordNumber: newRecord.number,
                },
              })
            );
            break;
          case "email":
            dispatch(closeModal());
            customNavigate.goToList();
            dispatch(recordsChangedTick());
            dispatch(
              openDialog({
                type: "email",
                dialogProps: {
                  objectIds: [newRecord.id],
                  objectType: objectType,
                  documents: newRecord.documents,
                  transactionNumber: newRecord.number,
                  vendorId,
                  customerId,
                },
              })
            );
            break;
          default:
            handleProgramError(
              new Error(`unexpected value for afterSave (${afterSave})`)
            );
        }
        setErrors(initialErrorState());
      }
      if (
        objectType === OBJECT_TYPES.TASK.fullString &&
        record.deleteNotification &&
        record.notificationId
      ) {
        const response = await deleteRecords("notification", [
          record.notificationId,
        ]);
        if (response === SUCCESS) {
          dispatch(deleteNotifications([record.notificationId]));
        } else {
          handleProgramError(
            new Error("Unable to delete associated notification")
          );
        }
      }
    } else {
      setErrors((prev) => ({ ...prev, messages: [message] }));
    }
  }

  function handleOperation(operation) {
    setAnchorEl(null);
    operation();
  }

  function getShowAction(type) {
    if (!saveActions[objectType].includes(type)) {
      return false;
    }
    if (!VIEW_COST_REQUIRED[type]?.includes(objectType)) {
      return true;
    }
    return viewCosts;
  }

  function showMore() {
    return Boolean(saveActions[objectType].length);
  }

  return (
    <>
      {!fromForm && showMore() && (
        <div style={{ width: "6em" }}>
          <ButtonGroup fullWidth>
            <Button
              ref={anchorRef}
              variant="outlined"
              onClick={() => setAnchorEl(anchorRef.current)}
              sx={{ backgroundColor: "white" }}
              disabled={costLoading}
            >
              {i18n("saveActions.More")}
            </Button>
            <Button
              size="small"
              variant="contained"
              onClick={() => setAnchorEl(anchorRef.current)}
              disableElevation
              disabled={costLoading}
            >
              <KeyboardArrowDownIcon />
            </Button>
          </ButtonGroup>
        </div>
      )}
      <div style={{ width: "7em" }}>
        <Tooltip title={costLoading ? i18n("tooltip.SaveDisabled") : ""}>
          <span>
            <Button
              variant="contained"
              onClick={() => {
                save("close");
              }}
              data-testing="saveAndClose"
              disableElevation
              fullWidth
              disabled={costLoading}
            >
              {i18n("saveActions.SaveAndClose")}
            </Button>
          </span>
        </Tooltip>
      </div>

      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        keepMounted
        onClose={() => setAnchorEl(null)}
        disableScrollLock={true}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {getShowAction("goTo") && (
          <MenuItem onClick={() => handleOperation(async () => save("goTo"))}>
            {i18n("saveActions.SaveAndGoTo")}
          </MenuItem>
        )}
        {getShowAction("keepEditing") && (
          <MenuItem onClick={() => handleOperation(() => save("keepEditing"))}>
            {i18n("saveActions.SaveAndKeepEditing")}
          </MenuItem>
        )}
        {getShowAction("new") && (
          <MenuItem onClick={() => handleOperation(() => save("new"))}>
            {i18n("saveActions.SaveAndNew")}
          </MenuItem>
        )}
        {getShowAction("pdf") && (
          <MenuItem onClick={() => handleOperation(() => save("pdf"))}>
            {i18n("saveActions.SaveAndPDF")}
          </MenuItem>
        )}
        {getShowAction("email") && (
          <MenuItem onClick={() => handleOperation(() => save("email"))}>
            {i18n("saveActions.SaveAndSend")}
          </MenuItem>
        )}
        {getShowAction("ship") && (
          <MenuItem onClick={() => handleOperation(() => save("ship"))}>
            {i18n("saveActions.SaveAndShip")}
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
