import { i18n } from "services/i18nService";
import {
  formatDocumentInfo,
  formatLinkField,
  formatSync,
} from "services/utility/formatting";

export const columnInfo = [
  {
    name: "name",
    fixed: true,
    heading: i18n("columns.Name"),
    formatFunc: formatLinkField,
    fieldName: "fullName",
    defaultSort: true,
    linkField: true,
    sortable: true,
  },
  {
    name: "sync",
    fixed: true,
    fixedRightOfAction: true,
    heading: i18n("columns.Sync"),
    formatFunc: formatSync,
    default: true,
  },
  {
    name: "documents",
    fixed: true,
    fixedRightOfAction: true,
    heading: i18n("columns.Documents"),
    formatFunc: formatDocumentInfo,
    minWidth: "15em",
    default: true,
    isDocument: true,
    verticalAlign: "top",
  },
];

const columnArray = Object.entries(columnInfo);
const defaultColumnInfo = columnArray.filter((col) => col[1].required);
export const defaultColumns = defaultColumnInfo.map((col) => col[0]);

export const LINE_ACTIONS = ["delete", "sync"];
export const BATCH_ACTIONS = ["delete", "sync"];
