import { useState } from "react";

import { InventoryDialog } from "components/InventoryDialog";
import { Link } from "components/html/Link";
import { LinkText } from "components/utility/LinkText";

import { getCustomFieldValue } from "services/utility/customFields";

import { AddToReorderList } from "views/Reports/jsonReports/AddToReorderList";
import { AddToSync } from "views/Reports/jsonReports/AddToSync";
import { Checkbox } from "views/Reports/jsonReports/Checkbox";
import { SelectAllCheckbox } from "views/Reports/jsonReports/SelectAllCheckbox";

import { theme } from "SosTheme";
import { getObjectFromTypeString } from "appConstants";

export function JsonReport(props) {
  const {
    columns,
    reportJson,
    reportAction,
    sortJsonReport,
    setCheckedIds,
    checkedIds,
    groupByIsActive,
  } = props;

  const [modalProps, setModalProps] = useState({
    id: null,
    type: null,
    open: false,
  });

  function getReportAction(row) {
    const { id } = reportAction;
    switch (id) {
      case "AddToReorderList":
        return <AddToReorderList id={row.item?.id} />;
      case "AddToSync":
        return (
          <AddToSync
            id={row.id}
            objectType={getObjectFromTypeString(row.txnType).fullString}
          />
        );
      case "Checkbox":
        return (
          <Checkbox
            id={row.item?.id}
            setCheckedIds={setCheckedIds}
            isChecked={checkedIds.includes(row.item?.id)}
          />
        );
      default:
        throw new Error(
          `List action ${reportAction} not supported in getReportAction`
        );
    }
  }

  function getReportActionHeader(reportAction) {
    const { id, label } = reportAction;
    switch (id) {
      case "AddToReorderList":
      case "AddToSync":
        return <th>{label}</th>;
      case "Checkbox":
        return (
          <SelectAllCheckbox
            setCheckedIds={setCheckedIds}
            reportJson={reportJson}
          />
        );
      default:
        throw new Error(
          `List action ${reportAction} not supported in getReportAction`
        );
    }
  }

  function getListPageLink(id, typeString) {
    const { fullString } = getObjectFromTypeString(typeString);
    return `/${fullString}?id=${id}`;
  }

  function getCellContents(column, row, cellValue) {
    const {
      linkColumn,
      linkObjectType,
      dynamicLinkKeyValue,
      inventoryColumn,
      inventoryType,
    } = column;

    if (linkColumn) {
      return (
        <Link
          to={getListPageLink(
            linkObjectType ? row[linkObjectType]?.id : row.id,
            dynamicLinkKeyValue ? row[dynamicLinkKeyValue] : linkObjectType
          )}
          underline="none"
        >
          {cellValue}
        </Link>
      );
    }
    if (inventoryColumn) {
      return (
        <LinkText
          onClick={() =>
            setModalProps({ item: row.item, type: inventoryType, open: true })
          }
        >
          {cellValue}
        </LinkText>
      );
    }
    return cellValue;
  }

  const showFooter = columns.reduce(
    (seed, { footerFunc }) => seed || Boolean(footerFunc),
    false
  );

  return (
    <>
      {modalProps.open && (
        <InventoryDialog
          onClose={() => setModalProps((prev) => ({ ...prev, open: false }))}
          id={modalProps.item.id}
          name={modalProps.item.name}
          type={modalProps.type}
        />
      )}
      <table
        className="table-sort"
        style={{ overflowX: "scroll", width: "100%" }}
      >
        <thead data-testing="reportTableHeader">
          <tr>
            {reportAction && getReportActionHeader(reportAction)}
            {columns.map((c, index) => {
              const { number, apiName, label, jsonName, formatFunc } = c;
              return (
                <th
                  onClick={() =>
                    !groupByIsActive &&
                    sortJsonReport(jsonName, formatFunc, number)
                  }
                  style={{
                    textAlign: number ? "right" : "left",
                    cursor: groupByIsActive ? "inherit" : "pointer",
                  }}
                  key={index}
                >
                  {label || apiName}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody data-testing="reportTableBody">
          {reportJson.map((row, index) => {
            const { exceptionCode, groupName, isGroupByTotal } = row;
            if (groupName) {
              // group by row
              return (
                <tr key={index}>
                  {columns.map(({ jsonName }, i) => {
                    const cellContent = isGroupByTotal ? row[jsonName] : "";
                    return (
                      <td
                        key={i}
                        style={{
                          textAlign: i === 0 ? "left" : "right",
                          fontWeight: "bold",
                        }}
                      >
                        {i === 0 ? groupName : cellContent}
                      </td>
                    );
                  })}
                </tr>
              );
            }
            return (
              <tr key={index}>
                {reportAction && getReportAction(row)}
                {columns.map((column, index) => {
                  const {
                    formatFunc,
                    apiName,
                    jsonName,
                    number,
                    isExceptionColumn,
                    isCustomField,
                  } = column;
                  const textAlign = number ? "right" : "left";
                  const backgroundColor =
                    exceptionCode && isExceptionColumn
                      ? theme.palette.exceptionBackgroundColor[exceptionCode]
                      : "inherit";
                  let cellValue;
                  if (isCustomField) {
                    cellValue = getCustomFieldValue(row.customFields, apiName);
                  } else {
                    cellValue = formatFunc
                      ? formatFunc(row[jsonName], row)
                      : row[jsonName];
                  }

                  return (
                    <td key={index} style={{ textAlign, backgroundColor }}>
                      {getCellContents(column, row, cellValue)}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        {showFooter && (
          <tfoot>
            <tr>
              {reportAction && <th></th>}
              {columns.map((c, index) => {
                const { number, jsonName, footerFunc } = c;
                if (!footerFunc) {
                  return <th key={index}></th>;
                }
                return (
                  <th
                    key={index}
                    style={{ textAlign: number ? "right" : "left" }}
                  >
                    {footerFunc(reportJson, jsonName)}
                  </th>
                );
              })}
            </tr>
          </tfoot>
        )}
      </table>
    </>
  );
}
