import { useEffect, useState } from "react";

import { Paper, MenuItem, Button, Menu, Typography } from "@mui/material";

import { DatePicker } from "components/html/Input/DatePicker";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import { quickDateGroups } from "services/utility/dateFilter";

export function BaseDateFilter(props) {
  const {
    toDate,
    fromDate,
    setDateError,
    setDateFilter,
    dateFilter,
    onDateChange,
  } = props;

  const [dates, setDates] = useState({ toDate, fromDate });

  const [anchorEl, setAnchorEl] = useState(null);

  const [currentQuickName, setCurrentQuickName] = useState("");

  useEffect(() => {
    setDates({ toDate, fromDate });
    setDateFilter(dateFilter);
  }, [setDates, setDateFilter, toDate, fromDate, dateFilter]);

  function onError(error, dateType) {
    if (error === "minDate" || error === "maxDate") {
      setDateError((prev) => ({ ...prev, [dateType]: true }));
    }
  }

  function handleQuickDate(fromDate, toDate, name) {
    setDates({ toDate, fromDate });
    onDateChange({ fromDate, toDate });
    setCurrentQuickName(name);
    setAnchorEl(null);
  }

  function handleDateChange(dateType, date) {
    setDateFilter("");
    setDates((prev) => ({ ...prev, [dateType]: date }));
    setCurrentQuickName("");
    onDateChange({ fromDate, toDate });
  }

  return (
    <>
      <Paper
        variant="outlined"
        sx={{ padding: "8px" }}
        data-testing="dateFilter"
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            variant="outlined"
            size="small"
            onClick={(e) => setAnchorEl(e.currentTarget)}
            sx={{ width: "10.125rem", marginRight: "5px" }}
          >
            {i18n("global.QuickDateSelector")}
          </Button>
          <Typography
            align="center"
            sx={{ width: "10.125rem", marginLeft: "5px" }}
          >
            {currentQuickName && `Current: ${currentQuickName}`}
          </Typography>
        </div>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          keepMounted
        >
          <div style={{ display: "flex" }}>
            {quickDateGroups.map((qdg, index) => (
              <div key={index}>
                <>
                  {qdg.map((quickDate) => (
                    <MenuItem
                      key={quickDate.name}
                      onClick={() =>
                        handleQuickDate(
                          quickDate.from(),
                          quickDate.to(),
                          quickDate.name
                        )
                      }
                    >
                      {quickDate.name}
                    </MenuItem>
                  ))}
                </>
              </div>
            ))}
          </div>
        </Menu>
        <VSpace space={1} />
        <div style={{ display: "flex", gap: "1rem" }}>
          <DatePicker
            name="fromDate"
            key={`${dates.fromDate}fromDate`}
            value={dates.fromDate}
            label={`${i18n("Dates.From")}`}
            onError={(error) => onError(error, "from")}
            onAccept={() => setDateError((prev) => ({ ...prev, from: false }))}
            onValueChange={handleDateChange}
          />
          <DatePicker
            name="toDate"
            key={`${dates.toDate}toDate`}
            value={dates.toDate}
            label={`${i18n("Dates.To")}`}
            onError={(error) => onError(error, "to")}
            onAccept={() => setDateError((prev) => ({ ...prev, to: false }))}
            onValueChange={handleDateChange}
          />
        </div>
      </Paper>
    </>
  );
}
