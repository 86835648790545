import { useState, useEffect } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { TextField } from "@mui/material";

import { currencyMinMaxFractionDigits } from "services/utility/formatting";
import { LOCALIZATION } from "services/utility/localization";
import { checkForUnexpectedProps } from "services/utility/misc";

export function FrmTextField(props) {
  const {
    value: initialValue,
    multiline,
    number,
    money,
    moneyDecimalPlaces,
    maxLength,
    children,
    readOnly,
    onValueChange,
    onValueBlur,
    dataTesting,
    disabled,
    autoFocus,
    label,
    error,
    name,
    placeholder,
    helperText,
    type,
    select,
    id,
    startAdornment,
    sx,
    ...unexpected
  } = props;
  checkForUnexpectedProps("FrmTextField", unexpected);

  const [value, setValue] = useState("");
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    if (money) {
      setValue(
        currencyMinMaxFractionDigits(
          initialValue,
          LOCALIZATION.locale,
          LOCALIZATION.currencyMinimumFractionDigits,
          moneyDecimalPlaces
            ? moneyDecimalPlaces
            : LOCALIZATION.currencyMinimumFractionDigits,
          false
        )
      );
    } else {
      setValue(initialValue);
    }
  }, [initialValue, money, moneyDecimalPlaces]);

  let inputSx = { textAlign: number || money ? "right" : "left" };
  if (!multiline) {
    inputSx = {
      ...inputSx,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    };
  }

  function onChange(e) {
    const { name, value } = e.target;
    if (maxLength && value.length > maxLength) {
      return;
    }
    setUpdated(true);
    setValue(value);
    onValueChange && onValueChange(name, value);
  }

  function handleValueBlur(e) {
    if (updated && onValueBlur) {
      onValueBlur(e.target.name, e.target.value);
      setUpdated(false);
    }
  }

  return (
    <TextField
      label={label}
      id={id}
      name={name}
      disabled={disabled}
      autoComplete="off"
      margin="dense"
      onChange={onChange}
      onBlur={handleValueBlur}
      value={value || value === 0 ? value : ""}
      multiline={multiline}
      variant="outlined"
      fullWidth
      inputProps={{ readOnly, sx: inputSx }}
      InputProps={
        startAdornment ? { startAdornment: startAdornment } : undefined
      }
      SelectProps={{
        IconComponent: KeyboardArrowDownIcon,
        MenuProps: { disableScrollLock: true },
      }}
      sx={sx}
      InputLabelProps={{ shrink: true }}
      data-testing={dataTesting}
      autoFocus={autoFocus}
      error={error}
      placeholder={placeholder}
      helperText={helperText}
      type={money || number ? "number" : type}
      select={select}
    >
      {children}
    </TextField>
  );
}
