import { i18n } from "services/i18nService";
import {
  getItemQuicklist,
  getRecord,
} from "services/sosInventoryService/sosApi";
import { formatDate } from "services/utility/dates";
import { handleProgramError } from "services/utility/errors";

import { UNASSIGNED_OPTION } from "views/Reports/reportConstants";

import globalState from "globalState/globalState";

import { OBJECT_TYPES, ALL_FILTER_OPTION, ITEM_TYPES } from "appConstants";

function getName(value, options) {
  if (value?.name) {
    return value.name;
  }
  return options ? options.find(({ id }) => id === value.id).name : "";
}

function getFullDataFromArray(valueArray, options) {
  return valueArray.map((value) => {
    if (value?.name) {
      return value;
    } else if (value?.id === ALL_FILTER_OPTION.id) {
      return ALL_FILTER_OPTION;
    }
    return options
      ? options.find(({ id }) => id === value.id)
      : { id: value.id };
  });
}

export async function formatListPageFilterList(
  objectType,
  filterSettings,
  statusQueryParam
) {
  const {
    Action,
    Category,
    Customer,
    FromDate,
    FromExpiration,
    FromLocation,
    Item,
    Location,
    ShowArchived,
    ShowClosed,
    ShowStatus,
    ShowVariants,
    Tags,
    ToDate,
    ToExpiration,
    ToLocation,
    Type,
    UserId,
    Vendor,
  } = filterSettings;

  const { findCustomerByFullName } =
    globalState.getState().userCompanySettings.settings;

  // the filter list will look like "[preParts] objectType (plural) [postParts]"
  // and will be blank "" if there are no pre- or post- parts
  let preParts = [];
  let postParts = [];

  if (statusQueryParam) {
    preParts.push(`${i18n("filter.heading.status." + statusQueryParam)}`);
  } else {
    if (ShowArchived) {
      // note: there are some legacy values of "no", instead of "No", so the
      // localization files should account for both
      preParts.push(`${i18n("filter.heading.archive." + ShowArchived)}`);
    }

    if (ShowClosed && ShowClosed !== "No") {
      preParts.push(`${i18n("filter.heading.status." + ShowClosed)}`);
    }

    if (ShowStatus && ShowStatus !== "All") {
      preParts.push(i18n("filter.heading.status." + ShowStatus));
    } else if (!ShowStatus && objectType === OBJECT_TYPES.TASK.fullString) {
      preParts.push(i18n("filter.heading.status.active"));
    }

    if (Action) {
      preParts.push(Action);
    }

    if (Type) {
      preParts.push(
        `${i18n("filter.heading.itemType." + Type.replace(/ /g, ""))}`
      );
    }

    if (Category) {
      const response = await getItemQuicklist({ type: ITEM_TYPES.CATEGORY });
      const categories = response.data;
      const category = categories.find(({ id }) => id === parseInt(Category));
      preParts.push(category.name);
    }

    if (FromDate) {
      postParts.push(`${i18n("filter.heading.from")} ${formatDate(FromDate)}`);
    }

    if (FromExpiration) {
      postParts.push(
        `${i18n("filter.heading.fromExpiration")} ${formatDate(FromExpiration)}`
      );
    }

    if (ToDate) {
      postParts.push(`${i18n("filter.heading.to")} ${formatDate(ToDate)}`);
    }

    if (ToExpiration) {
      postParts.push(
        `${i18n("filter.heading.toExpiration")} ${formatDate(ToExpiration)}`
      );
    }

    if (Item) {
      try {
        const item = await getRecord("item", Item);
        postParts.push(`for ${item.name}`);
      } catch (e) {
        handleProgramError(e);
      }
    }

    if (Location) {
      try {
        const location = await getRecord("location", Location);
        postParts.push(`at ${location.name}`);
      } catch (e) {
        handleProgramError(e);
      }
    }

    if (FromLocation) {
      try {
        const location = await getRecord("location", FromLocation);
        postParts.push(`from ${location.name}`);
      } catch (e) {
        handleProgramError(e);
      }
    }

    if (ToLocation) {
      try {
        const location = await getRecord("location", ToLocation);
        postParts.push(`to ${location.name}`);
      } catch (e) {
        handleProgramError(e);
      }
    }

    if (Vendor) {
      try {
        const vendor = await getRecord("vendor", Vendor);
        postParts.push(`from ${vendor.name}`);
      } catch (e) {
        handleProgramError(e);
      }
    }

    if (Customer) {
      const customer = await getRecord("customer", Customer);
      postParts.push(
        `for ${customer[findCustomerByFullName ? "fullname" : "name"]}`
      );
    }

    if (UserId) {
      const user = await getRecord("user", UserId);
      postParts.push(`${i18n("global.assignedTo")} ${user.name}`);
    }

    if (Tags) {
      postParts.push(`${i18n("global.tagged")} ${Tags.split(",").join(", ")}`);
    }

    if (ShowVariants && ShowVariants === "HideVariants") {
      postParts.push(i18n("filter.heading.variants.HideVariants"));
    }
  }

  if (preParts.length === 0 && postParts.length === 0) {
    return "";
  }

  let filterLine = i18n("filter.heading.intro.listPage") + " ";
  if (preParts.length > 0) {
    filterLine += preParts.join(" ");
  }
  filterLine += " " + i18n(`objectType.${objectType}.LowerPlural`) + " ";

  if (postParts.length > 0) {
    filterLine += " " + postParts.join(" ");
  }

  return filterLine;
}

export function formatReportFilterList(
  filterState,
  filters,
  relatedLists,
  optionCounts
) {
  const MAX_OPTIONS_TO_LIST = 4;
  const {
    categories,
    locations,
    users,
    vendor,
    department,
    customer,
    salesReps,
    item,
    channel,
    nameContains,
    priceGroup,
    nameContains2,
    date1,
    date2,
  } = filterState;

  // TODO: As future reports call for it, it may make sense to update
  // this functionality to create a new object with the filter names
  // and their (headingText)
  const date1Defaults = filters.find(({ apiName }) => apiName === "date1");

  // the filter list will look like "[preParts] item [postParts]"
  // and will be blank "" if there are no pre- or post- parts
  let preParts = [];
  let postParts = [];

  function getOptionArraySubHeaderString(options, filterName, preposition) {
    if (options.length === optionCounts[filterName]) {
      return `${i18n("filter.all")} ${i18n(
        `reports.Subheader.${filterName}.plural`
      )}`;
    } else {
      const names = options.map(({ name }) => name);
      if (options.length <= MAX_OPTIONS_TO_LIST) {
        return names.join(", ");
      } else {
        const firstFive = names.slice(0, MAX_OPTIONS_TO_LIST);
        const howManyMore = options.length - MAX_OPTIONS_TO_LIST;
        const singularOrPluralString =
          howManyMore === 1 ? "singular" : "plural";
        return `${firstFive.join(", ")} +${howManyMore} ${i18n(
          "global.more"
        )} ${i18n(
          `reports.Subheader.${filterName}.${singularOrPluralString}`
        ).toLowerCase()} ${preposition} `;
      }
    }
  }

  if (categories) {
    const fullCategoriesData = getFullDataFromArray(
      categories,
      relatedLists?.category
    );
    const categoryParts = getOptionArraySubHeaderString(
      fullCategoriesData,
      "categories",
      i18n("global.of")
    );
    preParts.push(categoryParts);
  }

  if (locations) {
    const fullLocationsData = getFullDataFromArray(
      locations,
      relatedLists?.location
    );
    const locationsParts = getOptionArraySubHeaderString(
      fullLocationsData,
      "locations",
      i18n("global.of")
    );
    preParts.push(locationsParts);
  }

  if (users) {
    const userFilter = filters.find(({ apiName }) => apiName === "users");
    let userNames;
    if (userFilter?.options) {
      userNames = users.map(
        ({ name, id }) =>
          name || userFilter.options.find((option) => id === option.id)?.name
      );
    } else {
      userNames = users.map(({ name, id }) => {
        if (id === UNASSIGNED_OPTION.id) {
          return UNASSIGNED_OPTION.name;
        }
        return getName({ name, id }, relatedLists?.user);
      });
    }
    postParts.push(`${i18n("global.for")} ${userNames.join(", ")}`);
  }

  if (vendor?.id) {
    const vendorName = getName(vendor, relatedLists?.vendor);
    postParts.push(`${i18n("global.from")} ${vendorName}`);
  }

  if (customer?.id) {
    const customerName = getName(customer, relatedLists?.customer);
    postParts.push(`${i18n("global.to")} ${customerName}`);
  }

  if (filterState.class?.id) {
    const className = getName(filterState.class, relatedLists?.class);
    postParts.push(`${i18n("reports.withClass")} ${className}`);
  }

  if (department?.id) {
    const departmentName = getName(department, relatedLists?.department);
    postParts.push(`${i18n("reports.withDepartment")} ${departmentName}`);
  }

  if (channel?.id) {
    const channelName = getName(channel, relatedLists?.channel);
    postParts.push(`${i18n("reports.withChannel")} ${channelName}`);
  }

  if (priceGroup?.id) {
    const priceGroupName = getName(priceGroup, relatedLists?.pricetier);
    postParts.push(`${i18n("reports.withPriceTier")} ${priceGroupName}`);
  }
  if (salesReps) {
    const fullSalesRepsData = getFullDataFromArray(
      salesReps,
      relatedLists?.salesrep
    );
    const salesRepParts = getOptionArraySubHeaderString(
      fullSalesRepsData,
      "salesReps",
      i18n("global.for")
    );
    preParts.push(salesRepParts);
  }

  if (nameContains) {
    postParts.push(`${i18n("reports.containing")} "${nameContains}"`);
  }

  if (nameContains2) {
    postParts.push(`${i18n("reports.containing")} "${nameContains2}"`);
  }

  if (date1) {
    postParts.push(`${date1Defaults.headerText} ${formatDate(date1)}`);
  }

  if (date2) {
    postParts.push(`${i18n("filter.heading.to")} ${formatDate(date2)}`);
  }

  if (preParts.length === 0 && postParts.length === 0) {
    return "";
  }

  let filterLine = `${i18n("filter.heading.intro.report")} `;
  if (preParts.length > 0) {
    filterLine += preParts.join(" ");
  }
  filterLine += item?.id ? ` ${item.name}` : "";
  filterLine += ` ${i18n(
    `objectType.item.${item?.id ? "Lower" : "LowerPlural"}`
  )} `;

  if (postParts.length > 0) {
    filterLine += " " + postParts.join(" ");
  }

  return filterLine;
}
