import { i18n } from "services/i18nService";

import { SUB_COMPONENT } from "views/Reports/reportConstants";

export const ItemsInBins = {
  userHasSufficientPrivilege: (p) =>
    p.viewInventory || p.viewItems || p.editItems,
  planIncludesReport: (plus, pro) => plus || pro,
  customFieldObjectTypes: "item",
  relatedLists: ["vendor", "location", "category"],
  columns: [
    { apiName: "Name", default: true },
    { apiName: "Category" },
    { apiName: "Description", default: true },
    { apiName: "Purchase Description" },
    { apiName: "SKU" },
    { apiName: "Barcode" },
    { apiName: "Vendor" },
    { apiName: "VendorPartNumber", label: "Vendor Part #" },
    { apiName: "Sales Price", number: true },
    { apiName: "Purchase Cost", number: true },
    { apiName: "Default Bin" },
    { apiName: "Asset Account" },
    { apiName: "Lead Time", number: true },
    { apiName: "Reorder Point", number: true },
    { apiName: "Max Stock", number: true },
    { apiName: "Tags" },
    { apiName: "Quantity", number: true, alwaysShow: true },
  ],
  requiredFiltersNotSetMsg: i18n("reports.LocationFilterRequired"),
  settings: [
    {
      apiName: "date1",
      macroApiName: "date1Macro",
      headerText: i18n("filter.heading.asOf"),
      component: "DateFilter",
      label: i18n("reports.FilterLabel.AsOfDate"),
      datePrefix: "endOf",
      defaultValue: () => new Date(),
      type: "filter",
    },
    {
      apiName: "date1Macro",
      component: SUB_COMPONENT,
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("filter.BinTrackedLocations"),
      component: "LocationsFilter",
      apiName: "locations",
      defaultValue: null,
      onlyBinTracked: true,
      placeholder: i18n("reports.placeholder.DefaultIsAll", {
        type: i18n("reports.locations"),
      }),
      type: "filter",
    },
    {
      label: i18n("filter.Categories"),
      component: "CategoryFilter",
      apiName: "categories",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.ItemNameContains"),
      component: "FrmTextField",
      apiName: "nameContains",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.BinNumberContains"),
      component: "FrmTextField",
      apiName: "nameContains2",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("filter.Vendor"),
      component: "VendorFilter",
      apiName: "vendor",
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "showArchived",
      defaultValue: false,
      label: i18n("reports.OptionLabel.IncludeArchivedItems"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "showFromTo",
      defaultValue: false,
      component: "FrmCheckbox",
      label: i18n("reports.OptionLabel.ShowAsOfDate"),
      isAsOfDate: true,
      type: "option",
    },
    {
      defaultValue: "",
      apiName: "header",
      component: "FrmTextField",
      label: i18n("global.Header"),
      type: "option",
    },
    {
      defaultValue: "",
      apiName: "footer",
      component: "FrmTextField",
      label: i18n("global.Footer"),
      type: "option",
    },
  ],
};
