import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { IN_TRANSACTION } from "appConfig";

import { getAll } from "services/sosInventoryService/sosApi";
import { handleProgramError } from "services/utility/errors";

export function useWorkers() {
  const [workers, setWorkers] = useState(null);
  const trackWorkers = useSelector(
    (state) => state.userCompanySettings.settings.trackWorkers
  );
  const allowProFeatures = useSelector(
    (state) => state.userCompanySettings.settings.allowProFeatures
  );

  useEffect(() => {
    async function retrieveWorkers() {
      const response = await getAll("worker", IN_TRANSACTION);
      if (response.success) {
        const workers = response.data.records.map((worker) => {
          const { firstName, lastName } = worker;
          return { ...worker, name: `${firstName} ${lastName}` };
        });
        setWorkers(workers);
      } else {
        handleProgramError(
          new Error(
            `useWorkers | unsuccessful call to getAll, message: ${response.message}`
          )
        );
      }
    }

    // only send request if account has worker tracking enabled and Pro account
    if (trackWorkers && allowProFeatures) {
      retrieveWorkers();
    } else {
      setWorkers([]);
    }
  }, [trackWorkers, allowProFeatures]);

  return workers;
}
