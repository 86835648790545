import { useSelector } from "react-redux";

import { usePlans } from "hooks/usePlans";
import { usePrivileges } from "hooks/usePrivileges";

import { ITEM_TYPES_WITH_BOM } from "appConstants";
import { USER_PRIVILEGE, VIEW_COST_REQUIRED } from "appConstants";

export function useActionPrivileges(objectType, record) {
  const { allowPlusFeatures, allowProFeatures } = usePlans();
  const { hasPrivilegesOrIsAdmin, canEditObjectType, isSupport } =
    usePrivileges();
  const allowBillofMaterials =
    ITEM_TYPES_WITH_BOM.includes(record?.type) && allowPlusFeatures;
  const isAllowedToEditObjectType = canEditObjectType(objectType);
  const viewCosts = hasPrivilegesOrIsAdmin(USER_PRIVILEGE.viewCosts);
  const approvePos = hasPrivilegesOrIsAdmin(USER_PRIVILEGE.approvePos);

  const requireApprovalPo = useSelector(
    (state) => state.userCompanySettings.settings.requireApprovalPO
  );

  const sosSupportTier3 = useSelector(
    (state) => state.userCompanySettings.supportPrivileges?.sosSupportTier3
  );

  const allowTransfer = hasPrivilegesOrIsAdmin(
    USER_PRIVILEGE.transferInventory
  );
  const connectedToQBO = useSelector(
    (state) => state.userCompanySettings.settings.connectedToQBO
  );

  const paymentsBoarded = useSelector(
    (state) => state.userCompanySettings.settings.paymentsBoarded
  );
  const allowPayment =
    hasPrivilegesOrIsAdmin(USER_PRIVILEGE.processPayment) && paymentsBoarded;

  const allowPdf = !VIEW_COST_REQUIRED.pdf.includes(objectType) || viewCosts;
  const allowEmail =
    !VIEW_COST_REQUIRED.email.includes(objectType) || viewCosts;
  const allowQuickView =
    !VIEW_COST_REQUIRED.quickView.includes(objectType) || viewCosts;
  const allowHistory =
    !VIEW_COST_REQUIRED.history.includes(objectType) || viewCosts;

  return {
    approve: approvePos && requireApprovalPo,
    billOfMaterials: allowBillofMaterials,
    deleteTemplateLibraryForm: sosSupportTier3,
    disassemble: allowProFeatures,
    edit: isSupport, // for editing a sync item, only support privileged users
    email: allowEmail,
    history: allowHistory,
    itemHistory: allowHistory,
    job: allowProFeatures,
    locationSettings: isAllowedToEditObjectType,
    payment: allowPayment,
    pdf: allowPdf,
    profitLoss: viewCosts,
    quickView: allowQuickView,
    returnToVendor: allowPlusFeatures,
    RMA: allowPlusFeatures,
    sync: isAllowedToEditObjectType,
    transfer: allowTransfer,
    uom: allowPlusFeatures,
    variants: record?.hasVariants,
    vendorHistory: allowHistory,
    viewInQuickBooks: connectedToQBO,
    workOrder: allowProFeatures,
  };
}
