import { TotalsLine } from "components/formFields/Totals/TotalsLine";

import { currencyMinMaxFractionDigits } from "services/utility/formatting";
import { LOCALIZATION } from "services/utility/localization";

export function LineItemTotals(props) {
  const { value, label } = props;

  function getLocalizedValue(value) {
    return currencyMinMaxFractionDigits(
      value,
      LOCALIZATION.locale,
      LOCALIZATION.currencyMinimumFractionDigits,
      LOCALIZATION.currencyMinimumFractionDigits,
      true
    );
  }

  return <TotalsLine label={label} value={getLocalizedValue(value)} />;
}
