import { REFERENCE_FIELDS } from "appConfig";

import { INITIAL_INVENTORY_FIELDS } from "services/sosInventoryService/item/schema";
import { convertNativeToCustom } from "services/utility/customFields";

const REFERENCE_FIELD = REFERENCE_FIELDS.item;

export function beforeSave(record) {
  const newRecord = { ...record };

  // renamed field to eliminate outdated "manufacturing" terminology
  newRecord.showOnManufacturingForms = record.showOnProductionForms;
  delete newRecord.showOnProductionForms;

  newRecord.customFields = convertNativeToCustom(record.customFields);

  // if a not a new record, get rid of initial inventory fields
  if (record.id) {
    INITIAL_INVENTORY_FIELDS.forEach((field) => {
      delete newRecord[field];
    });
  }

  // make array of tags back into a string
  if (record.tags) {
    newRecord.tags = record.tags.join(",");
  }

  REFERENCE_FIELD.forEach((field) => {
    if (
      record[field] &&
      typeof record[field].id === "string" &&
      record[field].id === ""
    ) {
      newRecord[field] = null;
    }
  });

  return newRecord;
}
