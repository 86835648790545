import { Fragment } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import { Menu, MenuItem } from "@mui/material";

import { Divider } from "components/Divider";
import { LINE_ACTIONS, BATCH_ACTIONS } from "components/ListPage/actionConfig";
import { Tooltip } from "components/Tooltip";

import { i18n } from "services/i18nService";
import {
  updateBatchRecords,
  restoreDefaultTemplates,
  removePaymentInfo,
  syncRecords,
  ediBatch,
  clearItemCache,
  createInvoicesFromSalesOrders,
  addToReorderList,
  testAlert,
  getTemplate,
  copyTemplateToAccount,
  SUCCESS,
} from "services/sosInventoryService/sosApi";
import { moveSyncItemToEnd } from "services/sosInventoryService/sosApi";
import { downloadDocument } from "services/utility/documents";
import { handleProgramError } from "services/utility/errors";
import { checkForUnexpectedProps } from "services/utility/misc";
import { generateAndDownloadPdfs } from "services/utility/pdfs";

import { useActionPrivileges } from "hooks/useActionPrivileges";

import { openAlert } from "globalState/alertSlice";
import {
  loadingIndicatorOn,
  loadingIndicatorOff,
} from "globalState/loadingSlice";
import { openModal } from "globalState/modalSlice";
import { recordsChangedTick } from "globalState/recordsChangedSlice";

import { OBJECT_TYPES } from "appConstants";

export function ActionMenu(props) {
  const {
    ids,
    setCheckedIds,
    row,
    anchorEl,
    setQuickViewId,
    setAnchorEl,
    setShowJobDashboard,
    setShowPdf,
    setShowEmail,
    setShowDelete,
    setShowMerge,
    setShowRefund,
    setShowHistory,
    setShowProfitLoss,
    setShowRentalHistory,
    setShowLocationSettings,
    setShowUom,
    setShowAddresses,
    setShowBillOfMaterials,
    setShowBoxes,
    setShowVariants,
    setShowGeneratePurchaseOrders,
    formTemplates,
    keepMounted,
    isBatchMenu,
    objectType,
    disabled,
    setSelectAllState,
    ...unexpected
  } = props;
  checkForUnexpectedProps("ActionMenu", unexpected);

  const privileges = useActionPrivileges(objectType, row);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  function showEntityAction(action) {
    const showForList = isBatchMenu
      ? BATCH_ACTIONS[objectType].flat().includes(action)
      : LINE_ACTIONS[objectType].flat().includes(action);
    const hasPrivilege = privileges.hasOwnProperty(action)
      ? privileges[action]
      : true;
    return showForList && hasPrivilege;
  }

  async function handleArchive(archive) {
    dispatch(loadingIndicatorOn());
    const { success, message } = await updateBatchRecords(objectType, {
      ids,
      field: "archived",
      value: archive,
    });
    actionEnd();
    if (success) {
      dispatch(recordsChangedTick());
      dispatch(
        openAlert({
          type: "success",
          message: i18n(
            archive ? "alert.SuccessArchived" : "alert.SuccessUnarchived"
          ),
        })
      );
    } else {
      handleProgramError(new Error(message));
    }
  }

  async function handleActivate(value) {
    dispatch(loadingIndicatorOn());
    const { success, message } = await updateBatchRecords(objectType, {
      ids,
      field: "isActive",
      value,
    });
    actionEnd();
    if (success) {
      dispatch(recordsChangedTick());
      dispatch(
        openAlert({
          type: "success",
          message: i18n(
            value ? "alert.SuccessActivated" : "alert.SuccessDeactivated"
          ),
        })
      );
    } else {
      handleProgramError(new Error(message));
    }
  }

  async function handleUnlock() {
    dispatch(loadingIndicatorOn());
    const { success, message } = await updateBatchRecords(objectType, {
      ids,
      field: "unlock",
    });
    actionEnd();
    if (success) {
      dispatch(recordsChangedTick());
      dispatch(
        openAlert({ type: "success", message: i18n("alert.SuccessUnlocked") })
      );
    } else {
      handleProgramError(new Error(message));
    }
  }

  async function handleMoveToEnd() {
    const success = await moveSyncItemToEnd(ids[0]);
    if (success) {
      dispatch(
        openAlert({
          type: "success",
          message: i18n("syncitem.MoveToEndSuccess"),
        })
      );
    } else {
      dispatch(
        openAlert({
          type: "error",
          message: i18n("syncitem.MoveToEndFailure"),
        })
      );
    }
    dispatch(recordsChangedTick());
  }

  async function handleGenerateInvoices() {
    dispatch(loadingIndicatorOn());
    const { success, message } = await createInvoicesFromSalesOrders(ids);
    actionEnd();
    if (success) {
      dispatch(recordsChangedTick());
      dispatch(
        openAlert({
          type: "success",
          message: i18n("alert.SuccessInvoicesGenerated"),
        })
      );
    } else {
      handleProgramError(new Error(message));
    }
  }

  async function handleApprove() {
    dispatch(loadingIndicatorOn());
    const { success, message } = await updateBatchRecords(objectType, {
      ids,
      field: "approved",
      value: true,
    });

    actionEnd();
    if (success) {
      dispatch(recordsChangedTick());
      dispatch(
        openAlert({ type: "success", message: i18n("alert.SuccessApproved") })
      );
    } else {
      handleProgramError(new Error(message));
    }
  }

  async function handleConfirm(confirmed) {
    dispatch(loadingIndicatorOn());
    const { success, message } = await updateBatchRecords(objectType, {
      ids,
      field: "confirmed",
      value: confirmed,
    });
    actionEnd();
    if (success) {
      dispatch(recordsChangedTick());
      dispatch(
        openAlert({
          type: "success",
          message: i18n(
            confirmed ? "alert.SuccessConfirmed" : "alert.SuccessUnconfirmed"
          ),
        })
      );
    } else {
      handleProgramError(new Error(message));
    }
  }

  async function handleAddToReorderList() {
    dispatch(loadingIndicatorOn());
    const response = await addToReorderList(ids);
    actionEnd();
    if (response === SUCCESS) {
      dispatch(recordsChangedTick());
      dispatch(
        openAlert({
          type: "success",
          message: i18n("alert.SuccessAddedToReorderList"),
        })
      );
    } else {
      handleProgramError(new Error(i18n("alert.ErrorNotAddedToReorderList")));
    }
  }

  async function handleStatus(status) {
    dispatch(loadingIndicatorOn());
    const { success, message } = await updateBatchRecords(objectType, {
      ids,
      field: "status",
      value: status,
    });
    if (success) {
      dispatch(recordsChangedTick());
      dispatch(
        openAlert({
          type: "success",
          message: i18n(
            status === "open" ? "alert.SuccessOpened" : "alert.SuccessClosed"
          ),
        })
      );
    } else {
      handleProgramError(new Error(message));
    }
    actionEnd();
  }

  async function handleSync() {
    dispatch(loadingIndicatorOn());
    const { success, message } = await syncRecords(objectType, ids);
    if (success) {
      dispatch(
        openAlert({ type: "success", message: i18n("alert.SuccessSync") })
      );
    } else {
      handleProgramError(new Error(message));
    }
    actionEnd();
  }

  async function handleRemovePaymentInfo() {
    dispatch(loadingIndicatorOn());
    const { success, message } = await removePaymentInfo(ids[0]);
    if (success) {
      dispatch(
        openAlert({
          type: "success",
          message: i18n("alert.SuccessRemovePaymentInfo"),
        })
      );
      dispatch(recordsChangedTick());
    } else {
      handleProgramError(new Error(message));
    }
    actionEnd();
  }

  async function generatePdfs() {
    dispatch(loadingIndicatorOn());
    const downloadText = isBatchMenu
      ? `${i18n(`objectType.${objectType}.SentencePlural`)}.pdf`
      : `${i18n(`objectType.${objectType}.Sentence`)}${
          row.number ? ` ${row.number}` : ""
        }.pdf`;
    await generateAndDownloadPdfs(
      objectType,
      ids,
      formTemplates[0].id,
      downloadText
    );
    actionEnd();
  }

  function createNew(type) {
    setAnchorEl(null);
    dispatch(
      openModal({
        objectType: type,
        modalProps: { newFromObjectType: objectType, newFromId: ids[0] },
      })
    );
  }

  async function handleTest() {
    setAnchorEl(null);
    const { success, message } = await testAlert(ids[0]);
    if (success) {
      dispatch(
        openAlert({ type: "success", message: i18n("alert.SuccessTestAlert") })
      );
    } else {
      handleProgramError(new Error(message));
    }
  }

  function quickView() {
    setAnchorEl(null);
    setQuickViewId(ids[0]);
  }

  function createDropShip() {
    setAnchorEl(null);
    dispatch(
      openModal({
        objectType: OBJECT_TYPES.PURCHASE_ORDER.fullString,
        modalProps: {
          newFromObjectType: objectType,
          newFromId: ids[0],
          dropShip: true,
        },
      })
    );
  }

  async function downloadTemplate() {
    const documentBlob = await getTemplate(ids[0]);
    downloadDocument(documentBlob, row.name);
  }

  async function copyTemplate() {
    const { success } = await copyTemplateToAccount(ids);
    if (success) {
      const message = i18n(`alert.SuccessCopyTemplateToAccount`);
      dispatch(openAlert({ type: "success", message }));
    }
  }

  function createSubCustomer() {
    setAnchorEl(null);
    dispatch(
      openModal({
        objectType: OBJECT_TYPES.CUSTOMER.fullString,
        modalProps: { newFromParent: ids[0] },
      })
    );
  }

  async function clearCache() {
    setAnchorEl(null);
    const { success, message } = await clearItemCache(ids);
    if (success) {
      dispatch(recordsChangedTick());
      dispatch(
        openAlert({ type: "success", message: i18n(`alert.ClearCache`) })
      );
    } else {
      handleProgramError(new Error(message));
    }
  }

  async function handleEdi(action) {
    const { success, message } = await ediBatch(ids, action);
    if (success) {
      dispatch(
        openAlert({ type: "success", message: i18n(`alert.${action}`) })
      );
    } else {
      handleProgramError(new Error(message));
    }
    actionEnd();
  }

  function handleBillofMaterials() {
    setAnchorEl(null);
    setShowBillOfMaterials(true);
  }

  function handleShowVariants() {
    setAnchorEl(null);
    setShowVariants(true);
  }

  async function handleShowHistory() {
    setAnchorEl(null);
    setShowHistory(true);
  }

  async function handleShowJobDashboard() {
    setAnchorEl(null);
    setShowJobDashboard(true);
  }

  async function handleShowRentalHistory() {
    setAnchorEl(null);
    setShowRentalHistory(true);
  }

  function handleShowProfitLoss() {
    setAnchorEl(null);
    setShowProfitLoss(true);
  }

  function handleShowRefund() {
    setAnchorEl(null);
    setShowRefund(true);
  }

  async function handlelocationSettings() {
    setAnchorEl(null);
    setShowLocationSettings(true);
  }

  async function handleBoxes() {
    setAnchorEl(null);
    setShowBoxes(true);
  }

  async function handleShowUnitsOfMeasure() {
    setAnchorEl(null);
    setShowUom(true);
  }

  async function handleComplete() {
    dispatch(loadingIndicatorOn());
    const { success, message } = await updateBatchRecords(objectType, {
      ids,
      field: "complete",
      value: null,
    });
    if (success) {
      dispatch(recordsChangedTick());
      dispatch(
        openAlert({ type: "success", message: i18n("alert.SuccessComplete") })
      );
    } else {
      handleProgramError(new Error(message));
    }
    actionEnd();
  }

  async function handleRestore() {
    setAnchorEl(null);
    dispatch(loadingIndicatorOn());

    const { success, message } = await updateBatchRecords(objectType, {
      ids,
      field: "deleted",
      value: null,
    });

    if (success) {
      dispatch(recordsChangedTick());
      dispatch(
        openAlert({ type: "success", message: i18n("alert.SuccessRestore") })
      );
    } else {
      handleProgramError(new Error(message));
    }
    actionEnd();
  }

  async function handleShowAddresses() {
    setAnchorEl(null);
    setShowAddresses(true);
  }

  async function handleDisassemble() {
    setAnchorEl(null);
    dispatch(
      openModal({
        objectType: OBJECT_TYPES.PROCESS.fullString,
        modalProps: { disassembleId: ids[0] },
      })
    );
  }

  function actionEnd() {
    dispatch(loadingIndicatorOff());
    setAnchorEl(null);
    setCheckedIds([]);
    setSelectAllState && setSelectAllState(false);
  }

  function getIsClosed() {
    if (objectType === OBJECT_TYPES.ESTIMATE.fullString) {
      return row.status === "Closed";
    }
    return row.closed;
  }

  async function handleRestoreTemplates() {
    setAnchorEl(null);
    const { success, message } = await restoreDefaultTemplates();
    if (success) {
      const message = i18n("alert.SuccessRestoreDefaultTemplates");
      dispatch(openAlert({ type: "success", message }));
      dispatch(recordsChangedTick());
    } else {
      handleProgramError(new Error(message));
    }
  }

  function viewInQuickBooks() {
    setAnchorEl(null);
    window.open(
      `https://qbo.intuit.com/app/invoice?txnId=${row.qboTransactionId}`
    );
  }

  function notBuiltAlert() {
    dispatch(openAlert({ type: "error", message: "Not built yet" }));
  }

  function getAction(action) {
    switch (action) {
      case "edit":
        return (
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              navigate(`/syncitem/${row.id}`);
            }}
          >
            {i18n("global.Edit")}
          </MenuItem>
        );
      case "pdf":
        return (
          <MenuItem
            disabled={disabled}
            onClick={() =>
              formTemplates.length > 1 ? setShowPdf(true) : generatePdfs()
            }
          >
            {i18n("global.Pdf")}
          </MenuItem>
        );
      case "email":
        return (
          <MenuItem disabled={disabled} onClick={() => setShowEmail(true)}>
            {i18n("global.Email")}
          </MenuItem>
        );
      case "contents":
        return (
          <MenuItem disabled={disabled} onClick={notBuiltAlert}>
            {i18n("global.Contents")}
          </MenuItem>
        );
      case "activate":
        return (
          <>
            {isBatchMenu || row.active ? (
              <MenuItem
                disabled={disabled}
                onClick={() => handleActivate(true)}
              >
                {i18n("entityActions.Deactivate")}
              </MenuItem>
            ) : (
              <MenuItem
                disabled={disabled}
                onClick={() => handleActivate(false)}
              >
                {i18n("entityActions.Activate")}
              </MenuItem>
            )}
          </>
        );
      case "unlock":
        return (
          <MenuItem disabled={disabled} onClick={handleUnlock}>
            {i18n("entityActions.Unlock")}
          </MenuItem>
        );
      case "download":
        return (
          <MenuItem disabled={disabled} onClick={downloadTemplate}>
            {i18n("global.Download")}
          </MenuItem>
        );
      case "restoreDefaultTemplates":
        return (
          <MenuItem onClick={handleRestoreTemplates}>
            {i18n("global.RestoreDefaultTemplates")}
          </MenuItem>
        );
      case "receive":
        return (
          <MenuItem
            onClick={() => createNew(OBJECT_TYPES.ITEM_RECEIPT.fullString)}
            data-testing="createItemReceipt"
          >
            {i18n("entityActions.Receive")}
          </MenuItem>
        );
      case "dropShip":
        return (
          <MenuItem onClick={createDropShip}>
            {i18n("entityActions.CreateDropShip")}
          </MenuItem>
        );
      case "purchaseOrder":
        return (
          <MenuItem
            onClick={() => createNew(OBJECT_TYPES.PURCHASE_ORDER.fullString)}
          >
            {i18n("entityActions.CreatePurchaseOrder")}
          </MenuItem>
        );
      case "pickTicket":
        return (
          <MenuItem
            disabled={disabled}
            onClick={() => createNew(OBJECT_TYPES.PICK_TICKET.fullString)}
          >
            {i18n("entityActions.PickTicket")}
          </MenuItem>
        );
      case "createPayment":
        if (!row.hasCardOnFile) {
          return null;
        }
        return (
          <MenuItem
            disabled={disabled}
            onClick={() => createNew(OBJECT_TYPES.PAYMENT.fullString)}
          >
            {i18n("entityActions.ChargeCardOnFile")}
          </MenuItem>
        );
      case "removePaymentInfo":
        if (!row.hasCardOnFile) {
          return null;
        }
        return (
          <MenuItem disabled={disabled} onClick={handleRemovePaymentInfo}>
            {i18n("entityActions.RemovePaymentInfo")}
          </MenuItem>
        );
      case "shipment":
        return (
          <MenuItem
            disabled={disabled}
            onClick={() => createNew(OBJECT_TYPES.SHIPMENT.fullString)}
            data-testing="createShipment"
          >
            {i18n("entityActions.Shipment")}
          </MenuItem>
        );
      case "salesOrder":
        return (
          <MenuItem
            disabled={disabled}
            onClick={() => createNew(OBJECT_TYPES.SALES_ORDER.fullString)}
          >
            {i18n("entityActions.CreateSalesOrder")}
          </MenuItem>
        );
      case "salesReceipt":
        return (
          <MenuItem
            disabled={disabled}
            onClick={() => createNew(OBJECT_TYPES.SALES_RECEIPT.fullString)}
          >
            {i18n("entityActions.CreateSalesReceipt")}
          </MenuItem>
        );
      case "invoice":
        return (
          <MenuItem
            disabled={disabled}
            onClick={() => createNew(OBJECT_TYPES.INVOICE.fullString)}
          >
            {i18n("entityActions.CreateInvoice")}
          </MenuItem>
        );
      case "invoices":
        return (
          <MenuItem disabled={disabled} onClick={handleGenerateInvoices}>
            {i18n("entityActions.Invoices")}
          </MenuItem>
        );
      case "pos":
        return (
          <MenuItem
            disabled={disabled}
            onClick={() => setShowGeneratePurchaseOrders(true)}
          >
            {i18n("entityActions.PurchaseOrders")}
          </MenuItem>
        );
      case "disassemble":
        return (
          <MenuItem disabled={disabled} onClick={handleDisassemble}>
            {i18n("entityActions.Disassemble")}
          </MenuItem>
        );
      case "approve":
        return (
          <MenuItem disabled={disabled} onClick={handleApprove}>
            {i18n("entityActions.Approve")}
          </MenuItem>
        );
      case "deleteTemplateLibraryForm":
      case "delete":
        return (
          <MenuItem disabled={disabled} onClick={() => setShowDelete(true)}>
            {i18n("global.Delete")}
          </MenuItem>
        );
      case "merge":
        return (
          <MenuItem disabled={disabled} onClick={() => setShowMerge(true)}>
            {i18n("global.Merge")}
          </MenuItem>
        );
      case "template":
        return (
          <Tooltip
            title={i18n("tooltip.UseAsTemplate", {
              objectType: i18n(`objectType.${objectType}.Lower`),
            })}
          >
            <MenuItem disabled={disabled} onClick={() => createNew(objectType)}>
              {i18n("entityActions.UseAsTemplate")}
            </MenuItem>
          </Tooltip>
        );
      case "copy":
        return (
          <Tooltip title={i18n("tooltip.CopyThisItem")}>
            <MenuItem disabled={disabled} onClick={() => createNew(objectType)}>
              {i18n("entityActions.CopyThisItem")}
            </MenuItem>
          </Tooltip>
        );
      case "sendEdi":
        return (
          <MenuItem disabled={disabled} onClick={() => handleEdi("sendEdi")}>
            {i18n("entityActions.SendEdi")}
          </MenuItem>
        );
      case "updateEdi":
        return (
          <MenuItem disabled={disabled} onClick={() => handleEdi("updateEdi")}>
            {i18n("entityActions.UpdateEdi")}
          </MenuItem>
        );
      case "shipUPS":
        return (
          <MenuItem disabled={disabled} onClick={notBuiltAlert}>
            {i18n("entityActions.ShipUps")}
          </MenuItem>
        );
      case "get":
        return (
          <MenuItem disabled={disabled} onClick={copyTemplate}>
            {i18n("entityActions.Get")}
          </MenuItem>
        );
      case "clearCache":
        return (
          <MenuItem disabled={disabled} onClick={clearCache}>
            {i18n("entityActions.ClearCache")}
          </MenuItem>
        );
      case "refund":
        return (
          <MenuItem disabled={disabled} onClick={handleShowRefund}>
            {i18n("entityActions.Refund")}
          </MenuItem>
        );
      case "return":
        return (
          <MenuItem
            disabled={disabled}
            onClick={() => createNew(OBJECT_TYPES.RETURN.fullString)}
          >
            {i18n("entityActions.Return")}
          </MenuItem>
        );
      case "rental":
        return (
          <MenuItem
            disabled={disabled}
            onClick={() => createNew(OBJECT_TYPES.RENTAL.fullString)}
          >
            {i18n("entityActions.Rental")}
          </MenuItem>
        );
      case "restore":
        return (
          <MenuItem disabled={disabled} onClick={handleRestore}>
            {i18n("entityActions.Restore")}
          </MenuItem>
        );
      case "rentalReturn":
        return (
          <MenuItem
            disabled={disabled}
            onClick={() => createNew(OBJECT_TYPES.RENTAL_RETURN.fullString)}
          >
            {i18n("entityActions.RentalReturn")}
          </MenuItem>
        );
      case "payment":
        return (
          <MenuItem
            disabled={disabled}
            onClick={() => createNew(OBJECT_TYPES.PAYMENT.fullString)}
          >
            {i18n("entityActions.ReceivePayment")}
          </MenuItem>
        );
      case "history":
        return (
          <MenuItem disabled={disabled} onClick={handleShowHistory}>
            {i18n("entityActions.ShowHistory")}
          </MenuItem>
        );
      case "rentalHistory":
        return (
          <MenuItem disabled={disabled} onClick={handleShowRentalHistory}>
            {i18n("entityActions.RentalHistory")}
          </MenuItem>
        );
      case "customerHistory":
        return (
          <MenuItem disabled={disabled} onClick={handleShowHistory}>
            {i18n("entityActions.ShowCustomerHistory")}
          </MenuItem>
        );
      case "vendorHistory":
        return (
          <MenuItem disabled={disabled} onClick={handleShowHistory}>
            {i18n("entityActions.ShowVendorHistory")}
          </MenuItem>
        );
      case "uom":
        return (
          <MenuItem
            disabled={disabled}
            onClick={handleShowUnitsOfMeasure}
            data-testing="uomAction"
          >
            {i18n("entityActions.UnitsOfMeasure")}
          </MenuItem>
        );
      case "subCustomer":
        return (
          <MenuItem disabled={disabled} onClick={createSubCustomer}>
            {i18n("entityActions.CreateSubCustomer")}
          </MenuItem>
        );
      case "ediImport":
        return (
          <MenuItem disabled={disabled} onClick={notBuiltAlert}>
            {i18n("entityActions.EdiImport")}
          </MenuItem>
        );

      case "billOfMaterials":
        return (
          <MenuItem
            data-testing="bomAction"
            disabled={disabled}
            onClick={handleBillofMaterials}
          >
            {i18n("entityActions.BillOfMaterials")}
          </MenuItem>
        );

      case "confirm":
        return (
          <>
            {(isBatchMenu || !row.confirmed) && (
              <MenuItem disabled={disabled} onClick={() => handleConfirm(true)}>
                {i18n("entityActions.Confirm")}
              </MenuItem>
            )}
            {(isBatchMenu || row.confirmed) && (
              <MenuItem
                disabled={disabled}
                onClick={() => handleConfirm(false)}
              >
                {i18n("entityActions.Unconfirm")}
              </MenuItem>
            )}
          </>
        );
      case "archive":
        return (
          <>
            {(isBatchMenu || !row.archived) && (
              <MenuItem disabled={disabled} onClick={() => handleArchive(true)}>
                {i18n("global.Archive")}
              </MenuItem>
            )}
            {(isBatchMenu || row.archived) && (
              <MenuItem
                disabled={disabled}
                onClick={() => handleArchive(false)}
              >
                {i18n("global.Unarchive")}
              </MenuItem>
            )}
          </>
        );
      case "moveToEnd":
        return (
          <MenuItem disabled={disabled} onClick={() => handleMoveToEnd()}>
            {i18n("syncitem.MoveToEnd")}
          </MenuItem>
        );
      case "openClose":
        return (
          <>
            {(isBatchMenu || getIsClosed()) && (
              <MenuItem
                disabled={disabled}
                onClick={() => handleStatus("open")}
              >
                {i18n("entityActions.Open")}
              </MenuItem>
            )}
            {(isBatchMenu || !getIsClosed()) && (
              <MenuItem
                disabled={disabled}
                onClick={() => handleStatus("closed")}
              >
                {i18n("entityActions.Close")}
              </MenuItem>
            )}
          </>
        );
      case "addresses":
        return (
          <MenuItem disabled={disabled} onClick={handleShowAddresses}>
            {i18n("entityActions.Addresses")}
          </MenuItem>
        );
      case "variants":
        return (
          <MenuItem
            disabled={disabled}
            onClick={handleShowVariants}
            data-testing="variants"
          >
            {i18n("entityActions.Variants")}
          </MenuItem>
        );
      case "job":
        return (
          <MenuItem
            disabled={disabled}
            onClick={() => createNew(OBJECT_TYPES.JOB.fullString)}
          >
            {i18n("entityActions.Job")}
          </MenuItem>
        );
      case "workOrder":
        return (
          <MenuItem
            disabled={disabled}
            onClick={() => createNew(OBJECT_TYPES.WORK_ORDER.fullString)}
          >
            {i18n("entityActions.WorkOrder")}
          </MenuItem>
        );
      case "profitLoss":
        return (
          <MenuItem disabled={disabled} onClick={handleShowProfitLoss}>
            {i18n("entityActions.ProfitLoss")}
          </MenuItem>
        );
      case "RMA":
        return (
          <MenuItem
            disabled={disabled}
            onClick={() => createNew(OBJECT_TYPES.RMA.fullString)}
          >
            {i18n("entityActions.RMA")}
          </MenuItem>
        );
      case "quickView":
        return (
          <MenuItem
            data-testing="quickView"
            disabled={disabled}
            onClick={quickView}
          >
            {i18n("entityActions.QuickView")}
          </MenuItem>
        );
      case "jobDashboard":
        return (
          <MenuItem disabled={disabled} onClick={handleShowJobDashboard}>
            {i18n("entityActions.JobDashboard")}
          </MenuItem>
        );
      case "returnToVendor":
        return (
          <MenuItem
            disabled={disabled}
            onClick={() => createNew(OBJECT_TYPES.RETURN_TO_VENDOR.fullString)}
          >
            {i18n("entityActions.CreateReturnToVendor")}
          </MenuItem>
        );
      case "transfer":
        return (
          <MenuItem
            disabled={disabled}
            onClick={() => createNew(OBJECT_TYPES.TRANSFER.fullString)}
          >
            {i18n("entityActions.CreateTransfer")}
          </MenuItem>
        );
      case "sync":
        return (
          <Tooltip
            title={i18n("tooltip.AddToSync", {
              objectType: i18n(`objectType.${objectType}.Lower`),
            })}
          >
            <MenuItem disabled={disabled} onClick={handleSync}>
              {i18n("entityActions.AddToSync")}
            </MenuItem>
          </Tooltip>
        );
      case "viewInQuickBooks":
        return (
          <Tooltip
            title={
              row.qboTransactionId
                ? ""
                : i18n("tooltip.NotSyncedQBO", {
                    objectType: i18n(`objectType.${objectType}.Lower`),
                  })
            }
          >
            <div>
              <MenuItem
                disabled={disabled || !row.qboTransactionId}
                onClick={viewInQuickBooks}
              >
                {i18n("entityActions.ViewInQuickBooks")}
              </MenuItem>
            </div>
          </Tooltip>
        );
      case "addToReorderList":
        return (
          <MenuItem disabled={disabled} onClick={handleAddToReorderList}>
            {i18n("entityActions.AddToReorderList")}
          </MenuItem>
        );
      case "locationSettings":
        return (
          <MenuItem
            disabled={disabled}
            onClick={handlelocationSettings}
            data-testing="locationSettingsAction"
          >
            {i18n("entityActions.LocationSettings")}
          </MenuItem>
        );
      case "test":
        return (
          <MenuItem disabled={disabled} onClick={handleTest}>
            {i18n("entityActions.TestNow")}
          </MenuItem>
        );
      case "boxes":
        return (
          <MenuItem
            disabled={disabled}
            onClick={handleBoxes}
            data-testing="boxesAction"
          >
            {i18n("entityActions.Boxes")}
          </MenuItem>
        );
      case "complete":
        return (
          <MenuItem disabled={disabled} onClick={handleComplete}>
            {i18n("entityActions.MarkAsComplete")}
          </MenuItem>
        );
      default:
        throw new Error(`List action ${action} not supported in ActionMenu`);
    }
  }

  function getList() {
    const list = isBatchMenu
      ? BATCH_ACTIONS[objectType]
      : LINE_ACTIONS[objectType];

    return list.reduce((seed, ele) => {
      if (Array.isArray(ele)) {
        const section = ele.filter((action) => showEntityAction(action));
        if (section.length) {
          seed.push(section);
        }
      } else if (showEntityAction(ele)) {
        seed.push(ele);
      }
      return seed;
    }, []);
  }

  return (
    <Menu
      open={Boolean(anchorEl)}
      keepMounted={Boolean(keepMounted)}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <div>
        {disabled && (
          <>
            <MenuItem
              sx={{
                color: "warning.error",
                cursor: "default",
                backgroundColor: "transparent",
              }}
            >
              {i18n("entityActions.BatchActionDisabled", {
                objectType: i18n(`objectType.${objectType}.Lower`),
              })}
            </MenuItem>
            <Divider />
          </>
        )}
        {getList().map((action, index, array) => {
          const isLastIndex = index === array.length - 1;
          return (
            <Fragment key={index}>
              {Array.isArray(action)
                ? action.map((act, index) => (
                    <Fragment key={index}>{getAction(act)}</Fragment>
                  ))
                : getAction(action)}
              {!isLastIndex && <Divider />}
            </Fragment>
          );
        })}
      </div>
    </Menu>
  );
}
