import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Box } from "@mui/material";

import { Layout } from "Layout";

import { NEW_RECORD_SKELETON } from "appConfig";

import { EditModalHeader } from "components/EditModalHeader";
import { FrmFieldGrid } from "components/FrmFieldGrid";
import { ObjectEditDialogContent } from "components/ObjectEditDialogContent";
import { FrmResizeTextField } from "components/formFields/FrmResizeTextField";
import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";
import { FrmTextFieldWithHoverHelp } from "components/formFields/FrmTextFieldWithHoverHelp";
import { FixedLoadingIndicator } from "components/utility/FixedLoadingIndicator";
import { FormErrors } from "components/utility/FormErrors";
import { HoverHelp } from "components/utility/HoverHelp";
import { Loading } from "components/utility/Loading";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import {
  SHOW_ON_OPTIONS,
  DATA_TYPE,
  listDataTypeOption,
} from "services/sosInventoryService/customField/schema";
import { getRecord } from "services/sosInventoryService/sosApi";

import { useErrors } from "hooks/useErrors";
import { useRecord } from "hooks/useRecord";

import {
  editModalLoadingIndicatorOn,
  editModalLoadingIndicatorOff,
} from "globalState/loadingSlice";

export function CustomField(props) {
  const { id, onClose, objectType } = props;

  const dispatch = useDispatch();

  const [record, setRecord, handleInputFieldChange] = useRecord();
  const { errors, setErrors, isInError } = useErrors();

  const loadingState = useSelector((state) => state.loading.editModal);

  useEffect(() => {
    async function _getRecord() {
      if (id) {
        dispatch(editModalLoadingIndicatorOn());
        const response = await getRecord(objectType, id);
        setRecord(response);
        dispatch(editModalLoadingIndicatorOff());
      } else {
        const emptyRecord = NEW_RECORD_SKELETON[objectType]();
        setRecord(emptyRecord);
      }
    }
    _getRecord();
  }, [id, dispatch, setRecord, objectType]);

  return (
    <>
      <EditModalHeader
        record={record}
        setRecord={setRecord}
        objectType={objectType}
        setErrors={setErrors}
        text={record?.name || ""}
        handleClose={onClose}
        hideDocuments={true}
      />

      {loadingState && <Loading />}

      {record && (
        <ObjectEditDialogContent>
          <Layout
            pageTitle={
              id
                ? `${i18n(`objectType.${objectType}.Sentence`)} ${
                    record?.name || ""
                  }`
                : i18n(`objectType.${objectType}.New`)
            }
          >
            <Box flexGrow="1" position="relative" m={2} mb={0}>
              <FormErrors errors={errors} setErrors={setErrors} />
              {record ? (
                <Box>
                  <FrmFieldGrid gridAutoFlow="dense">
                    <div style={{ gridColumn: "1 / 2", maxWidth: "24rem" }}>
                      <FrmTextFieldWithHoverHelp
                        name="name"
                        value={record.name}
                        label={i18n("frmLabel.Name")}
                        onValueBlur={handleInputFieldChange}
                        helpText={i18n("hoverHelp.UniqueName", {
                          objectType: i18n(`objectType.${objectType}.Lower`),
                        })}
                        error={isInError("name")}
                      />
                    </div>
                    <div style={{ gridColumn: "1 / 2" }}>
                      <FrmResizeTextField
                        name="description"
                        value={record.description}
                        label={i18n("frmLabel.Description")}
                        minRows={2}
                        width="100%"
                        onValueBlur={handleInputFieldChange}
                      />
                    </div>
                    <div style={{ gridColumn: "1 / 2", maxWidth: "24rem" }}>
                      <FrmSelectFromObjects
                        name="dataType"
                        value={record.dataType}
                        label={i18n("frmLabel.DataType")}
                        onValueChange={handleInputFieldChange}
                        options={DATA_TYPE}
                        disableClearable
                      />
                    </div>
                    <div style={{ gridColumn: "1 / 2", maxWidth: "24rem" }}>
                      <FrmSelectFromObjects
                        name="showOn"
                        label={i18n("frmLabel.ShowOn")}
                        value={record.showOn}
                        onValueChange={handleInputFieldChange}
                        options={SHOW_ON_OPTIONS}
                        error={isInError("showOn")}
                      />
                    </div>
                    {record.dataType?.id === listDataTypeOption.id && (
                      <div
                        style={{
                          gridColumn: "1 / 2",
                          display: "flex",
                          alignItems: "center",
                          gap: 6,
                        }}
                      >
                        <FrmResizeTextField
                          name="list"
                          value={record.list}
                          label={i18n("frmLabel.ListValues")}
                          minRows={2}
                          width="100%"
                          onValueBlur={handleInputFieldChange}
                        />
                        <HoverHelp title={i18n("hoverHelp.CustomFieldList")} />
                      </div>
                    )}
                  </FrmFieldGrid>
                  <VSpace space={1} />
                  <FrmFieldGrid gridAutoFlow="dense"></FrmFieldGrid>
                </Box>
              ) : (
                !isInError("record") && (
                  <FixedLoadingIndicator
                    text={`${i18n("global.Loading")}...`}
                  />
                )
              )}
            </Box>
            <VSpace space={4} />
          </Layout>
        </ObjectEditDialogContent>
      )}
    </>
  );
}
