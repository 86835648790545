import { memo, useState } from "react";
import { useSelector } from "react-redux";

import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  TableSortLabel,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";

import { Row } from "components/ListPage/List/Row/Row";
import { QuickViewModal } from "components/QuickViewModal";

import { i18n } from "services/i18nService";
import {
  getCustomFieldName,
  pruneOldCustomFieldColumns,
} from "services/utility/customFields";
import { checkForUnexpectedProps } from "services/utility/misc";

import { OBJECT_TYPES, NAME_LINKED_FIELD_OBJECTS } from "appConstants";

function List_(props) {
  const {
    objectType,
    identifierProperty,
    fixedColumns,
    records,
    checkedIds,
    columnConfig,
    selectAllIds,
    selectNoIds,
    setCheckedIds,
    customFieldDefinitions,
    formTemplates,
    retrievalParams,
    setRetrievalParams,
    selectAllState,
    setSelectAllState,
    setRecords,
    isConfigurationList,
    ...unexpected
  } = props;
  checkForUnexpectedProps("List", unexpected);

  function getLinkField() {
    if (objectType === OBJECT_TYPES.DELETED_ITEM.fullString) {
      return "restore";
    }
    return NAME_LINKED_FIELD_OBJECTS.includes(objectType) ? "name" : "number";
  }

  // numbers will be converted to px for CSS use
  const stickyColumnWidth = {
    // purchase orders can have a "pending approval" color bar in addition
    // to the "archived" color bar
    colorCode: objectType === "purchaseorder" ? 14 : 7,
    checkbox: 40,
    starred: 32,
    number: 150,
    name: 250,
    dropDown: 35,
    restore: 100,
  };

  const linkField = getLinkField();

  const stickyColumns = {
    colorCode: {
      width: `${stickyColumnWidth.colorCode}px`,
      left: 0,
    },
    checkbox: {
      width: `${stickyColumnWidth.checkbox}px`,
      left: `${stickyColumnWidth.colorCode}px`,
    },
    starred: {
      width: `${stickyColumnWidth.starred}px`,
      left: `${stickyColumnWidth.colorCode + stickyColumnWidth.checkbox}px`,
    },
    [linkField]: {
      width: `${stickyColumnWidth[linkField]}px`,
      left: `${
        stickyColumnWidth.colorCode +
        stickyColumnWidth.checkbox +
        stickyColumnWidth.starred
      }px`,
    },
    dropDown: {
      width: `${stickyColumnWidth.dropDown}px`,
      left: isConfigurationList
        ? `${stickyColumnWidth.checkbox} + ${stickyColumnWidth[linkField]}`
        : `${
            stickyColumnWidth.colorCode +
            stickyColumnWidth.checkbox +
            stickyColumnWidth.starred +
            stickyColumnWidth[linkField]
          }px`,
    },
  };

  const [quickViewId, setQuickViewId] = useState(null);
  const editModalIsOpen = useSelector((state) => state.modal.open);
  const styles = {
    height: "100%",
    "@media print": {
      overflow: "hidden",
      minHeight: "100%",
      maxHeight: "100%",
      display: quickViewId || editModalIsOpen ? "none" : "block",
    },
  };

  if (!retrievalParams?.columns || !checkedIds || !customFieldDefinitions) {
    return null;
  }

  function handleCheckChange() {
    if (selectAllState) {
      selectNoIds();
      setSelectAllState(false);
    } else {
      selectAllIds();
      setSelectAllState(true);
    }
  }

  const prunedColumns = pruneOldCustomFieldColumns(
    retrievalParams.columns,
    customFieldDefinitions
  );

  const fixedColumnsLeftOfAction = fixedColumns.filter(
    ({ fixedRightOfAction }) => !fixedRightOfAction
  );

  const fixedColumnsRightOfAction = fixedColumns.filter(
    ({ fixedRightOfAction }) => fixedRightOfAction
  );

  if (records.length === 0) {
    return (
      <Box sx={{ height: "24rem", width: "100%", display: "table" }}>
        <Box
          sx={{
            display: "table-cell",
            textAlign: "center",
            verticalAlign: "middle",
          }}
        >
          {i18n("listPage.NoResultsFound")}
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ whiteSpace: "nowrap", height: "100%" }}>
        <TableContainer sx={styles}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    padding: 0,
                    minWidth: stickyColumns.colorCode.width,
                    maxWidth: stickyColumns.colorCode.width,
                    left: stickyColumns.colorCode.left,
                    position: "sticky",
                    backgroundColor: "white",
                    zIndex: "stickyHeaderAndColumn",
                  }}
                />
                <TableCell
                  sx={{
                    padding: 0,
                    minWidth: stickyColumns.checkbox.width,
                    maxWidth: stickyColumns.checkbox.width,
                    left: stickyColumns.checkbox.left,
                    position: "sticky",
                    backgroundColor: "white",
                    zIndex: "stickyHeaderAndColumn",
                  }}
                >
                  <Checkbox
                    id="all"
                    checked={selectAllState}
                    onClick={handleCheckChange}
                  />
                </TableCell>

                {fixedColumnsLeftOfAction.map((column) => (
                  <ColumnHeading
                    key={column.name}
                    objectType={objectType}
                    column={column}
                    columnConfig={columnConfig}
                    setRetrievalParams={setRetrievalParams}
                    retrievalParams={retrievalParams}
                    stickyColumnSpecifications={stickyColumns[column.name]}
                  />
                ))}

                {OBJECT_TYPES.FS_PAYMENT.fullString !== objectType && (
                  <TableCell
                    sx={{
                      whiteSpace: "normal",
                      top: "0px",
                      position: "sticky",
                      minWidth: stickyColumns.dropDown.width,
                      maxWidth: stickyColumns.dropDown.width,
                      left: stickyColumns.dropDown.left,
                      borderRight: "1px solid rgba(0,0,0,0.25)",
                      zIndex: "stickyHeaderAndColumn",
                    }}
                  />
                )}
                {fixedColumnsRightOfAction.map((column) => (
                  <ColumnHeading
                    key={column.name}
                    objectType={objectType}
                    column={column}
                    columnConfig={columnConfig}
                    setRetrievalParams={setRetrievalParams}
                    retrievalParams={retrievalParams}
                    stickyColumnSpecifications={stickyColumns[column.name]}
                  />
                ))}

                {prunedColumns.map((column) => (
                  <ColumnHeading
                    key={column.name}
                    objectType={objectType}
                    column={column}
                    columnConfig={columnConfig}
                    customFieldDefinitions={customFieldDefinitions}
                    setRetrievalParams={setRetrievalParams}
                    retrievalParams={retrievalParams}
                  />
                ))}
              </TableRow>
            </TableHead>
            <TableBody data-testing="tableBody">
              {records.map((row, index) => (
                <Row
                  key={row.id || row.number}
                  index={index}
                  objectType={objectType}
                  setQuickViewId={setQuickViewId}
                  identifierText={row[identifierProperty]}
                  columns={prunedColumns}
                  fixedColumnsLeftOfAction={fixedColumnsLeftOfAction}
                  fixedColumnsRightOfAction={fixedColumnsRightOfAction}
                  customFieldDefinitions={customFieldDefinitions}
                  row={row}
                  selected={checkedIds.includes(row.id)}
                  setCheckedIds={setCheckedIds}
                  formTemplates={formTemplates}
                  stickyColumns={stickyColumns}
                  setRecords={setRecords}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {quickViewId && (
        <QuickViewModal
          objectType={objectType}
          onClose={() => setQuickViewId(null)}
          id={quickViewId}
        />
      )}
    </>
  );
}

function ColumnHeading(props) {
  const {
    columnConfig,
    customFieldDefinitions,
    column,
    setRetrievalParams,
    retrievalParams,
    objectType,
    stickyColumnSpecifications,
  } = props;

  const { name, sortable, defaultSort, sortDirection } = column;

  const isSorted =
    retrievalParams.sort === name || (!retrievalParams.sort && defaultSort);

  function getOppDirection(direction) {
    return direction !== "asc" ? "asc" : "desc";
  }

  function handleUpdate() {
    let direction;
    if (name === retrievalParams.sort) {
      direction = getOppDirection(retrievalParams.direction);
    } else {
      direction = column.sortDirection === "asc" ? "desc" : "asc";
    }
    setRetrievalParams((prevParams) => ({
      ...prevParams,
      sort: name,
      direction: direction,
      startingRecord: 1,
      persistUpdate: false,
    }));
  }

  let align, fieldName;
  if (column.isCustomField) {
    fieldName = getCustomFieldName(name);
    const dataType = customFieldDefinitions.asObject[fieldName]?.dataType;
    align = dataType === "Number" || dataType === "Money" ? "right" : "left";
  } else if (!columnConfig.find((c) => c.name === name)) {
    throw Error(`${name} not found within ${objectType} schema`);
  } else {
    align = column.align === "right" ? "right" : "left";
  }

  return (
    <TableCell
      id={name.toUpperCase()}
      padding="normal"
      align={align}
      sx={{
        verticalAlign: "bottom",
        whiteSpace: "normal",
        top: "0px",
        position: "sticky",
        minWidth: stickyColumnSpecifications
          ? stickyColumnSpecifications.width
          : "auto",
        maxWidth: stickyColumnSpecifications
          ? stickyColumnSpecifications.width
          : "auto",
        left: stickyColumnSpecifications
          ? stickyColumnSpecifications.left
          : "auto",
        backgroundColor: "white",
        zIndex: stickyColumnSpecifications
          ? "stickyHeaderAndColumn"
          : "stickyHeader",
      }}
    >
      {sortable ? (
        <TableSortLabel
          active={isSorted}
          direction={
            retrievalParams.direction || getOppDirection(sortDirection)
          }
          onClick={handleUpdate}
        >
          {column.isCustomField ? fieldName : column.heading}
        </TableSortLabel>
      ) : (
        <>{column.isCustomField ? fieldName : column.heading}</>
      )}
    </TableCell>
  );
}

export const List = memo(List_);
