import { i18n } from "services/i18nService";
import { formatLinkField } from "services/utility/formatting";

const dayUnit = { id: "days", name: i18n("global.Days") };

export const WARRANTY_DURATION_UNITS = [
  dayUnit,
  { id: "weeks", name: i18n("global.Weeks") },
  { id: "months", name: i18n("global.Months") },
  { id: "years", name: i18n("global.Years") },
];

export const columnInfo = [
  {
    name: "name",
    heading: i18n("columns.Name"),
    formatFunc: formatLinkField,
    fixed: true,
    defaultSort: true,
    linkField: true,
    sortable: true,
  },
  {
    // TODO: verify working once https://app.clickup.com/t/8687508kp complete
    name: "description",
    heading: i18n("columns.Description"),
    maxWidth: "20em",
    fixed: true,
    fixedRightOfAction: true,
    sortable: true,
  },
];

export const LINE_ACTIONS = ["delete"];
export const BATCH_ACTIONS = ["delete"];

export function getEmptyRecord() {
  return { name: null, description: null, duration: null, unit: dayUnit };
}
