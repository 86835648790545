import { FormControlLabel, Checkbox } from "@mui/material";

import { HoverHelp } from "components/utility/HoverHelp";

import { i18n } from "services/i18nService";

import { usePlans } from "hooks/usePlans";

export function FrmAutoSerialLots(props) {
  const { value, onValueChange, name } = props;

  const { allowPlusFeatures, allowProFeatures } = usePlans();

  return allowPlusFeatures || allowProFeatures ? (
    <div style={{ display: "flex", alignItems: "center" }}>
      <FormControlLabel
        control={
          <Checkbox
            name={name}
            checked={value}
            onChange={(e) => onValueChange(e.target.name, e.target.checked)}
          />
        }
        label={i18n("frmLabel.AutoSerialLots")}
        sx={{ marginRight: 0 }}
      />
      <HoverHelp
        title={i18n("hoverHelp.autoSerialLots")}
        sx={{ marginLeft: 0.5 }}
      />
    </div>
  ) : null;
}
