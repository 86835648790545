// See services/sosInventoryService/README.md for information about the structure
// of schema.js files.
import { i18n } from "services/i18nService";
import {
  extractName,
  formatStatus,
  formatDocumentInfo,
  formatLinkField,
  formatAddress,
  truncate,
} from "services/utility/formatting";
import { starColumn } from "services/utility/schema";

import { NO_COLUMNS } from "appConstants";

export const columnInfo = [
  starColumn,
  {
    name: "name",
    heading: i18n("columns.Name"),
    fixed: true,
    formatFunc: formatLinkField,
    sortDirection: "asc",
    linkField: true,
    sortable: true,
  },
  {
    name: "number",
    heading: i18n("columns.Number"),
    defaultSort: true,
    sortDirection: "asc",
    sortable: true,
    default: true,
  },
  {
    name: "customer",
    heading: i18n("columns.Customer"),
    formatFunc: extractName,
    minWidth: "10em",
    maxWidth: "25em",
    sortable: true,
    default: true,
  },
  {
    name: "description",
    heading: i18n("columns.Description"),
    minWidth: "15em",
    formatFunc: truncate,
    sortable: true,
    default: true,
  },
  {
    name: "status",
    fieldName: "closed",
    heading: i18n("columns.Status"),
    formatFunc: formatStatus,
    sortable: true,
    default: true,
  },
  {
    name: "documents",
    heading: i18n("columns.Documents"),
    formatFunc: formatDocumentInfo,
    minWidth: "15em",
    default: true,
  },
  {
    name: "address",
    heading: i18n("columns.Address"),
    formatFunc: formatAddress,
  },
];

export function getEmptyRecord() {
  return {
    syncToken: 0,
    starred: 0,
    name: null,
    number: "",
    description: null,
    closed: false,
    archived: false,
    customer: null,
    address: {
      line1: null,
      line2: null,
      line3: null,
      line4: null,
      line5: null,
      city: null,
      stateProvince: null,
      postalCode: null,
      country: null,
    },
    workcenters: [],
    customFields: null,
    documents: [],
    keys: null,
    values: null,
  };
}

export const FIELDS_TO_TRANSFORM = {
  DECIMAL_FIELDS: [],
  MONEY_FIELDS: [],
  DATE_FIELDS: [],
  DATETIME_FIELDS: [],
  NON_UTC_DATETIME_FIELDS: [],
};

export const REFERENCE_FIELDS = ["customer"];

export const LINE_ACTIONS = [
  ["jobDashboard", "profitLoss"],
  "delete",
  "archive",
  "openClose",
];
export const BATCH_ACTIONS = ["delete", "archive", "openClose"];

export const ITEM_CALCULATED_FIELDS = [NO_COLUMNS];
