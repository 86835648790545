import { Decimal } from "classes/DecimalClasses";

import { totalWeight, weightUnit } from "services/utility/afterTouchLine";
import { LOCALIZATION } from "services/utility/localization";

export const ZERO_TOTALS = {
  quantity: new Decimal(0),
  weight: new Decimal(0),
  volume: new Decimal(0),
  amount: new Decimal(0),
};

export function updateTotals(lines, setTotals) {
  if (!lines || lines.length === 0) {
    setTotals(ZERO_TOTALS);
    return;
  }

  const quantity = lines.reduce(
    (total, line) => total.plus(line.quantity),
    new Decimal(0)
  );

  const amount = lines.reduce(
    (total, line) => total.plus(line.amount),
    new Decimal(0)
  );

  const weight = totalWeight(lines, LOCALIZATION);
  const weightunit = weightUnit(lines);

  // if we ever support more than cbm for volume, this will have to be
  // changed to mirror the weight calculation
  const volume = lines.reduce((total, line) => {
    return total.plus(line.volume);
  }, new Decimal(0));

  setTotals({
    amount,
    quantity,
    weight,
    weightunit,
    volume,
    volumeunit: "cbm",
  });
}
