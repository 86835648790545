import { useSelector } from "react-redux";

import { FrmSelectWithAdd } from "components/formFields/FrmSelectWithAdd";

import { i18n } from "services/i18nService";
import { checkForUnexpectedProps } from "services/utility/misc";

export function FrmCustomerWithAdd(props) {
  const {
    value: externalValue,
    onValueChange,
    setAddNewCustomer,
    setNewCustomerName,
    error,
    customers,
    ...unexpected
  } = props;
  checkForUnexpectedProps("FrmCustomerWithAdd", unexpected);

  const findCustomerByFullName = useSelector(
    (state) => state.userCompanySettings.settings.findCustomerByFullName
  );

  async function handleValueChange(_, value, input) {
    if (value?.id === "add") {
      setAddNewCustomer(true);
      setNewCustomerName(input);
      return;
    }
    onValueChange("customer", value);
  }

  if (!customers) {
    return null;
  }

  const value = customers.find(({ id }) => id === externalValue?.id) || null;

  return (
    <FrmSelectWithAdd
      name="customer"
      options={customers}
      optionDisplayText={findCustomerByFullName ? "fullname" : "name"}
      label={i18n("frmLabel.Customer")}
      onValueChange={handleValueChange}
      value={value}
      error={error}
      sx={{ width: "25rem" }}
      dataTesting="customer"
    />
  );
}
