// See services/sosInventoryService/README.md for information about the structure
// of schema.js files.
import { Decimal, Money } from "classes/DecimalClasses";

import { i18n } from "services/i18nService";
import {
  extractName,
  formatDocumentInfo,
  formatLinkField,
  formatMoneyWithAdornments,
  formatDateTimeToDate,
  truncate,
  extractId,
  formatMoney,
  extractDescription,
  formatBooleanToYesOrBlank,
  formatQuantityWithUom,
  getMoneyTotalWithAdornments,
  formatLinkableItem,
} from "services/utility/formatting";
import {
  dateTypeEditStrFunction,
  booleanTypeEditStrFunction,
  referenceTypeEditStrFunction,
  arrayOfReferencesTypeEditStrFunction,
  inventoryItemTypeEditStrFunction,
  textTypeEditStrFunction,
  linkedTransactionTypeEditStrFunction,
  numberTypeEditStrFunction,
  decimalTypeEditStrFunction,
} from "services/utility/list";
import { parseHTML } from "services/utility/parsing";
import { TYPES, starColumn } from "services/utility/schema";

import { NO_REF_NUMBER_STRING } from "appConstants";

const DEFAULT_PAYMENT = "Bill";

export const columnInfo = [
  starColumn,
  {
    name: "number",
    fixed: true,
    heading: i18n("columns.ReferenceNumber"),
    formatFunc: formatLinkField,
    minWidth: "7em",
    linkField: true,
    sortable: true,
  },
  {
    name: "date",
    heading: i18n("columns.Date"),
    formatFunc: formatDateTimeToDate,
    defaultSort: true,
    sortDirection: "asc",
    sortable: true,
    default: true,
  },
  {
    name: "vendor",
    heading: i18n("columns.Vendor"),
    formatFunc: extractName,
    minWidth: "10em",
    maxWidth: "20em",
    sortable: true,
    default: true,
  },
  {
    name: "location",
    heading: i18n("columns.Location"),
    formatFunc: extractName,
    sortable: true,
    default: true,
  },
  {
    name: "comment",
    heading: i18n("columns.Comment"),
    formatFunc: [parseHTML, truncate],
    minWidth: "25em",
    maxWidth: "25em",
    sortable: true,
  },
  {
    name: "documents",
    heading: i18n("columns.Documents"),
    minWidth: "15em",
    formatFunc: formatDocumentInfo,
    default: true,
  },
  {
    name: "id",
    heading: `${i18n("columns.System")}`,
    sortDirection: "asc",
    minWidth: "7em",
    align: "right",
    sortable: true,
  },
  {
    name: "total",
    heading: i18n("columns.Amount"),
    formatFunc: formatMoneyWithAdornments,
    align: "right",
  },
  {
    name: "department",
    heading: i18n("columns.Department"),
    formatFunc: extractName,
    sortable: true,
  },

  {
    name: "terms",
    heading: i18n("columns.Terms"),
    formatFunc: extractName,
  },
  {
    name: "vendorNotes",
    heading: i18n("columns.VendorNotes"),
    formatFunc: [parseHTML, truncate],
    minWidth: "25em",
    maxWidth: "25em",
  },
  {
    name: "payment",
    heading: i18n("columns.Payment"),
    formatFunc: extractId,
  },
  {
    name: "deposit",
    heading: i18n("columns.Deposit"),
    fieldName: "depositAmount",
    formatFunc: formatMoneyWithAdornments,
    align: "right",
  },
  {
    name: "hasSignature",
    heading: i18n("columns.Signature"),
    formatFunc: formatBooleanToYesOrBlank,
  },
];

export function getEmptyRecord(settings) {
  return {
    account: null,
    archived: false,
    autoSerialLots: settings.autoSerialLotNumbers,
    billDate: null,
    comment: null,
    currency: settings.homeCurrencyRef,
    customFields: [],
    date: new Date(),
    department: null,
    depositAmount: new Money(0),
    depositPercent: new Money(0),
    documents: null,
    exchangeRate: new Decimal(1.0),
    hasSignature: false,
    keys: null,
    lines: [],
    linkedTransaction: null,
    location: null,
    number: "",
    otherCosts: [],
    payment: { id: DEFAULT_PAYMENT },
    starred: 0,
    subTotal: new Money(0),
    summaryOnly: false,
    syncToken: 0,
    taxAmount: new Money(0),
    terms: null,
    total: new Money(0),
    updateDefaultCosts: settings.updateDefaultCostsOnPurchase,
    values: null,
    vendor: null,
    vendorInvoiceDueDate: null,
    vendorMessage: "",
    vendorNotes: "",
  };
}

// can omit initialValue if null
// editStr should return the value that should be used on an input element of a
// form; for example, null is a good value for the database, but should be "" on
// an input item
export const LINE_ITEM_METADATA = {
  lineNumber: {},
  item: {
    type: TYPES.reference,
    editStr: inventoryItemTypeEditStrFunction,
    initialValue: { id: null, name: null, description: null },
  },
  vendorPartNumber: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  class: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  bin: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  jobWorkcenter: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  customer: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  taxCode: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  description: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  quantity: {
    type: TYPES.decimal,
    placesRight: 5,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  weight: {
    type: TYPES.decimal,
    placesRight: 5,
    editStr: numberTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  weightunit: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  volume: {
    type: TYPES.decimal,
    placesRight: 5,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  volumeunit: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  unitprice: {
    type: TYPES.money,
    currency: true,
    placesRight: 5,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Money(0),
  },
  amount: {
    type: TYPES.money,
    currency: true,
    placesRight: 5,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Money(0),
  },
  received: {
    type: TYPES.decimal,
    placesRight: 5,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  uom: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  serials: {
    type: TYPES.arrayOfReferences,
    editStr: arrayOfReferencesTypeEditStrFunction,
    initialValue: null,
  },
  lot: {
    type: TYPES.reference,
    editStr: textTypeEditStrFunction,
  },
  lotExpiration: {
    type: TYPES.date,
    editStr: dateTypeEditStrFunction,
  },
  linkedTransaction: {
    type: TYPES.linkedTransaction,
    editStr: linkedTransactionTypeEditStrFunction,
    transactionType: "PO",
  },
  linkedTransactionRefNumber: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  linkedTransactionLineNumber: {
    type: TYPES.number,
    editStr: numberTypeEditStrFunction,
  },
  relatedRecords: { initialValue: {} },
  itemDetails: { initialValue: {} },
};

// can omit initialValue if null
// editStr should return the value that should be used on an input element of a
// form; for example, null is a good value for the database, but should be "" on
// an input item
export const OTHER_COST_METADATA = {
  lineNumber: {},
  item: {
    type: TYPES.reference,
    editStr: inventoryItemTypeEditStrFunction,
    initialValue: { id: null, name: null },
  },
  vendor: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  class: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  amount: {
    type: TYPES.money,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Money(0),
  },
  bill: {
    type: TYPES.boolean,
    editStr: booleanTypeEditStrFunction,
    initialValue: false,
  },
};

export const FIELDS_TO_TRANSFORM = {
  DECIMAL_FIELDS: ["exchangeRate", "depositPercent"],
  MONEY_FIELDS: ["depositAmount", "subTotal", "taxAmount", "total"],
  DATE_FIELDS: ["vendorInvoiceDate", "vendorInvoiceDueDate"],
  DATETIME_FIELDS: ["date"],
  NON_UTC_DATETIME_FIELDS: [],
};

export const REFERENCE_FIELDS = ["location", "terms", "vendor", "currency"];
export const OTHER_COST_REFERENCE_FIELDS = ["vendor", "class"];

export const LINE_ACTIONS = [
  ["quickView", "pdf", "email"],
  "template",
  "transfer",
  "archive",
  "delete",
  "returnToVendor",
  "history",
  "sync",
];
export const BATCH_ACTIONS = [["pdf", "email"], "delete", "archive", "sync"];

export const ITEM_CALCULATED_FIELDS = ["purchaseCost"];

export const QUICK_VIEW_CONFIG = {
  signatureLinesLabel: i18n("colon.ReceivedBy"),
  tableConfig: [{ type: "lines" }],
  headerFields: [
    {
      name: "date",
      formatFunc: formatDateTimeToDate,
      label: i18n("columns.Date"),
    },
    {
      name: "number",
      label: i18n("frmLabel.ReceiptNumber"),
      defaultValue: NO_REF_NUMBER_STRING,
    },
  ],
  fields: [
    { label: i18n("frmLabel.Vendor"), name: "vendor", formatFunc: extractName },
    {
      label: i18n("frmLabel.Location"),
      name: "location",
      formatFunc: extractName,
    },
  ],
  lines: [
    {
      name: "item",
      formatFunc: formatLinkableItem,
      heading: i18n("columns.Item"),
      align: "left",
      isItemField: true,
    },
    {
      name: "description",
      formatFunc: extractDescription,
      heading: i18n("columns.Description"),
      align: "left",
    },
    {
      name: "quantity",
      formatFunc: formatQuantityWithUom,
      heading: i18n("columns.Quantity"),
      align: "right",
    },
    {
      name: "unitprice",
      formatFunc: formatMoney,
      heading: i18n("columns.Rate"),
      align: "right",
    },
    {
      name: "amount",
      formatFunc: formatMoney,
      footerFunc: getMoneyTotalWithAdornments,
      heading: i18n("columns.Amount"),
      align: "right",
    },
  ],
};
