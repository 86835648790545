import { useState, useEffect } from "react";

import { RowSelectBase } from "components/formFields/row/RowSelectBase";

import { getItemByNameBarcodeSku } from "services/sosInventoryService/sosApi";
import { checkForUnexpectedProps } from "services/utility/misc";

export function RowItemSelect(props) {
  const {
    onValueChange,
    dataIndex,
    value,
    label,
    options: initialOptions,
    onInputChange,
    onBlur: externalBlur,
    fetchItemsOnChange,
    dataTesting,
    ...unexpected
  } = props;
  checkForUnexpectedProps("RowItemSelect", unexpected);

  const [userInput, setUserInput] = useState("");
  const [currentValue, setCurrentValue] = useState(value);
  const [options, setOptions] = useState(initialOptions);

  useEffect(() => setCurrentValue(value), [value]);

  useEffect(() => setOptions(initialOptions), [initialOptions]);

  // this gets called whenever the user changes the selected option
  function handleChange(_, newValue, reason) {
    if (reason === "clear") {
      setCurrentValue(null);
      setUserInput("");
      onValueChange("item", null, dataIndex);
      return;
    }

    setCurrentValue(newValue);
    onValueChange("item", newValue, dataIndex);
  }

  async function onBlur() {
    externalBlur && externalBlur();
    if (!currentValue?.id) {
      // ...look up by name, barcode, and SKU
      const items = await getItemByNameBarcodeSku(userInput);
      if (items.length === 1) {
        const [item] = items;
        onValueChange("item", item, dataIndex);
      }
    }
  }

  // this gets called when the component needs to know what to display
  // in the input field; not the value, which is the item id, but the
  // human-friendly *name* of the item
  function getOptionLabel(option) {
    // option or option.id can be blank on a newly inserted line;
    // and if we're adding a new item we don't want any text in the
    // component
    if (!Boolean(option?.id)) {
      return "";
    }
    if (currentValue?.name) {
      return currentValue.name;
    }
    const selectedOption = options.find(({ id }) => id === option.id);
    return selectedOption ? selectedOption.name : "";
  }

  // this gets called for each option, when the selected option changes, to
  // determine which, of all the options, was selected
  function isOptionEqualToValue(option, value) {
    return option.id === value?.id || (value === "" && option.id === "");
  }

  // this gets called when the user changes what is in the input field; it
  // should return an array of all the options that meet whatever criteria
  // we want to use; in the current case, we're just looking for a simple
  // substring match
  function filterOptions(options, state) {
    return options.filter((option) => {
      if (option.id === null) {
        return false;
      }
      // if we're making API calls for queries, the list of options will
      // always be matches, so no need to check; however, if there's a value
      // already selected, skip this and match against that value's name,
      // below
      if (fetchItemsOnChange && !currentValue?.name) {
        return true;
      }

      // when focusing back to this field, with a value already selected,
      // state.inputValue will be null (the user hasn't typed anything new
      // here); so use the currentValue instead; if no current value, set
      // the value to match against to ""
      const matchValue = state.inputValue
        ? state.inputValue
        : currentValue?.name || "";
      return (
        `${option.name} ${option.description}`
          .toLowerCase()
          .indexOf(matchValue.toLowerCase()) >= 0
      );
    });
  }

  function renderOption(props, option) {
    return (
      <div
        {...props}
        data-testing="selectOption"
        style={{ minHeight: "1.5em" }}
        key={option.id}
      >
        {option.name}
      </div>
    );
  }

  return (
    <>
      <RowSelectBase
        renderOption={renderOption}
        isOptionEqualToValue={isOptionEqualToValue}
        filterOptions={filterOptions}
        getOptionLabel={getOptionLabel}
        onValueChange={handleChange}
        options={options}
        onInputChange={onInputChange}
        onBlur={onBlur}
        value={currentValue}
        label={label}
        dataTesting={dataTesting}
      />
    </>
  );
}
