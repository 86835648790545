import { i18n } from "services/i18nService";

import { SUB_COMPONENT } from "views/Reports/reportConstants";

const BUILD_ORDER_TYPE_OPTION = {
  id: "Build",
  name: i18n("objectType.build.SentencePlural"),
};

const ORDER_TYPE_OPTIONS = [
  BUILD_ORDER_TYPE_OPTION,
  { id: "PT", name: i18n("objectType.process.SentencePlural") },
];

export const WorkInProgress = {
  userHasSufficientPrivilege: (p) => p.buildAssemblies && p.viewCosts,
  planIncludesReport: (_, pro) => pro,
  relatedLists: ["department", "class", "location", "category"],
  customFieldObjectTypes: [],
  columns: [
    { apiName: "Date", default: true },
    { apiName: "Txn Type", default: true },
    { apiName: "RefNumber", label: "Ref #", default: true },
    { apiName: "Output", default: true },
    { apiName: "Input", default: true },
    { apiName: "Input Qty", default: true, number: true },
    { apiName: "Input Value", default: true, number: true },
  ],
  settings: [
    {
      apiName: "date1",
      macroApiName: "date1Macro",
      headerText: i18n("filter.heading.asOf"),
      component: "DateFilter",
      label: i18n("reports.FilterLabel.AsOfDate"),
      datePrefix: "endOf",
      defaultValue: () => new Date(),
      type: "filter",
    },
    {
      apiName: "date1Macro",
      component: SUB_COMPONENT,
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.OrderType"),
      component: "ReportMultiSelect",
      apiName: "users",
      defaultValue: [BUILD_ORDER_TYPE_OPTION],
      options: ORDER_TYPE_OPTIONS,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.Location"),
      component: "LocationsFilter",
      apiName: "locations",
      defaultValue: null,
      placeholder: i18n("reports.placeholder.DefaultIsAll", {
        type: i18n("reports.locations"),
      }),
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.Category"),
      component: "CategoryFilter",
      apiName: "categories",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.Class"),
      component: "ClassFilter",
      apiName: "class",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.Department"),
      component: "DepartmentFilter",
      apiName: "department",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.OutputItemNameContains"),
      component: "FrmTextField",
      apiName: "nameContains2",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.InputItemNameContains"),
      component: "FrmTextField",
      apiName: "nameContains",
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "showFromTo",
      defaultValue: false,
      label: i18n("reports.OptionLabel.ShowAsOfDate"),
      component: "FrmCheckbox",
      isAsOfDate: true,
      type: "option",
    },
    {
      apiName: "header",
      defaultValue: "",
      label: i18n("global.Header"),
      component: "FrmTextField",
      type: "option",
    },
    {
      apiName: "footer",
      defaultValue: "",
      label: i18n("global.Footer"),
      component: "FrmTextField",
      type: "option",
    },
  ],
};
