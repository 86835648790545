import { useState, useEffect } from "react";

import { DEBOUNCE_WAIT } from "appConfig";

import { ItemSelect } from "components/formFields/ItemSelect";
import {
  LineTableCell,
  LineTableHeadingCell,
} from "components/formFields/LineTableCell";

import { i18n } from "services/i18nService";
import { getItemQuicklist } from "services/sosInventoryService/sosApi";

import { ITEM_QUICKLIST_FORM_TYPES } from "appConstants";

export function OcostInventoryItemHeading() {
  return <LineTableHeadingCell>{i18n("columns.Item")}</LineTableHeadingCell>;
}

export function OcostInventoryItem(props) {
  const {
    line,
    items: externalItems,
    onValueChange,
    itemsCount,
    lineLoading,
  } = props;

  const [items, setItems] = useState(externalItems);
  const [timeoutId, setTimeoutId] = useState();

  const allowApi = items && items.length < itemsCount;

  useEffect(() => setItems(externalItems), [externalItems]);

  function handleInputChange(e, query) {
    if (!e?.target?.value) {
      return;
    }
    clearTimeout(timeoutId);
    setTimeoutId(
      setTimeout(async () => {
        const { data } = await getItemQuicklist({
          formType: ITEM_QUICKLIST_FORM_TYPES.PURCHASING,
          query,
        });
        setItems(data);
      }, DEBOUNCE_WAIT)
    );
  }

  return (
    <LineTableCell sx={{ minWidth: "12rem" }}>
      <ItemSelect
        line={line}
        loading={lineLoading}
        options={items}
        value={line.item}
        onValueChange={onValueChange}
        onInputChange={(e, value) => allowApi && handleInputChange(e, value)}
        onBlur={() => allowApi && setItems(externalItems)}
      />
    </LineTableCell>
  );
}
