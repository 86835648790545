import { i18n } from "services/i18nService";

import {
  NONE_GROUP_BY_OPTION,
  SUB_COMPONENT,
} from "views/Reports/reportConstants";

const SHOW_ALL_OUTPUTS = {
  id: "All",
  name: i18n("reports.FilterLabel.ShowAllOutputs"),
};

const GROUP_BY_OPTIONS = [
  NONE_GROUP_BY_OPTION,
  { id: "Location", name: i18n("reports.OptionLabel.Location") },
  { id: "Output", name: i18n("reports.OptionLabel.Output") },
];

const OUTPUTS_OPTIONS = [
  SHOW_ALL_OUTPUTS,
  { id: "NotWaste", name: i18n("reports.FilterLabel.ShowNonWasteOnly") },
  { id: "Waste", name: i18n("reports.FilterLabel.ShowWasteOnly") },
];

const BUILD_ORDER_TYPE_OPTION = {
  id: "Build",
  name: i18n("objectType.build.SentencePlural"),
};

const ORDER_TYPE_OPTIONS = [
  BUILD_ORDER_TYPE_OPTION,
  { id: "PT", name: i18n("objectType.process.SentencePlural") },
];

export const ProductionReport = {
  userHasSufficientPrivilege: (p) => p.buildAssemblies,
  planIncludesReport: () => true,
  relatedLists: ["department", "class", "location", "category"],
  requiredFiltersNotSetMsg: i18n("reports.OrderTypeFilterRequired"),
  customFieldObjectTypes: ["build", "process", "item"],
  columns: [
    { apiName: "RefNumber", label: "Ref #", default: true },
    { apiName: "Date", default: true },
    { apiName: "Txn Type", default: true },
    { apiName: "Output", default: true },
    { apiName: "Location" },
    { apiName: "Description" },
    { apiName: "Output Qty", default: true, number: true },
    { apiName: "UOM" },
    { apiName: "Inputs" },
    { apiName: "Accounting Costs" },
    { apiName: "Category" },
    { apiName: "Class" },
    { apiName: "Department" },
    { apiName: "Comment" },
    { apiName: "On Hand", number: true },
    { apiName: "Available", number: true },
    { apiName: "SKU" },
    { apiName: "CustomerPartNumber", label: "Customer Part #" },
    { apiName: "Waste" },
    { apiName: "Lot" },
    { apiName: "Bin" },
    { apiName: "Serial Numbers" },
    { apiName: "Vendor" },
    { apiName: "VendorPartNumber", label: "Vendor Part #" },
    { apiName: "List Price" },
    { apiName: "Vendor" },
  ],
  settings: [
    {
      apiName: "date1",
      macroApiName: "date1Macro",
      headerText: i18n("filter.heading.from"),
      component: "DateFilter",
      label: i18n("reports.FilterLabel.FromDate"),
      defaultValue: () => new Date(),
      datePrefix: "startOf",
      type: "filter",
    },
    {
      apiName: "date1Macro",
      defaultValue: null,
      component: SUB_COMPONENT,
      type: "filter",
    },
    {
      apiName: "date2",
      macroApiName: "date2Macro",
      component: "DateFilter",
      label: i18n("reports.FilterLabel.ToDate"),
      datePrefix: "endOf",
      defaultValue: () => new Date(),
      type: "filter",
    },
    {
      apiName: "date2Macro",
      defaultValue: null,
      component: SUB_COMPONENT,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.OrderType"),
      component: "ReportMultiSelect",
      apiName: "users",
      defaultValue: [BUILD_ORDER_TYPE_OPTION],
      options: ORDER_TYPE_OPTIONS,
      type: "filter",
      required: true,
    },
    {
      label: i18n("reports.FilterLabel.Location"),
      component: "LocationsFilter",
      apiName: "locations",
      defaultValue: null,
      placeholder: i18n("reports.placeholder.DefaultIsAll", {
        type: i18n("reports.locations"),
      }),
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.ItemProduced"),
      component: "ItemFilter",
      apiName: "item",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.Outputs"),
      component: "FrmSelectFromObjects",
      apiName: "orderType",
      options: OUTPUTS_OPTIONS,
      defaultValue: SHOW_ALL_OUTPUTS,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.Category"),
      component: "CategoryFilter",
      apiName: "categories",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.Class"),
      component: "ClassFilter",
      apiName: "class",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.Department"),
      component: "DepartmentFilter",
      apiName: "department",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.OutputItemNameContains"),
      component: "FrmTextField",
      apiName: "nameContains2",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.InputItemNameContains"),
      component: "FrmTextField",
      apiName: "nameContains",
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "showFromTo",
      defaultValue: false,
      label: i18n("reports.OptionLabel.ShowFromAndToDates"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "groupBy",
      label: i18n("reports.OptionLabel.GroupBy"),
      component: "FrmButtonGroup",
      defaultValue: NONE_GROUP_BY_OPTION,
      options: GROUP_BY_OPTIONS,
      type: "option",
    },
    {
      apiName: "header",
      defaultValue: "",
      label: i18n("global.Header"),
      component: "FrmTextField",
      type: "option",
    },
    {
      apiName: "footer",
      defaultValue: "",
      label: i18n("global.Footer"),
      component: "FrmTextField",
      type: "option",
    },
  ],
};
