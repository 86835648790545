import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Typography, Button } from "@mui/material";

import { i18n } from "services/i18nService";
import {
  getDashboardSettings,
  updateDashboardSettings,
} from "services/sosInventoryService/sosApi";

import { usePlans } from "hooks/usePlans";

import { SettingsDialog } from "views/Dashboard/SettingsDialog";
import { Widget } from "views/Dashboard/Widget";
import { DASHBOARD_CONFIG } from "views/Dashboard/dashboardConfig";

import {
  loadingIndicatorOn,
  loadingIndicatorOff,
} from "globalState/loadingSlice";

export function Dashboard() {
  const isLoading = useSelector((state) => state.loading.list);
  const dispatch = useDispatch();
  const { allowPlusFeatures, allowProFeatures } = usePlans();
  const [settings, setSettings] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [refreshCount, setRefreshCount] = useState(0);

  useEffect(() => {
    async function getSettings() {
      dispatch(loadingIndicatorOn());
      const settings = await getDashboardSettings();
      setSettings(settings);
      dispatch(loadingIndicatorOff());
    }
    getSettings();
  }, [dispatch]);

  async function saveSettings(settings) {
    setModalOpen(false);
    dispatch(loadingIndicatorOn());
    const response = await updateDashboardSettings(settings);
    setSettings(response);
    dispatch(loadingIndicatorOff());
  }

  return (
    <>
      <Box sx={{ display: "grid" }} m={2} mb={0}>
        <Box mb={1}>
          <Typography variant="h2">{i18n("global.Dashboard")}</Typography>
        </Box>
        {settings && (
          <div>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Button
                disabled={isLoading}
                sx={{ mb: 2 }}
                variant="outlined"
                onClick={() => setRefreshCount((c) => c + 1)}
              >
                {i18n("dashboard.RefreshDashboard")}
              </Button>
              <Button
                disabled={isLoading}
                sx={{ mb: 2 }}
                variant="outlined"
                onClick={() => setModalOpen(true)}
                data-testing="settingsButton"
              >
                {i18n("dashboard.Settings")}
              </Button>
            </Box>
            {DASHBOARD_CONFIG.map((section) => {
              const { label, widgets } = section;
              // map through widgets and make sure that
              // the row has at least one widget present
              const showRow = widgets.reduce(
                (seed, { setting, planIncludesSetting }) => {
                  const planEnabled =
                    !planIncludesSetting ||
                    planIncludesSetting(allowPlusFeatures, allowProFeatures);
                  return seed || (settings[setting] && planEnabled);
                },
                false
              );
              return (
                showRow && (
                  <Box key={label} mb={1}>
                    <Typography
                      variant="tag"
                      component="p"
                      sx={{ marginLeft: "0.2rem", textTransform: "uppercase" }}
                    >
                      {label}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        columnGap: 1,
                        rowGap: 1,
                        flexWrap: "wrap",
                      }}
                    >
                      {widgets.map((widget, i) => {
                        const {
                          type,
                          setting,
                          planIncludesSetting,
                          formatFunc,
                          isDateTime,
                          linkUrl,
                        } = widget;
                        const planEnabled =
                          !planIncludesSetting ||
                          planIncludesSetting(
                            allowPlusFeatures,
                            allowProFeatures
                          );
                        return (
                          <Widget
                            key={`${i}${refreshCount}`}
                            type={type}
                            formatFunc={formatFunc}
                            isDateTime={isDateTime}
                            color={`dashboard.${type}`}
                            settingEnabled={settings[setting] && planEnabled}
                            label={i18n(`dashboard.${type}`)}
                            linkUrl={linkUrl}
                          />
                        );
                      })}
                    </Box>
                  </Box>
                )
              );
            })}
          </div>
        )}
      </Box>
      {modalOpen && (
        <SettingsDialog
          close={() => setModalOpen(false)}
          settings={settings}
          saveSettings={saveSettings}
        />
      )}
    </>
  );
}
