import { ITEM_CALCULATED_FIELDS } from "appConfig";
import { IN_TRANSACTION } from "appConfig";

import { Decimal, Money } from "classes/DecimalClasses";
import { Loading } from "classes/Loading";

import { i18n } from "services/i18nService";
import { getItemRecord } from "services/sosInventoryService/domainLogic";
import { afterTouchLine } from "services/sosInventoryService/salesTransaction/afterTouchLine";
import {
  getRecord,
  getExchangeRate,
  calculateSalesCostBasis,
} from "services/sosInventoryService/sosApi";
import {
  reconcileCustomFields,
  copyCustomFieldValues,
} from "services/utility/customFields";
import { setPageDirty } from "services/utility/edit";
import { handleProgramError } from "services/utility/errors";
import { formatContact } from "services/utility/formatting";
import { calculateMarginPercent } from "services/utility/misc";
import {
  getBaseUom,
  getUomConversionFromUomReference,
} from "services/utility/uoms";

import { openAlert } from "globalState/alertSlice";
import globalState from "globalState/globalState";

import {
  DEFAULT_DECIMALS_ROUNDED,
  MAX_CHARS_LINE_ITEM_DESCRIPTION,
} from "appConstants";

// call this when the customer changes for sales transactions
export async function changeCustomer(
  newCustomerId,
  {
    record,
    customerCustomFieldDefs,
    transactionCustomFieldDefs,
    transactionCustomFields,
    lines,
    taxCodes,
  }
) {
  try {
    // get the newly selected customer record, so that we can...
    const customer = await getRecord("customer", newCustomerId, IN_TRANSACTION);

    // ...see if the customer has a currency setting; if so, get the
    // exchange rate for that currency
    let newCurrency = null;
    let newExchangeRate = null;

    if (customer?.currency) {
      const response = await getExchangeRate(
        customer.currency.name,
        record.date
      );
      if (response) {
        newCurrency = customer.currency;
        newExchangeRate = response.exchangeRate;
      }
    }

    // be sure there are custom field entries for each defined custom field
    // in the customer record...
    const customerCustomFields = reconcileCustomFields(
      customerCustomFieldDefs,
      customer.customFields
    );

    // ...then initialize any transaction custom fields to their matching customer
    // custom field values, if any
    const newTransactionCustomFields = copyCustomFieldValues(
      customerCustomFieldDefs,
      customerCustomFields,
      transactionCustomFieldDefs,
      transactionCustomFields
    );
    const { billing, shipping, name, contact, companyName, phone, email } =
      customer || {};

    const contactDetails = {
      company: companyName,
      contact: formatContact(contact, name),
      phone,
      email,
    };
    const billingAddress = { address: billing, ...contactDetails };
    const shippingAddress = { address: shipping, ...contactDetails };

    if (customer.creditHold) {
      globalState.dispatch(
        openAlert({ type: "error", message: i18n("customer.CreditHold") })
      );
    }

    const newLines = lines.map((line) => {
      if (line.item) {
        const newLine = {
          ...line,
          tax: { ...line.taxable, taxable: customer.tax.taxable },
        };
        return afterTouchLine(newLine, "tax");
      }
      return line;
    });

    const newRecord = {
      ...record,
      customer,
      currency: newCurrency ? newCurrency : record.currency,
      exchangeRate: newExchangeRate ? newExchangeRate : record.exchangeRate,
      customerNotes: customer.notes,
      taxCode: customer.tax.taxCode,
      terms: customer.terms,
      customFields: newTransactionCustomFields,
      billing: billingAddress,
      shipping: shippingAddress,
      salesRep: customer.salesRep,
    };

    if (customer.tax.taxCode?.id && taxCodes) {
      const { salesTaxRate } = taxCodes.find(
        ({ id }) => id === customer.tax.taxCode.id
      );
      newRecord.taxPercent = salesTaxRate;
    }

    return { newRecord, newLines, customer };
  } catch (e) {
    handleProgramError(e);
  }
}

export async function updateLineWithItem(item, line, record) {
  const { date, priceTier } = record;
  if (!item) {
    return line;
  }
  const { percentDiscount } = priceTier || {};
  const baseUom = getBaseUom(item.uoms);

  const itemCost = await calculateSalesItemCost(
    date,
    line.quantity,
    item.id,
    baseUom,
    item?.uoms
  );
  const unitPrice = calculateSalesItemPrice(
    item,
    line.quantity,
    priceTier,
    baseUom,
    item.uoms,
    itemCost
  );

  const margin = item.useMarkup
    ? item.markupPercent
    : calculateMarginPercent(itemCost, unitPrice, line.quantity);

  // transfer the appropriate properties from the new inventory
  // item to the line item
  const newLine = {
    ...line,
    item,
    margin,
    percentdiscount: percentDiscount,
    description: item.description?.slice(0, MAX_CHARS_LINE_ITEM_DESCRIPTION),
    weightunit: item.weightUnit,
    volumeunit: item.volumeUnit,
    unitprice: unitPrice,
    listPrice: item.salesPrice,
    uom: baseUom,
    taxCode: item.salesTaxCode,
    tax: { taxable: item.taxable },
    basePurchaseCost: item.basePurchaseCost,
    purchaseCost: item.purchaseCost,
    cost: itemCost,
    available: item.available,
    relatedRecords: { ...line.relatedRecords, item },
    itemDetails: {
      itemWeight: item.weight,
      itemVolume: item.volume,
      itemUoms: item.uoms,
      type: item.type,
      useMarkup: item.useMarkup,
      markupPercent: item.markupPercent,
      baseSalesPrice: item.baseSalesPrice,
    },
  };
  return newLine;
}

export async function onHandleUomChange(uom, record, line, lineHandler) {
  const { itemDetails, item } = line;
  const useMarkup = itemDetails.useMarkup;
  const beforeCostFetchKey = useMarkup
    ? "costLoadingWithMarkupItem"
    : "uomWithCostLoading";

  let updatedLine = afterTouchLine(
    { ...line, uom },
    beforeCostFetchKey,
    record
  );
  setPageDirty();
  lineHandler({ type: "update", updatedLine });

  const cost = await calculateSalesItemCost(
    record.date,
    line.quantity,
    item.id,
    uom,
    itemDetails.itemUoms
  );

  const afterCostFetchKey = useMarkup ? "uom" : "cost";
  const uomCostLine = { ...updatedLine, cost };
  updatedLine = afterTouchLine(uomCostLine, afterCostFetchKey, record);
  setPageDirty();
  lineHandler({ type: "update", updatedLine });
}

export async function onHandleQuantityChange(
  quantity,
  record,
  line,
  lineHandler
) {
  const { itemDetails, item, userHasSetUnitPrice } = line;
  const newLine = { ...line, quantity };
  if (!item) {
    setPageDirty();
    lineHandler({ type: "update", updatedLine: newLine });
    return;
  }

  let beforeCostFetchKey;
  let afterCostFetchKey;
  if (userHasSetUnitPrice) {
    // don't recalculate unit price if user
    // has explicity set the unit price
    beforeCostFetchKey = "quantityCostLoadingNoPriceUpdate";
    afterCostFetchKey = "quantityNoPriceUpdate";
  } else if (itemDetails.useMarkup) {
    // markup item's unit price is dependent on
    // cost so don't update unit price until cost
    // data is available
    beforeCostFetchKey = "costLoadingWithMarkupItem";
    afterCostFetchKey = "quantity";
  } else {
    beforeCostFetchKey = "quantityCostLoading";
    afterCostFetchKey = "cost";
  }

  let updatedLine = afterTouchLine(newLine, beforeCostFetchKey, record);
  setPageDirty();
  lineHandler({ type: "update", updatedLine });
  const uom = line.uom || getBaseUom(itemDetails.itemUoms);
  const cost = await calculateSalesItemCost(
    record.date,
    quantity,
    item.id,
    uom,
    itemDetails.itemUoms
  );

  updatedLine = afterTouchLine(
    { ...updatedLine, cost },
    afterCostFetchKey,
    record
  );
  setPageDirty();
  lineHandler({ type: "update", updatedLine });
}

export async function calculateSalesItemCost(
  date,
  quantity,
  itemId,
  uom,
  itemUoms
) {
  // get UOM conversion factor using the UOM selection
  const selectedOrBaseUom = uom || getBaseUom(itemUoms);
  const fullUom = itemUoms.find(({ uom }) => uom.id === selectedOrBaseUom?.id);
  const conversion = fullUom?.conversion || new Decimal(1);

  // retrieve cost for one when quantity is zero
  const quantityForCost = quantity.eq(Decimal.ZERO) ? new Decimal(1) : quantity;
  // multiply the quantity by the UOM conversion so that
  // calculateSalesCostBasis utilizes the correct quantity
  const baseUomQuantity = quantityForCost.times(conversion);
  const { costBasis } = await calculateSalesCostBasis(
    itemId,
    baseUomQuantity,
    date
  );
  return costBasis;
}

export function calculateSalesItemPrice(
  itemDetails,
  quantity,
  priceTier,
  uom,
  itemUoms,
  itemCost
) {
  const { markupPercent, useMarkup, baseSalesPrice } = itemDetails;

  // get UOM conversion factor using the UOM selection
  const baseUom = getBaseUom(itemUoms);
  const selectedOrBaseUom = uom || baseUom;
  const fullUom = itemUoms.find(({ uom }) => uom.id === selectedOrBaseUom?.id);
  const conversion = fullUom?.conversion || new Decimal(1);

  let unitPrice;
  if (useMarkup) {
    // calculate per unit cost for markup pricing
    const unitCost = quantity.eq(Decimal.ZERO)
      ? itemCost.div(conversion)
      : itemCost.div(conversion).div(quantity);
    unitPrice = unitCost
      .times(markupPercent.div(new Decimal(100)))
      .plus(unitCost);
  } else {
    unitPrice = baseSalesPrice;
  }

  // if no uoms or Base UOM allow for price tier pricing
  const isBaseUom = uom && uom?.id === baseUom?.id;
  if (isBaseUom || !itemUoms.length) {
    return priceTier
      ? getPriceTierUnitPrice(itemDetails, quantity, priceTier)
      : unitPrice;
  }

  // if not base UOM - check for UOM sales price
  if (fullUom?.salesPrice) {
    return fullUom.salesPrice;
  }
  // default to unitPrice accounting for conversion of UOM
  return unitPrice.times(conversion);
}

export function getPriceTierUnitPrice(itemDetails, quantity, recordPriceTier) {
  if (!recordPriceTier) {
    return itemDetails.baseSalesPrice;
  }
  const { percentDiscount, amountDiscount } = recordPriceTier || {};

  const itemPrices = recordPriceTier.items.filter(
    (reference) =>
      reference.item.id === itemDetails.id && quantity.gte(reference.quantity)
  );

  const itemPrice = itemPrices.reduce((seed, ele) => {
    if (!seed) {
      return ele;
    }
    if (ele.quantity === seed.quantity) {
      return ele.price.lt(seed.price) ? ele : seed;
    }
    return ele.quantity.gt(seed.quantity) ? ele : seed;
  }, null);

  const discount = percentDiscount
    ? new Decimal(1).minus(percentDiscount.times(new Decimal(0.01)))
    : percentDiscount;

  let unitPrice = itemDetails.baseSalesPrice;
  unitPrice = itemPrice ? itemPrice.price : unitPrice;
  unitPrice = percentDiscount ? unitPrice.times(discount) : unitPrice;
  unitPrice = amountDiscount ? unitPrice.minus(amountDiscount) : unitPrice;

  return unitPrice;
}

export async function updateLineItemAvailable(record, lines, objectType) {
  return await Promise.all(
    lines.map(async (line) => {
      if (line.item?.id) {
        const item = await getItemRecord(
          line.item.id,
          record.location?.id,
          record.date,
          ITEM_CALCULATED_FIELDS[objectType]
        );
        const conversion = getUomConversionFromUomReference(
          line.uom,
          item.uoms
        );
        const available = item.available
          .div(conversion)
          .round(DEFAULT_DECIMALS_ROUNDED, Decimal.roundDown);
        return { ...line, available };
      }
      return line;
    })
  );
}

/**
 * @name    getCustomerAddresses
 *
 * @summary given a customer object, extracts and returns the billing
 *          and shipping addresses, with the default contact info filled
 *          in from the customer record
 *
 * @param   customer (object) - a customer object
 *
 * @returns (object) - an object with billing and shipping addresses
 *          separated into their respective properties; each of "billing"
 *          and "shipping" properties will contain an array of their
 *          respective addresses (along with contact info)
 */
export function getCustomerAddresses(customer) {
  const billingAddresses = customer.altAddresses
    .filter((address) => address?.addressType === "Billing")
    .map((address) => ({
      ...address,
      name: address.addressName,
    }))
    .sort((a, b) => (a.name < b.name ? -1 : 1));

  const shippingAddresses = customer.altAddresses
    .filter((address) => address.addressType === "Shipping")
    .map((address) => ({ ...address, name: address.addressName }))
    .sort((a, b) => (a.name < b.name ? -1 : 1));

  const defaultContactInfo = {
    name: "Default",
    phone: customer.phone,
    contact: formatContact(customer.contact),
    email: customer.email,
    company: customer.companyName,
  };

  return {
    billing: [
      {
        ...defaultContactInfo,
        address: customer.billing,
      },
      ...billingAddresses,
    ],
    shipping: [
      {
        ...defaultContactInfo,
        address: customer.shipping,
      },
      ...shippingAddresses,
    ],
  };
}

// updates line data on load for all lines on transaction
export async function calculateCostAndMargin(date, lines) {
  return await Promise.all(
    lines.map(async (line) => {
      if (!line.item?.id) {
        return line;
      }

      // set available to null so that available column shows "eye" icon
      line.available = null;

      const { itemUoms } = line.itemDetails;

      const uom = line.uom || getBaseUom(itemUoms);
      if (line.cost instanceof Loading) {
        // if cost/margin are Loading instance, recalculate cost/margin
        // TODO: update once https://app.clickup.com/t/2rxvm9p fixed
        line.cost = await calculateSalesItemCost(
          date,
          line.quantity,
          line.item.id,
          uom,
          itemUoms
        );
        line.margin = calculateMarginPercent(
          line.cost,
          line.unitprice,
          line.quantity
        );
      }
      return line;
    })
  );
}

export function nonGlobalTaxCalculation(
  taxableAmount,
  discountTaxable,
  discountAmount,
  taxPercent,
  shippingTaxable,
  shippingAmount
) {
  const taxablePlusDiscount = taxableAmount.plus(
    discountTaxable ? discountAmount : new Money(0)
  );
  // don't tax an amount < 0
  const positiveTaxableAmount = taxablePlusDiscount.lt(new Money(0))
    ? new Money(0)
    : taxablePlusDiscount;
  const totalTaxable = positiveTaxableAmount.plus(
    shippingTaxable ? shippingAmount : new Money(0)
  );
  return totalTaxable.times(taxPercent).times(new Decimal(0.01));
}

export function globalTaxCalculation(
  taxAmount,
  discountTaxable,
  discountAmount,
  discountShippingTaxPercent,
  shippingTaxable,
  shippingAmount
) {
  const taxableDiscountAmount = discountTaxable
    ? discountAmount.times(discountShippingTaxPercent).times(new Decimal(0.01))
    : new Money(0);

  const taxableShippingAmount = shippingTaxable
    ? shippingAmount.times(discountShippingTaxPercent).times(new Decimal(0.01))
    : new Money(0);

  const tax = taxAmount.plus(taxableDiscountAmount).plus(taxableShippingAmount);

  // don't tax an amount < 0
  return tax.lt(new Money(0)) ? new Money(0) : tax;
}

export function refreshTierRates(priceTier, lines, lineHandler) {
  lines.forEach((line) => {
    const item = line.relatedRecords.item;
    if (item) {
      const unitprice = getPriceTierUnitPrice(item, line.quantity, priceTier);
      const newLine = {
        ...line,
        unitprice,
      };
      setPageDirty();
      lineHandler({
        type: "update",
        updatedLine: afterTouchLine(newLine, "priceTier"),
      });
    }
  });
}
