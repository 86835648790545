import { Decimal, Money } from "classes/DecimalClasses";
import { Loading } from "classes/Loading";

import { UOM_FIELDS_TO_TRANSFORM } from "services/sosInventoryService/item/schema";
import {
  isoToLocalDateTime,
  yyyymmddToDate,
  isoToDateTime,
} from "services/utility/dates";
import { TYPES } from "services/utility/schema";
import { normalizeSerials } from "services/utility/serials";

export function transformFields(record, fieldsToTransform) {
  let newRecord = { ...record };

  // convert ISO string date values (that have no time component)
  // to local date values with no time component
  fieldsToTransform.DATE_FIELDS.forEach((dateField) => {
    if (newRecord[dateField]) {
      newRecord[dateField] = yyyymmddToDate(newRecord[dateField]);
    } else {
      newRecord[dateField] = null;
    }
  });

  // convert ISO string datetime values to JavaScript date objects
  // in user's local time zone
  fieldsToTransform.DATETIME_FIELDS.forEach((datetimeField) => {
    if (newRecord[datetimeField]) {
      newRecord[datetimeField] = isoToLocalDateTime(newRecord[datetimeField]);
    } else {
      newRecord[datetimeField] = null;
    }
  });

  // convert ISO string datetime values to JavaScript date objects
  // without any timezone conversion
  fieldsToTransform.NON_UTC_DATETIME_FIELDS.forEach((nonUtcDatetimeField) => {
    if (newRecord[nonUtcDatetimeField]) {
      newRecord[nonUtcDatetimeField] = isoToDateTime(
        newRecord[nonUtcDatetimeField]
      );
    } else {
      newRecord[nonUtcDatetimeField] = null;
    }
  });

  // convert numbers from float to Decimal
  newRecord = transformToDecimal(newRecord, fieldsToTransform.DECIMAL_FIELDS);

  // convert numbers from float to Money
  newRecord = transformToMoney(newRecord, fieldsToTransform.MONEY_FIELDS);

  return newRecord;
}

export function transformToMoney(record, moneyFields) {
  const newRecord = { ...record };
  moneyFields.forEach((numField) => {
    if (typeof newRecord[numField] === "number") {
      newRecord[numField] = new Money(
        newRecord[numField],
        record.currency?.name
      );
    } else if (newRecord[numField] !== null) {
      throw new Error(
        `Unexpected value (${newRecord[numField]}) for ${numField}`
      );
    }
  });
  return newRecord;
}

export function transformToDecimal(record, decimalFields) {
  const newRecord = { ...record };
  decimalFields.forEach((numField) => {
    if (typeof newRecord[numField] === "number") {
      newRecord[numField] = new Decimal(newRecord[numField]);
    } else if (newRecord[numField] !== null) {
      throw new Error(
        `Unexpected value (${newRecord[numField]}) for ${numField}`
      );
    }
  });
  return newRecord;
}

export function transformLineItemFields(lines, fields) {
  const newLines = [...lines];
  newLines.forEach((line) => {
    // convert decimals to Decimal objects
    fields[TYPES.decimal].forEach((field) => {
      if (line[field] !== null) {
        line[field] = new Decimal(line[field]);
      }
    });

    // convert money types to Money objects
    fields[TYPES.money].forEach((field) => {
      if (line[field] !== null) {
        line[field] = new Money(line[field]);
      }
    });

    // convert date types to date objects
    fields[TYPES.date].forEach((field) => {
      if (line[field]) {
        line[field] = yyyymmddToDate(line[field]);
      } else {
        line[field] = null;
      }
    });

    // set itemFields to Loading class because the data is dependent on item request
    // certain records have line item data with incorrect data
    // this keeps the FE from displaying incorrect data until item data fetch
    // (i.e margin/cost on sales transactions, newQuantity on adjustment)
    // Loading class allows inner components to determine when to show loading skeleton
    fields[TYPES.itemField].forEach((field) => {
      line[field] = new Loading();
    });
  });
  return newLines;
}

export function transformUoms(uoms) {
  // update UOM decimal and money values into the class values
  return uoms.map((uom) => transformFields(uom, UOM_FIELDS_TO_TRANSFORM));
}

export function transformWorkCenters(workCenters) {
  return workCenters
    .sort((a, b) => {
      return a.sequence < b.sequence ? -1 : 1;
    })
    .map(({ workcenter }) => workcenter);
}

export function transformSerial(line, autoSerialLots) {
  const newLine = { ...line };

  // set serials appropriately
  newLine.serials = normalizeSerials(
    line.serials,
    line.quantity,
    line.itemDetails.serialTracking,
    autoSerialLots
  );

  return newLine;
}

export function nonUtcDateTimeHandling(record) {
  const hasDate = Boolean(record.date);
  return {
    ...record,
    originalDate: hasDate ? record.date : new Date(),
    dateChanged: !hasDate,
  };
}
