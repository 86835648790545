import { i18n } from "services/i18nService";
import { formatDate } from "services/utility/dates";
import {
  formatLinkField,
  formatCompleted,
  strikeThroughCompleted,
} from "services/utility/formatting";

export const columnInfo = [
  {
    name: "name",
    heading: i18n("columns.Name"),
    formatFunc: [formatLinkField, strikeThroughCompleted],
    fixed: true,
    linkField: true,
    sortable: true,
    defaultSort: true,
  },
  {
    name: "description",
    heading: i18n("columns.Description"),
    minWidth: "15rem",
    maxWidth: "15rem",
    fixed: true,
    fixedRightOfAction: true,
    sortable: true,
    default: true,
  },
  {
    name: "dueDate",
    heading: i18n("columns.DueDate"),
    formatFunc: formatDate,
    minWidth: "8em",
    fixed: true,
    fixedRightOfAction: true,
    sortable: true,
    default: true,
  },
  {
    name: "completed",
    heading: i18n("columns.Status"),
    formatFunc: formatCompleted,
    fixed: true,
    fixedRightOfAction: true,
    default: true,
  },
];

const columnArray = Object.entries(columnInfo);
const defaultColumnInfo = columnArray.filter((col) => col[1].required);
export const defaultColumns = defaultColumnInfo.map((col) => col[0]);

export const LINE_ACTIONS = ["delete", "complete"];
export const BATCH_ACTIONS = ["delete", "complete"];

export const FIELDS_TO_TRANSFORM = {
  DECIMAL_FIELDS: [],
  MONEY_FIELDS: [],
  DATE_FIELDS: [],
  DATETIME_FIELDS: ["dueDate"],
  NON_UTC_DATETIME_FIELDS: [],
};

export function getEmptyRecord() {
  return {
    syncToken: 0,
    starred: 0,
    description: null,
    name: null,
    assignedToUser: null,
    completed: false,
    dueDate: new Date(),
  };
}
