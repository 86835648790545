import { i18n } from "services/i18nService";

import {
  NONE_GROUP_BY_OPTION,
  GROUP_BY_CATEGORY_OR_VENDOR_OPTIONS,
  ON_HAND_VALUE_CALCULATION_OPTION,
  VALUE_CALCULATION_OPTIONS,
  SUB_COMPONENT,
} from "views/Reports/reportConstants";

export const InventoryStockStatus = {
  userHasSufficientPrivilege: (p) =>
    p.viewInventory || p.viewItems || p.editItems,
  planIncludesReport: () => true,
  relatedLists: ["vendor", "location", "category"],
  customFieldObjectTypes: "item",
  columns: [
    { apiName: "ItemNumber", label: "Item #" },
    { apiName: "Name", default: true },
    { apiName: "Category" },
    { apiName: "Description", default: true },
    { apiName: "Purchase Description" },
    { apiName: "SKU" },
    { apiName: "Barcode" },
    { apiName: "Vendor" },
    { apiName: "VendorPartNumber", label: "Vendor Part #" },
    { apiName: "Sales Price", number: true },
    { apiName: "Purchase Cost", number: true },
    { apiName: "Default Bin" },
    { apiName: "Asset Account" },
    { apiName: "Lead Time", number: true },
    { apiName: "Reorder Point", number: true },
    { apiName: "Max Stock", number: true },
    { apiName: "Tags" },
    { apiName: "System Count", alwaysShow: true, number: true },
    { apiName: "Total", alwaysShow: true, number: true },
  ],
  settings: [
    {
      apiName: "date1",
      macroApiName: "date1Macro",
      headerText: i18n("filter.heading.asOf"),
      component: "DateFilter",
      label: i18n("reports.FilterLabel.AsOfDate"),
      datePrefix: "endOf",
      defaultValue: () => new Date(),
      type: "filter",
    },
    {
      apiName: "date1Macro",
      component: SUB_COMPONENT,
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("filter.Locations"),
      component: "LocationsFilter",
      apiName: "locations",
      defaultValue: null,
      includeTotalOnlyOption: true,
      placeholder: i18n("reports.placeholder.DefaultIsTotalsOnly"),
      type: "filter",
    },
    {
      label: i18n("filter.Categories"),
      component: "CategoryFilter",
      apiName: "categories",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("filter.Vendor"),
      component: "VendorFilter",
      apiName: "vendor",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.ItemNameContains"),
      component: "FrmTextField",
      apiName: "nameContains",
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "suppressIfEmpty",
      defaultValue: false,
      label: i18n("reports.OptionLabel.HideRowsWithAllZeros"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "showArchived",
      defaultValue: false,
      label: i18n("reports.OptionLabel.IncludeArchivedItems"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "highlightReorders",
      defaultValue: false,
      label: i18n("reports.OptionLabel.HighlightReorders"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "showFromTo",
      defaultValue: false,
      label: i18n("reports.OptionLabel.ShowAsOfDate"),
      component: "FrmCheckbox",
      isAsOfDate: true,
      type: "option",
    },
    {
      apiName: "groupBy",
      label: i18n("reports.OptionLabel.GroupBy"),
      component: "FrmButtonGroup",
      defaultValue: NONE_GROUP_BY_OPTION,
      options: GROUP_BY_CATEGORY_OR_VENDOR_OPTIONS,
      type: "option",
    },
    {
      apiName: "valueCalculation",
      defaultValue: ON_HAND_VALUE_CALCULATION_OPTION,
      label: i18n("reports.OptionLabel.ReportValue"),
      component: "FrmSelectFromObjects",
      options: VALUE_CALCULATION_OPTIONS,
      type: "option",
    },
    {
      apiName: "header",
      defaultValue: "",
      label: i18n("global.Header"),
      component: "FrmTextField",
      type: "option",
    },
    {
      apiName: "footer",
      defaultValue: "",
      label: i18n("global.Footer"),
      component: "FrmTextField",
      type: "option",
    },
  ],
};
