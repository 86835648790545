// See services/sosInventoryService/README.md for information about the structure
// of schema.js files.
import { Decimal, Money } from "classes/DecimalClasses";

import { i18n } from "services/i18nService";
import { formatDate } from "services/utility/dates";
import {
  extractName,
  formatDateTimeToDate,
  extractLinkedTransactions,
  formatMoneyWithAdornments,
  formatCompanyInfo,
  formatDocumentInfo,
  formatLinkField,
  formatStatus,
  truncate,
  formatBoolean,
  formatBooleanToYesOrBlank,
  formatQuantityWithUom,
  extractDescription,
  formatMoney,
  formatDecimal,
  formatLinkableItem,
  formatQuickViewTotal,
} from "services/utility/formatting";
import {
  referenceTypeEditStrFunction,
  arrayOfReferencesTypeEditStrFunction,
  inventoryItemTypeEditStrFunction,
  textTypeEditStrFunction,
  linkedTransactionTypeEditStrFunction,
  numberTypeEditStrFunction,
  decimalTypeEditStrFunction,
  dateTypeEditStrFunction,
} from "services/utility/list";
import { parseHTML } from "services/utility/parsing";
import { TYPES } from "services/utility/schema";
import { starColumn } from "services/utility/schema";

import {
  DEFAULT_DECIMALS_UNROUNDED,
  DEFAULT_DECIMALS_ROUNDED,
  NO_REF_NUMBER_STRING,
} from "appConstants";

export const columnInfo = [
  starColumn,
  {
    name: "number",
    fixed: true,
    heading: i18n("columns.ReferenceNumber"),
    formatFunc: formatLinkField,
    minWidth: "7em",
    linkField: true,
    sortable: true,
  },
  {
    name: "date",
    heading: i18n("columns.Date"),
    formatFunc: formatDateTimeToDate,
    defaultSort: true,
    sortDirection: "asc",
    sortable: true,
    default: true,
  },
  {
    name: "customer",
    heading: i18n("columns.Customer"),
    formatFunc: extractName,
    minWidth: "10em",
    maxWidth: "25em",
    sortable: true,
    default: true,
  },
  {
    name: "terms",
    heading: i18n("columns.Terms"),
    formatFunc: extractName,
    sortable: true,
    default: true,
  },
  {
    name: "total",
    heading: i18n("columns.Amount"),
    formatFunc: formatMoneyWithAdornments,
    align: "right",
    sortable: true,
    default: true,
  },
  {
    name: "status",
    fieldName: "closed",
    heading: i18n("columns.Status"),
    formatFunc: formatStatus,
    sortable: true,
    default: true,
  },
  {
    name: "comment",
    heading: i18n("columns.Comment"),
    formatFunc: [parseHTML, truncate],
    minWidth: "25em",
    maxWidth: "25em",
    sortable: true,
    default: true,
  },
  {
    name: "documents",
    heading: i18n("columns.Documents"),
    formatFunc: formatDocumentInfo,
    minWidth: "15em",
    default: true,
  },
  {
    name: "location",
    heading: i18n("columns.Location"),
    formatFunc: extractName,
    sortable: true,
  },
  {
    name: "openAmount",
    heading: i18n("columns.OpenAmt"),
    formatFunc: formatMoneyWithAdornments,
    sortable: true,
    align: "right",
  },
  {
    name: "depositAmount",
    heading: i18n("columns.Deposit"),
    formatFunc: formatMoneyWithAdornments,
    align: "right",
    sortable: true,
  },
  {
    name: "priority",
    heading: i18n("columns.Priority"),
    formatFunc: extractName,
    sortable: true,
  },
  {
    name: "shipping",
    heading: i18n("columns.ShipTo"),
    formatFunc: formatCompanyInfo,
    minWidth: "10em",
    maxWidth: "15em",
  },
  {
    name: "customerPO",
    heading: i18n("columns.CustomerPO"),
    sortable: true,
  },
  {
    name: "salesRep",
    heading: i18n("columns.SalesRep"),
    formatFunc: extractName,
    minWidth: "8em",
    sortable: true,
  },
  {
    name: "linkedInvoices",
    heading: i18n("columns.Invoices"),
    formatFunc: extractLinkedTransactions,
  },
  {
    name: "linkedPickTickets",
    heading: i18n("columns.PickTickets"),
    formatFunc: extractLinkedTransactions,
  },
  {
    name: "linkedShipments",
    heading: i18n("columns.Shipments"),
    formatFunc: extractLinkedTransactions,
  },
  {
    name: "canShip",
    heading: i18n("columns.CanShip"),
    sortable: true,
  },
  {
    name: "DueDate",
    fieldName: "earliestDueDate",
    heading: i18n("columns.DueDate"),
    formatFunc: formatDate,
    minWidth: "8em",
    sortable: true,
  },
  {
    name: "orderStage",
    heading: i18n("columns.Stage"),
    formatFunc: extractName,
    sortable: true,
  },
  {
    name: "assignedToUser",
    heading: i18n("columns.AssignedTo"),
    formatFunc: extractName,
    minWidth: "10em",
    sortable: true,
  },
  {
    name: "channel",
    heading: i18n("columns.Channel"),
    formatFunc: extractName,
    sortable: true,
  },
  {
    name: "department",
    heading: i18n("columns.Department"),
    formatFunc: extractName,
    sortable: true,
  },
  {
    name: "customerNotes",
    heading: i18n("columns.CustomerNotes"),
    formatFunc: [parseHTML, truncate],
    minWidth: "25em",
    maxWidth: "25em",
  },
  {
    name: "billing",
    heading: i18n("columns.Billing"),
    formatFunc: formatCompanyInfo,
  },
  {
    name: "customerMessage",
    heading: i18n("columns.CustomerMessage"),
    formatFunc: [parseHTML, truncate],
    minWidth: "25em",
    maxWidth: "25em",
  },
  {
    name: "dropShip",
    heading: i18n("columns.DropShip"),
    formatFunc: formatBoolean,
  },
  {
    name: "hasSignature",
    heading: i18n("columns.Signature"),
    formatFunc: formatBooleanToYesOrBlank,
  },
  {
    name: "linkedPayments",
    heading: i18n("columns.Payments"),
    formatFunc: extractLinkedTransactions,
    minWidth: "10em",
  },
];

export function getEmptyRecord(settings) {
  return {
    archived: false,
    assignedToUser: null,
    billing: {
      company: null,
      contact: null,
      phone: null,
      email: null,
      addressName: null,
      addressType: null,
      address: {
        line1: null,
        line2: null,
        line3: null,
        line4: null,
        line5: null,
        city: null,
        stateProvince: null,
        postalCode: null,
        country: null,
      },
    },
    channel: null,
    closed: false,
    comment: null,
    customerMessage: null,
    customerNotes: null,
    customerPO: null,
    customFields: null,
    canShip: null,
    currency: settings.homeCurrencyRef,
    customer: null,
    date: new Date(),
    dateChanged: false,
    department: null,
    depositAmount: new Money(0),
    depositPercent: new Decimal(0),
    discountAmount: new Money(0),
    discountPercent: new Decimal(0),
    discountTaxable: settings.discountTaxable,
    documents: [],
    dropShip: false,
    earliestDueDate: null,
    exchangeRate: new Decimal(1.0),
    hasSignature: false,
    keys: null,
    lines: [],
    linkedTransaction: null,
    linkedInvoices: [],
    linkedShipments: [],
    location: null,
    number: "",
    openAmount: new Money(0),
    orderStage: null,
    originalDate: "",
    portalPassword: null,
    priority: null,
    salesRep: null,
    serial: null,
    shipping: {
      company: null,
      contact: null,
      phone: null,
      email: null,
      addressName: null,
      addressType: null,
      address: {
        line1: null,
        line2: null,
        line3: null,
        line4: null,
        line5: null,
        city: null,
        stateProvince: null,
        postalCode: null,
        country: null,
      },
    },
    shippingAmount: new Money(0),
    shippingTaxable: false,
    starred: 0,
    statusLink: null,
    statusMessage: null,
    subTotal: new Money(0),
    summaryOnly: false,
    syncToken: 1,
    taxAmount: new Money(0),
    taxCode: settings.defaultTaxCode,
    taxPercent: new Decimal(0),
    terms: null,
    total: new Money(0),
    transactionLocationQuickBooks: null,
    values: null,
  };
}

export const LINE_ITEM_METADATA = {
  lineNumber: {},
  item: {
    type: TYPES.reference,
    editStr: inventoryItemTypeEditStrFunction,
    initialValue: { id: null, name: null, description: null },
  },
  class: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  bin: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  jobWorkcenter: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  taxCode: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  description: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  quantity: {
    type: TYPES.decimal,
    placesRight: DEFAULT_DECIMALS_UNROUNDED,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  weight: {
    type: TYPES.decimal,
    placesRight: DEFAULT_DECIMALS_UNROUNDED,
    editStr: numberTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  weightunit: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  volume: {
    type: TYPES.decimal,
    placesRight: DEFAULT_DECIMALS_UNROUNDED,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  volumeunit: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  unitprice: {
    type: TYPES.money,
    currency: true,
    placesRight: DEFAULT_DECIMALS_UNROUNDED,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Money(0),
  },
  amount: {
    type: TYPES.money,
    currency: true,
    placesRight: DEFAULT_DECIMALS_ROUNDED,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Money(0),
  },
  altAmount: {
    type: TYPES.money,
    currency: true,
    placesRight: DEFAULT_DECIMALS_UNROUNDED,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Money(0),
  },
  cost: {
    type: TYPES.money,
    currency: true,
    placesRight: DEFAULT_DECIMALS_UNROUNDED,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Money(0),
  },
  uom: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  serials: {
    type: TYPES.arrayOfReferences,
    editStr: arrayOfReferencesTypeEditStrFunction,
    initialValue: null,
  },
  lot: {
    type: TYPES.reference,
    editStr: textTypeEditStrFunction,
  },
  linkedTransaction: {
    type: TYPES.linkedTransaction,
    editStr: linkedTransactionTypeEditStrFunction,
  },
  linkedTransactionRefNumber: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  linkedTransactionLineNumber: {
    type: TYPES.number,
    editStr: numberTypeEditStrFunction,
  },
  picked: {
    type: TYPES.decimal,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  shipped: {
    type: TYPES.decimal,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  invoiced: {
    type: TYPES.decimal,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  produced: {
    type: TYPES.decimal,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  returned: {
    type: TYPES.decimal,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  listPrice: {
    type: TYPES.money,
    currency: true,
    placesRight: DEFAULT_DECIMALS_UNROUNDED,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Money(0),
  },
  margin: {
    type: TYPES.decimal,
    currency: true,
    placesRight: DEFAULT_DECIMALS_ROUNDED,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  percentdiscount: {
    type: TYPES.decimal,
    placesRight: DEFAULT_DECIMALS_ROUNDED,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  duedate: {
    type: TYPES.date,
    editStr: dateTypeEditStrFunction,
    initialValue: () => new Date(),
  },
  available: {
    placesRight: DEFAULT_DECIMALS_ROUNDED,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  itemDetails: { initialValue: {} },
  relatedRecords: { initialValue: {} },
};

const columnArray = Object.entries(columnInfo);
const defaultColumnInfo = columnArray.filter((col) => col[1].required);
export const defaultColumns = defaultColumnInfo.map((col) => col[0]);

export const FIELDS_TO_TRANSFORM = {
  DECIMAL_FIELDS: [
    "exchangeRate",
    "depositPercent",
    "discountPercent",
    "taxPercent",
  ],
  MONEY_FIELDS: [
    "depositAmount",
    "discountAmount",
    "openAmount",
    "taxAmount",
    "total",
    "shippingAmount",
    "subTotal",
  ],
  DATE_FIELDS: ["earliestDueDate"],
  DATETIME_FIELDS: [],
  NON_UTC_DATETIME_FIELDS: ["date"],
};

export const REFERENCE_FIELDS = ["customer"];

export const LINE_ACTIONS = [
  ["quickView", "pdf", "email"],
  ["pickTicket", "shipment"],
  "invoice",
  "purchaseOrder",
  "transfer",
  ["job", "workOrder"],
  "profitLoss",
  "template",
  ["RMA", "return"],
  "archive",
  "openClose",
  "delete",
  "history",
];
export const BATCH_ACTIONS = [
  ["pdf", "email"],
  "delete",
  "archive",
  "openClose",
  "ediImport",
  "invoices",
];

export const ITEM_CALCULATED_FIELDS = [
  "available",
  "salesPrice",
  "purchaseCost",
];

export const QUICK_VIEW_CONFIG = {
  titleSetting: "salesOrderTitle",
  footerSetting: "salesOrderFooter",
  showStatusLink: true,
  isSalesTransaction: true,
  addressConfig: [
    { name: "billing", label: i18n("address.BillTo") },
    { name: "shipping", label: i18n("address.ShipTo") },
  ],
  tableConfig: [{ type: "lines" }],
  headerFields: [
    {
      name: "date",
      formatFunc: formatDateTimeToDate,
      label: i18n("columns.Date"),
    },
    {
      name: "number",
      label: i18n("frmLabel.OrderNumber"),
      defaultValue: NO_REF_NUMBER_STRING,
    },
    {
      name: "terms",
      formatFunc: extractName,
      label: i18n("frmLabel.Terms"),
    },
    {
      name: "salesRep",
      formatFunc: extractName,
      label: i18n("frmLabel.SalesRep"),
    },
  ],
  fields: [
    {
      name: "customerPO",
      label: i18n("frmLabel.CustomerPO"),
    },
    {
      name: "customerMessage",
      label: i18n("frmLabel.CustomerMessage"),
    },
  ],
  lines: [
    {
      name: "item",
      formatFunc: formatLinkableItem,
      heading: i18n("columns.Item"),
      align: "left",
      isItemField: true,
    },
    {
      name: "description",
      formatFunc: extractDescription,
      heading: i18n("columns.Description"),
      align: "left",
    },
    {
      name: "quantity",
      formatFunc: formatQuantityWithUom,
      heading: i18n("columns.Quantity"),
      align: "right",
    },
    {
      name: "unitprice",
      formatFunc: formatMoney,
      heading: i18n("columns.Rate"),
      align: "right",
    },
    {
      name: "amount",
      formatFunc: formatMoney,
      heading: i18n("columns.Amount"),
      align: "right",
      isTotalColumn: true,
    },
    {
      name: "picked",
      formatFunc: formatDecimal,
      heading: i18n("columns.Picked"),
      align: "right",
    },
    {
      name: "shipped",
      formatFunc: formatDecimal,
      heading: i18n("columns.Shipped"),
      align: "right",
    },
    {
      name: "invoiced",
      formatFunc: formatDecimal,
      heading: i18n("columns.Invoiced"),
      align: "right",
    },
    {
      name: "duedate",
      formatFunc: formatDate,
      heading: i18n("columns.DueDate"),
    },
  ],
  tableTotals: [
    {
      label: i18n("quickView.SUBTOTAL"),
      formatFunc: formatMoneyWithAdornments,
      field: "subTotal",
    },
    {
      label: i18n("quickView.DISCOUNT"),
      formatFunc: formatMoneyWithAdornments,
      field: "discountAmount",
    },
    {
      label: i18n("quickView.SHIPPING"),
      formatFunc: formatMoneyWithAdornments,
      field: "shippingAmount",
    },
    {
      label: i18n("quickView.TAX"),
      formatFunc: formatMoneyWithAdornments,
      field: "taxAmount",
    },
    {
      label: i18n("quickView.TOTAL"),
      formatFunc: formatQuickViewTotal,
      alwaysShow: true,
    },
  ],
};
