// See services/sosInventoryService/README.md for information about the structure
// of schema.js files.
import { Decimal, Money } from "classes/DecimalClasses";

import { i18n } from "services/i18nService";
import {
  extractName,
  formatDocumentInfo,
  formatDateTimeToDate,
  formatLinkField,
  truncate,
  formatSync,
  formatBooleanToYesOrBlank,
  extractDescription,
  formatQuantityWithUom,
  getQuantityTotal,
  formatLinkableItem,
} from "services/utility/formatting";
import { formatMoneyWithAdornments } from "services/utility/formatting";
import {
  inventoryItemTypeEditStrFunction,
  textTypeEditStrFunction,
  decimalTypeEditStrFunction,
  numberTypeEditStrFunction,
  referenceTypeEditStrFunction,
  arrayOfReferencesTypeEditStrFunction,
} from "services/utility/list";
import { parseHTML } from "services/utility/parsing";
import { starColumn } from "services/utility/schema";
import { TYPES } from "services/utility/schema";

import { NO_REF_NUMBER_STRING } from "appConstants";
import { DEFAULT_DECIMALS_UNROUNDED } from "appConstants";

export const columnInfo = [
  starColumn,
  {
    name: "number",
    fixed: true,
    heading: i18n("columns.ReferenceNumber"),
    formatFunc: formatLinkField,
    minWidth: "7em",
    linkField: true,
    sortable: true,
  },
  {
    name: "date",
    heading: i18n("columns.TxnDate"),
    formatFunc: formatDateTimeToDate,
    defaultSort: true,
    sortDirection: "asc",
    minWidth: "8em",
    sortable: true,
    default: true,
  },
  {
    name: "fromLocation",
    heading: i18n("columns.From"),
    formatFunc: extractName,
    minWidth: "8em",
    sortable: true,
    default: true,
  },
  {
    name: "toLocation",
    heading: i18n("columns.To"),
    formatFunc: extractName,
    minWidth: "8em",
    sortable: true,
    default: true,
  },
  {
    name: "id",
    heading: i18n("columns.System"),
    sortDirection: "asc",
    minWidth: "7em",
    align: "right",
    sortable: true,
    default: true,
  },
  {
    name: "comment",
    heading: i18n("columns.Comment"),
    formatFunc: [parseHTML, truncate],
    minWidth: "25em",
    maxWidth: "25em",
    sortable: true,
    default: true,
  },
  {
    name: "documents",
    heading: i18n("columns.Documents"),
    formatFunc: formatDocumentInfo,
    minWidth: "15em",
    default: true,
  },
  {
    name: "sync",
    fieldName: "syncMessage",
    heading: i18n("columns.Sync"),
    formatFunc: formatSync,
    sortable: true,
  },
  {
    name: "department",
    heading: i18n("columns.Department"),
    formatFunc: extractName,
    sortable: true,
  },
  {
    name: "shippingMethod",
    heading: i18n("columns.ShipMethod"),
    formatFunc: extractName,
  },
  {
    name: "trackingNumber",
    heading: i18n("columns.TrackingNumber"),
  },
  {
    name: "shippingAmount",
    heading: i18n("columns.ShippingCost"),
    formatFunc: formatMoneyWithAdornments,
  },
  {
    name: "hasSignature",
    heading: i18n("columns.Signature"),
    formatFunc: formatBooleanToYesOrBlank,
  },
];

export function getEmptyRecord() {
  return {
    starred: 0,
    syncToken: 0,
    number: "",
    date: new Date(),
    total: new Decimal(0),
    fromLocation: null,
    toLocation: null,
    department: null,
    shippingMethod: null,
    trackingNumber: null,
    shippingAmount: new Money(0),
    createBillForShippingAmount: false,
    comment: null,
    customFields: null,
    archived: false,
    summaryOnly: false,
    hasSignature: false,
    documents: [],
    keys: null,
    values: null,
    lines: [],
  };
}

export const LINE_ITEM_METADATA = {
  lineNumber: {},
  item: {
    type: TYPES.reference,
    editStr: inventoryItemTypeEditStrFunction,
    initialValue: { id: null, name: null, description: null },
  },
  description: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  quantity: {
    type: TYPES.decimal,
    placesRight: DEFAULT_DECIMALS_UNROUNDED,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  weight: {
    type: TYPES.decimal,
    placesRight: DEFAULT_DECIMALS_UNROUNDED,
    editStr: numberTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  weightunit: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  volume: {
    type: TYPES.decimal,
    placesRight: DEFAULT_DECIMALS_UNROUNDED,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  class: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  jobWorkcenter: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  volumeunit: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  uom: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  serials: {
    type: TYPES.arrayOfReferences,
    editStr: arrayOfReferencesTypeEditStrFunction,
    initialValue: null,
  },
  lot: {
    type: TYPES.reference,
    editStr: textTypeEditStrFunction,
  },
  fromBin: { type: TYPES.reference, editStr: referenceTypeEditStrFunction },
  toBin: { type: TYPES.reference, editStr: referenceTypeEditStrFunction },
  onhand: {
    type: TYPES.itemField,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  itemDetails: { initialValue: {} },
  relatedRecords: { initialValue: {} },
};

export const FIELDS_TO_TRANSFORM = {
  DECIMAL_FIELDS: [],
  MONEY_FIELDS: ["total", "shippingAmount"],
  DATE_FIELDS: [],
  DATETIME_FIELDS: ["date"],
  NON_UTC_DATETIME_FIELDS: [],
};

export const LINE_ACTIONS = [
  ["quickView", "pdf", "email"],
  "template",
  "shipUPS",
  "archive",
  "delete",
  "history",
];
export const BATCH_ACTIONS = [["pdf", "email"], "delete", "archive"];
export const REFERENCE_FIELDS = ["toLocation", "fromLocation"];

export const ITEM_CALCULATED_FIELDS = ["onhand"];

export const QUICK_VIEW_CONFIG = {
  tableConfig: [{ type: "lines" }],
  headerFields: [
    {
      name: "date",
      formatFunc: formatDateTimeToDate,
      label: i18n("columns.Date"),
    },
    {
      name: "number",
      label: i18n("frmLabel.TransferNumber"),
      defaultValue: NO_REF_NUMBER_STRING,
    },
    {
      name: "fromLocation",
      formatFunc: extractName,
      label: i18n("columns.From"),
    },
    {
      name: "toLocation",
      formatFunc: extractName,
      label: i18n("columns.To"),
    },
  ],
  fields: [],
  lines: [
    {
      name: "item",
      formatFunc: formatLinkableItem,
      heading: i18n("columns.Item"),
      align: "left",
      isItemField: true,
    },
    {
      name: "description",
      formatFunc: extractDescription,
      heading: i18n("columns.Description"),
      align: "left",
    },
    {
      name: "quantity",
      formatFunc: formatQuantityWithUom,
      footerFunc: getQuantityTotal,
      heading: i18n("columns.Quantity"),
      align: "right",
    },
  ],
};
