import { useState, useEffect } from "react";

import { getAllReference } from "services/sosInventoryService/sosApi";

import { getObjectFromTypeString } from "appConstants";

export function useFormTemplates(objectType, externalTemplates) {
  const [records, setRecords] = useState(externalTemplates);

  useEffect(() => {
    if (!records) {
      async function retrieveRecords() {
        const templates = await getAllReference("formtemplate");
        const matchingTemplates = templates.filter(
          ({ form: { id } }) =>
            getObjectFromTypeString(id).fullString === objectType
        );
        setRecords(matchingTemplates);
      }
      retrieveRecords();
    }
  }, [objectType, records]);

  return records;
}
