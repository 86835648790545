import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Paper } from "@mui/material";

import { Header } from "components/ListPage/Header";
import { FormErrors } from "components/utility/FormErrors";

import { getUserListSettings } from "services/sosInventoryService/sosApi";
import { getListPageRecords } from "services/sosInventoryService/sosApi";
import { setPageDirty } from "services/utility/edit";
import { LOCALIZATION } from "services/utility/localization";
import { getPageHeight, getPageWidth } from "services/utility/misc";

import { useErrors } from "hooks/useErrors";

import { List } from "views/Notifications/List/List";
import { Widgets } from "views/Notifications/List/Widgets";

import {
  loadingIndicatorOn,
  loadingIndicatorOff,
} from "globalState/loadingSlice";

import { DEFAULT_LIST_MAX_RESULTS } from "appConstants";

const OBJECT_TYPE = "notification";

export function NotificationList() {
  // OTHER SETUP
  const dispatch = useDispatch();
  const userId = useSelector(
    (state) => state.userCompanySettings.company.userId
  );
  const { dateFormat } = LOCALIZATION;

  //List page state
  const [records, setRecords] = useState(null);
  const [totalRecords, setTotalRecords] = useState(null);

  // UI STATE
  const [selectAllState, setSelectAllState] = useState(false);
  const [retrievalParams, setRetrievalParams] = useState(null);
  const [checkedIds, setCheckedIds] = useState([]);
  const recordsChanged = useSelector((state) => state.recordsChanged);
  const { errors, setErrors } = useErrors();

  // load saved user settings when component loads
  useEffect(() => {
    (async () => {
      const retrievedSettings = await getUserListSettings(
        userId,
        OBJECT_TYPE,
        dateFormat
      );
      const maxResults =
        retrievedSettings?.maxResults || DEFAULT_LIST_MAX_RESULTS;
      setRetrievalParams((prev) => ({
        ...prev,
        maxResults,
        startingRecord: 1,
      }));
    })();
  }, [userId, dateFormat]);

  // fetches list data
  useEffect(() => {
    (async () => {
      if (!retrievalParams) {
        return;
      }
      dispatch(loadingIndicatorOn());
      setPageDirty(false);
      let response;
      if (!retrievalParams) {
        dispatch(loadingIndicatorOff());
        return;
      }
      response = await getListPageRecords(OBJECT_TYPE, {
        start: retrievalParams.startingRecord,
        maxresults: Number(retrievalParams.maxResults), //V8BACK: needs to be saved as number to work backwards with v8
      });
      if (response) {
        setRecords(response.data);
        setTotalRecords(response.totalCount);
        dispatch(loadingIndicatorOff());
      }
    })();
    return () => dispatch(loadingIndicatorOff());
  }, [recordsChanged, retrievalParams, dispatch]);

  if (!records) {
    return null;
  }

  return (
    <Box
      sx={{
        p: 2,
        pb: 1,
        display: "grid",
        minWidth: getPageWidth(),
        maxHeight: getPageHeight(),
      }}
    >
      <Box>
        <Header
          objectType={OBJECT_TYPE}
          totalRecords={totalRecords}
          retrievalParams={retrievalParams}
        />
        <FormErrors errors={errors} setErrors={setErrors} />
      </Box>
      <Box>
        <Paper
          elevation={1}
          sx={{
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
            borderBottomColor: "lineSeparatorMinor",
          }}
        >
          <Widgets
            checkedIds={checkedIds}
            setCheckedIds={setCheckedIds}
            setSelectAllState={setSelectAllState}
            retrievalParams={retrievalParams}
            setRetrievalParams={setRetrievalParams}
            totalRecords={totalRecords}
          />
        </Paper>
      </Box>
      <Box boxShadow={1} sx={{ height: "100%", overflow: "auto" }}>
        <Paper
          sx={{
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            height: "100%",
          }}
        >
          <List
            objectType={OBJECT_TYPE}
            records={records}
            checkedIds={checkedIds}
            selectAllIds={() => setCheckedIds(records.map(({ id }) => id))}
            selectAllState={selectAllState}
            setSelectAllState={setSelectAllState}
            selectNoIds={() => setCheckedIds([])}
            setCheckedIds={setCheckedIds}
          />
        </Paper>
      </Box>
    </Box>
  );
}
