import { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import Dialog from "@mui/material/Dialog";

import { i18n } from "services/i18nService";
import { setPageDirty } from "services/utility/edit";

import { useCustomNavigate } from "hooks/useCustomNavigate";

import { closeModal } from "globalState/modalSlice";

import { OBJECT_EDIT, NON_FULL_SCREEN_EDIT_MODALS } from "editConfig";

export function Modal() {
  const dispatch = useDispatch();
  const modalState = useSelector((state) => state.modal);
  const { open, id, objectType, modalProps } = modalState;
  const descriptionElementRef = useRef(null);
  const dirtyPage = useSelector((state) => state.dirtyPage);
  const customNavigate = useCustomNavigate(objectType);

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const IS_FULL_SCREEN = !NON_FULL_SCREEN_EDIT_MODALS.includes(objectType);

  const handleClose = useCallback(() => {
    if (!dirtyPage || window.confirm(i18n("global.LeaveThisPage"))) {
      dispatch(closeModal());
      setPageDirty(false);
      customNavigate.goToList();
    }
  }, [dirtyPage, dispatch, customNavigate]);

  const EditPage = OBJECT_EDIT[objectType];

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      fullWidth
      fullScreen={IS_FULL_SCREEN}
      maxWidth={IS_FULL_SCREEN ? "xl" : "md"}
      sx={{
        height: "100%",
        "& .MuiDialog-paper": {
          mb: IS_FULL_SCREEN ? "-3vh" : null,
          minHeight: IS_FULL_SCREEN ? "96vh" : "93vh",
          maxHeight: IS_FULL_SCREEN ? "96vh" : "93vh",
          "@media print": { minHeight: "100%", maxHeight: "100%" },
        },
      }}
    >
      {EditPage && (
        <EditPage
          key={open}
          onClose={handleClose}
          id={id}
          objectType={objectType}
          {...modalProps}
        />
      )}
    </Dialog>
  );
}
