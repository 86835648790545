import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Card, CardContent } from "@mui/material";

import { Layout } from "Layout";

import { CustomFields } from "components/CustomFields/CustomFields";
import { EditModalHeader } from "components/EditModalHeader";
import { FrmFieldGrid } from "components/FrmFieldGrid";
import { ObjectEditDialogContent } from "components/ObjectEditDialogContent";
import { QuickAddCustomer } from "components/QuickAddCustomer";
import { LineItemTotals } from "components/formFields/Totals/LineItemTotals";
import { BillingAddress } from "components/formFields/address/sales/Billing";
import { CardHeader } from "components/formFields/formCards/CardHeader";
import {
  FrmComment,
  FrmDepartment,
  FrmNumber,
  FrmCustomerWithAdd,
  FrmCustomerNotes,
  FrmCustomerMessage,
  FrmChannel,
} from "components/formFields/frm";
import { FrmCurrencyExchangeRate } from "components/formFields/frm";
import { FrmLocation } from "components/formFields/frm";
import { FrmDepositAccount } from "components/formFields/frm/FrmDepositAccount";
import { FrmFsPaymentMethod } from "components/formFields/frm/FrmFsPaymentMethod";
import { DateTimePicker } from "components/html/Input/DateTimePicker";
import { FixedLoadingIndicator } from "components/utility/FixedLoadingIndicator";
import { FormErrors } from "components/utility/FormErrors";
import { Loading } from "components/utility/Loading";
import { VSpace } from "components/utility/VSpace";
import { WarningModal } from "components/utility/WarningModal";

import { i18n } from "services/i18nService";
import {
  updateTotals,
  ZERO_TOTALS,
} from "services/sosInventoryService/payment/updateTotals";
import { getCustomerAddresses } from "services/sosInventoryService/salesTransaction/domainLogic";
import {
  fullAddNewCustomer,
  quickAddNewCustomer,
} from "services/utility/controller";
import { setPageDirty } from "services/utility/edit";
import { handleRefreshExchangeRate } from "services/utility/edit";

import { useCustomFieldDefsAsObject } from "hooks/useCustomFieldDefinitions";
import { useCustomers } from "hooks/useCustomers";
import { useErrors } from "hooks/useErrors";
import { useLineHandler } from "hooks/useLineHandler";
import { useRelated } from "hooks/useRelated";

import { LineItems } from "views/Payments/Payment/LineItems";
import {
  getTransaction,
  updateTransaction,
} from "views/Payments/Payment/controller";

import {
  editModalLoadingIndicatorOn,
  editModalLoadingIndicatorOff,
} from "globalState/loadingSlice";

import { theme } from "SosTheme";
import { OBJECT_TYPES } from "appConstants";
import { EMPTY_LINE_ITEM } from "editConfig";

const OBJECT_TYPE = OBJECT_TYPES.PAYMENT.fullString;
const CUSTOM_FIELD_OBJECT_TYPES = [
  OBJECT_TYPE,
  OBJECT_TYPES.CUSTOMER.fullString,
];

export function Payment(props) {
  const { id, onClose, newFromId, newFromObjectType } = props;

  // TRANSACTION STATE
  const [record, setRecord] = useState();
  const [lines, lineHandler] = useLineHandler();
  const [relatedRecords, setRelatedRecords] = useState({});
  const { customer } = relatedRecords;

  const addresses = useMemo(
    () => (customer?.id ? getCustomerAddresses(customer) : null),
    [customer]
  );

  // UI STATE
  const { errors, setErrors, isInError } = useErrors();
  const [clearLines, setClearLines] = useState(false);
  const customFieldDefs = useCustomFieldDefsAsObject(CUSTOM_FIELD_OBJECT_TYPES);
  const [addNewCustomer, setAddNewCustomer] = useState(false);
  const [newCustomerName, setNewCustomerName] = useState("");
  const [customerAdded, setCustomerAdded] = useState(0);
  const [totals, setTotals] = useState(ZERO_TOTALS);
  const loadingState = useSelector((state) => state.loading.editModal);

  // UPDATERS FOR CONTROLLER TO UPDATE STATE
  const UPDATERS = useMemo(
    () => ({
      setRecord,
      lineHandler,
      setRelatedRecords,
      setErrors,
    }),
    [lineHandler, setErrors]
  );

  // RELATED RECORD LISTS
  const classes = useRelated("class");
  const customers = useCustomers(customerAdded);
  const currencies = useRelated("currency");
  const paymentMethods = useRelated("paymentmethod");

  // SETTINGS
  const departmentEnabled = useSelector(
    (state) => state.userCompanySettings.settings.departmentEnabled
  );

  // OTHER SETUP
  const dispatch = useDispatch();

  const CURRENT_STATE = { record, lines, relatedRecords, paymentMethods };

  // LOAD INITIAL TRANSACTION STATE
  useEffect(() => {
    async function _getTransaction() {
      dispatch(editModalLoadingIndicatorOn());
      await getTransaction(
        id,
        newFromId,
        newFromObjectType,
        UPDATERS,
        customFieldDefs[OBJECT_TYPE]
      );
      dispatch(editModalLoadingIndicatorOff());
    }
    if (customFieldDefs) {
      _getTransaction();
    }
  }, [
    dispatch,
    id,
    newFromId,
    newFromObjectType,
    lineHandler,
    UPDATERS,
    customFieldDefs,
  ]);

  // HANDLE ALL USER INPUT
  function handleInputFieldChange(field, newValue) {
    setPageDirty();
    updateTransaction(
      field,
      newValue,
      customFieldDefs[OBJECT_TYPE],
      CURRENT_STATE,
      UPDATERS,
      currencies
    );
  }

  // ANCILLARY UI FUNCTIONS

  // update totals whenever the line items change
  useEffect(() => updateTotals(lines, setTotals), [lines]);

  // quick add customer
  async function handleQuickAddNewCustomer(name) {
    dispatch(editModalLoadingIndicatorOn());

    await quickAddNewCustomer(name, UPDATERS);

    // force a re-get of the customer list, since we know one was just added
    setCustomerAdded((prev) => prev + 1);

    dispatch(editModalLoadingIndicatorOff());
    setPageDirty();
    setAddNewCustomer(false);
  }

  // full add customer
  async function handleFullAddNewCustomer(newCustomer) {
    await fullAddNewCustomer(
      newCustomer,
      customFieldDefs.customer,
      customFieldDefs[OBJECT_TYPE],
      record.customFields,
      UPDATERS
    );

    // force a re-get of the customer list, since we know one was just added
    setCustomerAdded((prev) => prev + 1);

    setPageDirty();
    setAddNewCustomer(false);
  }

  // clear lines
  function doClearLines() {
    setPageDirty();
    lineHandler({ type: "clear", blankLine: EMPTY_LINE_ITEM[OBJECT_TYPE] });
    setClearLines(false);
  }

  return (
    <>
      <EditModalHeader
        record={record}
        setRecord={setRecord}
        objectType={OBJECT_TYPE}
        relatedRecords={relatedRecords}
        lines={lines}
        setErrors={setErrors}
        errors={errors}
        text={record?.number}
        handleClose={onClose}
        customerId={record?.customer?.id}
      />
      {loadingState && <Loading />}
      <ObjectEditDialogContent>
        <Layout
          pageTitle={
            id
              ? `${i18n("objectType.payment.Sentence")} ${record?.number || ""}`
              : i18n("objectType.payment.New")
          }
        >
          <div style={{ margin: theme.spacing(2) }}>
            <FormErrors errors={errors} setErrors={setErrors} />
            {record ? (
              <>
                <FrmFieldGrid gridAutoFlow="dense">
                  <div style={{ gridColumn: "1 / 2" }}>
                    <DateTimePicker
                      name="date"
                      value={record.date}
                      label={i18n("frmLabel.DateTime")}
                      onValueChange={handleInputFieldChange}
                      error={isInError("date")}
                    />
                  </div>
                  <div style={{ gridColumn: "2 / 2" }}>
                    <FrmNumber
                      recordId={record.id}
                      label={i18n("payment.labelNumber")}
                      value={record.number}
                      onValueChange={handleInputFieldChange}
                      error={isInError("number")}
                    />
                  </div>
                  <div style={{ gridColumn: "2 / 2" }}>
                    <FrmLocation
                      name="location"
                      value={record.location}
                      label={i18n("frmLabel.Location")}
                      onValueChange={handleInputFieldChange}
                      sx={{ width: "25rem", maxWidth: "100%" }}
                    />
                  </div>
                  <div style={{ gridColumn: "1 / 2" }}>
                    <FrmCustomerWithAdd
                      value={record.customer}
                      onValueChange={handleInputFieldChange}
                      setAddNewCustomer={setAddNewCustomer}
                      setNewCustomerName={setNewCustomerName}
                      error={isInError("customer")}
                      customers={customers}
                    />
                  </div>
                  <div style={{ gridColumn: "1 / 2" }}>
                    <FrmCustomerNotes
                      value={record.customerNotes}
                      onValueChange={handleInputFieldChange}
                    />
                  </div>
                </FrmFieldGrid>
                <VSpace space={1} />
                <FrmFieldGrid gridAutoFlow="dense">
                  <div style={{ maxWidth: "30rem" }}>
                    <BillingAddress
                      locations={addresses?.billing}
                      value={record.billing}
                      handleInputFieldChange={handleInputFieldChange}
                      label={i18n("address.Customer")}
                    />
                  </div>
                  <div style={{ maxWidth: "30rem" }}>
                    <Card variant="outlined">
                      <CardContent>
                        <CardHeader
                          text={i18n("frmLabel.PaymentMethod")}
                          gutterBottom={false}
                          sx={{ marginBottom: "1rem" }}
                        />
                        <FrmFsPaymentMethod
                          recordId={record.id}
                          label={i18n("frmLabel.Type")}
                          showKeepOnFile={record.showKeepOnFile}
                          hasCardOnFile={record.hasCardOnFile}
                          paymentInfo={record.paymentInfo}
                          storeCustomerToken={record.storeCustomerToken}
                          paymentMethod={record.paymentMethod}
                          checkNumber={record.checkNumber}
                          onValueChange={handleInputFieldChange}
                          postalCode={record.billing?.address?.postalCode}
                          contact={record.billing?.contact}
                          customer={record.customer}
                          options={paymentMethods}
                          error={isInError("paymentMethod")}
                        />
                      </CardContent>
                    </Card>
                  </div>
                </FrmFieldGrid>
                <VSpace space={2} />
                <FrmFieldGrid gridAutoFlow="dense">
                  <div style={{ gridColumn: "1 / 2" }}>
                    <FrmCurrencyExchangeRate
                      customerOrVendor={relatedRecords.customer}
                      record={record}
                      currencies={currencies}
                      currencyError={isInError("currency")}
                      exchangeRate={record.exchangeRate}
                      handleInputChange={handleInputFieldChange}
                      handleRefreshExchangeRate={() =>
                        handleRefreshExchangeRate(
                          currencies,
                          record,
                          handleInputFieldChange
                        )
                      }
                    />
                  </div>
                  <div style={{ gridColumn: "2 / 2" }} />
                </FrmFieldGrid>
                <VSpace space={2} />
                <LineItems
                  relatedRecords={relatedRecords}
                  objectType={OBJECT_TYPE}
                  lines={lines}
                  classes={classes}
                  lineHandler={lineHandler}
                  lineItemsErrors={errors.line}
                  setErrors={setErrors}
                  setClearLines={setClearLines}
                />
                <VSpace space={2} />
                <FrmFieldGrid gridAutoFlow="dense">
                  <div style={{ gridColumn: "1 / 2" }} />
                  <div style={{ gridColumn: "2 / 2" }}>
                    <LineItemTotals
                      value={totals.amount}
                      label={i18n("global.Amount")}
                    />
                  </div>
                </FrmFieldGrid>
                <VSpace space={2} />
                <FrmFieldGrid gridAutoFlow="dense">
                  <div style={{ gridColumn: "1 / 2" }}>
                    <FrmChannel
                      maxWidth="20rem"
                      onValueChange={handleInputFieldChange}
                      value={record?.channel}
                    />
                  </div>
                  {departmentEnabled && (
                    <div style={{ gridColumn: "2 / 2" }}>
                      <FrmDepartment
                        maxWidth="20rem"
                        value={record.department}
                        onValueChange={handleInputFieldChange}
                      />
                    </div>
                  )}
                  <FrmDepositAccount
                    value={record.depositAccount}
                    onValueChange={handleInputFieldChange}
                  />
                  <div style={{ gridColumn: "1 / 2", maxWidth: "100%" }}>
                    <FrmCustomerMessage
                      value={record.customerMessage}
                      onValueChange={handleInputFieldChange}
                    />
                  </div>
                  <div style={{ gridColumn: "1 / 2" }}>
                    <FrmComment
                      value={record.comment}
                      onValueChange={handleInputFieldChange}
                    />
                  </div>
                </FrmFieldGrid>
                <CustomFields
                  customFieldDefinitions={customFieldDefs[OBJECT_TYPE]}
                  customFields={record.customFields}
                  onValueChange={handleInputFieldChange}
                  setErrors={setErrors}
                />
              </>
            ) : (
              !isInError("record") && (
                <FixedLoadingIndicator text={`${i18n("global.Loading")}...`} />
              )
            )}
            <VSpace space={4} />
          </div>
          {record && (
            <>
              {!!addNewCustomer && (
                <QuickAddCustomer
                  open
                  initialNameValue={newCustomerName}
                  onClose={() => setAddNewCustomer(false)}
                  onQuickAdd={handleQuickAddNewCustomer}
                  onAdd={handleFullAddNewCustomer}
                />
              )}
              <WarningModal
                title={i18n("global.ClearLines")}
                message={i18n("global.clearLinesMessage")}
                open={clearLines}
                onOk={doClearLines}
                onClose={() => setClearLines(false)}
              />
            </>
          )}
        </Layout>
      </ObjectEditDialogContent>
    </>
  );
}
