import { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";

import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import { NavToggle } from "components/LeftNav/NavToggle";
import { menuNodes } from "components/LeftNav/sosMenuNodes";

import { i18n } from "services/i18nService";
import { filterNavigationMenu } from "services/utility/filters";

import { usePlans } from "hooks/usePlans";
import { usePrivileges } from "hooks/usePrivileges";

import { setSelectedMenuNode } from "globalState/leftNavSlice";
import { LIST_TABLE_OFFSET_MAX } from "globalState/leftNavSlice";

const sx = {
  topNode: {
    py: 0.5,
    pl: 2,
    margin: 0,
    "&:hover": { cursor: "pointer" },
    "&:hover svg": { fill: "accent" },
  },
  child: {
    margin: 0,
    marginLeft: 6,
    borderLeftWidth: "3px",
    borderLeft: "solid 3px",
    borderLeftColor: "tooltipBackground",
    "&:hover": { borderLeft: "solid 3px ", borderLeftColor: "accent" },
    "&.active": {
      borderLeft: "solid 3px ",
      borderLeftColor: "accent",
      "& span": { color: "accent" },
    },
  },
  text: { paddingLeft: 0, margin: 0 },
  icon: { minWidth: 0.24 },
};

export function LeftNavText() {
  const leftNavSelectedNode = useSelector(
    (state) => state.leftNav.selectedMenuNode
  );

  const restrictedSalesRep = useSelector(
    (state) => state.userCompanySettings.company.restrictedSalesRep
  );

  const dispatch = useDispatch();

  function handleClick(e) {
    const { id } = e.currentTarget;
    dispatch(setSelectedMenuNode(leftNavSelectedNode !== id ? id : null));
  }

  return (
    <List
      component="nav"
      sx={{ width: `${LIST_TABLE_OFFSET_MAX}rem`, padding: 0 }}
    >
      {menuNodes.map((node) => (
        <Fragment key={node.id}>
          {(!node.hideFromRestrictedSalesReps || !restrictedSalesRep) && (
            <NavEntry
              key={node.id}
              menuNode={node}
              onClick={handleClick}
              selectedNode={leftNavSelectedNode}
            />
          )}
        </Fragment>
      ))}
      <NavToggle listSx={sx.topNode} />
    </List>
  );
}

function NavEntry(props) {
  const { selectedNode, menuNode, onClick } = props;
  const { to, label, subNodes, Icon, id } = menuNode;

  const open = id === selectedNode;
  const parent = subNodes && subNodes.length > 0;
  const { hasPrivilegesOrIsAdmin } = usePrivileges();

  const { allowPlusFeatures, allowProFeatures } = usePlans();

  const { settings } = useSelector((state) => state.userCompanySettings);

  const filteredNodes = filterNavigationMenu(
    subNodes,
    allowPlusFeatures,
    allowProFeatures,
    hasPrivilegesOrIsAdmin,
    settings
  );

  return (
    <>
      <ListItem
        dense
        id={id}
        to={to}
        component={to ? NavLink : null}
        onClick={onClick}
        data-testing={id}
        sx={sx.topNode}
      >
        <ListItemIcon sx={sx.icon}>
          <Icon sx={{ width: "25px", height: "25px" }} />
        </ListItemIcon>
        <ListItemText
          primary={i18n(label)}
          sx={sx.text}
          primaryTypographyProps={{ sx: { fontWeight: "fontWeightMedium" } }}
        />

        {parent ? open ? <ExpandLess /> : <ExpandMore /> : ""}
      </ListItem>
      {parent && (
        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
          data-testing="nodeContainer"
        >
          {filteredNodes.map((node) => {
            return (
              <ListItem
                key={node.label}
                dense
                sx={sx.child}
                component={NavLink}
                to={node.path}
                data-testing={node.label}
              >
                <ListItemText
                  primary={i18n(node.label)}
                  sx={sx.text}
                  primaryTypographyProps={{
                    sx: { fontWeight: "fontWeightLight" },
                  }}
                />
              </ListItem>
            );
          })}
        </Collapse>
      )}
    </>
  );
}
