import {
  REFERENCE_FIELDS as ADJUSTMENT_REFERENCE_FIELDS,
  getEmptyRecord as getEmptyAdjustmentRecord,
  ITEM_CALCULATED_FIELDS as ADJUSTMENT_ITEM_CALCULATED_FIELDS,
} from "services/sosInventoryService/adjustment/schema";
import { getEmptyRecord as getEmptyBinRecord } from "services/sosInventoryService/bin/schema";
import {
  REFERENCE_FIELDS as BUILD_REFERENCE_FIELDS,
  getEmptyRecord as getEmptyBuildRecord,
  ITEM_CALCULATED_FIELDS as BUILD_ITEM_CALCULATED_FIELDS,
} from "services/sosInventoryService/build/schema";
import { getEmptyRecord as getEmptyChannelRecord } from "services/sosInventoryService/channel/schema";
import { getEmptyRecord as getEmptyCustomField } from "services/sosInventoryService/customField/schema";
import { getEmptyRecord as getEmptyCustomerRecord } from "services/sosInventoryService/customer/schema";
import { getEmptyRecord as getEmptyCustomerMessageRecord } from "services/sosInventoryService/customerMessage/schema";
import {
  REFERENCE_FIELDS as ESTIMATE_REFERENCE_FIELDS,
  getEmptyRecord as getEmptyEstimateRecord,
  ITEM_CALCULATED_FIELDS as ESTIMATE_ITEM_CALCULATED_FIELDS,
} from "services/sosInventoryService/estimate/schema";
import { getEmptyRecord as getEmptyFormTemplateRecord } from "services/sosInventoryService/formTemplate/schema";
import {
  REFERENCE_FIELDS as INVOICE_REFERENCE_FIELDS,
  getEmptyRecord as getEmptyInvoiceRecord,
  ITEM_CALCULATED_FIELDS as INVOICE_ITEM_CALCULATED_FIELDS,
} from "services/sosInventoryService/invoice/schema";
import { REFERENCE_FIELDS as ITEM_REFERENCE_FIELDS } from "services/sosInventoryService/item/schema";
import { getEmptyRecord as getEmptyItemRecord } from "services/sosInventoryService/item/schema";
import {
  REFERENCE_FIELDS as ITEM_RECEIPT_REFERENCE_FIELDS,
  getEmptyRecord as getEmptyItemReceiptRecord,
  OTHER_COST_REFERENCE_FIELDS as ITEM_RECEIPT_OTHER_COST_REFERENCE_FIELDS,
  ITEM_CALCULATED_FIELDS as ITEM_RECEIPT_ITEM_CALCULATED_FIELDS,
} from "services/sosInventoryService/itemReceipt/schema";
import {
  REFERENCE_FIELDS as JOB_REFERENCE_FIELDS,
  getEmptyRecord as getEmptyJobRecord,
  ITEM_CALCULATED_FIELDS as JOB_ITEM_CALCULATED_FIELDS,
} from "services/sosInventoryService/job/schema";
import { getEmptyRecord as getEmptyLocationRecord } from "services/sosInventoryService/location/schema";
import { getEmptyRecord as getEmptyLotRecord } from "services/sosInventoryService/lot/schema";
import { getEmptyRecord as getEmptyOrderStageRecord } from "services/sosInventoryService/orderStage/schema";
import {
  REFERENCE_FIELDS as PICK_TICKET_REFERENCE_FIELDS,
  getEmptyRecord as getEmptyPickTicketRecord,
  ITEM_CALCULATED_FIELDS as PICK_TICKET_ITEM_CALCULATED_FIELDS,
} from "services/sosInventoryService/pickTicket/schema";
import { getEmptyRecord as getEmptyPriorityRecord } from "services/sosInventoryService/priority/schema";
import {
  REFERENCE_FIELDS as PROCESS_REFERENCE_FIELDS,
  getEmptyRecord as getEmptyProcessRecord,
  ITEM_CALCULATED_FIELDS as PROCESS_ITEM_CALCULATED_FIELDS,
} from "services/sosInventoryService/process/schema";
import {
  REFERENCE_FIELDS as PURCHASE_ORDER_REFERENCE_FIELDS,
  getEmptyRecord as getEmptyPurchaseOrderRecord,
  ITEM_CALCULATED_FIELDS as PURCHASE_ORDER_ITEM_CALCULATED_FIELDS,
} from "services/sosInventoryService/purchaseOrder/schema";
import {
  REFERENCE_FIELDS as RENTAL_REFERENCE_FIELDS,
  getEmptyRecord as getEmptyRentalRecord,
  ITEM_CALCULATED_FIELDS as RENTAL_ITEM_CALCULATED_FIELDS,
} from "services/sosInventoryService/rental/schema";
import {
  REFERENCE_FIELDS as RENTAL_RETURN_REFERENCE_FIELDS,
  getEmptyRecord as getEmptyRentalReturnRecord,
  ITEM_CALCULATED_FIELDS as RENTAL_RETURN_ITEM_CALCULATED_FIELDS,
} from "services/sosInventoryService/rentalReturn/schema";
import {
  getEmptyRecord as getEmptyReorderRecord,
  REFERENCE_FIELDS as REORDER_REFERENCE_FIELDS,
} from "services/sosInventoryService/reorder/schema";
import {
  REFERENCE_FIELDS as RETURN_REFERENCE_FIELDS,
  getEmptyRecord as getEmptyReturnRecord,
  ITEM_CALCULATED_FIELDS as RETURN_ITEM_CALCULATED_FIELDS,
} from "services/sosInventoryService/return/schema";
import {
  REFERENCE_FIELDS as PAYMENT_REFERENCE_FIELDS,
  getEmptyRecord as getEmptyPaymentRecord,
} from "services/sosInventoryService/return/schema";
import {
  REFERENCE_FIELDS as RETURN_TO_VENDOR_REFERENCE_FIELDS,
  getEmptyRecord as getEmptyReturnToVendorRecord,
  ITEM_CALCULATED_FIELDS as RETURN_TO_VENDOR_ITEM_CALCULATED_FIELDS,
} from "services/sosInventoryService/returnToVendor/schema";
import {
  REFERENCE_FIELDS as RMA_REFERENCE_FIELDS,
  getEmptyRecord as getEmptyRmaRecord,
  ITEM_CALCULATED_FIELDS as RMA_ITEM_CALCULATED_FIELDS,
} from "services/sosInventoryService/rma/schema";
import {
  REFERENCE_FIELDS as SALES_ORDER_REFERENCE_FIELDS,
  getEmptyRecord as getEmptySalesOrderRecord,
  ITEM_CALCULATED_FIELDS as SALES_ORDER_ITEM_CALCULATED_FIELDS,
} from "services/sosInventoryService/salesOrder/schema";
import {
  REFERENCE_FIELDS as SALES_RECEIPT_REFERENCE_FIELDS,
  getEmptyRecord as getEmptySalesReceiptRecord,
  ITEM_CALCULATED_FIELDS as SALES_RECEIPT_ITEM_CALCULATED_FIELDS,
} from "services/sosInventoryService/salesReceipt/schema";
import { getEmptyRecord as getEmptySalesRepRecord } from "services/sosInventoryService/salesRep/schema";
import { getEmptyRecord as getEmptySerialRecord } from "services/sosInventoryService/serial/schema";
import {
  REFERENCE_FIELDS as SHIPMENT_REFERENCE_FIELDS,
  getEmptyRecord as getEmptyShipmentRecord,
  ITEM_CALCULATED_FIELDS as SHIPMENT_ITEM_CALCULATED_FIELDS,
} from "services/sosInventoryService/shipment/schema";
import { getEmptyRecord as getEmptyTaskRecord } from "services/sosInventoryService/task/schema";
import { getEmptyRecord as getEmptyTemplateLibraryRecord } from "services/sosInventoryService/templateLibrary/schema";
import { getEmptyRecord as getEmptyTermRecord } from "services/sosInventoryService/term/schema";
import {
  REFERENCE_FIELDS as TRANSFER_REFERENCE_FIELDS,
  getEmptyRecord as getEmptyTransferRecord,
  ITEM_CALCULATED_FIELDS as TRANSFER_ITEM_CALCULATED_FIELDS,
} from "services/sosInventoryService/transfer/schema";
import { getEmptyRecord as getEmptyUomRecord } from "services/sosInventoryService/uom/schema";
import { getEmptyRecord as getEmptyVendorRecord } from "services/sosInventoryService/vendor/schema";
import { getEmptyRecord as getEmptyVendorMessageRecord } from "services/sosInventoryService/vendorMessage/schema";
import { getEmptyRecord as getEmptyWarrantyRecord } from "services/sosInventoryService/warranty/schema";
import { getEmptyRecord as getEmptyWorkCenterRecord } from "services/sosInventoryService/workCenter/schema";
import {
  REFERENCE_FIELDS as WORK_ORDER_REFERENCE_FIELDS,
  getEmptyRecord as getEmptyWorkOrderRecord,
  ITEM_CALCULATED_FIELDS as WORK_ORDER_ITEM_CALCULATED_FIELDS,
} from "services/sosInventoryService/workOrder/schema";
import { getEmptyRecord as getEmptyWorkerRecord } from "services/sosInventoryService/worker/schema";

import { OBJECT_TYPES } from "appConstants";

export const SERVER = process.env.REACT_APP_SOS_SERVER_URL;
export const LOGIN_PATH = "/api/login";
export const AUTH_PATH = "/api/login/oauth2";
const DAYS_TO_NEXT_LOGIN = 30;
export const DAYS_TO_NEXT_LOGIN_MS = DAYS_TO_NEXT_LOGIN * 24 * 60 * 60 * 1000;

export const DEBOUNCE_WAIT = 350;
export const ITEM_MAX_RESULTS = "500";
export const IMAGE_DIMENSION = "200px";
export const TOTALS_SINGLE_COLUMN_DIMENSION = "20rem";
export const TOTALS_DOUBLE_COLUMN_DIMENSION = "28rem";
export const TOTALS_SALES_COLUMN_DIMENSION = "30rem";

export const IN_TRANSACTION = "in-transaction";
export const IN_REPORT = "in-report";

// should be set to the number of serial numbers we want the user
// to be able to enter by hand; above this number we will present
// only the upload option
export const SERIAL_ENTRY_LIMIT = 100;

function getEmptyNameRecord() {
  return { name: null };
}

export const NEW_RECORD_SKELETON = {
  adjustment: getEmptyAdjustmentRecord,
  bins: getEmptyBinRecord,
  build: getEmptyBuildRecord,
  customer: getEmptyCustomerRecord,
  customfield: getEmptyCustomField,
  customertype: getEmptyNameRecord,
  customermessage: getEmptyCustomerMessageRecord,
  class: getEmptyNameRecord,
  channel: getEmptyChannelRecord,
  department: getEmptyNameRecord,
  estimate: getEmptyEstimateRecord,
  formtemplate: getEmptyFormTemplateRecord,
  invoice: getEmptyInvoiceRecord,
  item: getEmptyItemRecord,
  itemreceipt: getEmptyItemReceiptRecord,
  location: getEmptyLocationRecord,
  lot: getEmptyLotRecord,
  job: getEmptyJobRecord,
  orderstage: getEmptyOrderStageRecord,
  payment: getEmptyPaymentRecord,
  paymentmethod: getEmptyNameRecord,
  pickticket: getEmptyPickTicketRecord,
  priority: getEmptyPriorityRecord,
  process: getEmptyProcessRecord,
  purchaseorder: getEmptyPurchaseOrderRecord,
  returntovendor: getEmptyReturnToVendorRecord,
  salesorder: getEmptySalesOrderRecord,
  salesreceipt: getEmptySalesReceiptRecord,
  salesrep: getEmptySalesRepRecord,
  rental: getEmptyRentalRecord,
  rentalreturn: getEmptyRentalReturnRecord,
  reorder: getEmptyReorderRecord,
  return: getEmptyReturnRecord,
  rma: getEmptyRmaRecord,
  serial: getEmptySerialRecord,
  shipment: getEmptyShipmentRecord,
  transfer: getEmptyTransferRecord,
  tag: getEmptyNameRecord,
  task: getEmptyTaskRecord,
  terms: getEmptyTermRecord,
  templatelibrary: getEmptyTemplateLibraryRecord,
  uom: getEmptyUomRecord,
  workorder: getEmptyWorkOrderRecord,
  warranty: getEmptyWarrantyRecord,
  workcenter: getEmptyWorkCenterRecord,
  worker: getEmptyWorkerRecord,
  vendor: getEmptyVendorRecord,
  vendormessage: getEmptyVendorMessageRecord,
};

export const REFERENCE_FIELDS = {
  adjustment: ADJUSTMENT_REFERENCE_FIELDS,
  build: BUILD_REFERENCE_FIELDS,
  estimate: ESTIMATE_REFERENCE_FIELDS,
  invoice: INVOICE_REFERENCE_FIELDS,
  item: ITEM_REFERENCE_FIELDS,
  itemreceipt: ITEM_RECEIPT_REFERENCE_FIELDS,
  job: JOB_REFERENCE_FIELDS,
  payment: PAYMENT_REFERENCE_FIELDS,
  pickticket: PICK_TICKET_REFERENCE_FIELDS,
  process: PROCESS_REFERENCE_FIELDS,
  purchaseorder: PURCHASE_ORDER_REFERENCE_FIELDS,
  rental: RENTAL_REFERENCE_FIELDS,
  rentalreturn: RENTAL_RETURN_REFERENCE_FIELDS,
  reorder: REORDER_REFERENCE_FIELDS,
  returntovendor: RETURN_TO_VENDOR_REFERENCE_FIELDS,
  rma: RMA_REFERENCE_FIELDS,
  salesorder: SALES_ORDER_REFERENCE_FIELDS,
  salesreceipt: SALES_RECEIPT_REFERENCE_FIELDS,
  shipment: SHIPMENT_REFERENCE_FIELDS,
  transfer: TRANSFER_REFERENCE_FIELDS,
  workorder: WORK_ORDER_REFERENCE_FIELDS,
  return: RETURN_REFERENCE_FIELDS,
};

export const REFERENCE_ENTITY_MAP = {
  toLocation: "location",
  fromLocation: "location",
};

export const OTHER_COST_REFERENCE_FIELDS = {
  itemreceipt: ITEM_RECEIPT_OTHER_COST_REFERENCE_FIELDS,
};

export const ITEM_CALCULATED_FIELDS = {
  adjustment: ADJUSTMENT_ITEM_CALCULATED_FIELDS,
  build: BUILD_ITEM_CALCULATED_FIELDS,
  estimate: ESTIMATE_ITEM_CALCULATED_FIELDS,
  invoice: INVOICE_ITEM_CALCULATED_FIELDS,
  itemreceipt: ITEM_RECEIPT_ITEM_CALCULATED_FIELDS,
  job: JOB_ITEM_CALCULATED_FIELDS,
  pickticket: PICK_TICKET_ITEM_CALCULATED_FIELDS,
  process: PROCESS_ITEM_CALCULATED_FIELDS,
  purchaseorder: PURCHASE_ORDER_ITEM_CALCULATED_FIELDS,
  rental: RENTAL_ITEM_CALCULATED_FIELDS,
  rentalreturn: RENTAL_RETURN_ITEM_CALCULATED_FIELDS,
  returntovendor: RETURN_TO_VENDOR_ITEM_CALCULATED_FIELDS,
  rma: RMA_ITEM_CALCULATED_FIELDS,
  salesorder: SALES_ORDER_ITEM_CALCULATED_FIELDS,
  salesreceipt: SALES_RECEIPT_ITEM_CALCULATED_FIELDS,
  shipment: SHIPMENT_ITEM_CALCULATED_FIELDS,
  transfer: TRANSFER_ITEM_CALCULATED_FIELDS,
  workorder: WORK_ORDER_ITEM_CALCULATED_FIELDS,
  return: RETURN_ITEM_CALCULATED_FIELDS,
};

export const LINKED_TRANSACTION_OPTIONS = {
  workorder: [
    OBJECT_TYPES.SALES_ORDER.shortString,
    OBJECT_TYPES.SALES_RECEIPT.shortString,
  ],
  pickticket: [
    OBJECT_TYPES.INVOICE.shortString,
    OBJECT_TYPES.SALES_ORDER.shortString,
    OBJECT_TYPES.SALES_RECEIPT.shortString,
  ],
  shipment: [
    OBJECT_TYPES.INVOICE.shortString,
    OBJECT_TYPES.SALES_ORDER.shortString,
    OBJECT_TYPES.SALES_RECEIPT.shortString,
  ],
  payment: [
    OBJECT_TYPES.INVOICE.shortString,
    OBJECT_TYPES.SALES_ORDER.shortString,
  ],
};

// Remove this object once https://app.clickup.com/t/8689a073c complete
export const TEMPLATE_NAMES = {
  adjustment: "Inventory Adjustment",
  build: "Build",
  customer: "Customer",
  invoice: "Invoice",
  estimate: "Estimate",
  item: "Item",
  itemreceipt: "Item Receipt",
  lot: "Lot",
  payment: "Payment",
  process: "Process Transaction",
  purchaseorder: "Purchase Order",
  return: "Return",
  rental: "Rental",
  rentalreturn: "Rental Return",
  returntovendor: "Return To Vendor",
  rma: "RMA",
  pickticket: "Pick Ticket",
  salesorder: "Sales Order",
  salesreceipt: "Sales Receipt",
  shipment: "Shipment",
  transfer: "Inventory Transfer",
  serial: "Serial Item",
  vendor: "Vendor",
  workorder: "Work Order",
};

export const AUTOMATED_TESTING_ACCOUNTS = [
  { name: "Saddle Oak Software Auto Test", id: "37875" }, // Auto Test Company A
  { name: "Saddle Oak Software Auto Test", id: "62925" }, // Auto Test Company B
];

export function getIsAutomatedTestingAccount(company) {
  return Boolean(
    AUTOMATED_TESTING_ACCOUNTS.find(
      ({ name, id }) => name === company.name && id === company.id
    )
  );
}

export const CONFIGURATION_LISTS = [
  OBJECT_TYPES.BIN.fullString,
  OBJECT_TYPES.CHANNEL.fullString,
  OBJECT_TYPES.CUSTOM_FIELD.fullString,
  OBJECT_TYPES.CUSTOMER_MESSAGE.fullString,
  OBJECT_TYPES.CUSTOMER_TYPE.fullString,
  OBJECT_TYPES.FORM_TEMPLATE.fullString,
  OBJECT_TYPES.LOCATION.fullString,
  OBJECT_TYPES.ORDER_STAGE.fullString,
  OBJECT_TYPES.PAYMENT_METHOD.fullString,
  OBJECT_TYPES.PRICE_TIER.fullString,
  OBJECT_TYPES.PRIORITY.fullString,
  OBJECT_TYPES.SALES_REP.fullString,
  OBJECT_TYPES.TAG.fullString,
  OBJECT_TYPES.TEMPLATE_LIBRARY.fullString,
  OBJECT_TYPES.TERM.fullString,
  OBJECT_TYPES.UOM.fullString,
  OBJECT_TYPES.VENDOR_ITEM.fullString,
  OBJECT_TYPES.VENDOR_MESSAGE.fullString,
  OBJECT_TYPES.WARRANTY.fullString,
  OBJECT_TYPES.WORK_CENTER.fullString,
  OBJECT_TYPES.WORKER.fullString,
];
