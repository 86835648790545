import { Button, Typography } from "@mui/material";

import { Decimal, Money } from "classes/DecimalClasses";

import { Link } from "components/html/Link";
import { LinkText } from "components/utility/LinkText";
import Truncate from "components/utility/Truncate";

import { i18n } from "services/i18nService";
import { isoToLocalDateTime } from "services/utility/dates";
import { timeZoneAwareDateTime } from "services/utility/dates";
import { formatDate, yyyymmddToDate } from "services/utility/dates";
import { downloadDocument } from "services/utility/documents";
import { handleProgramError } from "services/utility/errors";
import { LOCALIZATION } from "services/utility/localization";
import { getUomConversionFromUomReference } from "services/utility/uoms";
import { isSafeUrl } from "services/utility/validation";

import { openDialog } from "globalState/globalDialogSlice";
import globalState from "globalState/globalState";

import { theme } from "SosTheme";
import { getObjectFromTypeString } from "appConstants";
import { INDENTED_LIST_PADDING } from "appConstants";
import { DEFAULT_DECIMALS_UNROUNDED } from "appConstants";
import {
  FULL_OBJECT_TYPE_FROM_SHORT,
  ITEM_TYPES_WITH_INVENTORY_QUANTITIES,
  ITEM_TYPES_WITH_AVAILABILITY,
  NO_REF_NUMBER_STRING,
} from "appConstants";
import { OBJECT_TYPES } from "appConstants";
import { format } from "date-fns";

export const INCLUDE_ADORNMENTS = true;
export const NO_ADORNMENTS = false;

export const ACCOUNTING_OPTION = true;

export function formatLinkField(linkText) {
  if (!linkText || !linkText.trim().length) {
    return NO_REF_NUMBER_STRING;
  }
  return linkText;
}

export function strikeThroughCompleted(linkText, row) {
  return (
    <div style={{ textDecoration: row.completed ? "line-through" : "inherit" }}>
      {linkText}
    </div>
  );
}

export function extractName(refObject) {
  return refObject && refObject.name ? refObject.name : "";
}

export function extractId(refObject) {
  return refObject && refObject.id ? refObject.id : "";
}

export function extractNamesFromReferenceArray(referenceArray) {
  const justNames = referenceArray.map((ref) => ref.name);
  return justNames.join("\n");
}

export function extractItemNamesFromReferenceArray(referenceArray) {
  const justNames = referenceArray.map((ref) => ref.item?.name);
  return justNames.join("\n");
}

export function formatArray(referenceArray) {
  return referenceArray.map((ref, index) => {
    return <div key={index}>{ref}</div>;
  });
}

export function formatCompanyInfo(companyObject, onlyAddress) {
  const { company, contact, email, phone, address } = companyObject || {};
  const {
    city,
    stateProvince,
    postalCode,
    country,
    line1,
    line2,
    line3,
    line4,
    line5,
  } = address || {};

  const data = [
    company,
    contact && typeof contact === "object" ? formatName(contact) : contact,
    line1,
    line2,
    line3,
    line4,
    line5,
    city || stateProvince || postalCode
      ? `${city || ""} ${stateProvince || ""} ${postalCode || ""}`
      : null,
    country,
  ];
  if (!onlyAddress) {
    data.push(email, phone);
  }
  return (
    <>
      <Typography sx={{ lineHeight: 1.25 }}>
        {data.filter((item) => item).join("\n")}
      </Typography>
    </>
  );
}

export function formatAddress(addressObject) {
  if (!addressObject) {
    return "";
  }

  const {
    line1,
    line2,
    line3,
    line4,
    line5,
    city,
    stateProvince,
    postalCode,
    country,
  } = addressObject;

  const addressLines = [line1, line2, line3, line4, line5]
    .filter((item) => item)
    .join("\n");

  const cityLine = [city, stateProvince, postalCode, country]
    .filter((item) => item)
    .join(" ");

  return [addressLines, cityLine].filter((item) => item).join("\n");
}

export function extractPaymentMethod(paymentMethod, record) {
  return `${paymentMethod} ${i18n("payment.endingIn")} ${record.lastFour}`;
}

export function formatNumber(amount, decimalPlaces, allowBlank) {
  if (!amount && allowBlank) {
    return null;
  }
  if (!decimalPlaces) {
    decimalPlaces = 1;
  }
  const multiplier = Math.pow(10, decimalPlaces || 0);
  return Math.round(amount * multiplier) / multiplier;
}

export function formatName(nameObject) {
  const { title, firstName, middleName, lastName, suffix } = nameObject;
  return [title, firstName, middleName, lastName, suffix]
    .filter((item) => item)
    .join(" ");
}

export function formatTaxCodeNames(taxCodes) {
  return taxCodes.map(({ name }, i) => <div key={i}>{name}</div>);
}

export function formatTaxCodeRates(taxCodes) {
  return taxCodes.map(({ rate }, i) => <div key={i}>{rate}%</div>);
}

export function formatContact(contact, name) {
  if (!contact) {
    return name;
  }
  if (typeof contact === "object") {
    return formatName(contact) || name;
  }
  return contact || name;
}

export function formatActiveUser(value, row) {
  if (row.master) {
    return i18n("global.Master");
  }
  return formatBoolean(value);
}

export function formatBoolean(value) {
  return value ? i18n("global.Yes") : i18n("global.No");
}

export function formatReverseBoolean(value) {
  return value ? i18n("global.No") : i18n("global.Yes");
}

export function formatBooleanYesOrBlank(value) {
  return value ? i18n("global.Yes") : "";
}

export function formatDocumentInfo(documents, _row, _column, getDocument) {
  return documents.map(({ id, fileName, description }) => (
    <span
      key={id}
      style={{ color: theme.palette.selectIcon, cursor: "pointer" }}
      onClick={() => downloadAttachedDocument(id, fileName, getDocument)}
    >
      <div>{`${fileName} ${description ? `(${description})` : ""}`}</div>
    </span>
  ));
}

async function downloadAttachedDocument(documentId, downloadText, getDocument) {
  const documentBlob = await getDocument(documentId);
  downloadDocument(documentBlob, downloadText);
}

export function decimalMaximumFractionDigits(
  value,
  locale,
  maximumFractionDigits,
  grouping
) {
  return new Intl.NumberFormat(locale, {
    maximumFractionDigits: maximumFractionDigits,
    useGrouping: grouping,
  }).format(value);
}

export function currencyMinMaxFractionDigits(
  value,
  locale,
  minimumFractionDigits,
  maximumFractionDigits,
  useGrouping
) {
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits,
    maximumFractionDigits,
    useGrouping,
  }).format(value);
}

export function addPercentAdornment(value) {
  return `${value}%`;
}

export function formatPercentDigits(value, locale) {
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 5,
  }).format(value);
}

export function formatPercentWithAdornments(value, locale) {
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 5,
    style: "percent",
  }).format(value ? value.times(new Decimal(0.01)) : null);
}

export function formatDecimal(decimal) {
  return decimal || decimal === 0 ? decimal.toString() : "";
}

export function formatQuantityWithUom(quantity, row) {
  if (!quantity) {
    return "";
  }
  return row.uom
    ? `${quantity.toString()} ${row.uom.name}`
    : quantity.toString();
}

export function formatPercent(decimal) {
  return decimal || decimal === 0
    ? decimal.times(new Decimal(100)).toString()
    : "";
}

export function formatTaxable(tax) {
  return tax ? formatBoolean(tax.taxable) : "";
}

export function formatDefaultTax(tax) {
  return tax ? extractName(tax.taxCode) : "";
}

export function formatItemQtyByItemType(decimal, record) {
  return formatDecimalByItemType(
    decimal,
    ITEM_TYPES_WITH_INVENTORY_QUANTITIES,
    record.type
  );
}

export function formatItemAvailabilityByItemType(decimal, record) {
  return formatDecimalByItemType(
    decimal,
    ITEM_TYPES_WITH_AVAILABILITY,
    record.type
  );
}

function formatDecimalByItemType(decimal, allowedTypes, type) {
  if (allowedTypes.includes(type)) {
    return decimal ? decimal.toString() : "";
  }
  return "";
}

export function formatHyperlink(url) {
  if (!url) {
    return "";
  }
  if (!isSafeUrl(url)) {
    return url;
  }
  let normalizedUrl = url;
  if (!url.match(/^https?:\/\/.*$/)) {
    normalizedUrl = "http://" + url;
  }
  return (
    <a href={normalizedUrl} target="_blank" rel="noreferrer">
      {normalizedUrl}
    </a>
  );
}

export function getReportMoneyTotal(reportJson, column) {
  return formatMoneyWithAdornments(
    reportJson.reduce((seed, row) => seed.plus(row[column]), new Money(0))
  );
}

export function formatStatus(closed) {
  return closed ? i18n("global.Closed") : i18n("global.Open");
}

export function formatCompleted(completed) {
  return completed ? i18n("global.Completed") : i18n("global.Open");
}

export function formatIsoDateNoTime(isoString) {
  return yyyymmddToDate(isoString);
}

export function extractRentalReturns(arrayOfReturnRefs) {
  return linkedTransactionUrls(arrayOfReturnRefs);
}

export function extractLinkedTransactions(arrayOfLinkedTransactions) {
  return arrayOfLinkedTransactions?.length > 0
    ? linkedTransactionUrls(arrayOfLinkedTransactions)
    : "";
}

export function extractPaymentId(paymentId) {
  return paymentId ? (
    <span style={theme.typography.body1}>
      <Link to={`/fspayment?id=${paymentId}`} underline="none">
        {paymentId}
      </Link>
    </span>
  ) : (
    ""
  );
}

export function formatLinkableItem(item) {
  return item ? (
    <span style={theme.typography.body1}>
      <Link to={`/item?id=${item.id}`} underline="none">
        {item.name}
      </Link>
    </span>
  ) : (
    ""
  );
}

export function formatLinkableShipment(shipmentArray) {
  const shipment = shipmentArray[0];
  return shipment ? (
    <span style={theme.typography.body1}>
      <Link to={`/shipment?id=${shipment.id}`} underline="none">
        {shipment.refNumber}
      </Link>
    </span>
  ) : (
    ""
  );
}

export function extractWarranty(warrantyObject) {
  return warrantyObject && warrantyObject.warranty
    ? warrantyObject.warranty.name
    : "";
}

export function extractReturned(text, row) {
  const linkedReturns = row.linkedReturns;
  return (
    <span>
      {text}{" "}
      {linkedReturns.length > 0 && (
        <>({linkedTransactionUrls(linkedReturns)})</>
      )}
    </span>
  );
}

export function extractReceived(linkedReceipts, row) {
  const text = row.receivedStatus;
  return (
    <span>
      {text}{" "}
      {linkedReceipts?.length > 0 && (
        <>({linkedTransactionUrls(linkedReceipts)})</>
      )}
    </span>
  );
}

export function extractDescription(description, row) {
  const { bin, toBin, fromBin, lot, serials } = row;
  const mappedSerials = serials
    ? serials.map(({ name }) => `S/N: ${name}`).join("\n")
    : "";
  const descriptionValue = description ? `${description}\n` : "";
  const serialValues = mappedSerials ? `${mappedSerials}\n` : "";
  const lotValue = lot ? `${i18n("frmLabel.Lot")}: ${lot.name}\n` : "";
  const binValue = bin ? `${i18n("frmLabel.Bin")}: ${bin.name}\n` : "";
  const fromBinValue = fromBin
    ? `${i18n("frmLabel.FromBin")}: ${fromBin.name}\n`
    : "";
  const toBinValue = toBin ? `${i18n("frmLabel.ToBin")}: ${toBin.name}\n` : "";
  return `${descriptionValue}${serialValues}${lotValue}${binValue}${fromBinValue}${toBinValue}`;
}

export function formatImage(hasImage, record) {
  if (hasImage) {
    return (
      <Button
        sx={{ padding: "1px 6px" }}
        variant="text"
        size="small"
        onClick={() =>
          globalState.dispatch(
            openDialog({
              type: "image",
              dialogProps: {
                objectType: "item",
                id: record.id,
                name: record.name,
              },
            })
          )
        }
      >
        {i18n("global.SeeImage")}
      </Button>
    );
  }
  return "";
}

export function formatInteractiveInventoryColumn(value, row, column) {
  if (!value) {
    return value;
  }
  const dialogProps = {
    objectType: "item",
    id: row.id,
    name: row.name,
    type: column.inventoryType,
  };

  return (
    <LinkText
      onClick={() =>
        globalState.dispatch(openDialog({ type: "inventory", dialogProps }))
      }
    >
      {value}
    </LinkText>
  );
}

export function formatQuickViewTotal(_, record) {
  const { subTotal, taxAmount, shippingAmount, discountAmount } = record;
  const amount = subTotal
    .plus(taxAmount)
    .plus(shippingAmount)
    .plus(discountAmount);

  return formatMoneyWithAdornments(amount);
}

export function formatCardOnFile(_, row) {
  const { expMonth, expYear, tokenType, lastFour } = row;
  if (!tokenType || !lastFour) {
    return "";
  }

  let cardOnFile = `${tokenType} ${i18n("global.endingIn")} ${lastFour}`;
  if (expMonth > 0 && expYear > 0) {
    cardOnFile += ` ${i18n("global.expiring")} ${expMonth}/${expYear}`;
  }
  return cardOnFile;
}

export function truncate(text, _, column) {
  const styles = {
    color: theme.palette.primaryLink,
    cursor: "pointer",
    fontWeight: "bold",
  };

  return (
    <div style={{ display: "inline-block", width: "100%" }}>
      <Truncate
        lines={3}
        ellipsis={
          <span>
            ...{" "}
            <span
              style={styles}
              onClick={() =>
                globalState.dispatch(
                  openDialog({
                    type: "moreText",
                    dialogProps: { content: text, heading: column.heading },
                  })
                )
              }
            >
              {i18n("global.showMore")}
            </span>
          </span>
        }
      >
        {text}
      </Truncate>
    </div>
  );
}

function linkedTransactionUrls(list) {
  return list.map((listItem, index, array) => {
    return listItem ? (
      <span style={theme.typography.body1} key={index}>
        <Link
          to={`/${FULL_OBJECT_TYPE_FROM_SHORT[listItem.transactionType]}/?id=${
            listItem.id
          }`}
          underline="none"
        >
          {listItem.refNumber ? listItem.refNumber : NO_REF_NUMBER_STRING}
        </Link>
        {index !== array.length - 1 && ", "}
      </span>
    ) : (
      ""
    );
  });
}

export function formatLotsWithQuantityAndExpiration(rawLots) {
  return rawLots.map(({ id, number, onhand, expiration }) => ({
    id,
    onhand,
    number,
    name: `${number} (${onhand})${
      expiration ? " - Exp: " + formatDate(expiration) : ""
    }`,
  }));
}

export function formatLotsWithItemName(rawLots) {
  return rawLots.map(({ id, number, item }) => ({
    id,
    name: `${number} (${item.name})`,
  }));
}

/**
 * @name    formatBinInfo
 *
 * @summary takes bin information for a location and returns a bins
 * options object as used by our select components, with quantity
 * information included with the bin name
 *
 * @param locationBins (object) - as returned with an item record,
 * when a location and date/time is provided; example:
 *
 * [
 *   {
 *     bin: {
 *       id: 1, name: "bin1"
 *     },
 *     quantity: 123
 *   },
 * ...
 * ]
 *
 * @return an array of bin reference objects, with quantity included
 * with the bin name; example:
 * [
 *   { id: 41, name: "Bin 28 (133)" },
 *   { id: 4, name: "Bin 85 (279)" },
 *   { id: 19, name: "Bin 41 (14)" },
 * ]
 */
export function formatBinInfo(locationBins) {
  return locationBins.map((lb) => ({
    id: lb.bin.id,
    name: `${lb.bin.name} (${lb.quantity.toString()})`,
  }));
}

// input is a JavaScript date object; output is a localized date
// (no time) string
export function formatDateTimeToDate(date) {
  const { dateFormat } = LOCALIZATION;
  const userTimeZone =
    globalState.getState().userCompanySettings.settings.userTimeZone;
  if (!date) {
    return "";
  }
  if (!(date instanceof Date)) {
    throw new Error("Invalid date object: " + date);
  }

  const tzDate = timeZoneAwareDateTime(date, userTimeZone);
  try {
    return format(tzDate, dateFormat);
  } catch (error) {
    handleProgramError(
      new Error(
        `date: ${date}, userTimeZone: ${userTimeZone}, tzDate: ${tzDate}, dateFormat: ${dateFormat}`
      )
    );
  }
}

export function formatLastSync(date) {
  return date
    ? formatDateTimeToDate(isoToLocalDateTime(date))
    : i18n("dashboard.Never");
}

// input is a JavaScript date object; output is a localized date
// and time string
export function formatDateTimeToDateTimeString(date) {
  const { dateFormat } = LOCALIZATION;
  const { userTimeZone } = globalState.getState().userCompanySettings.settings;
  if (!date) {
    return "";
  }
  if (!(date instanceof Date)) {
    throw new Error("Invalid date object: " + date);
  }

  const tzDate = timeZoneAwareDateTime(date, userTimeZone);
  return format(tzDate, dateFormat + " h:mm:ssaaa");
}

export function replaceSpaceWithNewLine(string) {
  return string.replace(" ", "\n");
}

export function formatSpaceIntoLines(date) {
  return date.replace(" ", "\n");
}

export const SHOW_ADORNMENTS = true;
export function formatMoney(
  amount,
  showAdornments = false,
  accountingOption = false,
  unrounded = false
) {
  const { locale, currencyMinimumFractionDigits } = LOCALIZATION;
  const style = showAdornments ? "currency" : "decimal";
  const currencySign = accountingOption ? "accounting" : "standard";
  const useGrouping = showAdornments ? true : false;
  const maxDigits = unrounded
    ? { maximumFractionDigits: DEFAULT_DECIMALS_UNROUNDED }
    : {
        maximumFractionDigits: currencyMinimumFractionDigits,
      };
  if (!amount) {
    return "";
  }
  return new Intl.NumberFormat(locale, {
    style,
    currencySign,
    currency: amount.currency,
    useGrouping: useGrouping,
    minimumFractionDigits: currencyMinimumFractionDigits,
    ...maxDigits,
  }).format(amount);
}

export function formatSync(syncMessage, row) {
  const { dateFormat } = LOCALIZATION;
  const userTimeZone =
    globalState.getState().userCompanySettings.settings.userTimeZone;
  if (syncMessage) {
    return syncMessage;
  }

  if (row.lastSync) {
    const tzDate = timeZoneAwareDateTime(row.lastSync, userTimeZone);
    return format(tzDate, dateFormat);
  }

  return "";
}

export function formatMoneyWithAdornments(amount) {
  return formatMoney(amount, INCLUDE_ADORNMENTS);
}

export function formatMoneyWithoutAdornments(amount) {
  return formatMoney(amount);
}

export function formatMoneyUnrounded(amount, includeAdornments) {
  return formatMoney(amount, includeAdornments, false, true);
}

export function formatMoneyForAccounting(amount) {
  return formatMoney(amount, INCLUDE_ADORNMENTS, ACCOUNTING_OPTION);
}

export function extractWarrantyStart(warrantyObject) {
  return warrantyObject && warrantyObject.start
    ? formatDateTimeToDate(warrantyObject.start)
    : "";
}

export function extractWarrantyEnd(warrantyObject) {
  return warrantyObject && warrantyObject.end
    ? formatDateTimeToDate(warrantyObject.end)
    : "";
}

export function formatBooleanToYesOrBlank(value) {
  return value ? i18n("global.Yes") : "";
}

export function formatTypeStringIntoLabel(typeString, row) {
  const { fullString } = getObjectFromTypeString(typeString);
  let label = i18n(`objectType.${fullString}.Sentence`);

  if (row.direction === "in") {
    label += ` (${i18n("global.input")})`;
  }

  if (row.direction === "out") {
    label += ` (${i18n("global.output")})`;
  }
  return label;
}

export function indentVariantNames(itemName, row) {
  return row.variantMaster
    ? itemName.padStart(itemName.length + 1, INDENTED_LIST_PADDING)
    : itemName;
}

export function getDecimalTotal(data, column) {
  return formatDecimal(
    data.reduce(
      (seed, row) => (row[column] ? seed.plus(row[column]) : seed),
      new Decimal(0)
    )
  );
}

export function getMoneyTotalWithAdornments(data, column) {
  return formatMoneyWithAdornments(
    data.reduce(
      (seed, row) => (row[column] ? seed.plus(row[column]) : seed),
      new Money(0)
    )
  );
}

export function getMoneyTotal(data, column) {
  return formatMoney(
    data.reduce(
      (seed, row) => (row[column] ? seed.plus(row[column]) : seed),
      new Money(0)
    )
  );
}

export function getQuantityTotal(data, column) {
  return formatDecimal(
    data.reduce((seed, row) => {
      const { itemDetails, uom } = row;
      const quantityValue = row[column];
      if (uom) {
        const conversion = getUomConversionFromUomReference(
          uom,
          itemDetails.itemUoms
        );
        const conversionAdjusted = quantityValue.times(conversion);
        return quantityValue ? seed.plus(conversionAdjusted) : seed;
      }
      return quantityValue ? seed.plus(quantityValue) : seed;
    }, new Decimal(0))
  );
}

export function extractAppliedToTransaction(linkedTransaction) {
  return linkedTransaction
    ? `${linkedTransaction.transactionType} ${linkedTransaction.refNumber}`
    : "";
}

export function calculatePaymentAmount(value, record) {
  const { subTotal, taxAmount, shippingAmount, discountAmount } = record;
  const amount = subTotal
    .plus(taxAmount)
    .plus(shippingAmount)
    .plus(discountAmount);

  return formatMoneyWithAdornments(value.minus(amount));
}

export function alwaysShowZeroMoney() {
  return formatMoneyWithAdornments(new Money(0));
}

// for sync items; input object is as below, but some objects have less
// information (for example, no refNumber)
// object: {
//   id: 209,
//   type: "purchase order",
//   name: "Customer or vendor name",
//   refNumber: "SYNC-TEST",
//   date: (Javascript date)
// },
export function formatSyncItemObject(object) {
  const { name, refNumber, date, type, id } = object;
  if (
    [
      OBJECT_TYPES.VENDOR.typeString,
      OBJECT_TYPES.ITEM.typeString,
      OBJECT_TYPES.CUSTOMER.typeString,
      OBJECT_TYPES.CLASS.typeString,
      OBJECT_TYPES.DEPARTMENT.typeString,
      OBJECT_TYPES.SALES_TERM.typeString,
    ].includes(type)
  ) {
    return name;
  } else if (
    [
      OBJECT_TYPES.BILL.typeString,
      OBJECT_TYPES.VENDOR_CREDIT.typeString,
    ].includes(type)
  ) {
    return `${name}, ${formatDate(date)}`;
  } else if (type === OBJECT_TYPES.JOURNAL_ENTRY.typeString) {
    return `${id} (${name}, ${formatDate(date)})`;
  } else {
    return `${refNumber} (${name}, ${formatDate(date)})`;
  }
}

export function extractObjectType(object) {
  return object.type;
}
