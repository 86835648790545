import { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Grid } from "@mui/material";

import { Layout } from "Layout";

import { CustomFields } from "components/CustomFields/CustomFields";
import { EditModalHeader } from "components/EditModalHeader";
import { FrmFieldGrid } from "components/FrmFieldGrid";
import { ObjectEditDialogContent } from "components/ObjectEditDialogContent";
import { QuickAddVendor } from "components/QuickAddVendor";
import { SectionHeading } from "components/SectionHeading";
import { LineItemTotals } from "components/formFields/Totals/Purchasing/LineItemTotals";
import {
  FrmDeposit,
  FrmLocation,
  FrmNumber,
  FrmPayment,
  FrmTerms,
  FrmVendorWithAdd,
  FrmVendorNotes,
  FrmComment,
  FrmDepartment,
} from "components/formFields/frm";
import { FrmCurrencyExchangeRate } from "components/formFields/frm";
import { FrmAutoSerialLots } from "components/formFields/frm";
import { FrmUpdateDefaultCosts } from "components/formFields/frm";
import { PAYMENT_TYPE_BILL_ID } from "components/formFields/frm/FrmPayment";
import { FrmSignature } from "components/formFields/frm/FrmSignature";
import { DatePicker } from "components/html/Input/DatePicker";
import { DateTimePicker } from "components/html/Input/DateTimePicker";
import { FixedLoadingIndicator } from "components/utility/FixedLoadingIndicator";
import { FormErrors } from "components/utility/FormErrors";
import { Loading } from "components/utility/Loading";
import { VSpace } from "components/utility/VSpace";
import { WarningModal } from "components/utility/WarningModal";

import { i18n } from "services/i18nService";
import { getAllBinsForLocation } from "services/sosInventoryService/domainLogic";
import {
  updateTotals,
  ZERO_TOTALS,
} from "services/sosInventoryService/purchasingTransaction/updateTotals";
import {
  fullAddNewVendor,
  quickAddNewVendor,
} from "services/utility/controller";
import { setPageDirty, handleRefreshExchangeRate } from "services/utility/edit";
import { normalizeSerials } from "services/utility/serials";

import { useCustomFieldDefsAsObject } from "hooks/useCustomFieldDefinitions";
import { useErrors } from "hooks/useErrors";
import { useItems } from "hooks/useItems";
import { useLineHandler } from "hooks/useLineHandler";
import { usePlans } from "hooks/usePlans";
import { useRelated } from "hooks/useRelated";
import { useTaxCodes, TAX_CODE_TYPE_PURCHASE } from "hooks/useTaxCodes";
import { useVendors } from "hooks/useVendors";

import { AddPo } from "views/ItemReceipts/ItemReceipt/ItemReceipt/AddPo";
import { OtherCosts } from "views/ItemReceipts/ItemReceipt/ItemReceipt/OtherCosts";
import {
  getTransaction,
  updateTransaction,
} from "views/ItemReceipts/ItemReceipt/ItemReceipt/controller";
import { LineItems } from "views/PurchaseTransactions/LineItems";

import {
  editModalLoadingIndicatorOn,
  editModalLoadingIndicatorOff,
} from "globalState/loadingSlice";

import { theme } from "SosTheme";
import { OBJECT_TYPES, ITEM_QUICKLIST_CASES } from "appConstants";
import { EMPTY_LINE_ITEM, EMPTY_OTHER_COST } from "editConfig";

const OBJECT_TYPE = OBJECT_TYPES.ITEM_RECEIPT.fullString;
const CUSTOM_FIELD_OBJECT_TYPES = [OBJECT_TYPE, OBJECT_TYPES.VENDOR.fullString];

export function ItemReceipt(props) {
  const { id, onClose, newFromObjectType, newFromId } = props;

  // TRANSACTION STATE
  const [record, setRecord] = useState();
  const [lines, lineHandler] = useLineHandler();
  const [otherCosts, otherCostHandler] = useLineHandler();
  const [relatedRecords, setRelatedRecords] = useState({});
  const [vendorBillDateChanged, setVendorBillDateChanged] = useState(false);
  const CURRENT_STATE = {
    record,
    lines,
    vendorBillDateChanged,
    relatedRecords,
  };

  // UI STATE
  const { errors, setErrors, isInError } = useErrors();
  const [clearLines, setClearLines] = useState(false);
  const [clearOtherCosts, setClearOtherCosts] = useState(false);
  const customFieldDefs = useCustomFieldDefsAsObject(CUSTOM_FIELD_OBJECT_TYPES);
  const [addNewVendor, setAddNewVendor] = useState(false);
  const [newVendorName, setNewVendorName] = useState("");
  const [totals, setTotals] = useState(ZERO_TOTALS);
  const [vendorAdded, setVendorAdded] = useState(0);
  const [itemAdded, setItemAdded] = useState(0);
  const loadingState = useSelector((state) => state.loading.editModal);

  // RELATED RECORD LISTS
  const taxCodes = useTaxCodes(TAX_CODE_TYPE_PURCHASE);
  const currencies = useRelated("currency");
  const classes = useRelated("class");
  const vendors = useVendors(vendorAdded);
  const [bins, setBins] = useState(null);
  const { items, itemsCount } = useItems(
    ITEM_QUICKLIST_CASES.PURCHASING,
    itemAdded
  );

  // SETTINGS
  const departmentEnabled = useSelector(
    (state) => state.userCompanySettings.settings.departmentEnabled
  );
  const { allowPlusFeatures, allowProFeatures } = usePlans();
  const multiCurrencyEnabled = useSelector(
    (state) => state.userCompanySettings.settings.multiCurrencyEnabled
  );

  // OTHER SETUP
  const dispatch = useDispatch();

  // UPDATERS FOR CONTROLLER TO UPDATE STATE
  const UPDATERS = useMemo(
    () => ({
      setRecord,
      lineHandler,
      setRelatedRecords,
      otherCostHandler,
      setVendorBillDateChanged,
      setErrors,
    }),
    [lineHandler, otherCostHandler, setErrors]
  );

  // LOAD INITIAL TRANSACTION STATE
  useEffect(() => {
    async function _getTransaction() {
      dispatch(editModalLoadingIndicatorOn());
      await getTransaction(
        id,
        newFromId,
        newFromObjectType,
        UPDATERS,
        customFieldDefs[OBJECT_TYPE]
      );
      dispatch(editModalLoadingIndicatorOff());
    }
    if (customFieldDefs) {
      _getTransaction();
    }
  }, [
    dispatch,
    id,
    newFromId,
    newFromObjectType,
    lineHandler,
    UPDATERS,
    customFieldDefs,
  ]);

  // HANDLE ALL USER INPUT
  function handleInputFieldChange(field, newValue) {
    setPageDirty();
    updateTransaction(
      field,
      newValue,
      customFieldDefs[OBJECT_TYPE],
      CURRENT_STATE,
      UPDATERS,
      currencies
    );
  }

  useEffect(() => {
    // when location changes, get bins for that location
    async function _getBins() {
      if (record?.location?.id) {
        setBins(await getAllBinsForLocation(record.location.id));
      }
    }
    _getBins();
  }, [record?.location]);

  useEffect(() => updateTotals(lines, setTotals, taxCodes), [lines, taxCodes]);

  async function handleQuickAddNewVendor(name) {
    dispatch(editModalLoadingIndicatorOn());
    await quickAddNewVendor(name, UPDATERS);

    // force a re-get of the vendor list, since we know one was just added
    setVendorAdded((prev) => prev + 1);
    dispatch(editModalLoadingIndicatorOff());
    setPageDirty();
    setAddNewVendor(false);
  }

  async function handleAddNewVendor(newVendor) {
    await fullAddNewVendor(
      newVendor,
      customFieldDefs.vendor,
      customFieldDefs[OBJECT_TYPE],
      record.customFields,
      UPDATERS
    );

    // force a re-get of the vendor list, since we know one was just added
    setVendorAdded((prev) => prev + 1);
    dispatch(editModalLoadingIndicatorOff());
    setAddNewVendor(false);
  }

  function doClearLines() {
    setPageDirty();
    lineHandler({ type: "clear", blankLine: EMPTY_LINE_ITEM[OBJECT_TYPE] });
    setClearLines(false);
  }

  function doClearOtherCosts() {
    setPageDirty();
    otherCostHandler({
      type: "clear",
      blankLine: EMPTY_OTHER_COST[OBJECT_TYPE],
    });
    setClearOtherCosts(false);
  }

  function handleAutoSerialLotUpdate(name, checked) {
    setRecord((prevRecord) => ({ ...prevRecord, [name]: checked }));

    lines.forEach((line) => {
      const serials = normalizeSerials(
        line.serials,
        line.quantity,
        line.itemDetails.serialTracking,
        checked
      );
      setPageDirty();
      lineHandler({ type: "update", updatedLine: { ...line, serials } });
    });
  }

  return (
    <>
      <EditModalHeader
        record={record}
        setRecord={setRecord}
        objectType={OBJECT_TYPE}
        relatedRecords={relatedRecords}
        lines={lines}
        otherCosts={otherCosts}
        setErrors={setErrors}
        errors={errors}
        text={record?.number}
        handleClose={onClose}
        vendorId={record?.vendor?.id}
      />
      {loadingState && <Loading />}
      <ObjectEditDialogContent>
        <Layout
          pageTitle={
            id
              ? `${i18n("objectType.itemreceipt.Sentence")} ${
                  record?.number || ""
                }`
              : i18n("objectType.itemreceipt.New")
          }
        >
          <div id="B" style={{ margin: theme.spacing(2) }}>
            <FormErrors errors={errors} setErrors={setErrors} />
            {record ? (
              <>
                <FrmFieldGrid gridAutoFlow="dense">
                  <DateTimePicker
                    value={record.date}
                    name="date"
                    onValueChange={handleInputFieldChange}
                    label={i18n("frmLabel.Date")}
                    error={isInError("date")}
                    dataTesting="date"
                  />
                  <FrmNumber
                    recordId={record.id}
                    label={i18n("itemReceipt.labelNumber")}
                    value={record.number}
                    onValueChange={handleInputFieldChange}
                    error={isInError("number")}
                  />
                  <div style={{ gridColumn: "1 / 2" }}>
                    <FrmVendorWithAdd
                      value={record.vendor}
                      onValueChange={handleInputFieldChange}
                      setAddNewVendor={setAddNewVendor}
                      setNewVendorName={setNewVendorName}
                      vendors={vendors}
                      error={isInError("vendor")}
                    />
                  </div>
                  {(allowPlusFeatures || allowProFeatures) && (
                    <FrmLocation
                      value={record.location}
                      error={isInError("location")}
                      onValueChange={handleInputFieldChange}
                      width="25rem"
                    />
                  )}
                  <div style={{ gridColumn: "1 / 2" }}>
                    <FrmVendorNotes
                      value={record.vendorNotes}
                      onValueChange={handleInputFieldChange}
                    />
                  </div>
                </FrmFieldGrid>
                <VSpace space={1} />
                <FrmFieldGrid gridAutoFlow="dense">
                  <div style={{ gridColumn: "1 / 2" }}>
                    {multiCurrencyEnabled && (
                      <Grid item xs={4}>
                        <FrmCurrencyExchangeRate
                          customerOrVendor={record.vendor}
                          record={record}
                          currencies={currencies}
                          currencyError={isInError("currency")}
                          exchangeRate={record.exchangeRate}
                          handleInputChange={handleInputFieldChange}
                          handleRefreshExchangeRate={() =>
                            handleRefreshExchangeRate(
                              currencies,
                              record,
                              handleInputFieldChange
                            )
                          }
                        />
                      </Grid>
                    )}
                  </div>
                  <div style={{ gridColumn: "2 / 2" }}>
                    <AddPo vendor={record.vendor} lineHandler={lineHandler} />
                  </div>
                </FrmFieldGrid>

                <VSpace space={1} />

                <LineItems
                  record={record}
                  objectType={OBJECT_TYPE}
                  lines={lines}
                  totals={totals}
                  taxCodes={taxCodes}
                  items={items}
                  itemsCount={itemsCount}
                  bins={bins}
                  addItem={() => setItemAdded((prev) => prev + 1)}
                  classes={classes}
                  lineHandler={lineHandler}
                  relatedRecords={relatedRecords}
                  lineItemsErrors={errors.line}
                  setErrors={setErrors}
                  setClearLines={setClearLines}
                  showAppliedTo
                  showBinSerialAndLot
                />

                <VSpace space={1} />

                <FrmFieldGrid gridAutoFlow="dense">
                  <div style={{ gridColumn: "1 / 2" }}>
                    <FrmAutoSerialLots
                      value={record.autoSerialLots}
                      name="autoSerialLots"
                      onValueChange={handleAutoSerialLotUpdate}
                    />
                    <FrmUpdateDefaultCosts
                      value={record.updateDefaultCosts}
                      onValueChange={handleInputFieldChange}
                    />
                  </div>
                  <div style={{ gridColumn: "2 / 2" }}>
                    <LineItemTotals totals={totals} />
                  </div>
                </FrmFieldGrid>

                {allowProFeatures && (
                  <>
                    <>
                      <SectionHeading>
                        {i18n("itemReceipt.OtherCostsHeader")}
                      </SectionHeading>
                      <OtherCosts
                        otherCosts={otherCosts}
                        otherCostHandler={otherCostHandler}
                        items={items}
                        itemsCount={itemsCount}
                        vendors={vendors}
                        classes={classes}
                        recordVendorId={record.vendor?.id}
                        setClearOtherCosts={setClearOtherCosts}
                        lineHandler={otherCostHandler}
                        objectType={OBJECT_TYPE}
                      />
                    </>
                    <VSpace space={2} />
                  </>
                )}

                <VSpace space={4} />
                <FrmFieldGrid gridAutoFlow="dense">
                  <div style={{ gridColumn: "2 / 3" }}>
                    <FrmPayment
                      value={record.payment}
                      onValueChange={handleInputFieldChange}
                      error={isInError("payment")}
                    />
                  </div>
                  {record.payment?.id === PAYMENT_TYPE_BILL_ID && (
                    <>
                      <div style={{ gridColumn: "2 / 3" }}>
                        <FrmTerms
                          maxWidth="20rem"
                          value={record.terms}
                          onValueChange={handleInputFieldChange}
                          error={isInError("terms")}
                          disabled={record.payment?.id !== PAYMENT_TYPE_BILL_ID}
                        />
                      </div>
                      <div style={{ gridColumn: "2 / 3" }}>
                        <DatePicker
                          key={record.vendorInvoiceDate}
                          name="vendorInvoiceDate"
                          value={record.vendorInvoiceDate}
                          label={i18n("frmLabel.VendorBillDate")}
                          disabled={record.payment?.id !== PAYMENT_TYPE_BILL_ID}
                          onValueChange={handleInputFieldChange}
                          dataTesting="vendorInvoiceDate"
                        />
                      </div>
                      <div style={{ gridColumn: "2 / 3" }}>
                        <DatePicker
                          name="vendorInvoiceDueDate"
                          value={record.vendorInvoiceDueDate}
                          label={i18n("frmLabel.DueDate")}
                          onValueChange={handleInputFieldChange}
                          disabled={record.payment?.id !== PAYMENT_TYPE_BILL_ID}
                        />
                      </div>
                    </>
                  )}

                  <div style={{ gridColumn: "1 / 2" }}>
                    <FrmDeposit
                      maxWidth="20rem"
                      amountValue={record.depositAmount}
                      percentValue={record.depositPercent}
                      onValueChange={handleInputFieldChange}
                      amountError={isInError("depositAmount")}
                      percentError={isInError("depositPercent")}
                    />
                  </div>
                  {departmentEnabled && (
                    <div style={{ gridColumn: "1 / 2" }}>
                      <FrmDepartment
                        maxWidth="20rem"
                        value={record.department}
                        onValueChange={handleInputFieldChange}
                      />
                    </div>
                  )}
                  <div style={{ gridColumn: "1 / 2", gridRow: "span 2" }}>
                    <FrmComment
                      value={record.comment}
                      onValueChange={handleInputFieldChange}
                    />
                  </div>
                  <div style={{ gridColumn: "1 / 2" }}>
                    <FrmSignature
                      objectType={OBJECT_TYPE}
                      id={record.id}
                      hasSignature={record.hasSignature}
                    />
                  </div>
                </FrmFieldGrid>

                <CustomFields
                  customFieldDefinitions={customFieldDefs[OBJECT_TYPE]}
                  customFields={record.customFields}
                  onValueChange={handleInputFieldChange}
                  setErrors={setErrors}
                />
              </>
            ) : (
              !isInError("record") && (
                <FixedLoadingIndicator text={`${i18n("global.Loading")}...`} />
              )
            )}
            <VSpace space={4} />
          </div>
          {record && (
            <>
              {!!addNewVendor && (
                <QuickAddVendor
                  open
                  initialNameValue={newVendorName}
                  onClose={() => setAddNewVendor(false)}
                  onQuickAdd={handleQuickAddNewVendor}
                  onAdd={handleAddNewVendor}
                />
              )}
              <WarningModal
                title={i18n("global.ClearLines")}
                message={i18n("global.clearLinesMessage")}
                open={clearLines}
                onOk={doClearLines}
                onClose={() => setClearLines(false)}
              />

              <WarningModal
                title={i18n("global.clearOtherCostsTitle")}
                message={i18n("global.clearOtherCostsMessage")}
                open={clearOtherCosts}
                onOk={doClearOtherCosts}
                onClose={() => setClearOtherCosts(false)}
              />
            </>
          )}
        </Layout>
      </ObjectEditDialogContent>
    </>
  );
}
