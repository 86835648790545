import { useState } from "react";
import { useSelector } from "react-redux";

import { Info, Business, People } from "@mui/icons-material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import { Box, Typography, MenuList } from "@mui/material";

import { Divider } from "components/Divider";
import { DropDownMenu } from "components/TopNav/DropDownMenu";
import { MenuItemLink } from "components/TopNav/MenuItemLink";
import { MenuListItemText } from "components/TopNav/MenuListItemText";
import { NavListItemIcon } from "components/TopNav/NavListItemIcon";

import { i18n } from "services/i18nService";

import { usePrivileges } from "hooks/usePrivileges";

import { theme } from "SosTheme";

const sx = {
  icon: {
    marginLeft: theme.spacing(1),
    cursor: "pointer",
    fontSize: "1.5rem",
    position: "relative",
    color: "primaryLink",
  },
};

export function CompanyMenu(props) {
  const { pathname } = props;
  const { isAdmin } = usePrivileges();

  const company = useSelector((state) => state.userCompanySettings.company);
  const companies = useSelector((state) => state.userCompanySettings.companies);

  const [anchor, setAnchor] = useState(null);

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
    }
    setAnchor(null);
  }

  const companyChooserPath = pathname === "/choosecompany";

  return (
    <>
      {!companyChooserPath && (
        <Box
          my={1}
          mx={2}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Box
            sx={{ display: "flex", cursor: "pointer", alignItems: "center" }}
            onClick={(e) => setAnchor(e.currentTarget)}
          >
            <Typography
              variant="h4"
              component="span"
              data-testing="menuCompanyName"
              sx={{
                fontSize:
                  (company.name.length < 72
                    ? 21
                    : 14 + (100 - company.name.length) / 4) + "px",
              }}
            >
              {company.name || i18n("global.Company")}
            </Typography>
            <Box sx={{ position: "relative", top: "4px" }}>
              {Boolean(anchor) && <KeyboardArrowUpRoundedIcon sx={sx.icon} />}
              {!Boolean(anchor) && (
                <KeyboardArrowDownRoundedIcon sx={sx.icon} />
              )}
            </Box>
          </Box>

          <DropDownMenu
            open={Boolean(anchor)}
            anchorEl={anchor}
            onClose={() => setAnchor(null)}
          >
            <Box>
              {isAdmin && (
                <>
                  <Typography sx={{ p: 1 }}>
                    {`${i18n("frmLabel.AccountNumber")} ${
                      company.accountNumber
                    }`}
                  </Typography>
                  <Divider />
                </>
              )}
              <MenuList
                id="help-list-grow"
                onKeyDown={handleListKeyDown}
                sx={{ px: 1, pt: 0 }}
              >
                {isAdmin && (
                  <MenuItemLink
                    to="/accountinfo"
                    onClick={() => setAnchor(null)}
                  >
                    <NavListItemIcon>
                      <Info />
                    </NavListItemIcon>
                    <MenuListItemText
                      primary={i18n("topNav.companyMenu.accountInfo")}
                    />
                  </MenuItemLink>
                )}

                {isAdmin && (
                  <MenuItemLink to="/user" onClick={() => setAnchor(null)}>
                    <NavListItemIcon>
                      <People />
                    </NavListItemIcon>
                    <MenuListItemText
                      primary={i18n("topNav.companyMenu.userAdmin")}
                    />
                  </MenuItemLink>
                )}
                {companies.length > 1 && (
                  <MenuItemLink
                    to="/choosecompany"
                    onClick={() => setAnchor(null)}
                  >
                    <NavListItemIcon>
                      <Business />
                    </NavListItemIcon>
                    <MenuListItemText primary={i18n("chooseCompany.Link")} />
                  </MenuItemLink>
                )}
              </MenuList>
            </Box>
          </DropDownMenu>
        </Box>
      )}
      {companyChooserPath && (
        <Box m={2}>
          <Typography variant="h4">{i18n("global.SosInventory")}</Typography>
        </Box>
      )}
    </>
  );
}
