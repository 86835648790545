import { Decimal } from "classes/DecimalClasses";

import { i18n } from "services/i18nService";
import { formatDate } from "services/utility/dates";
import {
  extractName,
  formatDateTimeToDate,
  formatDocumentInfo,
  extractLinkedTransactions,
  formatStatus,
  formatLinkField,
  truncate,
  formatCompanyInfo,
  formatBooleanToYesOrBlank,
  formatDecimal,
  formatQuantityWithUom,
  extractDescription,
  formatLinkableItem,
} from "services/utility/formatting";
import {
  inventoryItemTypeEditStrFunction,
  textTypeEditStrFunction,
  decimalTypeEditStrFunction,
  numberTypeEditStrFunction,
  referenceTypeEditStrFunction,
  arrayOfReferencesTypeEditStrFunction,
} from "services/utility/list";
import { parseHTML } from "services/utility/parsing";
import { TYPES } from "services/utility/schema";
import { starColumn } from "services/utility/schema";

import {
  NO_COLUMNS,
  NO_REF_NUMBER_STRING,
  DEFAULT_DECIMALS_UNROUNDED,
} from "appConstants";
import { startOfTomorrow } from "date-fns";

export const columnInfo = [
  starColumn,
  {
    name: "number",
    fixed: true,
    heading: i18n("columns.ReferenceNumber"),
    formatFunc: formatLinkField,
    minWidth: "7em",
    linkField: true,
    sortable: true,
  },
  {
    name: "date",
    heading: i18n("columns.Date"),
    formatFunc: formatDateTimeToDate,
    minWidth: "8em",
    defaultSort: true,
    sortDirection: "asc",
    sortable: true,
    default: true,
  },
  {
    name: "customer",
    heading: i18n("columns.Customer"),
    formatFunc: extractName,
    minWidth: "10em",
    maxWidth: "25em",
    sortable: true,
    default: true,
  },
  {
    name: "fromLocation",
    heading: i18n("columns.FromLocation"),
    formatFunc: extractName,
    sortable: true,
    default: true,
  },
  {
    name: "dueDate",
    heading: i18n("columns.DueDate"),
    formatFunc: formatDate,
    minWidth: "8em",
    sortable: true,
    default: true,
  },
  {
    name: "comment",
    heading: i18n("columns.Comment"),
    formatFunc: [parseHTML, truncate],
    minWidth: "25em",
    maxWidth: "25em",
    sortable: true,
    default: true,
  },
  {
    name: "documents",
    heading: i18n("columns.Documents"),
    formatFunc: formatDocumentInfo,
    minWidth: "15em",
    default: true,
  },
  {
    name: "linkedRentalReturns",
    heading: i18n("columns.Returns"),
    formatFunc: extractLinkedTransactions,
  },
  {
    name: "status",
    fieldName: "closed",
    formatFunc: formatStatus,
    heading: i18n("columns.Status"),
    sortable: true,
  },
  {
    name: "department",
    heading: i18n("columns.Department"),
    formatFunc: extractName,
    sortable: true,
  },
  {
    name: "customerNotes",
    heading: i18n("columns.CustomerNotes"),
    formatFunc: [parseHTML, truncate],
    minWidth: "25em",
    maxWidth: "25em",
  },
  {
    name: "billing",
    heading: i18n("columns.CustomerAddress"),
    formatFunc: formatCompanyInfo,
    minWidth: "10em",
    maxWidth: "15em",
  },
  {
    name: "shipping",
    heading: i18n("columns.ShippingAddress"),
    formatFunc: formatCompanyInfo,
    minWidth: "10em",
    maxWidth: "15em",
  },
  {
    name: "shippingMethod",
    heading: i18n("columns.ShipMethod"),
    formatFunc: extractName,
  },
  {
    name: "trackingNumber",
    heading: i18n("columns.TrackingNumber"),
  },
  {
    name: "channel",
    heading: i18n("columns.Channel"),
    formatFunc: extractName,
  },
  {
    name: "customerMessage",
    heading: i18n("columns.CustomerMessage"),
    formatFunc: [parseHTML, truncate],
    minWidth: "25em",
    maxWidth: "25em",
  },
  {
    name: "hasSignature",
    heading: i18n("columns.Signature"),
    formatFunc: formatBooleanToYesOrBlank,
  },
];

export function getEmptyRecord() {
  return {
    starred: 0,
    syncToken: 0,
    number: "",
    date: new Date(),
    customer: null,
    fromLocation: null,
    toLocation: null,
    billing: {
      company: null,
      contact: null,
      phone: null,
      email: null,
      addressName: null,
      addressType: null,
      address: {
        line1: null,
        line2: null,
        line3: null,
        line4: null,
        line5: null,
        city: null,
        stateProvince: null,
        postalCode: null,
        country: null,
      },
    },
    shipping: {
      company: null,
      contact: null,
      phone: null,
      email: null,
      addressName: null,
      addressType: null,
      address: {
        line1: null,
        line2: null,
        line3: null,
        line4: null,
        line5: null,
        city: null,
        stateProvince: null,
        postalCode: null,
        country: null,
      },
    },
    channel: null,
    department: null,
    dueDate: startOfTomorrow(), // default to next calendar day
    shippingMethod: null,
    trackingNumber: null,
    linkedTransaction: null,
    linkedRentalReturns: [],
    customerMessage: null,
    comment: null,
    customerNotes: null,
    total: new Decimal(0),
    customFields: null,
    closed: false,
    archived: false,
    summaryOnly: false,
    hasSignature: false,
    documents: [],
    keys: null,
    values: null,
    lines: [],
  };
}

export const LINE_ITEM_METADATA = {
  lineNumber: {},
  item: {
    type: TYPES.reference,
    editStr: inventoryItemTypeEditStrFunction,
    initialValue: { id: null, name: null, description: null },
  },
  description: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  quantity: {
    type: TYPES.decimal,
    placesRight: DEFAULT_DECIMALS_UNROUNDED,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  returned: {
    type: TYPES.decimal,
    placesRight: 5,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  weight: {
    type: TYPES.decimal,
    placesRight: DEFAULT_DECIMALS_UNROUNDED,
    editStr: numberTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  weightunit: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  volume: {
    type: TYPES.decimal,
    placesRight: DEFAULT_DECIMALS_UNROUNDED,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  volumeunit: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  class: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  jobWorkcenter: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  bin: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  uom: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  serials: {
    type: TYPES.arrayOfReferences,
    editStr: arrayOfReferencesTypeEditStrFunction,
    initialValue: null,
  },
  lot: {
    type: TYPES.reference,
    editStr: textTypeEditStrFunction,
  },
  itemDetails: { initialValue: {} },
  relatedRecords: { initialValue: {} },
};

export const FIELDS_TO_TRANSFORM = {
  DECIMAL_FIELDS: [],
  MONEY_FIELDS: ["total"],
  DATE_FIELDS: ["dueDate"],
  DATETIME_FIELDS: ["date"],
  NON_UTC_DATETIME_FIELDS: [],
};

export const LINE_ACTIONS = [
  ["quickView", "pdf", "email"],
  "invoice",
  "template",
  "rentalReturn",
  "shipUPS",
  "archive",
  "openClose",
  "delete",
  "history",
];
export const BATCH_ACTIONS = [
  ["pdf", "email"],
  "delete",
  "archive",
  "openClose",
];

export const REFERENCE_FIELDS = ["customer", "toLocation", "fromLocation"];

export const ITEM_CALCULATED_FIELDS = [NO_COLUMNS];

export const QUICK_VIEW_CONFIG = {
  titleSetting: "rentalTitle",
  footerSetting: "rentalFooter",
  signatureLinesLabel: i18n("colon.ReceivedBy"),
  tableConfig: [{ type: "lines" }],
  addressConfig: [
    { name: "billing", label: i18n("address.Customer") },
    { name: "shipping", label: i18n("address.ShipTo") },
  ],
  headerFields: [
    {
      name: "date",
      formatFunc: formatDateTimeToDate,
      label: i18n("columns.Date"),
    },
    {
      name: "number",
      label: i18n("frmLabel.RentalNumber"),
      defaultValue: NO_REF_NUMBER_STRING,
    },
  ],
  fields: [
    {
      name: "shippingMethod",
      label: i18n("frmLabel.ShippedVia"),
      formatFunc: extractName,
    },
    {
      name: "trackingNumber",
      label: i18n("frmLabel.TrackingNumber"),
    },
    {
      name: "customerMessage",
      label: i18n("frmLabel.CustomerMessage"),
    },
  ],
  lines: [
    {
      name: "item",
      formatFunc: formatLinkableItem,
      heading: i18n("columns.Item"),
      align: "left",
      isItemField: true,
    },
    {
      name: "description",
      formatFunc: extractDescription,
      heading: i18n("columns.Description"),
      align: "left",
    },
    {
      name: "quantity",
      formatFunc: formatQuantityWithUom,
      heading: i18n("columns.Quantity"),
      align: "right",
    },
    {
      name: "returned",
      formatFunc: formatDecimal,
      heading: i18n("columns.Returned"),
      align: "right",
    },
  ],
};
