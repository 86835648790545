// Actual domain logic should be in a domainLogic.js file, at the appropriate
// level in the hierarchy.
import { ITEM_CALCULATED_FIELDS } from "appConfig";
import { IN_TRANSACTION } from "appConfig";

import { Decimal, Money } from "classes/DecimalClasses";

import { i18n } from "services/i18nService";
import {
  updateAvailableBinsAndBin,
  updateLineRelatedRecordsItem,
} from "services/sosInventoryService/domainLogic";
import { getEmptyRecord } from "services/sosInventoryService/itemReceipt/schema";
import {
  getRecord,
  calculateDueDate,
  getItemLocationSettings,
  getExchangeRate,
  getRecordFrom,
} from "services/sosInventoryService/sosApi";
import {
  copyCustomFieldValues,
  reconcileCustomFields,
  getCustomFieldDefinitions,
} from "services/utility/customFields";
import { yyyymmddToDate } from "services/utility/dates";
import { isoToLocalDateTime } from "services/utility/dates";
import { setPageDirty } from "services/utility/edit";
import { getRelatedReferenceObjects } from "services/utility/edit";
import { handleProgramError } from "services/utility/errors";

import globalState from "globalState/globalState";

import { OBJECT_TYPES } from "appConstants";
import { EMPTY_OTHER_COST } from "editConfig";
import { EMPTY_LINE_ITEM } from "editConfig";

const OBJECT_TYPE = OBJECT_TYPES.ITEM_RECEIPT.fullString;

export async function getTransaction(
  id,
  newFromId,
  newFromObjectType,
  updaters,
  customFieldDefs
) {
  let record, lines, otherCosts, relatedRecords;

  if (id || newFromObjectType) {
    if (id) {
      record = await getRecord(OBJECT_TYPE, id);
    } else {
      // it's a "populate from" request
      record = await getRecordFrom(OBJECT_TYPE, newFromObjectType, newFromId);
      delete record.id;
    }

    record.customFields = reconcileCustomFields(
      customFieldDefs,
      record.customFields
    );

    lines = record.lines;
    otherCosts = record.otherCosts;
    delete record.lines;
    delete record.otherCosts;

    updaters.lineHandler({ type: "set", lines });
    updaters.otherCostHandler({ type: "set", lines: otherCosts });
    if (otherCosts && otherCosts.length === 0) {
      setPageDirty();
      updaters.otherCostHandler({
        type: "insert",
        insertAt: 1,
        newLine: EMPTY_OTHER_COST[OBJECT_TYPE],
      });
    }
    updaters.setRecord(record);

    relatedRecords = await getRelatedReferenceObjects(OBJECT_TYPE, record);
    updaters.setRelatedRecords(relatedRecords);

    // get items for each line item, so that we have updated item info for
    // the calculations below
    lines = await updateLineRelatedRecordsItem(
      record.location,
      record.date,
      lines,
      ITEM_CALCULATED_FIELDS[OBJECT_TYPE]
    );

    // set the default bin and the available bins for the dropdown
    lines = await updateAvailableBinsAndBin(
      record.location,
      lines,
      "bin",
      true
    );
  } else {
    // new record
    const { settings } = globalState.getState().userCompanySettings;
    const defaultLocation =
      globalState.getState().userCompanySettings.settings.defaultLocation;
    const numLinesToAdd =
      globalState.getState().userCompanySettings.settings.numLinesToAdd;
    const defaultLocationForPurchasing =
      globalState.getState().userCompanySettings.settings
        .defaultLocationForPurchasing;

    record = {
      ...getEmptyRecord(settings),
      customFields: reconcileCustomFields(customFieldDefs, []),
    };

    lines = Array(numLinesToAdd).fill(EMPTY_LINE_ITEM[OBJECT_TYPE]);
    updaters.otherCostHandler({
      type: "set",
      lines: [EMPTY_OTHER_COST[OBJECT_TYPE]],
    });

    relatedRecords = {};

    if (defaultLocation) {
      const txnLocation =
        defaultLocationForPurchasing || defaultLocation || null;
      record.location = txnLocation;

      const location = await getRecord(
        "location",
        txnLocation.id,
        IN_TRANSACTION
      );
      record.shipping = location;
      relatedRecords = { ...relatedRecords, location };
    }
    updaters.setRelatedRecords(relatedRecords);
    updaters.setRecord(record);
  }
  updaters.lineHandler({ type: "set", lines });
}

export async function updateTransaction(
  field,
  newValue,
  transactionCustomFieldDefs,
  currentState,
  updaters,
  currencies
) {
  const { record, lines, vendorBillDateChanged, relatedRecords } = currentState;
  if (!record || !lines || !relatedRecords) {
    handleProgramError(new Error("update Transaction | invalid currentState"));
  }

  let newRecord, newLines, newRelatedRecords;

  switch (field) {
    case "date":
      newRecord = {
        ...record,
        date: newValue,
        vendorInvoiceDate: vendorBillDateChanged
          ? record.vendorInvoiceDate
          : newValue,
      };

      if (!vendorBillDateChanged && record.terms && newValue) {
        const response = await calculateDueDate(record.terms.id, newValue);
        const dueDate = yyyymmddToDate(response.dueDate);
        newRecord = {
          ...record,
          date: newValue,
          vendorInvoiceDate: newValue,
          vendorInvoiceDueDate: dueDate,
        };
      }
      updaters.setRecord(newRecord);

      break;

    case "location":
      newRecord = { ...record, location: newValue };
      updaters.setRecord(newRecord);

      const location = newValue
        ? await getRecord("location", newValue?.id)
        : null;
      newRelatedRecords = { ...relatedRecords, location };
      // update line items' bins for new location
      newLines = await Promise.all(
        lines.map(async (line) => {
          if (!newValue || !line.item?.id) {
            // no location, can't be a bin
            return { ...line, bin: null };
          }
          let itemLocation;
          const response = await getItemLocationSettings(
            line.item.id,
            newValue.id
          );
          if (response.success) {
            itemLocation = response.data;
          } else {
            handleProgramError(
              new Error(i18n("error.CouldNotRetrieveItemLocationSettings"))
            );
          }
          return {
            ...line,
            bin: itemLocation ? itemLocation.defaultBin : null,
          };
        })
      );

      break;

    case "terms":
      newRecord = {
        ...record,
        terms: newValue.id ? { id: newValue.id, name: newValue.name } : null,
      };

      // calculate due date
      if (newValue.id) {
        const response = await calculateDueDate(
          newValue.id,
          record.vendorInvoiceDate || record.date
        );
        const dueDate = yyyymmddToDate(response.dueDate);
        newRecord.vendorInvoiceDueDate = dueDate;
      }
      updaters.setRecord(newRecord);
      break;

    case "vendorInvoiceDate":
      updaters.setVendorBillDateChanged(true);
      // calculate due date
      if (newValue && record.terms) {
        const response = await calculateDueDate(record.terms.id, newValue);
        const dueDate = yyyymmddToDate(response.dueDate);
        newRecord = {
          ...record,
          vendorInvoiceDate: newValue,
          vendorInvoiceDueDate: dueDate,
        };
      } else {
        newRecord = { ...record, vendorInvoiceDate: newValue };
      }
      updaters.setRecord(newRecord);

      break;

    case "comment":
    case "customFields":
    case "department":
    case "number":
    case "payment":
    case "updateDefaultCosts":
    case "vendorInvoiceDueDate":
    case "vendorMessage":
    case "vendorNotes":
      newRecord = { ...record, [field]: newValue };
      updaters.setRecord(newRecord);

      break;

    case "depositPercent":
    case "exchangeRate":
      newRecord = { ...record, [field]: new Decimal(newValue || 0) };
      updaters.setRecord(newRecord);
      break;

    case "depositAmount":
      newRecord = { ...record, [field]: new Money(newValue || 0) };
      updaters.setRecord(newRecord);
      break;

    case "vendor":
      // if the field is empty, null the field
      if (!newValue) {
        newRecord = { ...record, vendor: null };
        updaters.setRecord(newRecord);
        newRelatedRecords = { ...relatedRecords, vendor: null };
        break;
      }

      const vendorCustomFieldDefs = await getCustomFieldDefinitions("vendor");

      try {
        // get the newly selected vendor record, so that we can...
        const vendor = await getRecord("vendor", newValue.id, IN_TRANSACTION);
        // ...see if the vendor has a currency setting; if so, get the
        // exchange rate for that currency
        let newCurrency = null;
        let newExchangeRate = null;

        if (vendor?.currency) {
          const response = await getExchangeRate(
            vendor.currency.name,
            record.date
          );
          if (response) {
            newCurrency = vendor.currency;
            newExchangeRate = response.exchangeRate;
          }
        }

        // if the vendor has default terms, recalculate due date with new terms
        let dueDate = null;
        if (vendor.terms) {
          const response = await calculateDueDate(
            vendor.terms.id,
            record.vendorInvoiceDate || record.date
          );
          dueDate = isoToLocalDateTime(response.dueDate);
        }

        // be sure there are custom field entries for each defined custom field
        // in the vendor record...
        const vendorCustomFields = reconcileCustomFields(
          vendorCustomFieldDefs,
          vendor.customFields
        );

        const newTransactionCustomFields = copyCustomFieldValues(
          vendorCustomFieldDefs,
          vendorCustomFields,
          transactionCustomFieldDefs,
          record.customFields
        );

        // ...then initialize any transaction custom fields to their matching vendor
        // custom field values, if any

        newRecord = {
          ...record,
          vendor: { id: newValue.id },
          currency: newCurrency ? newCurrency : record.currency,
          exchangeRate: newExchangeRate ? newExchangeRate : record.exchangeRate,
          vendorNotes: vendor.notes,
          taxCode: vendor.taxCode,
          terms: vendor.terms,
          vendorInvoiceDueDate: dueDate,
          customFields: newTransactionCustomFields,
        };
        updaters.setRecord(newRecord);
        newRelatedRecords = { ...relatedRecords, vendor };
      } catch (e) {
        handleProgramError(e);
      }

      break;

    case "currency":
      const { id: newId, name: newName } = newValue || {};
      updaters.setRecord((prev) => ({
        ...prev,
        currency: newId ? { id: newId, name: newName } : null,
        exchangeRate: newId
          ? currencies.find(({ id }) => id === newId).exchangeRate
          : null,
      }));
      break;

    default:
      handleProgramError(
        new Error(`updateTransaction | unknown field (${field})`)
      );
  }

  if (newLines) {
    updaters.lineHandler({ type: "set", lines: newLines });
  }
  if (newRelatedRecords) {
    updaters.setRelatedRecords(newRelatedRecords);
  }
}
