import { i18n } from "services/i18nService";
import { formatLinkField } from "services/utility/formatting";

import { OBJECT_TYPES } from "appConstants";

export const columnInfo = [
  {
    name: "name",
    heading: i18n("columns.Name"),
    formatFunc: formatLinkField,
    fixed: true,
    defaultSort: true,
    linkField: true,
    sortable: true,
  },
  {
    name: "description",
    heading: i18n("columns.Description"),
    fixedRightOfAction: true,
    fixed: true,
    sortable: true,
  },
  {
    name: "dataType",
    heading: i18n("columns.DataType"),
    fixedRightOfAction: true,
    fixed: true,
    sortable: true,
  },
  {
    name: "showOn",
    heading: i18n("columns.ShowOn"),
    fixed: true,
    fixedRightOfAction: true,
  },
];

export const LINE_ACTIONS = ["delete"];
export const BATCH_ACTIONS = ["delete"];

const textDataTypeOption = { id: "Text", name: i18n("global.Text") };
export const listDataTypeOption = { id: "List", name: i18n("global.List") };

export const DATA_TYPE = [
  textDataTypeOption,
  { id: "Multiline Text", name: i18n("global.MultiLineText") },
  { id: "Number", name: i18n("global.Number") },
  { id: "Money", name: i18n("global.Money") },
  { id: "Date", name: i18n("global.Date") },
  { id: "Check Box", name: i18n("global.Checkbox") },
  listDataTypeOption,
];

export const SHOW_ON_OPTIONS = [
  {
    id: OBJECT_TYPES.ADJUSTMENT.fullString,
    name: i18n(
      `objectType.${OBJECT_TYPES.ADJUSTMENT.fullString}.SentencePlural`
    ),
  },
  {
    id: OBJECT_TYPES.BUILD.fullString,
    name: i18n(`objectType.${OBJECT_TYPES.BUILD.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.CUSTOMER.fullString,
    name: i18n(`objectType.${OBJECT_TYPES.CUSTOMER.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.ESTIMATE.fullString,
    name: i18n(`objectType.${OBJECT_TYPES.ESTIMATE.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.INVOICE.fullString,
    name: i18n(`objectType.${OBJECT_TYPES.INVOICE.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.ITEM_RECEIPT.fullString,
    name: i18n(
      `objectType.${OBJECT_TYPES.ITEM_RECEIPT.fullString}.SentencePlural`
    ),
  },
  {
    id: OBJECT_TYPES.ITEM.fullString,
    name: i18n(`objectType.${OBJECT_TYPES.ITEM.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.JOB.fullString,
    name: i18n(`objectType.${OBJECT_TYPES.JOB.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.LOT.fullString,
    name: i18n(`objectType.${OBJECT_TYPES.LOT.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.PAYMENT.fullString,
    name: i18n(`objectType.${OBJECT_TYPES.PAYMENT.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.PICK_TICKET.fullString,
    name: i18n(
      `objectType.${OBJECT_TYPES.PICK_TICKET.fullString}.SentencePlural`
    ),
  },
  {
    id: OBJECT_TYPES.PROCESS.fullString,
    name: i18n(`objectType.${OBJECT_TYPES.PROCESS.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.PURCHASE_ORDER.fullString,
    name: i18n(
      `objectType.${OBJECT_TYPES.PURCHASE_ORDER.fullString}.SentencePlural`
    ),
  },
  {
    id: OBJECT_TYPES.RENTAL.fullString,
    name: i18n(`objectType.${OBJECT_TYPES.RENTAL.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.RENTAL_RETURN.fullString,
    name: i18n(
      `objectType.${OBJECT_TYPES.RENTAL_RETURN.fullString}.SentencePlural`
    ),
  },
  {
    id: OBJECT_TYPES.RETURN.fullString,
    name: i18n(`objectType.${OBJECT_TYPES.RETURN.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.RETURN_TO_VENDOR.fullString,
    name: i18n(
      `objectType.${OBJECT_TYPES.RETURN_TO_VENDOR.fullString}.SentencePlural`
    ),
  },
  {
    id: OBJECT_TYPES.RMA.fullString,
    name: i18n(`objectType.${OBJECT_TYPES.RMA.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.SALES_ORDER.fullString,
    name: i18n(
      `objectType.${OBJECT_TYPES.SALES_ORDER.fullString}.SentencePlural`
    ),
  },
  {
    id: OBJECT_TYPES.SALES_RECEIPT.fullString,
    name: i18n(
      `objectType.${OBJECT_TYPES.SALES_RECEIPT.fullString}.SentencePlural`
    ),
  },
  {
    id: OBJECT_TYPES.SERIAL.fullString,
    name: i18n(`objectType.${OBJECT_TYPES.SERIAL.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.SHIPMENT.fullString,
    name: i18n(`objectType.${OBJECT_TYPES.SHIPMENT.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.TRANSFER.fullString,
    name: i18n(`objectType.${OBJECT_TYPES.TRANSFER.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.VENDOR.fullString,
    name: i18n(`objectType.${OBJECT_TYPES.VENDOR.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.WORK_ORDER.fullString,
    name: i18n(
      `objectType.${OBJECT_TYPES.WORK_ORDER.fullString}.SentencePlural`
    ),
  },
];

export function getEmptyRecord() {
  return {
    name: null,
    description: null,
    dataType: textDataTypeOption,
    showOn: null,
  };
}
