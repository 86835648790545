import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";

import { Link } from "components/html/Link";

import { i18n } from "services/i18nService";
import { getCalendarData } from "services/sosInventoryService/sosApi";
import { getCalendarSettings } from "services/sosInventoryService/sosApi";
import { updateCalendarSettings } from "services/sosInventoryService/sosApi";
import { dateToSosISODate } from "services/utility/dates";
import { handleProgramError } from "services/utility/errors";
import { getMinListPageWidth } from "services/utility/misc";

import { usePlans } from "hooks/usePlans";

import {
  loadingIndicatorOn,
  loadingIndicatorOff,
} from "globalState/loadingSlice";

import { SettingsDialog } from "./SettingsDialog";
import {
  DEFAULT_SETTINGS,
  getConfigForEntryType,
  getTypeSuffix,
} from "./calendarConfig";
import { MONTHS, DAYS_OF_WEEK } from "appConstants";
import {
  getDay,
  getMonth,
  getYear,
  lastDayOfMonth as getLastDayOfMonth,
  subDays,
  addDays,
} from "date-fns";

const calendarDaySx = {
  backgroundColor: "calendar.dayBackground",
  minHeight: "6em",
  position: "relative",
  margin: "2px",
};

function firstDayOfAMonth(year, month) {
  return new Date(year, month - 1, 1);
}

function findFirstAndLastDaysToShow(year, month) {
  const firstDayOfMonth = firstDayOfAMonth(year, month);
  const lastDayOfMonth = getLastDayOfMonth(firstDayOfMonth);
  const firstDayDayOfWeek = getDay(firstDayOfMonth);
  const lastDayDayOfWeek = getDay(lastDayOfMonth);
  const numPrologueDays = firstDayDayOfWeek;
  const numEpilogueDays = 6 - lastDayDayOfWeek;
  const firstDayToShow = subDays(firstDayOfMonth, numPrologueDays);
  const lastDayToShow = addDays(lastDayOfMonth, numEpilogueDays);
  return [firstDayToShow, lastDayToShow];
}

export function Calendar() {
  const today = new Date();
  const [year, setYear] = useState(getYear(today));
  const [month, setMonth] = useState(getMonth(today) + 1);
  const [calendarData, setCalendarData] = useState([]);
  const [firstDayToShow, setFirstDayToShow] = useState([]);
  const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);
  const [settings, setSettings] = useState(DEFAULT_SETTINGS);

  const dispatch = useDispatch();

  const loadingState = useSelector((state) => state.loading.list);

  const { allowPlusFeatures, allowProFeatures } = usePlans();

  const initialSettingsRetrieved = useRef(false);

  const retrieveData = useCallback(
    async function (year, month) {
      dispatch(loadingIndicatorOn());
      const [firstDayToShow, lastDayToShow] = findFirstAndLastDaysToShow(
        year,
        month
      );
      setFirstDayToShow(firstDayToShow);
      const response = await getCalendarData(firstDayToShow, lastDayToShow);
      if (!response) {
        // if response is null, it was an aborted request
        // do not set data or clear loading UI
        return;
      } else if (response.success) {
        setCalendarData(response.data);
        dispatch(loadingIndicatorOff());
      } else {
        dispatch(loadingIndicatorOff());
      }
    },
    [dispatch]
  );

  useEffect(() => {
    async function getCalendarSettingsAndData() {
      if (!initialSettingsRetrieved.current) {
        const response = await getCalendarSettings();
        if (response.success) {
          setSettings(response.data);
          initialSettingsRetrieved.current = true;
        } else {
          handleProgramError(
            new Error(
              "There was a problem retrieving your calendar settings. Using default settings."
            )
          );
        }
      }
      await retrieveData(year, month);
    }

    getCalendarSettingsAndData();
  }, [year, month, dispatch, retrieveData]);

  async function updateSettings(newSettings) {
    setSettingsDialogOpen(false);
    setSettings(newSettings);
    // persist the settings; it's important to persist the settings before
    // retrieving the calendar data, as the back end honors the persisted
    // settings when assembling the entry types to be returned
    const success = await updateCalendarSettings(newSettings);
    if (!success) {
      handleProgramError(
        new Error(i18n("calendar.Error.UnableToSaveSettings"))
      );
      return;
    }
    // update the calendar data, as the user may have requested new entry
    // types
    retrieveData(year, month);
  }

  return (
    <>
      <Box>
        <Box sx={{ m: 2 }}>
          <Typography variant="h2">{i18n("calendar.Heading")}</Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          <ArrowLeft
            sx={{ width: "2em", height: "2em", cursor: "pointer" }}
            onClick={() => {
              setMonth(month === 1 ? 12 : month - 1);
              setYear(month === 1 ? year - 1 : year);
            }}
          />
          <Typography variant="h3">
            {MONTHS[month - 1]} {year}
          </Typography>
          <ArrowRight
            sx={{ width: "2em", height: "2em", cursor: "pointer" }}
            onClick={() => {
              setMonth(month === 12 ? 1 : month + 1);
              setYear(month === 12 ? year + 1 : year);
            }}
          />
          {(year !== getYear(today) || month !== getMonth(today) + 1) && (
            <Button
              variant="outlined"
              onClick={() => {
                setYear(getYear(today));
                setMonth(getMonth(today) + 1);
              }}
            >
              {i18n("calendar.thisMonth")}
            </Button>
          )}
          <Button
            variant="outlined"
            sx={{ ml: 2 }}
            onClick={() => setSettingsDialogOpen(true)}
          >
            {i18n("calendar.settingsButton")}
          </Button>
        </Box>

        <Box
          sx={{
            display: "grid",
            minWidth: getMinListPageWidth(),
            m: 2,
            backgroundColor: "lineItemBorder",
            border: "2px solid",
            borderColor: "lineItemBorder",
          }}
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(7, 1fr)",
            }}
          >
            {DAYS_OF_WEEK.map((day) => (
              <Box
                sx={{
                  textAlign: "right",
                  pr: "7px",
                  pt: "5px",
                  height: "2rem",
                }}
                key={day}
              >
                <Typography variant="h5">{day}</Typography>
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(7, 1fr)",
            }}
          >
            {calendarData.map((day, dayIndex) => (
              <Box key={dayIndex} sx={calendarDaySx}>
                <Typography
                  sx={{
                    color:
                      addDays(firstDayToShow, dayIndex).getMonth() + 1 === month
                        ? "primaryText"
                        : "calendar.nonPrimaryMonthDays",
                    fontWeight: "bold",
                    position: "absolute",
                    right: "7px",
                    top: "3px",
                  }}
                >
                  {addDays(firstDayToShow, dayIndex).getDate().toString()}
                </Typography>
                <Box sx={{ mt: 3, pb: 0.3 }}>
                  {!loadingState &&
                    day.map(({ count, objectType }, index) => {
                      const config = getConfigForEntryType(objectType);
                      const { planIncludesEntryType, settingName, fullString } =
                        config;
                      const includedInPlan =
                        !planIncludesEntryType ||
                        planIncludesEntryType(
                          allowPlusFeatures,
                          allowProFeatures
                        );
                      if (includedInPlan && settings[settingName]) {
                        return (
                          <CalendarEntry
                            key={index}
                            text={`${count} ${
                              count > 1
                                ? i18n(`objectType.${fullString}.LowerPlural`)
                                : i18n(`objectType.${fullString}.Lower`)
                            } ${getTypeSuffix(objectType)}`}
                            objectType={objectType}
                            date={addDays(firstDayToShow, dayIndex)}
                          />
                        );
                      } else {
                        return null;
                      }
                    })}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>

      {settingsDialogOpen && (
        <SettingsDialog
          close={() => setSettingsDialogOpen(false)}
          allowPlusFeatures={allowPlusFeatures}
          allowProFeatures={allowProFeatures}
          settings={settings}
          saveSettings={updateSettings}
        />
      )}
    </>
  );
}

function CalendarEntry(props) {
  const { text, objectType, date } = props;

  const config = getConfigForEntryType(objectType);

  return (
    <Link
      to={`/${config.fullString}?fromCalendar=true&filterField=${
        config.listFilterFieldName
      }&date=${dateToSosISODate(date)}`}
      underline="none"
    >
      <Box
        sx={{
          borderRadius: 0.5,
          borderLeftWidth: "6px",
          borderLeftStyle: "solid",
          borderLeftColor: `calendar.entry.${config.typeString}.color`,
          my: 0.5,
          mx: 1,
          p: 0.5,
          backgroundColor: "white",
          lineHeight: 1.25,
        }}
      >
        {text}
      </Box>
    </Link>
  );
}
