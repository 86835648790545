import { useSelector } from "react-redux";

import {
  FrmAutoSerialLots,
  FrmUpdateDefaultCosts,
  FrmBlanketPO,
} from "components/formFields/frm";
import { LinkText } from "components/utility/LinkText";

import { setPageDirty } from "services/utility/edit";
import { textForAddLineOrLines } from "services/utility/lineItems";
import { checkForUnexpectedProps } from "services/utility/misc";
import { normalizeSerials } from "services/utility/serials";

export function BottomWidgets(props) {
  const {
    objectType,
    record,
    lineHandler,
    handleInputFieldChange,
    lines,
    setRecord,
    showAutoSerialLots,
    showBlanketPo,
    showUpdateDefaultCosts,
    ...unexpected
  } = props;
  checkForUnexpectedProps("BottomWidgets", unexpected);

  const numLinesToAdd = useSelector(
    (state) => state.userCompanySettings.settings.numLinesToAdd
  );

  function handleAutoSerialLotUpdate(name, checked) {
    setRecord((prevRecord) => ({ ...prevRecord, [name]: checked }));
    setPageDirty();
    lines.forEach((line) => {
      const serials = normalizeSerials(
        line.serials,
        line.quantity,
        line.itemDetails.serialTracking,
        checked
      );
      lineHandler({ type: "update", updatedLine: { ...line, serials } });
    });
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", flexGrow: "1" }}>
      <div style={{ marginBottom: "0.5rem" }}>
        <LinkText
          onClick={() => {
            setPageDirty();
            lineHandler({ type: "appendEmpty", numLinesToAdd, objectType });
          }}
        >
          {textForAddLineOrLines(numLinesToAdd)}
        </LinkText>
      </div>
      {showBlanketPo && (
        <FrmBlanketPO
          value={record.blanketPO}
          onValueChange={handleInputFieldChange}
        />
      )}
      {showAutoSerialLots && (
        <FrmAutoSerialLots
          value={record.autoSerialLots}
          name="autoSerialLots"
          onValueChange={handleAutoSerialLotUpdate}
        />
      )}
      {showUpdateDefaultCosts && (
        <FrmUpdateDefaultCosts
          value={record.updateDefaultCosts}
          onValueChange={handleInputFieldChange}
        />
      )}
    </div>
  );
}
