import { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useSelector, useDispatch } from "react-redux";

import { Delete } from "@mui/icons-material";
import {
  Dialog,
  TableContainer,
  DialogContent,
  Table,
  TableBody,
  IconButton,
  TableHead,
  TableRow,
  TableFooter,
  Button,
} from "@mui/material";

import { Decimal, Money } from "classes/DecimalClasses";
import { Loading as LoadingClass } from "classes/Loading";

import { AddLines } from "components/EditPage/AddLines";
import { ClearLines } from "components/EditPage/ClearLines";
import { LineItemText } from "components/formFields/LineItemText";
import { LineTableFooterCell } from "components/formFields/LineTableCell";
import { LineTableCell } from "components/formFields/LineTableCell";
import { LineColumnHeading } from "components/formFields/line/LineColumnHeading";
import { LineDragInsertCopy } from "components/formFields/line/LineDragInsertCopy";
import { RowDecimalField } from "components/formFields/row/RowDecimalField";
import { RowItem } from "components/formFields/row/RowItem";
import { RowStaticMoney } from "components/formFields/row/RowStaticMoney";
import { RowTextField } from "components/formFields/row/RowTextField";
import { DragHandleHeading } from "components/utility/DragHandle";
import { FixedLoadingIndicator } from "components/utility/FixedLoadingIndicator";
import { FormErrors } from "components/utility/FormErrors";
import { Loading } from "components/utility/Loading";
import { ModalHeader } from "components/utility/ModalHeader";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import { afterTouchLine } from "services/sosInventoryService/bom/afterTouchLine";
import { EMPTY_LINE } from "services/sosInventoryService/bom/schema";
import {
  expandBomItemGroup,
  getItemRecord,
} from "services/sosInventoryService/domainLogic";
import {
  saveItemBom,
  getItemBom,
  calculateBomCostBasis,
} from "services/sosInventoryService/sosApi";
import { handleProgramError } from "services/utility/errors";
import { getDecimalTotal, getMoneyTotal } from "services/utility/formatting";
import { textForAddLineOrLines } from "services/utility/lineItems";

import { useErrors } from "hooks/useErrors";
import { useItems } from "hooks/useItems";

import { openAlert } from "globalState/alertSlice";

import { ITEM_TYPES } from "appConstants";
import {
  DEFAULT_DECIMALS_UNROUNDED,
  ITEM_QUICKLIST_FORM_TYPES,
  ITEM_QUICKLIST_CASES,
} from "appConstants";

const styles = {
  "& .MuiDialog-paper": {
    minHeight: "35vh",
    maxHeight: "93vh",
    "@media print": { minHeight: "100%", maxHeight: "100%" },
  },
};

export function BillOfMaterials(props) {
  const { id, close, identifierText } = props;
  const { items, itemsCount } = useItems(ITEM_QUICKLIST_CASES.PRODUCTION);
  const [bom, setBom] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { errors, setErrors } = useErrors();
  const numLinesToAdd = useSelector(
    (state) => state.userCompanySettings.settings.numLinesToAdd
  );
  const dispatch = useDispatch();

  useEffect(() => {
    async function _getItemLocationSettings() {
      const response = await getItemBom(id);
      setBom(response);
    }
    _getItemLocationSettings();
  }, [id]);

  function handleFieldChange(name, value, rowIndex) {
    setBom((prevRecord) =>
      prevRecord.map((e, index) =>
        rowIndex === index ? afterTouchLine({ ...e, [name]: value }, name) : e
      )
    );
  }

  async function handleItemChange(_, value, rowIndex) {
    if (!value?.id) {
      setBom((prevRecord) =>
        prevRecord.map((item, index) =>
          rowIndex === index
            ? {
                ...item,
                componentItem: value,
                cost: Money.ZERO,
                total: Decimal.ZERO,
              }
            : item
        )
      );
      return;
    }

    setBom((prevRecord) =>
      prevRecord.map((item, index) =>
        rowIndex === index
          ? {
              ...item,
              componentItem: value,
              cost: new LoadingClass(),
              total: item.quantity.eq(Decimal.ZERO)
                ? Decimal.ZERO
                : new LoadingClass(),
            }
          : item
      )
    );

    const [{ purchaseDescription }, { costBasis }] = await Promise.all([
      await getItemRecord(value.id),
      await calculateBomCostBasis(value.id),
    ]);

    setBom((prevRecord) =>
      prevRecord.map((e, index) =>
        rowIndex === index
          ? {
              ...e,
              description: purchaseDescription,
              item: value,
              cost: costBasis,
              total: costBasis.times(e.quantity),
              typeOfItem: e.componentItem?.type,
            }
          : e
      )
    );
  }

  function deleteBomItem(dataIndex) {
    setBom((prev) => prev.filter((_, i) => i !== dataIndex));
  }

  function addLines() {
    const newLines = new Array(numLinesToAdd).fill(EMPTY_LINE);
    setBom((prevBom) => [...prevBom, ...newLines]);
  }

  async function saveAndClose() {
    setIsLoading(true);
    const item = { id };
    const response = await saveItemBom(id, { item, lines: bom });
    if (response.success) {
      const message = i18n("alert.SaveBillOfMaterialsSuccess");
      dispatch(openAlert({ type: "success", message }));
      close();
    } else {
      handleProgramError(i18n("error.ErrorSavingBillOfMaterials"));
    }
    setIsLoading(false);
  }

  function insertEmptyLine(insertAt) {
    setBom((prevBom) =>
      prevBom.reduce(
        (seed, bomItem, index) =>
          insertAt === index
            ? [...seed, EMPTY_LINE, bomItem]
            : [...seed, bomItem],
        []
      )
    );
  }

  function copyDown(line, insertAt) {
    setBom((prevBom) =>
      prevBom.reduce(
        (seed, bomItem, index) =>
          insertAt === index ? [...seed, bomItem, line] : [...seed, bomItem],
        []
      )
    );
  }

  async function expandItemGroup(index, itemToExpand) {
    setIsLoading(true);
    const bomLineItems = await expandBomItemGroup(itemToExpand);
    setBom((prev) => {
      const newLines = [...prev];
      newLines.splice(index, 1, ...bomLineItems);
      return newLines.map((line, i) => ({ ...line, lineNumber: i + 1 }));
    });
    setIsLoading(false);
  }

  function handleDrop(result) {
    const { source, destination } = result;

    // if there's no destination, the item was dropped outside of the
    // droppable area
    if (!destination) {
      return;
    }

    // destination same as source, no-op
    if (destination.index === source.index) {
      return;
    }

    const newBom = [...bom];
    const savedSource = newBom.splice(source.index, 1)[0];
    newBom.splice(destination.index, 0, savedSource);
    setBom(newBom);
  }

  function clearLines() {
    const bom = new Array(numLinesToAdd).fill(EMPTY_LINE);
    setBom(bom);
  }

  return (
    <Dialog open={true} onClose={() => close()} maxWidth="xl" sx={styles}>
      <ModalHeader
        label={i18n("global.BillofMaterials")}
        onClose={() => close()}
        text={identifierText}
        save={saveAndClose}
      />
      {(!bom || isLoading) && <Loading />}
      <DialogContent>
        <FormErrors errors={errors} setErrors={setErrors} />
        <VSpace space={1} />
        {bom ? (
          <>
            <TableContainer
              sx={{ overflowX: "initial" }}
              data-testing="lineItems"
            >
              <Table
                sx={{ borderCollapse: "collapse" }}
                size="small"
                padding="none"
                stickyHeader
              >
                <TableHead>
                  <TableRow>
                    <DragHandleHeading />
                    <ClearLines setClearLines={clearLines} />
                    <LineColumnHeading labelCode="Line" />
                    <LineColumnHeading labelCode="Item" />
                    <LineColumnHeading labelCode="Description" />
                    <LineColumnHeading align="right" labelCode="Quantity" />
                    <LineColumnHeading labelCode="CostEach" />
                    <LineColumnHeading labelCode="Total" />
                    <LineColumnHeading labelCode="Notes" />
                  </TableRow>
                </TableHead>
                <DragDropContext onDragEnd={handleDrop}>
                  <Droppable droppableId="lineItems">
                    {(provided) => (
                      <TableBody
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {bom.map((bomItem, index, array) => (
                          <Draggable
                            key={index}
                            draggableId={`${index}`}
                            index={index}
                          >
                            {(draggableProvided, snapshot) => {
                              return (
                                <TableRow
                                  ref={draggableProvided.innerRef}
                                  {...draggableProvided.draggableProps}
                                  sx={{
                                    ...draggableProvided.draggableProps.style,
                                    position: "inherit",
                                    backgroundColor: snapshot.isDragging
                                      ? "dragBackground"
                                      : "none",
                                  }}
                                >
                                  <LineDragInsertCopy
                                    draggableProvided={draggableProvided}
                                    snapshot={snapshot}
                                    insertEmptyLine={() =>
                                      insertEmptyLine(index)
                                    }
                                    lineNumber={index + 1}
                                    onCopyDown={() => copyDown(bomItem, index)}
                                    showCopyDown={index + 1 !== array.length}
                                  />

                                  <BomRow
                                    key={index}
                                    bomItem={bomItem}
                                    items={items}
                                    dataIndex={index}
                                    handleFieldChange={handleFieldChange}
                                    handleItemChange={handleItemChange}
                                    deleteBomItem={deleteBomItem}
                                    itemsCount={itemsCount}
                                    isLoading={isLoading}
                                    expandItemGroup={expandItemGroup}
                                  />
                                </TableRow>
                              );
                            }}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </TableBody>
                    )}
                  </Droppable>
                </DragDropContext>
                <BomFooter
                  bom={bom}
                  addLines={addLines}
                  numLinesToAdd={numLinesToAdd}
                />
              </Table>
            </TableContainer>
          </>
        ) : (
          <FixedLoadingIndicator text={`${i18n("global.Loading")}...`} />
        )}
        <VSpace space={1} />
      </DialogContent>
    </Dialog>
  );
}

function BomRow(props) {
  const {
    handleFieldChange,
    handleItemChange,
    items,
    bomItem,
    itemsCount,
    dataIndex,
    deleteBomItem,
    expandItemGroup,
    isLoading,
  } = props;

  return (
    <>
      <LineTableCell sx={{ width: "2rem", textAlign: "center" }}>
        <IconButton size="small" onClick={() => deleteBomItem(dataIndex)}>
          <Delete />
        </IconButton>
      </LineTableCell>
      <LineTableCell sx={{ width: "3rem", textAlign: "center" }}>
        {dataIndex + 1}
      </LineTableCell>
      <LineTableCell sx={{ width: "12.0rem" }}>
        <>
          <RowItem
            dataIndex={dataIndex}
            itemsCount={itemsCount}
            items={items}
            onValueChange={handleItemChange}
            value={bomItem.componentItem}
            itemFormType={ITEM_QUICKLIST_FORM_TYPES.PRODUCTION}
          />
          {bomItem.typeOfItem === ITEM_TYPES.KIT && (
            <Button
              size="small"
              disabled={isLoading}
              onClick={() => expandItemGroup(dataIndex, bomItem)}
            >
              {i18n("global.Expand")}
            </Button>
          )}
        </>
      </LineTableCell>
      <LineTableCell
        sx={{ width: "20rem", backgroundColor: "staticTableCell" }}
      >
        <LineItemText value={bomItem.description || ""} />
      </LineTableCell>
      <LineTableCell sx={{ width: "5rem" }}>
        <RowDecimalField
          name="quantity"
          dataIndex={dataIndex}
          value={bomItem.quantity || ""}
          onValueBlur={handleFieldChange}
        />
      </LineTableCell>
      <LineTableCell sx={{ width: "6rem", backgroundColor: "staticTableCell" }}>
        <RowStaticMoney
          decimalPlaces={DEFAULT_DECIMALS_UNROUNDED}
          value={bomItem.cost || ""}
        />
      </LineTableCell>
      <LineTableCell sx={{ width: "6rem", backgroundColor: "staticTableCell" }}>
        <RowStaticMoney
          decimalPlaces={DEFAULT_DECIMALS_UNROUNDED}
          value={bomItem.total || ""}
        />
      </LineTableCell>
      <LineTableCell sx={{ width: "20rem" }}>
        <RowTextField
          label={i18n("global.Notes")}
          onValueBlur={handleFieldChange}
          value={bomItem.notes || ""}
          dataIndex={dataIndex}
          name="notes"
          resize
        />
      </LineTableCell>
    </>
  );
}

function BomFooter(props) {
  const { bom, addLines, numLinesToAdd } = props;
  return (
    <TableFooter>
      <TableRow>
        <LineTableFooterCell />
        <AddLines
          title={textForAddLineOrLines(numLinesToAdd)}
          onClick={addLines}
        />
        <LineTableFooterCell colSpan={3} />
        <LineTableFooterCell
          bordered
          sx={{ textAlign: "right", padding: "9.5px 14px" }}
        >
          {getDecimalTotal(bom, "quantity")}
        </LineTableFooterCell>
        <LineTableFooterCell />
        <LineTableFooterCell
          bordered
          sx={{ textAlign: "right", padding: "0 0.3rem" }}
        >
          {getMoneyTotal(bom, "total")}
        </LineTableFooterCell>
        <LineTableFooterCell />
      </TableRow>
    </TableFooter>
  );
}
