import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";

import { i18n } from "services/i18nService";

import { usePlans } from "hooks/usePlans";
import { useRelated } from "hooks/useRelated";

export function FrmWorkCenter(props) {
  const { value, onValueChange, maxWidth, error } = props;
  const { allowProFeatures } = usePlans();
  const workcenters = useRelated("workcenter");

  return allowProFeatures ? (
    <FrmSelectFromObjects
      name="workcenter"
      disableClearable
      label={i18n("frmLabel.WorkCenter")}
      value={value}
      onValueChange={onValueChange}
      maxWidth={maxWidth}
      options={workcenters}
      error={error}
      dataTesting="workCenter"
    />
  ) : null;
}
