import {
  amber,
  blue,
  green,
  grey,
  purple,
  lightBlue,
  lightGreen,
  orange,
  pink,
  red,
  teal,
  yellow,
  deepOrange,
  brown,
  blueGrey,
} from "@mui/material/colors";
import { createTheme, alpha, lighten } from "@mui/material/styles";

import { OBJECT_TYPES } from "appConstants";

// z-index scheme
const zIndexOrder = [
  "base",
  "insertLine",
  "stickyColumn",
  "stickyHeader",
  "stickyHeaderAndColumn",
  "leftNav",
  "leftNavFlyout",
  "mobileStepper",
  "topNavBar",
  "topNavBarOverLay",
  "speedDial",
  "appBar",
  "drawer",
  "dropDownMenu",
  "modal",
  "snackbar",
  "tooltip",
];
const zIndexes = {};
zIndexOrder.forEach((z, index) => {
  zIndexes[z] = index;
});

export const theme = createTheme({
  zIndex: zIndexes,
  palette: {
    primary: { main: blue[700] },
    secondary: { main: teal[500] },
    background: { default: grey[50], lineItems: alpha(teal[50], 0.25) },
    // unable to use MUI teal here since the lowest teal value is teal[50] and
    // changing the opacity here will break the zIndex UI for list page columns
    banding: lighten(teal[50], 0.6),
    exitConfiguration: alpha(teal[50], 0.25),
    warning: { main: orange[600], error: red[600] },
    disabled: grey[500],
    componentBackground: "white",
    primaryText: grey[800],
    accent: teal[700],
    modalHeader: grey[600],
    frmLabel: lightBlue[800],
    primaryLink: lightBlue[800],
    selectIcon: lightBlue[800],
    tooltipBackground: grey[200],
    dialogTitleBackground: grey[200],
    dashboard: {
      pendingEstimates: blue[500],
      openSos: blue[900],
      openSrs: teal[500],
      readyToShip: orange[500],
      readyToInvoice: purple[500],
      openPos: green[500],
      openWos: red[500],
      openRmas: blueGrey[500],
      lastSync: pink[500],
      txnsToSend: brown[500],
      showTxnsSent: lightGreen[500],
      showRentalsPastDue: deepOrange[500],
    },
    calendar: {
      dayBackground: grey[100],
      nonPrimaryMonthDays: grey[400],
      entry: {
        [OBJECT_TYPES.TASK.typeString]: {
          color: blue[500],
        },
        [OBJECT_TYPES.INVOICE.typeString]: {
          color: purple[500],
        },
        [OBJECT_TYPES.RMA.typeString]: {
          color: blueGrey[500],
        },
        [OBJECT_TYPES.PICK_TICKET.typeString]: {
          color: pink[500],
        },
        [OBJECT_TYPES.WORK_ORDER.typeString]: {
          color: red[500],
        },
        [OBJECT_TYPES.SALES_ORDER.typeString]: {
          color: blue[900],
        },
        [OBJECT_TYPES.LOT.typeString]: {
          color: lightGreen[500],
        },
        [OBJECT_TYPES.PURCHASE_ORDER.typeString]: {
          color: green[500],
        },
        [OBJECT_TYPES.SALES_RECEIPT.typeString]: {
          color: teal[500],
        },
        [OBJECT_TYPES.SHIPMENT.typeString]: {
          color: orange[500],
        },
        [OBJECT_TYPES.RENTAL.typeString]: {
          color: deepOrange[500],
        },
      },
    },
    tableHeaderFooter: grey[200],
    menuHighlight: grey[300],
    tableRowHover: grey[200],
    icons: grey[600],
    successGreen: teal[700],
    staticTableCell: alpha(teal[50], 0.095),
    staticTableCellText: alpha(grey[800], 0.38),
    txnFieldIcons: lightBlue[800],
    checkBoxSelected: blue[800],
    checkBoxUnselected: grey[400],
    lineSeparatorMajor: grey[600],
    lineSeparatorMinor: grey[300],
    lineItemBorder: grey[300],
    lineItemBackground: red[100],
    groupingBracket: grey[300],
    highlightedListRow: red[100],
    notificationBadge: red[700],
    favoritedHeart: red[700],
    barColorPrimary: blue[100],
    exceptionBackgroundColor: {
      MatchingNotSentToQBO: lightBlue[100],
      CouldNotFindMatchinginQBO: green[100],
      CostMismatchWithQBO: pink[100],
      NoMatchingTransaction: yellow[100],
    },
    rowColorCode: {
      archived: amber[500],
      pendingApproval: pink[500],
    },
    TextFieldButtonCombo: {
      buttonBackground: blue[50],
      buttonHover: blue[100],
      buttonText: blue[800],
      buttonOutline: grey[300],
    },
    ActionButton: {
      buttonHover: blue[100],
      buttonText: blue[800],
      buttonOutline: blue[800],
    },
    Chipper: {
      basic: {
        color: grey[700],
        backgroundColor: grey[300],
      },
      disabled: {
        color: grey[500],
        backgroundColor: grey[100],
      },
    },
    highlightedReportArea: pink[100],
    multiPanelBackground: grey[50],
    add: green["A700"],
    configurationSearch: {
      searchInputField: alpha(teal[50], 0.25),
      selectedItem: alpha(teal[50], 0.8),
      searchIcon: "white",
    },
  },
  // TODO: Revisit usage of number value for borderRadius
  // https://github.com/mui/material-ui/issues/35156#issuecomment-1323205900
  shape: { borderRadius: 6 },
  spacing: 10,
  cardHeader: {
    root: {
      backgroundColor: teal[400],
      textAlign: "center",
      height: "3rem",
    },
    title: { color: "white" },
  },
  typography: {
    fontSize: 14,
    fontFamily: ["Roboto, sans-serif"],
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    useNextVariants: true,
    h1: {
      fontSize: "4.25rem",
      color: teal[700],
    },
    h2: {
      fontSize: "2.625rem",
      color: teal[700],
    },
    h3: {
      fontSize: "1.625rem",
      color: grey[800],
    },
    h4: {
      fontSize: "1.3125rem",
      color: grey[800],
    },
    h5: {
      fontSize: "1.1rem",
      color: grey[800],
    },
    h6: {
      fontSize: "1.2rem",
      fontWeight: 700,
      color: lightBlue[800],
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: "1.0rem",
    },
    subtitle2: {
      fontWeight: 300,
      fontSize: "0.875rem",
    },
    // body2 is the default, for some reason
    body2: {
      color: grey[800],
      fontSize: "0.875rem",
    },
    body1: {
      color: grey[800],
      fontSize: "0.875rem",
    },
    button: {
      fontSize: "0.875rem",
      textTransform: "none",
    },
    tag: {
      fontSize: "0.875rem",
      textTransform: "capitalize",
      color: grey[400],
      fontWeight: 1000,
    },
    helperText: {
      fontSize: "0.75rem",
      color: grey[600],
    },
  },
  components: {
    MuiPickersToolbar: {
      styleOverrides: {
        toolbar: {
          backgroundColor: blue[900],
        },
      },
    },
    MuiPickerDTTabs: {
      styleOverrides: {
        tabs: {
          backgroundColor: blue[900],
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        daySelected: {
          backgroundColor: blue[900],
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: "1.2rem",
        },
      },
    },
    PrivateTabIndicator: {
      styleOverrides: {
        colorSecondary: {
          backgroundColor: blue[200],
        },
      },
    },
    MuiPickersClock: {
      styleOverrides: {
        pin: {
          backgroundColor: blue[900],
        },
      },
    },
    MuiPickersClockPointer: {
      styleOverrides: {
        pointer: {
          backgroundColor: blue[900],
        },
        noPoint: {
          backgroundColor: blue[900],
        },
        thumb: {
          backgroundColor: blue[900],
          borderColor: blue[900],
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: { colorSecondary: { color: teal[500] } },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          backgroundColor: blue[700],
          "&:hover": {
            backgroundColor: alpha(blue[700], 0.75),
          },
        },
        outlinedPrimary: {
          color: lightBlue[800],
          border: "1px solid " + lightBlue[800],
          "&:hover": {
            backgroundColor: alpha(lightBlue[800], 0.15),
          },
        },
        textSecondary: {
          "&:hover": {
            backgroundColor: alpha(teal[500], 0.05),
          },
          textTransform: "uppercase",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: grey[800],
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          whiteSpace: "normal",
        },
      },
    },

    MuiTextField: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: {
          // hides arrow up and arrow down on number type MUI TextFields
          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
            {
              display: "none",
            },
          "& input[type=number]": {
            MozAppearance: "textfield",
          },
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          color: grey[800],
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: lightBlue[800],
          "&$focused": {
            color: lightBlue[800],
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: { root: { padding: "8px 24px" } },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: grey[800],
          fontSize: "1.625rem",
          lineHeight: 1.167,
          fontWeight: 400,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: grey[300],
        },
      },
    },
    MuiTableCell: {
      defaultProps: {
        padding: "none",
      },
      styleOverrides: {
        stickyHeader: {
          backgroundColor: "white",
          lineHeight: "inherit",
          zIndex: zIndexes.stickyHeader,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          // Controls default (unchecked) color for the thumb
          color: blue["A200"],
        },
        colorSecondary: {
          "&$checked": {
            // Controls checked color for the thumb
            color: blue["A200"],
          },
        },
        track: {
          // Controls default (unchecked) color for the track
          opacity: 0.2,
          backgroundColor: blue["A200"],
          "$checked$checked + &": {
            // Controls checked color for the track
            opacity: 0.2,
            backgroundColor: blue["A200"],
          },
        },
      },
    },
  },
});
