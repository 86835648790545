import { LOCALIZATION } from "services/utility/localization";

import { format, parse, parseISO } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

/**
 * @name    formatDate
 *
 * @summary get a localized date string from a Date value
 *
 * @param   date (Date)
 *
 * @returns a localized string with just the date portion of the input
 *          value, and which takes into account the user's date setting
 */
export function formatDate(date) {
  if (!date) {
    return "";
  }

  if (!(date instanceof Date)) {
    throw new Error(`Invalid date value: ${date}`);
  }
  const { dateFormat } = LOCALIZATION;
  return format(date, dateFormat);
}

export function removeDateSeconds(date) {
  date.setSeconds("00");
  return date;
}

export function formatZonelessDateTime(date) {
  const { dateFormat } = LOCALIZATION;
  if (!(date instanceof Date)) {
    throw new Error(`Invalid date value: ${date}`);
  }
  return format(date, dateFormat + " h:mm:ssaaa");
}

// input is ISO date/time format string, which is what SOS Inventory uses
// internally, with no time zone indicator, and assumed to be in UTC time
// (offset zero); returns a Date object in the user's local time
export function isoToLocalDateTime(isoString) {
  // append the "Z" (for UTC) to make it valid ISO 8601
  return isoString ? parseISO(isoString + "Z") : null;
}

// input is ISO date/time format string, which is what SOS Inventory uses
// internally, with no time zone indicator; in this case (as contrasted
// with isoToLocalDateTime, above)
export function isoToDateTime(isoString) {
  return new Date(isoString);
}

export function dateToSosISODate(dateObject) {
  // returns just the date, in format 2024-01-05
  return dateObject.toISOString().slice(0, -14);
}

export function dateToSosISODateTime(dateObject) {
  // the slice removes the "Z" time zone indicator (which our back end
  // assumes) and the decimal places on the seconds, which we don't care
  // about, and returns format 2024-01-05T05:15:47
  return dateObject.toISOString().slice(0, -5);
}

export function dateToLocalSosISO(dateObject) {
  // returns ISO-style string, but without timezone conversion
  return format(dateObject, "yyyy-MM-dd'T'HH':'mm':'ssx").slice(0, 19);
}

export function yyyymmddToDate(dateString) {
  const string = dateString.includes("T") // strip out time values
    ? dateString.split("T")[0]
    : dateString;
  return parse(string, "yyyy-MM-dd", new Date());
}

export function datefromDateFormat(dateString, dateFormat) {
  return parse(dateString, dateFormat, new Date());
}

// userTimeZone should be as formatted in SOS user settings (e.g., "UTC-05:00");
// returns the new time-zone-aware date
export function timeZoneAwareDateTime(date, userTimeZone) {
  if (userTimeZone === "device") {
    return date;
  } else {
    const timeZoneOffset = userTimeZone.substr(3, 6);
    return utcToZonedTime(date, timeZoneOffset);
  }
}

// this reverses the calculation done in timeZoneAwareDateTime,
// to get back to a UTC date/time
export function utcDateTime(timeZoneAwareDateTime, userTimeZone) {
  if (userTimeZone === "device") {
    return timeZoneAwareDateTime;
  } else {
    const timeZoneOffset = userTimeZone.substr(3, 6);
    return zonedTimeToUtc(timeZoneAwareDateTime, timeZoneOffset);
  }
}

// for system use only; this is not localized, so don't use it in the UI
export function calendarFormat(date) {
  return format(date, "yyyy-MM-dd");
}
