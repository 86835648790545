import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Box } from "@mui/material";

import { Layout } from "Layout";

import { NEW_RECORD_SKELETON } from "appConfig";

import { EditModalHeader } from "components/EditModalHeader";
import { FrmFieldGrid } from "components/FrmFieldGrid";
import { ObjectEditDialogContent } from "components/ObjectEditDialogContent";
import { FrmTextField } from "components/formFields/FrmTextField";
import { FixedLoadingIndicator } from "components/utility/FixedLoadingIndicator";
import { FormErrors } from "components/utility/FormErrors";
import { Loading } from "components/utility/Loading";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import { getRecord } from "services/sosInventoryService/sosApi";

import { useErrors } from "hooks/useErrors";
import { useRecord } from "hooks/useRecord";

import {
  editModalLoadingIndicatorOn,
  editModalLoadingIndicatorOff,
} from "globalState/loadingSlice";

export function Uom(props) {
  const { objectType, id, onClose } = props;
  const [record, setRecord, handleInputFieldChange] = useRecord();
  const { errors, setErrors, isInError } = useErrors();

  const dispatch = useDispatch();

  const loadingState = useSelector((state) => state.loading.editModal);

  useEffect(() => {
    async function _getRecord() {
      if (id) {
        dispatch(editModalLoadingIndicatorOn());
        const response = await getRecord(objectType, id);
        setRecord(response);
        dispatch(editModalLoadingIndicatorOff());
      } else {
        const emptyRecord = NEW_RECORD_SKELETON[objectType]();
        setRecord(emptyRecord);
      }
    }
    _getRecord();
  }, [id, dispatch, setRecord, objectType]);

  return (
    <>
      <EditModalHeader
        record={record}
        setRecord={setRecord}
        objectType={objectType}
        setErrors={setErrors}
        text={record?.name || ""}
        handleClose={onClose}
        hideDocuments={true}
      />

      {loadingState && <Loading />}

      {record && (
        <ObjectEditDialogContent>
          <Layout
            pageTitle={
              id
                ? `${i18n(`objectType.${objectType}.Sentence`)} ${
                    record?.name || ""
                  }`
                : i18n(`objectType.${objectType}.New`)
            }
          >
            <Box flexGrow="1" position="relative" m={2} mb={0}>
              <FormErrors errors={errors} setErrors={setErrors} />
              {record ? (
                <Box>
                  <FrmFieldGrid gridAutoFlow="dense">
                    <div style={{ gridColumn: "1 / 2", maxWidth: "24rem" }}>
                      <FrmTextField
                        name="name"
                        value={record.name}
                        label={i18n("frmLabel.Name")}
                        onValueBlur={handleInputFieldChange}
                        error={isInError("name")}
                      />
                    </div>
                    <div style={{ gridColumn: "1 / 2", maxWidth: "8rem" }}>
                      <FrmTextField
                        name="abbreviation"
                        maxLength={5}
                        value={record.abbreviation}
                        label={i18n("frmLabel.Abbreviation")}
                        onValueBlur={handleInputFieldChange}
                        error={isInError("abbreviation")}
                      />
                    </div>
                  </FrmFieldGrid>
                  <VSpace space={1} />
                  <FrmFieldGrid gridAutoFlow="dense"></FrmFieldGrid>
                </Box>
              ) : (
                !isInError("record") && (
                  <FixedLoadingIndicator
                    text={`${i18n("global.Loading")}...`}
                  />
                )
              )}
            </Box>
            <VSpace space={4} />
          </Layout>
        </ObjectEditDialogContent>
      )}
    </>
  );
}
