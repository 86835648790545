import { TableCell } from "@mui/material";

import { theme } from "SosTheme";

const borderString = `1px solid ${theme.palette.lineSeparatorMinor}`;

export function LineTableCell(props) {
  const { sx, colSpan, children } = props;

  return (
    <TableCell
      colSpan={colSpan}
      padding="none"
      sx={{
        borderRight: borderString,
        backgroundColor: "background.lineItems",
        ...sx,
      }}
    >
      {children}
    </TableCell>
  );
}

// used when the fields below are text fields to better align heading.
// It shouldn't be used for non-field columns
export function LineTableHeadingCell(props) {
  const {
    onClick,
    sx,
    children,
    align,
    alignItems,
    simpleCenteredContents = false,
  } = props;
  return (
    <TableCell
      onClick={onClick}
      align={align}
      sx={{
        padding: "0.8rem 1rem 0.2rem 0.8rem",
        top: "-9px",
        left: "auto",
        ...sx,
      }}
    >
      <div
        style={
          simpleCenteredContents
            ? {}
            : {
                display: align === "right" ? "" : "flex",
                flexWrap: "nowrap",
                justifyContent: "space-between",
                alignItems: alignItems || "center",
              }
        }
      >
        {children}
      </div>
    </TableCell>
  );
}

export function LineTableFooterCell(props) {
  const { sx, colSpan, children, bordered } = props;

  return (
    <TableCell
      colSpan={colSpan}
      align="right"
      sx={{
        fontSize: "0.8125rem",
        color: children ? "staticTableCellText" : "inherit",
        backgroundColor: children ? "staticTableCell" : "inherit",
        borderRight: bordered ? borderString : "",
        borderLeft: bordered ? borderString : "",
        borderBottom: children ? borderString : 0,
        height: "2.4rem",
        ...sx,
      }}
    >
      {children}
    </TableCell>
  );
}
